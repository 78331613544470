import React from 'react';
import {
  BaseOwner,
  BasePlanOwnerType,
  BaseCustomerPlan,
  BaseOrder,
  BasePayment,
  BaseTransaction,
  BaseSeat,
  BaseCustomer,
  BasePlanView,
  BaseOrganization,
  CustomerRole,
  Maybe,
  OrganizationMemberRole,
  CustomerPlanTypeForRelatedPlans,
} from '~/types';
import { Role, MemberStatus, RedeemCodeStatus } from './constants';

export interface CustomerOwnerInterface extends BaseOwner {}

export interface OrganizationOwnerInterface extends Omit<BaseOwner, 'email'> {}

export type OwnerInterface = CustomerOwnerInterface | OrganizationOwnerInterface;

export interface PaymentInterface extends BasePayment {}

export interface OrderInterface extends BaseOrder {
  paddleProductId: number;
  paddleSubscriptionId?: number;
}

export interface PlanLicense {
  id: string;
  customerId: string;
  productBundleId: string;
  customerProductPlanId: string;
  licenseKey: string;
  firstActivatedAt: Maybe<number>;
  isEnabled: boolean;
  disabledAt: Maybe<number>;
  additional: Maybe<string>;
  campaign: Maybe<string>;
}

export interface PlanViewInterface extends BasePlanView {}

export interface CommonPlanPlanInterface extends Omit<BaseCustomerPlan, 'ownerType'> {
  planView: PlanViewInterface;
  planLicense: Maybe<PlanLicense>;
  order?: OrderInterface;
}

export interface CustomerPlanRelatedPlan {
  planId: string;
  ownerId: string;
  productName: string;
  productPublicName: string;
  productPlanIconUrl: string;
  productPlanName: string;
}

export interface CustomerPlanRelatedPlans {
  planType: CustomerPlanTypeForRelatedPlans;
  plans: CustomerPlanRelatedPlan[];
}
export interface CustomerPlanInterface extends CommonPlanPlanInterface {
  ownerType: BasePlanOwnerType.Customer;
  owner?: CustomerOwnerInterface;
  mainLicenceId?: string;
  relatedPlans: CustomerPlanRelatedPlans;
}

export interface OrganizationPlanInterface extends CommonPlanPlanInterface {
  ownerType: BasePlanOwnerType.Organization;
  owner?: OrganizationOwnerInterface;
  seatCount: number;
  sagaStateId: Maybe<string>;
  sagaStateStatus: Maybe<string>;
}

export type PlanInterface = CustomerPlanInterface | OrganizationPlanInterface;

export interface TransactionInterface extends BaseTransaction {
  externalOrderId?: string;
}

export interface SeatInterface extends BaseSeat {
  owner: OwnerInterface;
}

export interface CustomerInterface extends BaseCustomer {
  source: string;
  createdAt: number;
  isCompleted: boolean;
  roles?: CustomerRole[];
}

export interface SupportProfileInterface {
  id: string;
  email: string;
  name: string;
  isCompleted: boolean;
  roles: [Role];
  status: MemberStatus;
}

export type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;

export type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>;

export type FieldLocalValidationError = string | React.ReactNode[] | React.ReactElement;
export interface ServerErrorTypeInterface {
  code: string;
  path: string;
}

export interface AuthSuccessInterface {
  signInPinCode: {
    token: string;
  };
}

export interface ChangePasswordInterface {
  changePassword: {
    token: string;
  };
}

export interface RedeemCodeInterface {
  code: string;
  status: RedeemCodeStatus;
  customerProductPlanId: string;
}

export interface CustomerPlanActionsConfig {
  resendActivation: boolean;
  refund: boolean;
  pause: boolean;
  cancel: boolean;
  block: boolean;
  reducePlanSize: boolean;
}

export interface ListQueryInput {
  page: number;
  limit: number;
  search?: string;
  strict?: boolean;
}

export interface OrganizationInterface extends BaseOrganization {
  ownerEmail: string;
  createdAt: number;
  isCmmBusinessDashboardBeta: boolean;
}

export interface LicenseInterface {
  customerId: string;
  productBundleId: string;
  customerProductPlanId: string;
  licenseKey: string;
  firstActivatedAt: number;
  isEnabled: boolean;
}

export interface RedeemCodeInfoInterface {
  code: string;
  status: string;
  customerProductPlanId: string;
  customerId: string;
}

export enum DropdownItemKey {
  Redeem = 'REDEEM',
  License = 'LICENSE',
}

export interface DropdownItemInterface {
  name: string;
  key: DropdownItemKey;
}

export type GQLResponse<Key extends string, ResponseType extends ObjectLiteral | string> = Record<Key, ResponseType>;

export interface ApolloInput<T> {
  input: T;
}

export interface CreateCustomerData {
  email: string;
  customerId: string;
}

export interface CreateOrganizationData extends Omit<BaseOrganization, 'id' | 'country' | 'state'> {
  ownerEmail: string;
  country: string;
  state?: string;
}

export interface OrganizationOwner
  extends Pick<CustomerInterface, 'id' | 'name' | 'email' | 'emailConfirmed' | 'isCompleted'> {
  role: OrganizationMemberRole;
}

export type ConfirmationModalType = 'info' | 'warning' | 'error' | 'success' | 'warning-info';

export enum SearchFormField {
  Search = 'search',
}

import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { ApolloInput } from '../../interfaces';
import { ORGANIZATION_SLOT_PRICE_QUERY } from '../queries/organizationSlotPriceQuery';

export const SET_ORGANIZATION_CUSTOM_SLOT_PRICE_MUTATION = gql`
  mutation setOrganizationCustomSlotPrice($input: SetOrganizationCustomPriceInput!) {
    setOrganizationCustomSlotPrice(input: $input)
  }
`;

interface SetOrganizationCustomSlotPriceInput {
  organizationId: string;
  productPlanSkuId: string;
  price: number;
}

interface UseSetOrganizationCustomSlotPriceMutation {
  setOrganizationCustomSlotPrice: (input: SetOrganizationCustomSlotPriceInput) => Promise<void>;
  loading: boolean;
}

export const useSetOrganizationCustomSlotPriceMutation = (): UseSetOrganizationCustomSlotPriceMutation => {
  const [mutate, { loading }] = useMutation<void, ApolloInput<SetOrganizationCustomSlotPriceInput>>(
    SET_ORGANIZATION_CUSTOM_SLOT_PRICE_MUTATION,
  );

  const setOrganizationCustomSlotPrice = async (input: SetOrganizationCustomSlotPriceInput) => {
    try {
      await mutate({
        variables: { input },
        refetchQueries: [
          {
            query: ORGANIZATION_SLOT_PRICE_QUERY,
            variables: {
              organizationId: input.organizationId,
              productPlanSkuId: input.productPlanSkuId,
            },
          },
        ],
      });
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);
    }
  };

  return {
    setOrganizationCustomSlotPrice,
    loading,
  };
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SuccessModal } from '~/ui';
import { CreateCustomerFields } from '../../../constants';

interface CreateCustomerSuccessProps {
  onSubmit: () => void;
}

const CreateCustomerSuccess: React.FC<CreateCustomerSuccessProps> = ({ onSubmit }) => {
  const { getValues } = useFormContext();

  const email = getValues(CreateCustomerFields.Email);

  return (
    <SuccessModal
      title="Creating customer account"
      description={`To claim this account, the customer has to follow the link in the invitation email sent to ${email}`}
      onSubmit={onSubmit}
    />
  );
};

export default CreateCustomerSuccess;

import { BaseCustomerPlan, CustomerPlanStatus } from '~/types';
import { MS_IN_S } from './constants';

export default function untilDeactivation(customerPlan: BaseCustomerPlan) {
  const secondsInDay = 86400;
  const fromDate = Math.floor(Date.now() / MS_IN_S);
  const toDate = customerPlan.dueDate!; // is unix timestamp
  let untilDeactivationSeconds = toDate - fromDate;

  if (customerPlan.status !== CustomerPlanStatus.Trialing) {
    const gracePeriodSeconds = customerPlan.planView.productPlanGracePeriod! * secondsInDay;

    untilDeactivationSeconds += gracePeriodSeconds;
  }

  if (untilDeactivationSeconds < 0) return 0;

  return Math.ceil(untilDeactivationSeconds / secondsInDay);
}

import { formatDistanceToNowStrict } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { MS_IN_DAY } from './constants';
import { DateDistanceUnit, OptionsType } from './distanceToNow';

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function distanceToNowSync(
  days: number,
  config?: {
    unit?: DateDistanceUnit;
    disableCapitalize?: boolean;
  },
) {
  const options: OptionsType = {
    roundingMethod: 'floor',
    locale: enUS,
  };

  if (config?.unit) {
    options.unit = config.unit;
  }

  let value = formatDistanceToNowStrict(new Date(new Date().getTime() + days * MS_IN_DAY), options);

  if (!config?.disableCapitalize) {
    value = value
      .split(' ')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ');
  }

  return value;
}

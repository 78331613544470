import React from 'react';
import { ExternalLink, GridCell, GridRow, Label } from '@macpaw/macpaw-ui';
import { ExternalIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { ActivationStatus } from '~/types';
import { BillingCycle, DynamicDate } from '~/ui';
import { conditionValue, formatDate } from '~/utils';
import PlanStatusLabel from '../../components/PlanStatusLabel/PlanStatusLabel';
import { getPaddleSubscriptionLink } from '../../helpers/getPaddleSubscriptionLink';
import { isTrial } from '../../helpers/plan';
import { OrderInterface, CustomerPlanInterface } from '../../interfaces';
import styles from './CustomerPlan.module.sass';
import CustomerPlanGridLicense from './CustomerPlanGridLicense';

interface CustomerPlanGridContent {
  customerPlan: CustomerPlanInterface;
  order?: OrderInterface;
}

const CustomerPlanGridContent: React.FC<CustomerPlanGridContent> = ({ customerPlan, order }) => {
  const paddleProductId = order && order.paddleProductId;
  const paddleSubscriptionId = order && order.paddleSubscriptionId;
  let paddleSubscriptionLink = null;

  if (paddleSubscriptionId) {
    paddleSubscriptionLink = getPaddleSubscriptionLink(paddleSubscriptionId);
  }

  const { productName } = customerPlan.planView;

  return (
    <>
      <GridRow>
        <GridCell type="primary">
          <Label>Product</Label>
          <b>{productName}</b>
        </GridCell>
        <GridCell type="primary">
          <Label>Plan Name</Label>
          {customerPlan.planView.productPlanName}
        </GridCell>
        <GridCell type="secondary">
          <Label>{isTrial(customerPlan) ? 'Trial Started' : 'Purchase Date'}</Label>
          {formatDate(customerPlan.startDate)}
        </GridCell>
        <GridCell type="secondary">
          <DynamicDate customerPlan={customerPlan} order={customerPlan.order} />
        </GridCell>
      </GridRow>

      <GridRow>
        <GridCell type="primary">
          <Label>Owner</Label>
          <b>{conditionValue(customerPlan.owner!.name)}</b>
        </GridCell>
        <GridCell type="primary">
          <Label>Email</Label>
          {customerPlan?.owner?.email ?? ''}
        </GridCell>
        <GridCell type="secondary">
          <Label>Can Access</Label>
          {customerPlan.activationStatus === ActivationStatus.CanAccess ? 'Yes' : 'No'}
        </GridCell>
        <GridCell type="secondary">
          <Label>Billing Cycle</Label>
          <BillingCycle customerPlan={customerPlan} />
        </GridCell>
      </GridRow>

      <GridRow>
        <GridCell type="primary">
          <Label>Subscription ID</Label>
          {conditionValue(
            paddleSubscriptionLink && (
              <div className={styles.withInlineIcon}>
                {paddleSubscriptionId}
                <ExternalLink href={paddleSubscriptionLink!}>
                  <ExternalIcon className={styles.inlineIcon} />
                </ExternalLink>
              </div>
            ),
          )}
        </GridCell>
        <GridCell type="primary">
          <Label>Product ID</Label>
          {conditionValue(paddleProductId)}
        </GridCell>
        <GridCell type="secondary">
          <Label>Status</Label>
          <PlanStatusLabel plan={customerPlan} />
        </GridCell>
        <GridCell type="secondary">
          <Label>Reason</Label>
          {conditionValue(
            customerPlan.reason && <span style={{ textTransform: 'capitalize' }}>{customerPlan.reason}</span>,
          )}
        </GridCell>
      </GridRow>
      <CustomerPlanGridLicense planLicense={customerPlan.planLicense} />
    </>
  );
};

export default CustomerPlanGridContent;

import React from 'react';
import { Button, Dropdown, DropdownItem } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { useModals } from '~/ui';
import { Modals } from '../../constants';
import { canBeBlocked, getIsSagaInProgress } from '../../helpers/plan';
import { CustomerPlanInterface, CustomerPlanActionsConfig } from '../../interfaces';

interface CustomerPlanManageMenu {
  actions: CustomerPlanActionsConfig;
  customerPlan: CustomerPlanInterface;
  onCompleteAction: () => void;
}

const CustomerPlanManageMenu: React.FC<CustomerPlanManageMenu> = ({ actions, customerPlan, onCompleteAction }) => {
  const { openModal, closeModal } = useModals();
  const isSagaInProgress = getIsSagaInProgress(customerPlan);

  if (Object.values(actions).filter((v) => v).length === 0) {
    return (
      <Button color="transparent" icon disabled>
        <MoreIcon />
      </Button>
    );
  }

  const handleOpenActionModal = (modalName: Modals) => () => {
    openModal({
      name: modalName,
      props: {
        customerPlan,
        onComplete: onCompleteAction,
      },
    });
  };

  const handleBlockPlan = () => {
    const isAvailableToBlock = canBeBlocked(customerPlan);

    if (isAvailableToBlock) {
      handleOpenActionModal(Modals.PlanBlockModal)();

      return;
    }

    openModal({
      name: Modals.ConfirmationModal,
      props: {
        type: 'warning',
        title: 'Plan Cannot Be Blocked',
        description:
          'In order to block a subscription plan, the plan must first be cancelled so that the customer is no longer charged for access they cannot receive. Please cancel the plan and try again.',
        actionTitle: 'Got It',
        onSubmit: () => closeModal(Modals.ConfirmationModal),
      },
    });
  };

  return (
    <Dropdown
      position="right"
      trigger={
        <Button color="transparent" icon>
          <MoreIcon />
        </Button>
      }>
      {actions.resendActivation && (
        <>
          <DropdownItem onClick={handleOpenActionModal(Modals.PlanResendActivationModal)}>
            Resend Activation
          </DropdownItem>
          <DropdownItem separator />
        </>
      )}
      {actions.refund && (
        <>
          <DropdownItem onClick={handleOpenActionModal(Modals.PlanTransactionRefundModal)}>Refund</DropdownItem>
          <DropdownItem separator />
        </>
      )}
      {actions.reducePlanSize && (
        <>
          <DropdownItem onClick={handleOpenActionModal(Modals.ReducePlanSizeModal)} disabled={isSagaInProgress}>
            Reduce Plan Size
          </DropdownItem>
          <DropdownItem separator />
        </>
      )}
      {actions.pause && <DropdownItem onClick={handleOpenActionModal(Modals.PlanPauseModal)}>Pause Plan</DropdownItem>}
      {actions.cancel && (
        <DropdownItem attention onClick={handleOpenActionModal(Modals.PlanCancelModal)}>
          Cancel Plan
        </DropdownItem>
      )}
      {actions.block && (
        <DropdownItem attention onClick={handleBlockPlan}>
          Block Plan
        </DropdownItem>
      )}
    </Dropdown>
  );
};

export default CustomerPlanManageMenu;

import React, { ReactElement } from 'react';
import { Banner } from '@macpaw/macpaw-ui';
import { ActivationStatus, CustomerPlanStatus } from '~/types';
import { formatDate, untilDeactivation } from '~/utils';
import { PlanInterface } from '../../interfaces';
import { UnpausePlanAction } from '../UnpausePlanAction/UnpausePlanAction';

export type CustomerPlanNotificationsConfig = {
  [key: string]: (customerPlan: PlanInterface, onStatusChanged: () => void) => ReactElement;
};

export const CUSTOMER_PLAN_NOTIFICATIONS: CustomerPlanNotificationsConfig = {
  [CustomerPlanStatus.PastDue]: () => (
    <Banner>
      <b>Payment due</b>.
    </Banner>
  ),
  [CustomerPlanStatus.FraudCheck]: () => (
    <Banner type="readonly">
      For security reasons, this payment has to undergo a fraud-check. We’ll be done soon, so please check back later.
    </Banner>
  ),
  [CustomerPlanStatus.DisputeOpened]: () => (
    <Banner type="readonly">
      <b>This plan has an open payment dispute.</b> It has been placed into a grace period until resolution of the
      dispute.
    </Banner>
  ),
  [CustomerPlanStatus.Paused]: (customerPlan, onStatusChanged) => {
    const { productName } = customerPlan.planView;
    const action = <UnpausePlanAction customerPlan={customerPlan} onStatusChanged={onStatusChanged} />;

    if (customerPlan.activationStatus === ActivationStatus.CanAccess) {
      return (
        <Banner action={action}>
          <b>Payments for this plan have been paused</b>. Please unpause them to continue using {productName}. Your app
          will remain active for {untilDeactivation(customerPlan)} days.
        </Banner>
      );
    }

    return (
      <Banner action={action}>
        <b>Payments for this plan have been paused.</b> Please unpause them to continue using {productName}. Access to
        the app has currently been revoked.
      </Banner>
    );
  },
  [CustomerPlanStatus.CancelRequested]: (customerPlan: PlanInterface) => (
    <Banner type="readonly">
      This Plan has been canceled and <b>will be deactivated {formatDate(customerPlan.dueDate!)}</b>
    </Banner>
  ),
  [CustomerPlanStatus.Blocked]: () => (
    <Banner type="readonly">
      <b>This plan has been blocked & currently inactive.</b>
    </Banner>
  ),
  [CustomerPlanStatus.PaymentDisputeBlocked]: () => (
    <Banner type="readonly">
      <b>This plan has been blocked & currently inactive.</b>
    </Banner>
  ),
  [CustomerPlanStatus.HighRiskTransactionBlocked]: () => (
    <Banner type="readonly">
      <b>This plan has been blocked & currently inactive.</b>
    </Banner>
  ),
};

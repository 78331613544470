import React from 'react';
import { Button, FormRow } from '@macpaw/macpaw-ui';
import { FormPinInput } from '~/ui';
import { AuthInputType, LENGTH_PINCODE } from '../../../constants';
import './FormPin.sass';

interface FormPin {
  resendTime: number;
  isResendAgainShow: boolean;
  handleResendPin: (event: React.SyntheticEvent) => void;
  isLoading: boolean;
  handleSubmit: () => void;
}

const FormPin: React.FC<FormPin> = ({
  isLoading = false,
  handleSubmit,
  resendTime = 0,
  isResendAgainShow,
  handleResendPin,
}) => {
  return (
    <>
      <FormRow>
        <FormPinInput
          name={AuthInputType.Pin}
          length={LENGTH_PINCODE}
          formatErrorMessage={(msg: string) => msg}
          autoFocus
          onComplete={handleSubmit}
        />
        <div className="pin-resend p4">
          {resendTime > 0 && (
            <>
              You can request another code in <strong className="pin-resend-time">{resendTime} seconds.</strong>
            </>
          )}
          {resendTime === 0 && isResendAgainShow && (
            <>
              Haven’t received verification code yet?{' '}
              <Button asLink onClick={handleResendPin} className="pin-resend-button">
                Resend Code
              </Button>
            </>
          )}
        </div>
      </FormRow>
      <Button wide loading={isLoading} color="secondary" onClick={handleSubmit}>
        Login
      </Button>
    </>
  );
};

export default FormPin;

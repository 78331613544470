import React from 'react';
import { FormSelect } from '~/ui';
import { getActivationPeriod } from '../../helpers/plan';
import { PlanViewInterface } from '../../interfaces';

interface GivePlanProductPlanSelectProps {
  name: string;
  currentProductId: string;
  planViews: PlanViewInterface[];
  label?: string;
  disabled?: boolean;
  placeholder?: string;
}

const GivePlanProductPlanSelect: React.FC<GivePlanProductPlanSelectProps> = ({
  name,
  currentProductId,
  planViews,
  label = 'Select Product Plan',
  disabled,
  placeholder = 'Select Product Plan',
}) => {
  return (
    <FormSelect name={name} label={label} disabled={disabled} validateOnChange>
      <option value="" disabled>
        {placeholder}
      </option>
      {planViews
        .filter((p) => p.productId === currentProductId)
        .map((planView) => (
          <option key={planView.productPlanId} value={planView.productPlanId}>
            {`${planView.productPlanName} - ${getActivationPeriod(planView)}`}
          </option>
        ))}
    </FormSelect>
  );
};

export default GivePlanProductPlanSelect;

import { useState, useEffect, useRef } from 'react';

export type UnknownFunction = () => Promise<ObjectLiteral>;

export default function useRefresh(refetch: UnknownFunction): [boolean, UnknownFunction] {
  const fn = useRef(refetch);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    fn.current = refetch;
  }, [refetch]);

  const refresh = () => {
    if (!fn.current) return Promise.resolve({});

    setIsRefreshing(true);

    return fn.current().then((response: unknown) => {
      setIsRefreshing(false);

      return response as ObjectLiteral;
    });
  };

  return [isRefreshing, refresh];
}

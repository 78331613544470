import React, { FormEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, DialogActions, DialogContent, DialogIcon } from '@macpaw/macpaw-ui';
import { StepItemProps } from '~/ui';
import { PlanReduceSizeInputTypes, ReduceSizeSteps } from '../../../constants';
import { useReducePlanSizeMutation } from '../../../graphql/mutations';
import AttentionIcon from '../../../images/icons/attention.svg?react';
import { OrganizationPlanInterface } from '../../../interfaces';

interface ReducePlanSizeWarningStepProps extends Partial<StepItemProps> {
  customerPlan: OrganizationPlanInterface;
  onComplete?: () => void;
}

const ReducePlanSizeWarningStep: React.FC<ReducePlanSizeWarningStepProps> = ({
  onComplete,
  customerPlan,
  ...props
}) => {
  const { reducePlanSize, loading } = useReducePlanSizeMutation();
  const { setStep } = props as StepItemProps;
  const { watch } = useFormContext();

  const reduceCount = watch(PlanReduceSizeInputTypes.ReduceCount);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await reducePlanSize(customerPlan, Number(reduceCount));
      setStep(ReduceSizeSteps.Success);
      onComplete?.();
    } catch (e) {
      // silence...
    }
  };

  return (
    <>
      <DialogContent>
        <DialogIcon>
          <AttentionIcon width={128} height={128} />
        </DialogIcon>
        <h4>Remove {reduceCount} slots from this plan?</h4>
        <p>This change will decrease the plan’s billing amount.</p>
      </DialogContent>
      <DialogActions>
        <Button color="primary" outline onClick={() => setStep(ReduceSizeSteps.Reduce)}>
          Back
        </Button>
        <Button color="secondary" onClick={handleSubmit} loading={loading}>
          Remove
        </Button>
      </DialogActions>
    </>
  );
};

export default ReducePlanSizeWarningStep;

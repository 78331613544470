import React from 'react';
import { ConfirmationType } from '~/types';
import { useModals } from '../../ModalLauncher';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

interface SuccessModalBasicProps {
  title: string;
  description: string | React.ReactElement;
  actionTitle?: string;
  dataHook?: string;
}

interface PropsWithModalName extends SuccessModalBasicProps {
  modalName: string;
}

interface PropsWithSubmitFunc extends SuccessModalBasicProps {
  onSubmit: () => void;
}

interface PropsWithActions extends SuccessModalBasicProps {
  actions: React.ReactElement;
}

type SuccessModalProps = PropsWithModalName | PropsWithSubmitFunc | PropsWithActions;

const SuccessModal: React.FC<SuccessModalProps> = (props) => {
  const { closeModal } = useModals();

  const submit: {
    onSubmit?: () => void;
  } = {};

  if (!(props as PropsWithActions)?.actions) {
    submit.onSubmit =
      (props as PropsWithSubmitFunc)?.onSubmit || (() => closeModal((props as PropsWithModalName).modalName as string));
  }

  return <ConfirmationModal type={ConfirmationType.Success} {...props} {...submit} />;
};

export default SuccessModal;

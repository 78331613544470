import { gql, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';

export const DEACTIVATE_MEMBER_MUTATION = gql`
  mutation DeactivateMember($userId: ID!) {
    deactivateMember(userId: $userId)
  }
`;

interface MemberDeactivateMutationVariables {
  userId: string;
}

interface UseMemberDeactivateMutationInput {
  onComplete?: () => Promise<void> | void;
  onError?: (error: GraphQLError) => void;
}

export const useMemberDeactivateMutation = ({ onComplete, onError }: UseMemberDeactivateMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<void, MemberDeactivateMutationVariables>(DEACTIVATE_MEMBER_MUTATION);

  const deactivateMember = async (variables: MemberDeactivateMutationVariables) => {
    try {
      await mutate({ variables });
      await onComplete?.();

      notify('Member has been deactivated successfully', NotificationType.Success);
    } catch (err) {
      if (!onError) throw err;

      onError(err as GraphQLError);
    }
  };

  return {
    deactivateMember,
    loading,
  };
};

import React from 'react';
import cx from 'clsx';
import { Banner, Button } from '@macpaw/macpaw-ui';
import { RefreshingIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';

interface Refresh {
  isFetching: boolean;
  onClick: () => void;
  action?: string;
  className?: string;
}

const Refresh: React.FC<Refresh> = (props) => {
  const { isFetching, onClick, className } = props;

  const Action = () => (
    <Button scale="small" color="primary" outline onClick={onClick} loading={isFetching}>
      Refresh
    </Button>
  );

  return (
    <Banner type="secondary" icon={<RefreshingIcon />} action={<Action />} className={cx('mb-32', className)}>
      <b>Request well received!</b> It may take a little time for changes to be reflected in our account.
    </Banner>
  );
};

export default Refresh;

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CustomerPlanStatus, Maybe } from '~/types';
import { isOneTime } from '~/utils';
import { MIN_ORGANIZATION_PLAN_DEVICE_COUNT } from '../../constants';
import { TRANSACTIONS_QUERY, useOrganizationInfoQuery } from '../../graphql/queries';
import {
  OrderInterface,
  PlanInterface,
  TransactionInterface,
  CustomerPlanActionsConfig,
  OrganizationPlanInterface,
} from '../../interfaces';
import { isTrial, findLastPurchaseTransaction, isOrganizationPlan } from '../plan';

const CUSTOMER_PLAN_NO_ACTION_STATUSES = [
  CustomerPlanStatus.PaymentDisputeBlocked,
  CustomerPlanStatus.HighRiskTransactionBlocked,
  CustomerPlanStatus.Cancelled,
  CustomerPlanStatus.Refunded,
  CustomerPlanStatus.Blocked,
];

const DEFAULT_ACTIONS = {
  resendActivation: false,
  refund: false,
  pause: false,
  cancel: false,
  block: false,
  reducePlanSize: false,
};

const useCustomerPlanActions = (
  order: Maybe<OrderInterface>,
  customerPlan: PlanInterface,
): CustomerPlanActionsConfig => {
  const isWithoutActions = CUSTOMER_PLAN_NO_ACTION_STATUSES.includes(customerPlan.status);
  const { data, loading } = useQuery<{ transactions: TransactionInterface[] }>(TRANSACTIONS_QUERY, {
    variables: { customerPlanId: customerPlan.id },
    skip: isWithoutActions || !order,
  });
  const transactions = data?.transactions ?? [];
  const { customerId } = useParams<Record<'customerId', string>>();
  const { owner, loading: organizationLoading } = useOrganizationInfoQuery({
    organizationId: customerPlan.ownerId,
    skip: customerPlan.ownerType !== 'organization',
  });

  const resendActivationActionValue = useMemo(() => {
    const trial = isTrial(customerPlan);
    const isOrganization = isOrganizationPlan(customerPlan);
    const isExternalAccount = customerPlan.planView?.productExternalAccountUrl;
    const hasOrder = customerPlan.order;

    return (Boolean(!isExternalAccount && hasOrder) || trial) && !isOrganization;
  }, [transactions, loading, organizationLoading, customerPlan]);

  const refundActionValue = useMemo(() => {
    const trial = isTrial(customerPlan);
    const isOrganization = isOrganizationPlan(customerPlan);
    const lastTransaction = findLastPurchaseTransaction(transactions);
    const isOwner = owner?.id === customerId;
    const hasTransactions = customerPlan.order && !trial && lastTransaction && !loading;

    const isB2CAccountHasTransactions = hasTransactions && !isOrganization;
    const isB2BOwnerAccountHasTransactions = hasTransactions && !organizationLoading && isOwner;

    return Boolean(isB2CAccountHasTransactions || isB2BOwnerAccountHasTransactions);
  }, [transactions, loading, organizationLoading, customerPlan]);

  const pauseActionValue = useMemo(() => {
    const trial = isTrial(customerPlan);
    const oneTime = isOneTime(customerPlan.planView);

    return Boolean(customerPlan.order && !trial && !oneTime);
  }, [customerPlan]);

  const cancelActionValue = useMemo(() => {
    const oneTime = isOneTime(customerPlan.planView);

    return Boolean(customerPlan.order && !oneTime);
  }, [customerPlan]);

  const reducePlanSizeActionValue = useMemo(() => {
    const trial = isTrial(customerPlan);
    const isOrganization = isOrganizationPlan(customerPlan);
    const seatsCount = isOrganization && (customerPlan as OrganizationPlanInterface).seatCount;

    return Boolean(isOrganization && !trial && seatsCount > MIN_ORGANIZATION_PLAN_DEVICE_COUNT);
  }, [transactions, loading, organizationLoading, customerPlan]);

  const customerPlanActions = useMemo(() => {
    return {
      resendActivation: resendActivationActionValue,
      refund: refundActionValue,
      pause: pauseActionValue,
      cancel: cancelActionValue,
      block: true,
      reducePlanSize: reducePlanSizeActionValue,
    };
  }, [transactions, loading, organizationLoading, customerPlan]);

  if (isWithoutActions)
    return {
      ...DEFAULT_ACTIONS,
      block: true,
    };

  return {
    ...customerPlanActions,
  };
};

export default useCustomerPlanActions;

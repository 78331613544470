import React, { useState, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, Stepper, Step, StepperContextValueType, useModals } from '~/ui';
import { Modals, OrgInviteToPlanFields, OrgInviteToPlanMode, OrgInviteToPlanStep } from '../../constants';
import { OrganizationInviteToPlanSchema } from '../../schemas';
import { InviteToPlanConfirm, InviteToPlanForm, InviteToPlanSuccess } from './steps';

const INITIAL_VALUES = {
  [OrgInviteToPlanFields.ProductTag]: '',
  [OrgInviteToPlanFields.ProductPlanSkuId]: '',
  [OrgInviteToPlanFields.BillImmediatelyPrice]: '',
  [OrgInviteToPlanFields.Quantity]: '',
  [OrgInviteToPlanFields.TrialDays]: '7',
  [OrgInviteToPlanFields.IsDefaultPaymentDate]: true,
  [OrgInviteToPlanFields.InviteMode]: OrgInviteToPlanMode.Paid,
};

interface OrganizationInviteToPlanProps {
  organizationId: string;
}

const OrganizationInviteToPlanModal: React.FC<OrganizationInviteToPlanProps> = ({ organizationId }) => {
  const [purchaseLink, setPurchaseLink] = useState<string>('');
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);
  const stepperRef = useRef<StepperContextValueType>(null);
  const { closeModal } = useModals();

  const handleCancel = () => closeModal(Modals.OrganizationInviteToPlanModal);

  const handleConfirmGoBack = () => {
    const { current: stepper } = stepperRef;

    stepper?.setStep(OrgInviteToPlanStep.Form);
  };

  return (
    <Form
      initialValues={INITIAL_VALUES}
      innerRef={formRef}
      validationSchema={OrganizationInviteToPlanSchema}
      validateMode="onBlur">
      <Stepper initialStep={OrgInviteToPlanStep.Form} innerRef={stepperRef} updateAnyway>
        <Step stepKey={OrgInviteToPlanStep.Form}>
          <InviteToPlanForm onCancel={handleCancel} organizationId={organizationId} />
        </Step>
        <Step stepKey={OrgInviteToPlanStep.Confirm}>
          <InviteToPlanConfirm
            organizationId={organizationId}
            onSuccess={setPurchaseLink}
            onGoBack={handleConfirmGoBack}
          />
        </Step>
        <Step stepKey={OrgInviteToPlanStep.Success}>
          <InviteToPlanSuccess purchaseLink={purchaseLink} onSubmit={handleCancel} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default OrganizationInviteToPlanModal;

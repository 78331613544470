import React from 'react';
import AccountTypeSwitcher from '../../components/AccountTypeSwitcher/AccountTypeSwitcher';
import LicensesSearch from '../../components/LicensesSearch/LicensesSearch';
import Section from '../../components/Section/Section';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';

const Licenses: React.FC = () => {
  return (
    <WithErrorBoundary>
      <AccountTypeSwitcher />
      <Section title="Licenses">
        <LicensesSearch />
      </Section>
    </WithErrorBoundary>
  );
};

export default Licenses;

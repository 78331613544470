import React from 'react';
import { Loadable, Pagination } from '~/ui';
import { ROUTE_PATHS } from '../../constants';
import { useOrganizationsQuery } from '../../graphql/queries';
import SearchableContainer from '../Search/SearchableContainer';
import Section from '../Section/Section';
import { TableBody, TableCell, TableHeader, TableRow } from '../Table';
import OrganizationListItem from './OrganizationListItem';
import styles from './OrganizationsList.module.sass';

const OrganizationList: React.FC = () => {
  const { organizations, pagination, loading } = useOrganizationsQuery();
  const isEmpty = loading ? false : organizations?.length === 0;

  return (
    <Section title="All Organizations">
      <SearchableContainer
        isWideSearch
        isEmpty={isEmpty}
        searchPath={ROUTE_PATHS.organizations()}
        placeholder="Search by Organization Name or Contact Email">
        <Loadable isLoading={loading}>
          <TableHeader className={styles.tableGridPattern}>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell>Owner Email</TableCell>
            <TableCell>Added</TableCell>
            <TableCell />
          </TableHeader>
          <TableBody>
            {organizations.map((organization) => (
              <TableRow key={organization.id}>
                <OrganizationListItem organization={organization} className={styles.tableGridPattern} />
              </TableRow>
            ))}
          </TableBody>
          {pagination && (
            <div className={styles.pagination_wrapper}>
              <Pagination pagination={pagination} />
            </div>
          )}
        </Loadable>
      </SearchableContainer>
    </Section>
  );
};

export default OrganizationList;

import React from 'react';
import { useFormContext, useFieldArray, ArrayPath } from 'react-hook-form';

interface FormArrayRenderProps {
  fields: Array<{ id: string }>;
  append: (value: ObjectLiteral) => void;
  remove: (index: number | number[]) => void;
}

type ArrayRenderFNType = (props: FormArrayRenderProps) => JSX.Element;

interface FormArrayProps {
  name: ArrayPath<ObjectLiteral>;
  children: ArrayRenderFNType | React.ReactNode;
}

const FormArray: React.FC<FormArrayProps> = ({ name, children }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  if (typeof children === 'function' && children instanceof Function) {
    return <>{children({ fields, append, remove })}</>;
  }

  return <>{children}</>;
};

export default FormArray;

import React from 'react';
import { Button, DialogActions, DialogContent, DialogIcon } from '@macpaw/macpaw-ui';
import { useModals } from '~/ui';
import { Modals } from '../../constants';
import { useBlockCustomerPlanMutation } from '../../graphql/mutations';
import AlertIcon from '../../images/icons/alert.svg?react';
import { PlanInterface } from '../../interfaces';

interface PlanBlockProps {
  customerPlan: PlanInterface;
  onComplete?: () => void;
}

const PlanBlockModal: React.FC<PlanBlockProps> = ({ customerPlan, onComplete }) => {
  const { closeModal } = useModals();
  const { blockPlan, loading } = useBlockCustomerPlanMutation();

  const handleCloseBlockPlan = () => {
    closeModal(Modals.PlanBlockModal);
  };

  const handleBlockPlan = async () => {
    await blockPlan(customerPlan.id);
    handleCloseBlockPlan();
    onComplete?.();
  };

  return (
    <>
      <DialogContent>
        <DialogIcon>
          <AlertIcon width={160} height={160} />
        </DialogIcon>
        <h3>Block Plan</h3>
        <p>
          Once blocked, the customer will no longer be able to activate their product on their devices with this plan.
          Note this cannot be undone.
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="primary" outline onClick={handleCloseBlockPlan}>
          Dismiss
        </Button>
        <Button color="warning" onClick={handleBlockPlan} loading={loading}>
          Block Plan
        </Button>
      </DialogActions>
    </>
  );
};

export default PlanBlockModal;

import React from 'react';
import { Payment } from '@macpaw/macpaw-ui';
import { BasePayment, PaymentMethod } from '~/types';

interface PaymentIcon {
  paymentInfo?: BasePayment | null;
  className?: string;
}

const PaymentIcon: React.FC<PaymentIcon> = (props) => {
  const { paymentInfo, ...other } = props;

  if (paymentInfo?.paymentMethod === PaymentMethod.PayPal) {
    return <Payment type={PaymentMethod.PayPal} {...other} />;
  }

  const type = paymentInfo?.cardType ? { type: paymentInfo?.cardType } : {};

  return <Payment {...type} {...other} />;
};

export default PaymentIcon;

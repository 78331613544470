import React from 'react';
import { uniq } from 'lodash';
import { Tag, Label, Grid, GridRow, GridCell } from '@macpaw/macpaw-ui';
import { LoadableText } from '~/ui';
import { formatDate } from '~/utils';
import { OrganizationInfoFields } from '../../constants';
import { formatToStrong } from '../../helpers/formatters';
import BusinessIcon from '../../images/icons/business.svg?react';
import { OrganizationInterface, OrganizationOwner } from '../../interfaces';
import styles from './OrganizationInfo.module.sass';

interface OrganizationInfo {
  organization?: Partial<OrganizationInterface>;
  owner?: Partial<OrganizationOwner>;
  action?: React.ReactNode;
  fields: OrganizationInfoFields[];
}

const MIN_CELL_COUNT = 3;

const OrganizationInfo: React.FC<OrganizationInfo> = ({ organization, owner, action, fields }) => {
  const uniqueFields = uniq(fields);

  const ORGANIZATION_INFO_FIELDS = {
    [OrganizationInfoFields.ContactEmail]: () => (
      <GridCell key={OrganizationInfoFields.OwnerName} type="secondary">
        <Label>Email Address</Label>
        <LoadableText isLoading={!owner} isConditional>
          {organization?.ownerEmail}
        </LoadableText>
      </GridCell>
    ),
    [OrganizationInfoFields.OwnerName]: () => (
      <GridCell key={OrganizationInfoFields.OwnerName} type="secondary">
        <Label>Owner</Label>
        <LoadableText formatter={formatToStrong} isLoading={!owner} isConditional>
          {owner?.name}
        </LoadableText>
      </GridCell>
    ),
    [OrganizationInfoFields.OwnerEmail]: () => (
      <GridCell key={OrganizationInfoFields.OwnerEmail} type="secondary">
        <Label>Owner Email</Label>
        <LoadableText isLoading={!owner} isConditional>
          {owner?.email}
        </LoadableText>
      </GridCell>
    ),
    [OrganizationInfoFields.OwnerRole]: () => (
      <GridCell key={OrganizationInfoFields.OwnerRole} type="secondary">
        <Label>User Role</Label>
        <LoadableText isLoading={!owner}>
          <Tag className={styles.roleTag} borderRadius={20} color="custom">
            {owner?.role}
          </Tag>
        </LoadableText>
      </GridCell>
    ),
    [OrganizationInfoFields.CreatedAt]: () => (
      <GridCell key={OrganizationInfoFields.CreatedAt} type="secondary">
        <Label>Added</Label>
        <LoadableText isLoading={!owner} formatter={formatDate}>
          {organization?.createdAt ?? 0}
        </LoadableText>
      </GridCell>
    ),
  };

  const renderRest = () =>
    new Array(Math.max(uniqueFields.length - MIN_CELL_COUNT, 0))
      .fill(null)
      .map((_, index) => <GridCell key={`placeholder-${index.toString()}`} type="secondary" />);

  return (
    <Grid icon={<BusinessIcon />} action={action} className={styles.details}>
      <GridRow>
        <GridCell type="primary">
          <Label>Company name</Label>
          <LoadableText formatter={formatToStrong} isLoading={!organization} isConditional>
            {organization?.name}
          </LoadableText>
        </GridCell>
        {uniqueFields.map((fieldKey: OrganizationInfoFields) => ORGANIZATION_INFO_FIELDS[fieldKey]())}
        {renderRest()}
      </GridRow>
    </Grid>
  );
};

export default OrganizationInfo;

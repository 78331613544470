import React from 'react';
import { useParams } from 'react-router-dom';
import { Loadable } from '~/ui';
import OrganizationInfo from '../../components/OrganizationInfo/OrganizationInfo';
import OrganizationPlanDevices from '../../components/OrganizationPlanDevices/OrganizationPlanDevices';
import OrganizationPlanOverview from '../../components/OrganizationPlanOverview/OrganizationPlanOverview';
import OrganizationPlanPayment from '../../components/OrganizationPlanPayment/OrganizationPlanPayment';
import PlanNotifications from '../../components/PlanNotifications/PlanNotifications';
import PlanTransactions from '../../components/PlanTransactions/PlanTransactions';
import SearchableContainer from '../../components/Search/SearchableContainer';
import Section from '../../components/Section/Section';
import ThreeDotsActions, { ThreeDotsAction } from '../../components/ThreeDotsActions/ThreeDotsActions';
import WidgetControls from '../../components/Widget/WidgetControlsComponent';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';
import { OrganizationInfoFields, ROUTE_PATHS } from '../../constants';
import { useOrganizationDetailsQuery, useOrganizationPlanQuery } from '../../graphql/queries';
import getIsRestrictedStatus from '../../helpers/getIsRestrictedStatus';
import { CustomerPlanInterface, OrganizationPlanInterface } from '../../interfaces';
import Error404 from '../Error/Error404';

type OrganizationPlanParams = Record<'planId' | 'organizationId', string>;

const OrganizationPlan: React.FC = () => {
  const { planId, organizationId } = useParams<OrganizationPlanParams>();

  const { organization, owner } = useOrganizationDetailsQuery({ organizationId });
  const { plan, loading, refreshPlan, refreshing } = useOrganizationPlanQuery({ planId });

  const isRestrictedStatus = plan ? getIsRestrictedStatus(plan?.status) : true;
  const isOrganizationPlanNotExists = !plan || plan.ownerId !== organizationId;
  const organizationActions: ThreeDotsAction[] = [
    {
      id: 'signInAsOwner',
      type: 'action',
      title: 'Log In as Owner',
      onAction: () => {
        if (owner?.id) {
          window.open(ROUTE_PATHS.signInAsOwner(owner?.id), '_blank');
        }
      },
    },
  ];

  return (
    <SearchableContainer>
      <Loadable isLoading={loading}>
        {isOrganizationPlanNotExists ? (
          <Error404 />
        ) : (
          <WithErrorBoundary>
            <WidgetControls />
            <PlanNotifications
              plan={plan as unknown as CustomerPlanInterface}
              isRefreshing={refreshing}
              refresh={refreshPlan}
            />
            <Section title={<h2>Plan Overview</h2>}>
              <OrganizationPlanOverview
                plan={plan as OrganizationPlanInterface}
                organization={organization}
                owner={owner}
              />
            </Section>
            <Section title="Company info">
              <OrganizationInfo
                organization={organization}
                owner={owner}
                action={<ThreeDotsActions actions={organizationActions} disabled={!owner} />}
                fields={[
                  OrganizationInfoFields.ContactEmail,
                  OrganizationInfoFields.OwnerRole,
                  OrganizationInfoFields.CreatedAt,
                ]}
              />
            </Section>
            {!isRestrictedStatus && (
              <>
                <OrganizationPlanDevices plan={plan as OrganizationPlanInterface} />
                <OrganizationPlanPayment plan={plan as OrganizationPlanInterface} organizationId={organizationId} />
              </>
            )}
            <PlanTransactions plan={plan as CustomerPlanInterface} />
          </WithErrorBoundary>
        )}
      </Loadable>
    </SearchableContainer>
  );
};

export default OrganizationPlan;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, DropdownItem } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { CustomerPlanType, Maybe } from '~/types';
import { useModals } from '~/ui';
import { Modals, ROUTE_PATHS } from '../../constants';
import { OrganizationInterface, OrganizationPlanInterface } from '../../interfaces';

interface OrganizationPlanManageMenu {
  ownerId: Maybe<string>;
  disabled: boolean;
  plan: Maybe<OrganizationPlanInterface>;
  organization: Maybe<OrganizationInterface>;
}

const OrganizationPlanManageMenu: React.FC<OrganizationPlanManageMenu> = ({
  ownerId,
  disabled,
  organization,
  plan,
}) => {
  const navigate = useNavigate();

  const { openModal } = useModals();

  const handleOpenRemoveBetaPlanModal = () => {
    openModal({
      name: Modals.RemoveBetaPlanModal,
      props: {
        planId: plan?.id,
        ownerId: plan?.ownerId,
        organizationId: organization?.id,
        onSuccess: () => {
          navigate(ROUTE_PATHS.organizationPage(organization?.id));
        },
      },
    });
  };

  const isBetaPlan = organization?.isCmmBusinessDashboardBeta && plan?.type === CustomerPlanType.CmmBusinessBeta;

  return (
    <Dropdown
      position="right"
      trigger={
        <Button color="transparent" icon disabled={disabled} aria-label="More">
          <MoreIcon />
        </Button>
      }>
      <DropdownItem href={ROUTE_PATHS.customerPage(ownerId ?? '')}>Go to Owner’s Page</DropdownItem>

      {isBetaPlan && (
        <>
          <DropdownItem separator />
          <DropdownItem onClick={handleOpenRemoveBetaPlanModal} attention>
            Remove Plan
          </DropdownItem>
        </>
      )}
    </Dropdown>
  );
};

export default OrganizationPlanManageMenu;

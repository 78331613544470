export default function valuePlaceholder<T>(value: T): T | string {
  const placeholder = '–';
  // do not use if (value) condition to prevent zero false check

  if (typeof value === 'undefined') return placeholder;
  if (value === null) return placeholder;
  if (typeof value === 'boolean' && value === false) return placeholder;
  if (typeof value === 'string' && value === '') return placeholder;

  return value;
}

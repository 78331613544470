import React from 'react';
import { Button } from '@macpaw/macpaw-ui';
import { UserIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { ROUTE_PATHS, Modals, IS_WIDGET } from '~/constants';
import { useCustomersQuery } from '~/graphql/queries';
import { Loadable, Pagination, useModals } from '~/ui';
import SearchableContainer from '../Search/SearchableContainer';
import Section from '../Section/Section';
import UsersList from '../UsersList/UsersList';
import styles from './CustomersList.module.sass';

const CustomersList: React.FC = () => {
  const { customers, pagination, loading } = useCustomersQuery({ strict: IS_WIDGET });
  const { openModal } = useModals();

  const isEmpty = loading ? false : customers?.length === 0;

  const handleCreateCustomer = () => {
    openModal({ name: Modals.CreateCustomerModal });
  };

  const renderAction = () => (
    <Button color="secondary" scale="medium" iconLeft={<UserIcon />} onClick={handleCreateCustomer}>
      Create Customer
    </Button>
  );

  return (
    <Section title="All Customers" action={renderAction()}>
      <SearchableContainer isWideSearch isEmpty={isEmpty} searchPath={ROUTE_PATHS.home()}>
        <Loadable isLoading={loading}>
          <UsersList users={customers} userPath={ROUTE_PATHS.customerPage} />
          {pagination && (
            <div className={styles.pagination_wrapper}>
              <Pagination pagination={pagination} />
            </div>
          )}
        </Loadable>
      </SearchableContainer>
    </Section>
  );
};

export default CustomersList;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Form, Stepper, Step } from '~/ui';
import { useQueryParams } from '~/utils';
import { AuthInputType, AuthStepType, ROUTE_PATHS } from '../../constants';
import { AuthFormSchema } from '../../schemas';
import { tokenVar } from '../../store';
import styles from './Auth.module.sass';
import AuthSignInPassword from './AuthSignInPassword';
import AuthSignInPinCode from './AuthSignInPinCode';

const Auth: React.FC = () => {
  const queryParams = useQueryParams();
  const emailQueryParam = decodeURIComponent(queryParams.get('email') || '');
  const token = useReactiveVar(tokenVar);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      let from: string = location.state?.pathname || ROUTE_PATHS.home();

      if (from === ROUTE_PATHS.zendesk()) {
        window.location.href = window.location.origin + from + location.state.search;
      }

      if (location?.state?.key !== 'default' || [ROUTE_PATHS.login(), ROUTE_PATHS.signup()].includes(from)) {
        from = ROUTE_PATHS.home();
      }

      navigate(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Form
      initialValues={{
        [AuthInputType.Email]: emailQueryParam,
        [AuthInputType.Password]: '',
        [AuthInputType.Pin]: '',
      }}
      validationSchema={AuthFormSchema}
      validateMode="onBlur"
      className={styles.formWrapper}>
      <Stepper initialStep={AuthStepType.PasswordSignIn}>
        <Step stepKey={AuthStepType.PasswordSignIn}>
          <AuthSignInPassword />
        </Step>
        <Step stepKey={AuthStepType.PinSignIn}>
          <AuthSignInPinCode />
        </Step>
      </Stepper>
    </Form>
  );
};

export default Auth;

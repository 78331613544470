import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Loader } from '@macpaw/macpaw-ui';
import { useQueryParams } from '~/utils';
import { APP_API_CLIENT_ID, APP_CUSTOMER_ACCOUNT_URL, ROUTE_PATHS } from '../../constants';
import { SIGNIN_AS_CUSTOMER_MUTATION } from '../../graphql/mutations';

const LoginAsCustomerRedirect: React.FC = () => {
  const clientId = APP_API_CLIENT_ID;
  const url = APP_CUSTOMER_ACCOUNT_URL;
  const queryParams = useQueryParams();
  const customerId = queryParams.get('customerId');

  const [getToken] = useMutation(SIGNIN_AS_CUSTOMER_MUTATION, {
    variables: { customerId, clientId },
  });

  useEffect(() => {
    if (!customerId || !clientId) return;

    getToken().then((data) => {
      const token = data?.data.signInAsCustomer.token;

      if (token) window.open(ROUTE_PATHS.loginAsCustomerRedirect(url, token, clientId), '_self');
    });
  }, [clientId, customerId, getToken, url]);

  return <Loader />;
};

export default LoginAsCustomerRedirect;

import { BaseCustomerPlan, CustomerPlanGroup, CustomerPlanType } from '~/types';
import daysToNow from './daysToNow';
import getCustomerPlanGroup from './getCustomerPlanGroup';

const MAX_DAYS_BEFORE_LAST_CALL = 7;

type Groups<T> = { [key in CustomerPlanGroup]: T[] };

export default function groupCustomerPlans<T extends BaseCustomerPlan>(customerPlans: T[]): Groups<T> {
  const groups: Groups<T> = {
    [CustomerPlanGroup.Active]: [],
    [CustomerPlanGroup.NeedsAttention]: [],
    [CustomerPlanGroup.Inactive]: [],
    [CustomerPlanGroup.Pending]: [],
    [CustomerPlanGroup.NewPlan]: [],
  };

  customerPlans.forEach((customerPlan) => {
    const customerPlanGroup = getCustomerPlanGroup(customerPlan);
    // move active plan without order to needs attention if less then 14 days until due date
    const isNeedToAddPaymentMethod = customerPlan.type !== CustomerPlanType.Order;
    const days = daysToNow(customerPlan.dueDate!);
    const isForcePushToNeedsAttention = isNeedToAddPaymentMethod && days <= MAX_DAYS_BEFORE_LAST_CALL;

    switch (customerPlanGroup) {
      case CustomerPlanGroup.Active:
        if (isForcePushToNeedsAttention) {
          groups.needs_attention.push(customerPlan);
        } else {
          groups.active.push(customerPlan);
        }
        break;
      case CustomerPlanGroup.Inactive:
        groups.inactive.push(customerPlan);
        break;
      case CustomerPlanGroup.NeedsAttention:
        groups.needs_attention.push(customerPlan);
        break;
      default:
        groups.pending.push(customerPlan);
    }
  });

  return groups;
}

import React from 'react';
import { BreadcrumbsMenu } from '~/ui';
import { IS_WIDGET } from '../../constants';
import SearchForm from '../SearchForm/SearchForm';
import styles from './Search.module.sass';

interface SearchProps {
  searchPath: string;
  placeholder?: string;
}

const Search: React.FC<SearchProps> = ({ placeholder, searchPath }) => {
  if (IS_WIDGET) return null;

  return (
    <div className={styles.search}>
      <BreadcrumbsMenu />
      <SearchForm
        searchPath={searchPath}
        placeholder={placeholder}
        formClassName={styles.form}
        inputClassName={styles.searchInput}
        searchTriggerEvents={['submit']}
      />
    </div>
  );
};

export default Search;

export enum CreateOrganizationStep {
  Form = 'form',
  Success = 'success',
}

export enum CreateOrganizationFields {
  Name = 'name',
  Country = 'country',
  City = 'city',
  State = 'state',
  Address = 'address',
  PostCode = 'postCode',
  VatNumber = 'vatNumber',
}

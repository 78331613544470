import { BaseCustomerPlan, CustomerPlanGroup, CustomerPlanStatus } from '~/types';
import getPlanGroup from './getCustomerPlanGroup';

export default function getCustomerPlanColor(customerPlan?: BaseCustomerPlan) {
  const activeColor = '#3FD866';

  if (!customerPlan) return activeColor;

  if (customerPlan.status === CustomerPlanStatus.Trialing) {
    return '#1ECFF1';
  }

  const customerPlanGroup = getPlanGroup(customerPlan);

  if (customerPlanGroup === CustomerPlanGroup.Inactive) {
    return '#C1BDC7';
  }

  if ([CustomerPlanStatus.Paused, CustomerPlanStatus.PastDue].includes(customerPlan.status)) {
    return '#FFB60A';
  }

  if (customerPlanGroup === CustomerPlanGroup.Pending) {
    return '#1b9eff';
  }

  return activeColor;
}

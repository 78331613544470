import { GENERAL_ERROR, SOMETHING_WENT_WRONG_TRY_AGAIN } from '../constants';
import GQLError from './GQLError';

class GeneralError extends GQLError {
  get errorCode(): string {
    return GENERAL_ERROR;
  }
  get message(): string {
    return SOMETHING_WENT_WRONG_TRY_AGAIN;
  }
}

export default GeneralError;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { MacPawLogo } from '@macpaw/macpaw-ui';
import { ROUTE_PATHS } from '../../constants';
import { tokenVar } from '../../store';
import HeaderMenu from './HeaderMenu';
import HeaderProfile from './HeaderProfile';
import './Header.sass';

const WITHOUT_PROFILE_ROUTES: string[] = [ROUTE_PATHS.login(), ROUTE_PATHS.acceptInvitation()];

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const token = useReactiveVar(tokenVar);
  const isWithoutHeader = WITHOUT_PROFILE_ROUTES.includes(pathname);

  return (
    <header className="nav">
      <div className="nav-header">
        <div className="nav-logo">
          <Link to="/">
            <MacPawLogo />
          </Link>
          {!isWithoutHeader ? <HeaderMenu /> : null}
        </div>
        {token && <HeaderProfile />}
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import cx from 'clsx';
import { useQueryParams } from '~/utils';
import { ROUTE_PATHS } from '../../constants';
import SearchForm from '../SearchForm/SearchForm';
import Search from './Search';
import styles from './SearchableContainer.module.sass';
import SearchEmptyState from './SearchEmptyState';

interface SearchableContainerProps {
  searchPath?: string;
  isEmpty?: boolean;
  isWideSearch?: boolean;
  placeholder?: string;
  isResetButtonLoading?: boolean;
  searchTriggerEvents?: Array<'submit' | 'typing'>;
  leftSideComponent?: React.ReactNode;
}

const SearchableContainer: React.FC<React.PropsWithChildren<SearchableContainerProps>> = ({
  searchPath = ROUTE_PATHS.home(),
  isWideSearch,
  isEmpty,
  placeholder,
  children,
  isResetButtonLoading = false,
  searchTriggerEvents = ['submit'],
  leftSideComponent,
}) => {
  const queryParams = useQueryParams();
  const search = queryParams.get('q') || '';

  return (
    <>
      <div className={cx('mb-32', Boolean(leftSideComponent) && styles.searchWrapper)}>
        {Boolean(leftSideComponent) && leftSideComponent}
        {isWideSearch ? (
          <SearchForm
            placeholder={placeholder}
            searchPath={searchPath}
            formClassName={styles.wideSearch}
            inputClassName={styles.input}
            isResetButtonLoading={isResetButtonLoading}
            searchTriggerEvents={searchTriggerEvents}
          />
        ) : (
          <Search placeholder={placeholder} searchPath={searchPath} />
        )}
      </div>
      {search && (
        <div className="mb-16">
          <h3>Search Result</h3>
        </div>
      )}
      {isEmpty ? <SearchEmptyState /> : children}
    </>
  );
};

export default SearchableContainer;

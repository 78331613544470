import { ErrorResponse } from '@apollo/client/link/error';
import { Maybe } from '~/types';
import { getGQLValidationErrors } from '~/utils';
import { ERROR_COPY, ERROR_CODE_TO_COPY } from '../constants';
import GQLError from './GQLError';

class ValidationError extends GQLError {
  get message(): string {
    return ERROR_CODE_TO_COPY?.[this.errorCode] ?? ERROR_COPY.general;
  }

  get validationErrorMessages(): Maybe<Record<string, string>> {
    const validationErrors = getGQLValidationErrors(this.origin as ErrorResponse);

    if (!validationErrors) return null;

    return Object.entries(validationErrors).reduce((acc, [key, errorCode]) => {
      return {
        ...acc,
        [key]: ERROR_CODE_TO_COPY?.[errorCode] ?? ERROR_COPY.general,
      };
    }, {});
  }
}

export default ValidationError;

import React, { ComponentType } from 'react';
import 'react-app-polyfill/stable';
import '@macpaw/macpaw-ui/lib/ui.css';
import './styles/common.sass';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { ModalLauncher, ModalLauncherProvider } from '~/ui';
import client from './ApolloClient';
import AppRoot from './AppRoot';
import * as modalComponents from './components/Modals';
import { SUPPORT_TOKEN } from './constants';
import { tokenVar } from './store';

const token = window.localStorage.getItem(SUPPORT_TOKEN);

tokenVar(token ?? null);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ModalLauncherProvider modalComponents={modalComponents as unknown as Record<string, ComponentType>}>
        <AppRoot />
        <ModalLauncher />
      </ModalLauncherProvider>
    </BrowserRouter>
  </ApolloProvider>,
);

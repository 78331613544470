import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { ERROR_CODE_TO_COPY, SESSION_EXPIRED_ERROR, SUPPORT_TOKEN } from './constants';
import { SIGN_OUT_MUTATION } from './graphql/mutations';
import { resetLocalStore } from './store';

export const signOut = async (client: ApolloClient<NormalizedCacheObject>) => {
  client
    .mutate({ mutation: SIGN_OUT_MUTATION })
    .then(() => resetLocalStore(client))
    .then(() => window.localStorage.removeItem(SUPPORT_TOKEN));
};

const cleanupClientSession = (client: ApolloClient<NormalizedCacheObject>) => {
  resetLocalStore(client).then(() => window.localStorage.removeItem(SUPPORT_TOKEN));
};

export const handleAuthError = (client: ApolloClient<NormalizedCacheObject>, errorCode: string) => {
  const errorMessage = ERROR_CODE_TO_COPY[errorCode];

  notify(errorMessage, NotificationType.Error);
  if (SESSION_EXPIRED_ERROR.includes(errorCode)) {
    cleanupClientSession(client);
  } else {
    signOut(client);
  }
};

import React from 'react';
import { Hint, Input } from '@macpaw/macpaw-ui';
import { ConfirmationType } from '~/types';
import { ConfirmationModal } from '~/ui';
import styles from './InviteToPlanSuccess.module.sass';

interface InviteToPlanSuccessProps {
  purchaseLink: string;
  onSubmit: () => void;
}

const InviteToPlanSuccess: React.FC<InviteToPlanSuccessProps> = ({ purchaseLink, onSubmit }) => (
  <ConfirmationModal
    type={ConfirmationType.Success}
    title="Payment link is ready!"
    actionTitle="Got It"
    description={
      <>
        As soon as the customer makes the first payment using the link below, their new plan will appear on the
        Organization’s page. Don’t forget to <strong>deactivate their personal plan</strong> after setting up this plan.
      </>
    }
    descriptionClassName={styles.description}
    onSubmit={onSubmit}>
    <Input
      type="text"
      value={purchaseLink}
      label="Share link with the customer"
      onChange={() => {}}
      clipboard={<span className={styles.tooltip}>Copy to clipboard</span>}
    />
    <Hint className={styles.hint}>
      The link will stay active for <strong>30 days</strong>.
    </Hint>
  </ConfirmationModal>
);

export default InviteToPlanSuccess;

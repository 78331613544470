import React, { createContext, useContext, useRef } from 'react';
import { Outlet, LinkProps, Location } from 'react-router-dom';

export type linkComponent = React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;

interface RouterDomProviderProps {
  linkComponent?: linkComponent;
  locationHook?: () => Location;
}

interface RouterDomContextValue {
  linkComponent: linkComponent;
  locationHook: () => Location;
}

const initialValue: RouterDomContextValue = {
  linkComponent: null as unknown as linkComponent,
  locationHook: null as unknown as () => Location,
};

const RouterDomContext = createContext(initialValue);

export const RouterDomProvider: React.FC<RouterDomProviderProps> = ({ linkComponent, locationHook }) => {
  const link = useRef(linkComponent ?? null);
  const useLocationHook = useRef(locationHook ?? null);

  const contextValue = {
    linkComponent: link.current as linkComponent,
    locationHook: useLocationHook.current as () => Location,
  };

  return (
    <RouterDomContext.Provider value={contextValue}>
      <Outlet />
    </RouterDomContext.Provider>
  );
};

export const useRouterDomContext = () => {
  const { linkComponent, locationHook } = useContext(RouterDomContext);

  return {
    linkComponent,
    locationHook,
  };
};

import { gql, useQuery, ApolloError } from '@apollo/client';
import { Maybe, BasePagination } from '~/types';
import { useQueryParams } from '~/utils';
import { LIMIT_PER_PAGE } from '../../constants';
import { ListQueryInput, OrganizationInterface, GQLResponse } from '../../interfaces';
import { PAGINATION_FRAGMENT } from '../fragments';

export const ORGANIZATIONS_QUERY = gql`
  query OrganizationsQuery($input: PaginatedListParams) {
    organizations(input: $input) {
      data {
        id
        name
        ownerEmail
        createdAt
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

interface OrganizationsQuery {
  data: OrganizationInterface[];
  pagination: Maybe<BasePagination>;
}

interface UseOrganizationsQuery {
  organizations: OrganizationInterface[];
  pagination: Maybe<BasePagination>;
  loading: boolean;
  error: Maybe<ApolloError>;
}

export const useOrganizationsQuery = (): UseOrganizationsQuery => {
  const queryParams = useQueryParams();
  const search = queryParams.get('q') || '';
  const page = queryParams.get('page') || '1';

  const { data, loading, error } = useQuery<
    GQLResponse<'organizations', OrganizationsQuery>,
    Record<'input', Partial<ListQueryInput>>
  >(ORGANIZATIONS_QUERY, {
    variables: {
      input: {
        limit: LIMIT_PER_PAGE,
        page: Number(page),
        search,
      },
    },
  });

  return {
    organizations: data?.organizations?.data ?? [],
    pagination: data?.organizations?.pagination,
    loading,
    error,
  };
};

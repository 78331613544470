import { ErrorResponse } from '@apollo/client/link/error';
import { isEmpty } from 'lodash';
import { getGQLValidationErrors } from '~/utils';
import { GeneralError, ValidationError } from '../errors';

const handleFormMutationError = (error: ErrorResponse) => {
  const validationErrors = getGQLValidationErrors(error);
  const isEmptyValidationErrors = isEmpty(validationErrors);

  if (isEmptyValidationErrors) {
    throw new GeneralError(error);
  }

  throw new ValidationError(error);
};

export default handleFormMutationError;

export enum RefundSteps {
  Form = 'form',
  PartialRefundWarning = 'partial-refund-warning',
  FullRefundWarning = 'full-refund-warning',
  Success = 'success',
}

export enum RefundTypes {
  Full = 'full',
  Partial = 'partial',
}

export const MAX_TRANSACTIONS_DAYS_TO_DISPLAY = 90;

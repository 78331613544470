import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { v4 as uuid } from 'uuid';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { CreateOrganizationData, ApolloInput } from '../../interfaces';
import { CUSTOMER_QUERY } from '../queries';

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input)
  }
`;

type CreateOrganizationVariables = ApolloInput<{
  organization: CreateOrganizationData;
  customerId: string;
}>;

export const useCreateOrganizationMutation = () => {
  const [mutate, { loading }] = useMutation<void, CreateOrganizationVariables>(CREATE_ORGANIZATION_MUTATION);

  const createOrganization = async (organization: CreateOrganizationData, customerId: string) => {
    const organizationDetails = {
      ...organization,
      organizationId: uuid(),
    };

    try {
      await mutate({
        variables: {
          input: {
            organization: organizationDetails,
            customerId,
          },
        },
        refetchQueries: [
          {
            query: CUSTOMER_QUERY,
            variables: {
              customerId,
            },
          },
        ],
      });

      return organizationDetails.organizationId;
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);

      return null;
    }
  };

  return {
    createOrganization,
    loading,
  };
};

import React from 'react';
import { Banner } from '@macpaw/macpaw-ui';
import { CustomerPlanStatus } from '~/types';
import { formatDate, untilDeactivation } from '~/utils';
import { PlanInterface } from '../../interfaces';
import { UnpausePlanAction } from '../UnpausePlanAction/UnpausePlanAction';
import { CustomerPlanNotificationsConfig } from './customerPlanNotifications';

export const DETAILED_CUSTOMER_PLAN_NOTIFICATIONS: CustomerPlanNotificationsConfig = {
  [CustomerPlanStatus.PauseRequested]: (customerPlan, onStatusChanged) => {
    const { productName } = customerPlan.planView;
    const action = <UnpausePlanAction customerPlan={customerPlan} onStatusChanged={onStatusChanged} />;

    return (
      <Banner action={action}>
        <b>Payments for this plan have been paused</b>. Please unpause them to continue using {productName}. Your app
        will remain active for {untilDeactivation(customerPlan)} days.
      </Banner>
    );
  },
  [CustomerPlanStatus.CancelApproved]: (customerPlan: PlanInterface) => (
    <Banner type="readonly">
      This plan has been cancelled and <b>will be deactivated on {formatDate(customerPlan.dueDate!)}</b>.
    </Banner>
  ),
  [CustomerPlanStatus.Cancelled]: (customerPlan: PlanInterface) => {
    const { productName } = customerPlan.planView;

    return (
      <Banner type="readonly">
        This plan has been canceled and is currently inactive. To use {productName} again, please purchase another plan
        from our MacPaw Store.
      </Banner>
    );
  },
  [CustomerPlanStatus.Refunded]: () => (
    <Banner type="readonly">This plan has been refunded and is currently inactive.</Banner>
  ),
};

import React, { ReactNode } from 'react';
import { Path } from 'react-hook-form';
import { Checkbox } from '@macpaw/macpaw-ui';
import type { CheckboxProps } from '@macpaw/macpaw-ui/lib/Checkbox/Checkbox';
import { useFormError, useFormField } from './Form.hooks';

interface FormCheckBox extends CheckboxProps, React.RefAttributes<HTMLInputElement> {
  name: Path<ObjectLiteral>;
  label?: string | ReactNode;
  labelClassName?: string;
}

const FormCheckBox: React.FC<FormCheckBox> = ({ name, label, labelClassName, children, ...rest }) => {
  const {
    field: { onBlur, onChange, value, ref },
  } = useFormField<boolean>(name);

  const { isError } = useFormError(name);

  const labelClassNameMerged = labelClassName ?? 'input-label h6';

  return (
    <>
      <Checkbox
        id={name}
        ref={ref}
        name={name}
        checked={value}
        error={isError}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}>
        {children}
      </Checkbox>
      {Boolean(label) && (
        <label className={labelClassNameMerged} htmlFor={name}>
          {label}
        </label>
      )}
    </>
  );
};

export default FormCheckBox;

export enum GivePlanStep {
  Form = 'form',
  Success = 'success',
}

export enum GivePlanFields {
  Product = 'product',
  ProductPlanId = 'productPlanId',
  Reason = 'reason',
}

export const AUTH_ERRORS = ['auth.required', 'auth.expired', 'user.session.notFound'];

export const SESSION_EXPIRED_ERROR = ['auth.expired', 'user.session.notFound'];

export const FORBIDDEN_ERROR = 'FORBIDDEN';
export const GENERAL_ERROR = 'errors.general';
export const UNEXPECTED_ERROR = 'order.redeemCodeGenerationStatus.unexpected';
export const USER_NOT_FOUND_ERROR = 'user.notFound';
export const USER_DEACTIVATED_ERROR = 'user.deactivated';
export const EMAIL_ALREADY_EXIST_ERROR = 'userInviteRequest.email.alreadyExist';
export const ZENDESK_CONSOLE_ERROR = 'zendesk client is not loaded';
export const NEW_PASSWORD_SAME_ERROR = 'changePasswordRequest.newPassword.same';
export const PASSWORD_LENGTH_ERROR = 'changePasswordRequest.password.length';
export const PASSWORD_INVALID_ERROR = 'password.invalid';
export const CHANGE_PASSWORD_INVALID_ERROR = 'changePasswordRequest.password.invalid';
export const PINCODE_INVALID_ERROR = 'pinCode.invalid';
export const PINCODE_MAX_ATTEMPT_ERROR = 'pinCode.maxAttemptCountExceeded';
export const INVITE_TOKEN_NOT_FOUND_ERROR = 'signUpRequest.inviteToken.notFound';
export const SIGN_UP_PASSWORD_LENGTH_ERROR = 'signUpRequest.password.length';
export const SIGN_UP_PASSWORD_BLANK_SPACE_ERROR = 'signUpRequest.password.blankSpace';
export const CREATE_CUSTOMER_ALREADY_EXIST_ERROR = 'customerCreateRequest.email.alreadyExist';

export const ERROR_COPY = {
  general: 'Something went wrong!',
  userDeactivated: 'An account with that email address has been deactivated',
  userNotFound: 'An account with that email address does not exist',
  passwordsDontMatch: 'The passwords don’t match. Please enter them again.',
  oldAndNewPasswordsAreIdentical: 'Your new password cannot be the same as the one you’re using right now.',
  pinCodeInvalid: 'Wrong verification code. Please try again.',
  pinCodeAttemt: 'You’ve entered the verification code too many times. Please start over.',
  enterOldCode: 'Please enter your old code.',
  passwordEmpty: 'This part is pretty important',
  passwordStartsWithSpace: 'Please make sure there are no spaces at the beginning or end of your password',
  passwordTooShort: 'Please create a password at least 8 characters long',
  passwordTooLong: 'Your password is too long. Please make it a couple of characters shorter',
  emptyField: 'This part is pretty important.',
  tokenInvalidTryLogin: 'Invitation token was not found. Please try to login',
};

export const FATAL_ERRORS = [
  'NETWORK_ERROR',
  'UNKNOWN_ERROR',
  'previewCustomerProductPlanUpdateProductPlan.requestError.byCustomerProductPlanAndProductPlan',
  'orderByCustomerProductPlanIdRequest.customerProductPlanId.notFound',
  'orderByCustomerProductPlanIdRequest.currentCustomerId.notFound',
  'productPlanTierGroupProvider.notFound',
];

export const NOT_FOUND_ERRORS = [
  'NOT_FOUND_ERROR',
  'orderByCustomerProductPlanIdRequest.customerProductPlanId.invalid',
  'customerProductPlanDetailRequest.customerProductPlanId.invalid',
  'customerProfileRequest.customerId.invalid',
  'customerProfileRequest.customerId.notFound',
  'customerProductPlanListRequest.customerId.invalid',
  'customerProfileRequest.customerId.notFound',
];

export const ERRORS_WITHOUT_REDIRECT = [
  'userInviteRequest.email.alreadyExist',
  'customerCreateRequest.email.alreadyExist',
  'changePasswordRequest.password.invalid',
];

export const IGNORED_ERRORS = [
  'user.notFound',
  'password.invalid',
  'customer.emailConfirmed',
  'pinCode.invalid',
  'pinCode.maxAttemptCountExceeded',
  'userInviteRequest.email.alreadyExist',
  'user.deactivated',
  'signUpRequest.inviteToken.notFound',
  'signUpRequest.password.blankSpace',
  'order.redeemCodeGenerationStatus.unexpected',
  'enableAllowedProductPlanSkuRequest.price.positive',
  'enableAllowedProductPlanSkuRequest.invalid',
  'customerCreateRequest.email.alreadyExist',
  'createOrganizationRequest.name.notBlank',
  'createOrganizationRequest.name.notNull',
  'createOrganizationRequest.country.notNull',
  'createOrganizationRequest.postCode.notBlank',
  'createOrganizationRequest.postCode.notNull',
  'customerProductPlanCreateRequest.productPlanId.notBlank',
  'customerProductPlanCreateRequest.reason.notBlank',
  'customerCreateRequest.email.invalid',
  'licenseKeyInfoRequest.licenseKey.notFound',
  INVITE_TOKEN_NOT_FOUND_ERROR,
];

export const ERROR_CODE_TO_COPY: { [key: string]: string } = {
  'customerProductPlanCreateRequest.email.invalid': 'Hm, that doesn’t look like an email address',
  'customerCreateRequest.email.invalid': 'Hm, that doesn’t look like an email address',
  'checkEmailRequest.email.invalid': 'Hm, that doesn’t look like an email address',
  'errors.email.empty': 'Please enter your email',
  'customer.alreadyExists': 'An account with that email address already exists',
  'userInviteRequest.email.alreadyExist': 'An account with that email address already exists',
  'check_email_request.email.not_found': 'An account with that email address does not exist',
  'errors.password.invalid': 'Please enter at least 8 characters',
  [PASSWORD_LENGTH_ERROR]: 'Please enter at least 8 characters',
  'signUpRequest.password.length': 'Please enter at least 8 characters',
  [CHANGE_PASSWORD_INVALID_ERROR]: 'Oops, wrong password. Please try again',
  [NEW_PASSWORD_SAME_ERROR]: 'Your new password cannot be the same as the one you’re using right now',
  'changePasswordRequest.password.changed':
    'Password updated! P.S. We’ve terminated all other sessions to make sure your account is safe',
  'customer.isNotActivated': 'You must first confirm your email address',
  'customer.emailNotConfirmed': 'Please confirm your email address to continue',
  'customer.notFound': 'An account with that email address does not exist',
  'customer.emailConfirmed': 'Email already confirmed',
  'errors.password.too_long': 'Your password is too long. Please make it a couple of characters shorter',
  'errors.password.with_space': 'Please make sure there are no spaces at the beginning or end of your password',
  [PASSWORD_INVALID_ERROR]: 'Oops, wrong password. Please try again',
  'errors.signin.password.empty': 'Please enter your password',
  'errors.reset.password.empty': 'Please enter the password',
  'errors.password.empty': 'This part is pretty important',
  'errors.pin.empty': 'Please enter the verification code we’ve sent to ',
  'errors.password.doNotMatch': 'The passwords don’t match. Please enter them again',
  'validateRecoveryPasswordPinCodeRequest.pinCode.invalid': 'Wrong verification code. Please try again',
  'validateClaimAccountPinCodeRequest.pinCode.invalid': 'Wrong verification code. Please try again',
  'validateRecoveryPasswordPinCodeRequest.pinCode.wrong': 'Wrong verification code. Please try again',
  'validateClaimAccountPinCodeRequest.pinCode.wrong': 'Wrong verification code. Please try again',
  'validateRecoveryPasswordPinCodeRequest.pinCode.maxAttemptQuantityExceeded':
    'You’ve entered the verification code too many times. Please start over',
  'validateClaimAccountPinCodeRequest.pinCode.maxAttemptQuantityExceeded':
    'You’ve entered the verification code too many times. Please start over',
  'validateRecoveryPasswordPinCodeRequest.pinCode.expired': 'The verification code has expired. Please start over',
  'validateClaimAccountPinCodeRequest.pinCode.expired': 'The verification code has expired. Please start over',
  'signUpRequest.name.tooLong': 'Your name is too long. Please make it a couple of characters shorter',
  'confirmationEmailRequest.confirmationToken.notFound': 'We could not confirm your email. Please try again',
  'recoveryPasswordSendPinCodeRequest.email.notFound': 'An account with that email address does not exist',
  'recoveryPasswordSendPinCodeRequest.email.rateLimit': 'Too many requests sent. Please try again after 60 seconds',
  'recoveryPasswordSendPinCodeRequest.email.invalid': 'Hm, that doesn’t look like an email address',
  'changeEmailRequest.email.alreadyExist': 'This email has already been used for other account',
  'changeEmailRequest.email.same': 'Your new email cannot be the same as the current one',
  'changeEmailRequest.password.length': 'Please enter at least 8 characters',
  'changeEmailRequest.password.invalid': 'Oops, wrong password. Please try again',
  'customerEditRequest.name.tooLong': 'Your user name is too long. Please make it a couple of characters shorter',
  'customerEditRequest.name.notNull': 'Need a little more effort here, friend',
  'auth.expired': 'Your session has expired. Please sign in',
  'customer.session.notFound': 'Your session has expired. Please sign in',
  'errors.invalidEmail': 'Hm, that doesn’t look like an email',
  'errors.similarOwnerEmail': 'You can’t invite yourself, silly!',
  'errors.empty': 'Need a little more effort here, friend',
  'errors.emailEmpty': 'Please enter your email',
  'errors.emailAlreadyExist': 'An account with that email address already exists',
  'errors.passwordTooShort': 'Please create a password at least 8 characters long',
  'errors.passwordIsTheSame': 'Your new password cannot be the same as the one you’re using right now',
  'errors.passwordChanged':
    'Password updated! P.S. We’ve terminated all other sessions to make sure your account is safe',
  'errors.emailNotConfirmed': 'You must first confirm your email address',
  'errors.emailNotFound': 'An account with that email address does not exist',
  'errors.passwordTooLong': 'Your password is too long. Please make it a couple of characters shorter',
  'errors.passwordStartsWithSpace': 'Please make sure there are no spaces at the beginning or end of your password',
  'errors.passwordInvalid': 'Oops, wrong password. Please try again',
  'errors.passwordEmpty': 'This part is pretty important',
  'errors.passwordDoNotMatch': 'The passwords don’t match. Please enter them again',
  'customerProductPlan.alreadyCancelled': 'This plan has already been cancelled',
  'refund.transaction.notRefundable': 'This transaction has already been refunded.',
  'transaction.hasEmptyExternalOrderId': 'We couldn’t track down the initial order to complete the refund.',
  [GENERAL_ERROR]: 'Seems like something went wrong. Please try again',
  'errors.emailIsTheSame': 'Your new email cannot be the same as the current one',
  'errors.nameTooLong': 'Your name is too long. Please make it a couple of characters shorter',
  'errors.emptySubmission': 'Need a little more effort here, friend',
  'auth.required': 'Your session has expired. Please sign in',
  'errors.required': 'This field is required',
  'user.session.notFound': 'Your session has expired. Please sign in',
  'signUpRequest.inviteToken.notFound': 'Invitation token was not found',
  'customerEditRequest.newEmail.invalid': 'Hm, that doesn’t look like an email address',
  'signUpRequest.password.blankSpace': 'Please make sure there are no spaces at the beginning or end of your password',
  'customerCreateRequest.email.alreadyExist': 'A customer with this email address already exists',
  'createOrganizationRequest.name.notBlank': 'Need a little more effort here, friend',
  'createOrganizationRequest.name.notNull': 'Need a little more effort here, friend',
  'createOrganizationRequest.country.notNull': 'Please select your country',
  'createOrganizationRequest.postCode.notBlank': 'Please enter your postal code',
  'createOrganizationRequest.postCode.notNull': 'Please enter your postal code',
  'customerProductPlanCreateRequest.productPlanId.notBlank': 'Please select a plan',
  'customerProductPlanCreateRequest.reason.notBlank': 'Please select a reason',
  // TODO: Rewrite to new backend endpoints to reduce keys duplicate:
  'storeAllowedProductPlanSkuListRequest.allowedProductPlanSkuList0.price.positive':
    'Please enter prices for all selected plans',
  'storeAllowedProductPlanSkuListRequest.allowedProductPlanSkuList1.price.positive':
    'Please enter prices for all selected plans',
  'storeAllowedProductPlanSkuListRequest.allowedProductPlanSkuList2.price.positive':
    'Please enter prices for all selected plans',
  'storeAllowedProductPlanSkuListRequest.allowedProductPlanSkuList3.price.positive':
    'Please enter prices for all selected plans',
  'storeAllowedProductPlanSkuListRequest.allowedProductPlanSkuList4.price.positive':
    'Please enter prices for all selected plans',
  'storeAllowedProductPlanSkuListRequest.allowedProductPlanSkuList5.price.positive':
    'Please enter prices for all selected plans',
};

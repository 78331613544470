import { gql } from '@apollo/client';

export const REMOVE_SEAT_MUTATION = gql`
  mutation RemoveSeat($customerProductPlanSeatId: ID!) {
    removeSeat(customerProductPlanSeatId: $customerProductPlanSeatId)
  }
`;

export const REMOVE_DEVICE_MUTATION = gql`
  mutation RemoveDevice($customerProductPlanSeatId: ID!) {
    removeDevice(customerProductPlanSeatId: $customerProductPlanSeatId)
  }
`;

import React, { PropsWithChildren } from 'react';
import cx from 'clsx';
import styles from './TableHeader.module.sass';

interface TableHeaderProps {
  className?: string;
}

const TableHeader: React.FC<PropsWithChildren<TableHeaderProps>> = ({ children, className }) => {
  const tableHeaderClassNames = cx(styles.header, className);

  return <div className={tableHeaderClassNames}>{children}</div>;
};

export default TableHeader;

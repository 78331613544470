import { gql, Reference, useMutation } from '@apollo/client';

export const REMOVE_CUSTOMER_MUTATION = gql`
  mutation RemoveCustomer($customerId: ID!) {
    removeCustomer(customerId: $customerId)
  }
`;

interface RemoveCustomerMutationVariables {
  customerId: string;
  currentCustomerId: string;
}

interface UseRemoveCustomerMutationInput {
  onComplete?: () => void;
}

export const useRemoveCustomerMutation = ({ onComplete }: UseRemoveCustomerMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<RemoveCustomerMutationVariables>(REMOVE_CUSTOMER_MUTATION);

  const removeCustomer = async (variables: RemoveCustomerMutationVariables) => {
    await mutate({
      variables,
      update(cache) {
        cache.modify({
          fields: {
            customers(existingTaskRefs: ObjectLiteral, { readField }) {
              const { data } = existingTaskRefs!;

              const filteredData = data.filter((customerRef: Reference) => {
                return variables.customerId !== readField('id', customerRef);
              });

              return filteredData;
            },
          },
        });
      },
    });

    onComplete?.();
  };

  return {
    removeCustomer,
    loading,
  };
};

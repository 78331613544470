import { gql, useQuery } from '@apollo/client';
import { CustomerInterface, OrganizationInterface } from '../../interfaces';
import { CUSTOMER_QUERY } from './customerQuery';

export const ORGANIZATION_INFO_QUERY = gql`
  query organizationInfo($organizationId: ID!) {
    organizationInfo(organizationId: $organizationId) {
      organization {
        id
        name
      }
      owner {
        customerId
        role
      }
    }
  }
`;

interface UseOrganizationInfoQueryInput {
  organizationId: string;
  skip?: boolean;
}

export const useOrganizationInfoQuery = ({ organizationId, skip = false }: UseOrganizationInfoQueryInput) => {
  const { data, loading: organizationLoading } = useQuery(ORGANIZATION_INFO_QUERY, {
    variables: { organizationId },
    skip,
  });
  const ownerId = data?.organizationInfo?.owner?.customerId as CustomerInterface | undefined;

  const { data: ownerData, loading: ownerLoading } = useQuery(CUSTOMER_QUERY, {
    variables: { customerId: ownerId },
    skip: !ownerId,
  });

  const organization = data?.organizationInfo?.organization as Partial<OrganizationInterface> | undefined;
  const owner = ownerData?.customer as CustomerInterface | undefined;

  return {
    organization,
    owner,
    loading: organizationLoading || ownerLoading,
  };
};

import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, Stepper, Step, StepperContextValueType, useModals } from '~/ui';
import { Modals, SetCustomPriceFields, SetCustomPriceStep } from '../../constants';
import { OrganizationCustomPriceFormSchema } from '../../schemas';
import { SetCustomPriceConfirm, SetCustomPriceForm, SetCustomPriceSuccess, SetCustomPriceReject } from './steps';

interface SetCustomPlanPriceProps {
  organizationId: string;
  name: string;
}

const INITIAL_VALUES = {
  [SetCustomPriceFields.ProductTag]: '',
  [SetCustomPriceFields.ProductPlanSkuId]: '',
  [SetCustomPriceFields.Price]: '',
  [SetCustomPriceFields.DefaultPrice]: '',
  [SetCustomPriceFields.IsDefaultPrice]: true,
};

const SetCustomPlanPriceModal: React.FC<SetCustomPlanPriceProps> = ({ organizationId, name }) => {
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);
  const stepperRef = useRef<StepperContextValueType>(null);
  const { closeModal } = useModals();

  const handleCancel = () => closeModal(Modals.SetCustomPlanPriceModal);

  const handleConfirmGoBack = () => {
    const { current: stepper } = stepperRef;

    stepper?.setStep(SetCustomPriceStep.Form);
  };

  return (
    <Form initialValues={INITIAL_VALUES} innerRef={formRef} validationSchema={OrganizationCustomPriceFormSchema}>
      <Stepper initialStep={SetCustomPriceStep.Form} innerRef={stepperRef} updateAnyway>
        <Step stepKey={SetCustomPriceStep.Form}>
          <SetCustomPriceForm organizationId={organizationId} onCancel={handleCancel} />
        </Step>
        <Step stepKey={SetCustomPriceStep.Confirm}>
          <SetCustomPriceConfirm name={name} organizationId={organizationId} onGoBack={handleConfirmGoBack} />
        </Step>
        <Step stepKey={SetCustomPriceStep.Success}>
          <SetCustomPriceSuccess onSubmit={handleCancel} />
        </Step>
        <Step stepKey={SetCustomPriceStep.Reject}>
          <SetCustomPriceReject onSubmit={handleCancel} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default SetCustomPlanPriceModal;

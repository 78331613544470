import {
  ActivationStatus,
  BasePlanView,
  CustomerPlanStatus,
  CustomerPlanType,
  OrderStatus,
  ProductPlanType,
  SeatInterface,
  SeatStatus,
  Tag,
} from '~/types';

interface PlanViewInterface extends BasePlanView {
  productPlanNameI18n: string;
}

const planViewMock: PlanViewInterface = {
  id: '123',
  vendorId: '123',
  productId: '123',
  productSlug: 'cleanmymac',
  productTag: Tag.CleanMyMac,
  productName: 'CleanMyMac X',
  productPublicName: 'CleanMyMac X',
  productDescription: 'The best cleaner ever',
  productIconUrl: 'url',
  productPriority: 0,
  productDownloadUrl: 'download_url',
  productPlanId: '123',
  productPlanType: ProductPlanType.Subscription,
  productPlanSeatCount: 5,
  productPlanName: 'monthly',
  productPlanIconUrl: 'url',
  productPlanNameI18n: 'CleanMyMac X',
  productPlanActivationPeriod: 365,
  productUrlSchemes: '',
  productExternalAccountUrl: '',
  productPlanGracePeriod: 123123123,
};

const seatMock: SeatInterface = {
  id: 'af372b49-d44f-40dd-8f43-f93cdfbf79a3',
  customerId: '5cc1ae90-bd4b-410f-9f7d-c801523c1538',
  status: SeatStatus.Active,
  lastAccessDate: 1640007031,
  machineName: "Fixel's MacBook Pro",
  machineModel: 'MacBook Pro',
  machineModelIconUrl: 'https://my.platformteam.io/images/device/macbook.png',
  serialNumber: 'D12D5K2RTD7Z',
};

const customerPlanMock = {
  id: '38bfeda8-0859-43fb-9ffd-c555fb12413e',
  ownerId: 'b20dcf2a-e719-436c-b2a5-72c72921d3c4',
  productPlanSkuId: '1fcf5165-8a10-44ce-be25-21867da3b42b',
  status: CustomerPlanStatus.Active,
  activationStatus: ActivationStatus.CanAccess,
  startDate: 1640004031,
  dueDate: 1671473300,
  blockDate: null,
  reason: null,
  type: CustomerPlanType.Order,
  planView: planViewMock,
  seats: [seatMock, { ...seatMock, id: '5cc1a394-b22b-ygh4-9f7d-c8ll333cff38' }],
};

const orderMock = {
  id: 'orderId',
  status: OrderStatus.Create,
  subscriptionStatus: 'subscriptionStatus',
  customerProductPlanId: 'customerProductPlanId',
  nextBillDate: 3333333,
  createdAt: 444444,
  canceledAt: 555555,
  nextPaymentAmount: 500,
  currency: 'USD',
  updateUrl: 'updateUrl',
  cancelUrl: 'cancelUrl',
  checkoutId: '123-213-123-123',
};

const testMocks = {
  planViewMock,
  customerPlanMock,
  orderMock,
};

export default testMocks;

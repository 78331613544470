export enum Role {
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  Admin = 'ROLE_ADMIN',
  Support = 'ROLE_SUPPORT',
  AffiliateManager = 'ROLE_AFFILIATE_MANAGER',
}

export enum MemberStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deactivated = 'deactivated',
  Invite = 'invite',
}

export enum RoleTitle {
  ROLE_SUPER_ADMIN = 'Administrator',
  ROLE_ADMIN = 'Administrator',
  ROLE_SUPPORT = 'Support',
  ROLE_AFFILIATE_MANAGER = 'Affiliate Manager',
}

export enum OrganizationPlanActions {
  ChangeOwner = 'Change Owner',
}

export enum CustomerRemoveSteps {
  Confirm = 'confirm',
  Success = 'success',
}

export const ANONYMUS_CUSTOMER = '696a00cb-03d0-4c33-8fad-8d0462d4781b';

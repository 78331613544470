export enum TransactionDescriptionCopy {
  transactionDescriptionPurchasePlan = 'Purchase Plan',
  transactionDescriptionPayment = 'Payment',
  transactionDescriptionUpdatePaymentMethod = 'Update Payment Method',
  transactionDescriptionRefund = 'Refund',
  transactionDescriptionCancelPlan = 'Cancel Plan',
  transactionDescriptionPausePlan = 'Pause Plan',
  transactionDescriptionChangePlan = 'Change Plan',
  transactionDescriptionTrialing = 'Trial',
}

import React from 'react';
import { Grid } from '@macpaw/macpaw-ui';
import { isCompanionPlan } from '~/helpers/plan';
import Section from '../../components/Section/Section';
import { useCustomerPlanActions } from '../../helpers/hooks';
import { CustomerPlanInterface, OrderInterface, OrganizationPlanInterface } from '../../interfaces';
import CustomerPlanGridContent from './CustomerPlanGridContent';
import CustomerPlanManageMenu from './CustomerPlanManageMenu';
import OrganizationPlanGridContent from './OrganizationPlanGridContent';

interface CustomerPlanOverview {
  customerPlan: CustomerPlanInterface;
  order?: OrderInterface;
  refresh: () => void;
  isOrganizationPlan: boolean;
}

const CustomerPlanOverview: React.FC<CustomerPlanOverview> = ({ customerPlan, order, refresh, isOrganizationPlan }) => {
  const actions = useCustomerPlanActions(order, customerPlan);

  const Action = () => (
    <CustomerPlanManageMenu actions={actions} customerPlan={customerPlan} onCompleteAction={refresh} />
  );

  return (
    <Section title={<h2>Plan Overview{isCompanionPlan(customerPlan) && ' (Companion)'}</h2>}>
      <Grid icon={<img src={customerPlan.planView.productPlanIconUrl} alt="" />} action={<Action />}>
        {isOrganizationPlan ? (
          <OrganizationPlanGridContent
            customerPlan={customerPlan as unknown as OrganizationPlanInterface}
            order={order}
          />
        ) : (
          <CustomerPlanGridContent customerPlan={customerPlan} order={order} />
        )}
      </Grid>
    </Section>
  );
};

export default CustomerPlanOverview;

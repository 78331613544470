export enum OrgInviteToPlanFields {
  ProductTag = 'productTag',
  ProductPlanSkuId = 'productPlanSkuId',
  BillImmediatelyPrice = 'billImmediatelyPrice',
  Quantity = 'quantity',
  NextBillingDate = 'nextBillingDate',
  TrialDays = 'trialDays',
  IsDefaultPaymentDate = 'isDefaultPaymentDate',
  InviteMode = 'inviteMode',
}

export enum OrgInviteToPlanStep {
  Form = 'form',
  Confirm = 'confirm',
  Success = 'success',
}

export enum OrgInviteToPlanMode {
  Paid = 'paid',
  Trial = 'trial',
}

export const ORGANIZATION_PLAN_MODE_NAMES = {
  [OrgInviteToPlanMode.Paid]: 'Paid',
  [OrgInviteToPlanMode.Trial]: 'Trial',
};

export const MINIMUM_ORGANIZATION_PLAN_PRICE = 0.7;

import { useMemo } from 'react';
import { ProductPlanType, Tag } from '~/types';
import { PlanViewInterface } from '../../interfaces';

interface PlanListItem {
  id: string;
  name: string;
}

const CUSTOM_NAME: { [key: string]: Partial<PlanViewInterface> } = {
  'Business w/ Dashboard': {
    productTag: Tag.CleanMyMacBusiness,
    productPlanType: ProductPlanType.Subscription,
  },
};

const customProductPlanName = (currentPlan: PlanViewInterface) => {
  for (const [name, nameParameters] of Object.entries(CUSTOM_NAME)) {
    if (Object.entries(nameParameters).every(([key, value]) => currentPlan[key as 'productTag'] === value)) {
      return name;
    }
  }

  return '';
};

const useMemoizedProductBetaPlansByTag = (planViews: PlanViewInterface[], tag: Tag) => {
  const productPlanList = useMemo(() => {
    if (!tag) return [];

    return planViews.reduce<PlanListItem[]>((acc, plan) => {
      const name = plan.productTag === tag && customProductPlanName(plan);

      return name ? [...acc, { id: plan.id, name }] : acc;
    }, []);
  }, [planViews, tag]);

  return productPlanList;
};

export default useMemoizedProductBetaPlansByTag;

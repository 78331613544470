import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { OrganizationPlanInterface } from '../../interfaces';

const REDUCE_PLAN_SIZE_MUTATION = gql`
  mutation reducePlanSize($organizationPlanId: ID!, $reduceSeatCount: Int!) {
    reducePlanSize(organizationPlanId: $organizationPlanId, reduceSeatCount: $reduceSeatCount)
  }
`;

export const useReducePlanSizeMutation = () => {
  const [mutate, { loading }] = useMutation(REDUCE_PLAN_SIZE_MUTATION);

  const reducePlanSize = async (customerPlan: OrganizationPlanInterface, reduceSeatCount: number) => {
    return await mutate({
      variables: {
        organizationPlanId: customerPlan.id,
        reduceSeatCount,
      },
    });
  };

  return {
    reducePlanSize,
    loading,
  };
};

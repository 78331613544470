import { Button, Dropdown, DropdownItem, Loader } from '@macpaw/macpaw-ui';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { useIsBetaPlanUsedQuery } from '../../../graphql/queries';
import GiveBetaPlanButtonLayout from '../GiveBetaPlanButtonLayout';
import styles from '../OrganizationPaymentLinkButton.module.sass';

interface OrganizationPaymentDropdownButtonProps {
  handleInviteToPlanModal: () => void;
  handleBetaPlanModal: () => void;
  organizationId: string;
  disabled: boolean;
}

const OrganizationPaymentDropdownButton: React.FC<OrganizationPaymentDropdownButtonProps> = ({
  handleInviteToPlanModal,
  handleBetaPlanModal,
  organizationId,
  disabled,
}) => {
  const { loading, isBetaPlanUsed } = useIsBetaPlanUsedQuery(organizationId);

  return (
    <Dropdown
      position="center"
      trigger={
        <Button scale="medium" color="secondary" disabled={disabled} iconRight={<DropdownIcon />}>
          Create a New Plan
        </Button>
      }>
      <DropdownItem onClick={handleInviteToPlanModal} className={styles.dropdownButton}>
        Standard Plan
      </DropdownItem>

      <GiveBetaPlanButtonLayout isBetaPlanUsed={isBetaPlanUsed} loading={loading}>
        <DropdownItem
          onClick={handleBetaPlanModal}
          className={styles.dropdownButton}
          disabled={isBetaPlanUsed || loading}>
          Beta Plan
          {loading && <Loader size={20} className={styles.betaPlanLoader} inline />}
        </DropdownItem>
      </GiveBetaPlanButtonLayout>
    </Dropdown>
  );
};

export default OrganizationPaymentDropdownButton;

import * as Yup from 'yup';

export const getValidateEmailShape = (invalidEmail: string, emptyEmail?: string) => {
  const isRequired = !!emptyEmail;
  const basicValidationSchema = Yup.string().trim().email(invalidEmail);

  if (!isRequired) {
    return basicValidationSchema;
  }

  return basicValidationSchema.required(emptyEmail);
};

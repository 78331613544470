import { gql, useMutation } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { EMAIL_HAS_BEEN_SENT } from '../../constants';

export const RESEND_ACTIVATION_EMAIL_MUTATION = gql`
  mutation ResendActivationEmail($customerPlanId: ID!) {
    resendActivationEmail(customerPlanId: $customerPlanId)
  }
`;

interface ResendActivationEmailMutationVariables {
  customerPlanId: string;
}

interface UseResendActivationEmailMutationInput {
  onComplete?: () => void;
}

export const useResendActivationEmailMutation = ({ onComplete }: UseResendActivationEmailMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<ResendActivationEmailMutationVariables>(RESEND_ACTIVATION_EMAIL_MUTATION);

  const resendEmail = async (customerPlanId: string) => {
    await mutate({
      variables: {
        customerPlanId,
      },
    });
    notify(EMAIL_HAS_BEEN_SENT, NotificationType.Success);
    onComplete?.();
  };

  return {
    resendEmail,
    loading,
  };
};

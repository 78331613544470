import { useEffect, useRef } from 'react';
import { Maybe } from '~/types';

interface UsePrevious<T> {
  previous: Maybe<T>;
  forceSetPrevious: (value: Maybe<T>) => void;
}

const usePrevious = <T>(value: Maybe<T>): UsePrevious<T> => {
  const ref = useRef<Maybe<T>>();

  const forceSetPrevious = (nextValue: Maybe<T>) => {
    ref.current = nextValue;
  };

  useEffect(() => {
    if (value !== ref.current) forceSetPrevious(value);
  }, [value]);

  return {
    previous: ref.current,
    forceSetPrevious,
  };
};

export default usePrevious;

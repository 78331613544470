import { MS_IN_S } from '~/utils';
import { CreateCustomerData } from '../../interfaces';

const createCustomerGQLEntity = ({ customerId: id, email }: CreateCustomerData) => ({
  id,
  email,
  name: null,
  isCompleted: false,
  emailConfirmed: false,
  language: 'en',
  source: 'support',
  roles: ['ROLE_USER'],
  createdAt: Math.ceil(Date.now() / MS_IN_S),
  __typename: 'Customer',
});

export default createCustomerGQLEntity;

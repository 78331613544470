import { gql, useQuery } from '@apollo/client';
import { BasePaymentSchema, Maybe } from '~/types';
import { GQLResponse, PaymentInterface } from '../../interfaces';

export const PAYMENT_INFO_QUERY = gql`
  query PaymentInfo($planId: ID!) {
    paymentInfo(planId: $planId) {
      ${BasePaymentSchema}
    }
  }
`;

interface UsePaymentInfoQueryInput {
  planId: Maybe<string>;
  skip?: boolean;
}

interface UsePaymentInfoQuery {
  paymentInfo: Maybe<PaymentInterface>;
  loading: boolean;
}

export const usePaymentInfoQuery = ({ planId, skip }: UsePaymentInfoQueryInput): UsePaymentInfoQuery => {
  const { data, loading } = useQuery<GQLResponse<'paymentInfo', PaymentInterface>, Record<'planId', Maybe<string>>>(
    PAYMENT_INFO_QUERY,
    {
      skip: skip || !planId,
      variables: {
        planId,
      },
    },
  );

  return {
    paymentInfo: data?.paymentInfo,
    loading,
  };
};

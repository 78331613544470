import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContent, DialogActions, Button, Input, FormRow } from '@macpaw/macpaw-ui';
import { StepItemProps, FormNumberInput } from '~/ui';
import { AddDeviceSlotsToPlanFields, AddDeviceSlotsToPlanSteps } from '../../../constants';
import { ORGANIZATION_PLAN_MAX_SEATS_COUNT } from '../../../constants/validation';
import { useAddDeviceSlotsToPlanMutation } from '../../../graphql/mutations';
import handleFormServerError from '../../../helpers/handleFormServerError';
import { OrganizationPlanInterface } from '../../../interfaces';
import styles from './AddDeviceSlotsToPlanForm.module.sass';

interface AddDeviceSlotsToPlanFormProps extends Partial<StepItemProps> {
  plan: OrganizationPlanInterface;
  vacantSeatsCount: number;
  seatsCount: number;
  onCancel: () => void;
}

const AddDeviceSlotsToPlanForm: React.FC<AddDeviceSlotsToPlanFormProps> = ({
  plan,
  vacantSeatsCount,
  seatsCount,
  onCancel,
  ...props
}) => {
  const { setNextStep, setStep } = props as StepItemProps;

  const [isLoading, setLoading] = useState(false);
  const { setError, handleSubmit, watch, setValue } = useFormContext();

  useEffect(() => {
    setValue(AddDeviceSlotsToPlanFields.CurrentSeatCount, seatsCount);
  }, []);

  const addedSeatCount = watch(AddDeviceSlotsToPlanFields.AddedSeatCount);

  const { addDeviceSlotsToPlan } = useAddDeviceSlotsToPlanMutation();

  const handleSubmitForm = handleSubmit(async (formData) => {
    try {
      setLoading(true);

      await addDeviceSlotsToPlan(plan, Number(formData[AddDeviceSlotsToPlanFields.AddedSeatCount]));
      setStep(AddDeviceSlotsToPlanSteps.Success);

      setNextStep();
    } catch (error) {
      handleFormServerError(error as Error, setError);
    } finally {
      setLoading(false);
    }
  });

  return (
    <>
      <DialogContent>
        <h3 className={styles.modalTitle}>Add Additional Device Slots to Plan Size</h3>
        <FormRow>
          <Input
            type="text"
            name="plan"
            value={plan.planView.productName}
            placeholder="Disabled"
            disabled
            label="Plan"
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            name="vacantSlots"
            value={`${vacantSeatsCount} of ${seatsCount}`}
            placeholder="Disabled"
            disabled
            label="Vacant Device Slots"
          />
        </FormRow>
        <FormRow>
          <FormNumberInput
            name={AddDeviceSlotsToPlanFields.AddedSeatCount}
            label="Add Device Slots to Plan"
            max={ORGANIZATION_PLAN_MAX_SEATS_COUNT - seatsCount}
          />
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSubmitForm} disabled={addedSeatCount <= 0} loading={isLoading}>
          Add Device Slots
        </Button>
      </DialogActions>
    </>
  );
};

export default AddDeviceSlotsToPlanForm;

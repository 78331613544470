import React from 'react';
import { Banner, Button } from '@macpaw/macpaw-ui';
import getIsPastDueWithAccess from '../../helpers/getIsPastDueWithAccess';
import { PlanInterface } from '../../interfaces';
import { CUSTOMER_PLAN_NOTIFICATIONS } from './customerPlanNotifications';
import { DETAILED_CUSTOMER_PLAN_NOTIFICATIONS } from './detailedCustomerPlanNotification';

interface PlanNotification {
  customerPlan: PlanInterface;
  detailed?: boolean;
  onStatusChanged: () => void;
}

const PlanNotification: React.FC<PlanNotification> = ({ customerPlan, detailed, onStatusChanged }) => {
  const { status } = customerPlan;

  if (getIsPastDueWithAccess(customerPlan)) {
    const action = (
      <Button href="#payment" scale="medium" color="secondary">
        Go to Section
      </Button>
    );

    return (
      <Banner action={action}>
        <b>Payment failed</b>. Need update billing method.
      </Banner>
    );
  }

  if (status in CUSTOMER_PLAN_NOTIFICATIONS) {
    const notification = CUSTOMER_PLAN_NOTIFICATIONS[status as keyof typeof CUSTOMER_PLAN_NOTIFICATIONS];

    return notification(customerPlan, onStatusChanged);
  }

  // detailed statuses are additional statuses for Product Plan View page
  if (detailed && status in DETAILED_CUSTOMER_PLAN_NOTIFICATIONS) {
    const notification =
      DETAILED_CUSTOMER_PLAN_NOTIFICATIONS[status as keyof typeof DETAILED_CUSTOMER_PLAN_NOTIFICATIONS];

    return notification(customerPlan, onStatusChanged);
  }

  return null;
};

export default PlanNotification;

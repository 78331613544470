import React from 'react';
import { CardMask } from '@macpaw/macpaw-ui';
import { CardMaskProps } from '@macpaw/macpaw-ui/lib/CardMask/CardMask';
import { BasePayment, PaymentMethod } from '~/types';

interface PaymentMask extends CardMaskProps {
  paymentInfo: BasePayment | null;
}

const PaymentMask: React.FC<PaymentMask> = (props) => {
  const { paymentInfo, short, ...other } = props;

  if (paymentInfo?.paymentMethod === PaymentMethod.PayPal) {
    return <>PayPal</>;
  }

  return <CardMask digits={paymentInfo?.lastFourDigits || ''} short={short} {...other} />;
};

export default PaymentMask;

export enum GiveOrganizationBetaPlanModalStep {
  Form = 'form',
  Success = 'success',
  Error = 'error',
}

export enum GiveOrganizationBetaPlanFields {
  ProductTag = 'ProductTag',
  ProductPlanSkuId = 'ProductPlanSkuId',
  Quantity = 'Quantity',
}

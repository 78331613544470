import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { BaseTransactionSchema, GQLResponse } from '~/types';
import { TransactionInterface } from '../../interfaces';

export const TRANSACTIONS_QUERY = gql`
  query Transactions($customerPlanId: ID!) {
    transactions(customerPlanId: $customerPlanId) {
      ${BaseTransactionSchema}
      externalOrderId
    }
  }
`;

interface UseTransactionsQueryInput {
  customerPlanId: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

interface UseTransactionsQueryOutput {
  loading: boolean;
  transactions: TransactionInterface[];
}

export const useTransactionsQuery = ({
  customerPlanId,
  skip,
  fetchPolicy = 'cache-and-network',
}: UseTransactionsQueryInput): UseTransactionsQueryOutput => {
  const { data, loading } = useQuery<
    GQLResponse<'transactions', TransactionInterface[]>,
    Record<'customerPlanId', string>
  >(TRANSACTIONS_QUERY, {
    skip: skip || !customerPlanId,
    fetchPolicy,
    variables: {
      customerPlanId,
    },
  });

  return {
    loading,
    transactions: data?.transactions ?? [],
  };
};

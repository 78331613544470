// Environment
export const APP_ENVIRONMENT = process.env.VITE_APP_ENVIRONMENT ?? 'production';
export const APP_IS_DEVELOP = APP_ENVIRONMENT === 'develop';
export const APP_IS_PRODUCTION = APP_ENVIRONMENT === 'production';

// API
export const APP_API_CLIENT_ID = process.env.VITE_APP_API_CLIENT_ID ?? '';

// GraphQL
export const APP_GRAPHQL_ENDPOINT = process.env.VITE_APP_GRAPHQL_ENDPOINT ?? '';

// Sentry
export const APP_SENTRY_DNS = process.env.VITE_APP_SENTRY_DNS ?? '';

// URLs
export const APP_CUSTOMER_ACCOUNT_URL = process.env.VITE_APP_CUSTOMER_ACCOUNT_URL ?? '';

import React from 'react';

const findChildrenContent = <ElementType extends React.ReactNode>(element: ElementType): string | boolean => {
  if (typeof element === 'string') return element;
  if (typeof element === 'number') return element.toString();
  if (typeof element === 'boolean') return element;
  if (!element) return '';
  if (Array.isArray(element)) return element.map(findChildrenContent).join('');
  if (React.isValidElement(element)) return findChildrenContent(element.props.children);

  const { props, type } = element as unknown as React.ReactElement;

  if (type) {
    if (props?.children) {
      if (typeof props.children === 'string') return props.children;

      return findChildrenContent(props.children);
    }
  }

  return '';
};

export default findChildrenContent;

import React, { ReactNode } from 'react';
import { Loader } from '@macpaw/macpaw-ui';
import { conditionValue, findChildrenContent } from '~/utils';

interface LoadableTextProps<ChildrenType> {
  children: ChildrenType;
  loaderSize?: number;
  isLoading?: boolean;
  isConditional?: boolean;
  formatter?: (value: ChildrenType) => string | React.ReactNode;
}

const DEFAULT_LOADER_SIZE = 24;

// eslint-disable-next-line func-style
function LoadableText<ChildrenType>({
  isLoading,
  children,
  loaderSize = DEFAULT_LOADER_SIZE,
  isConditional,
  formatter,
}: LoadableTextProps<ChildrenType>): JSX.Element {
  if (isLoading) return <Loader inline size={loaderSize} />;

  const value = children ?? '';
  const childrenContent = findChildrenContent(value as unknown as React.ReactNode);
  const nextChildrenContent = isConditional ? conditionValue(childrenContent) : childrenContent;
  const formattedChildrenContent = formatter?.(nextChildrenContent as unknown as ChildrenType) ?? nextChildrenContent;

  const isChildrenValidElementOrArray = React.isValidElement(value) || Array.isArray(value);

  const isFormattedPerformed = typeof formatter === 'function' && formattedChildrenContent !== childrenContent;
  const isConditionalPerformed = nextChildrenContent !== childrenContent;
  const nextChildren = isFormattedPerformed || isConditionalPerformed ? formattedChildrenContent : value;

  if (!isChildrenValidElementOrArray) {
    return <>{nextChildren}</>;
  }

  return <>{React.createElement(React.Fragment, {}, nextChildren as ReactNode)}</>;
}

export default LoadableText;

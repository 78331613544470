/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, FC, PropsWithChildren, useContext } from 'react';

interface TranslationMessageDescriptor {
  id?: string;
  description?: string | object;
  defaultMessage?: any;
}

export interface FormContextValue {
  trans: (id: string | TranslationMessageDescriptor, values?: ObjectLiteral, options?: ObjectLiteral) => string;
}

const initialValue: FormContextValue = {
  trans: (id) => (typeof id === 'string' ? id : id.defaultMessage),
};

export const FormContext = createContext<FormContextValue>(initialValue);

export const FormProvider: FC<PropsWithChildren<Partial<FormContextValue>>> = ({
  trans = initialValue.trans,
  children,
}) => {
  return <FormContext.Provider value={{ trans }}>{children}</FormContext.Provider>;
};

export const useFormContext = (): FormContextValue => {
  return useContext(FormContext);
};

import { gql, useQuery } from '@apollo/client';
import { Maybe, BasePagination } from '~/types';
import { useQueryParams } from '~/utils';
import { LIMIT_PER_PAGE } from '../../constants';
import { CustomerInterface, ListQueryInput, GQLResponse } from '../../interfaces';
import { CUSTOMER_FRAGMENT, PAGINATION_FRAGMENT } from '../fragments';

export const CUSTOMERS_QUERY = gql`
  query Customers($input: PaginatedListParams) {
    customers(input: $input) {
      data {
        ...CustomerFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export interface CustomersQuery {
  data: CustomerInterface[];
  pagination: Maybe<BasePagination>;
}

interface UseCustomersQuery {
  customers: CustomerInterface[];
  pagination: Maybe<BasePagination>;
  loading: boolean;
  refetch: () => void;
}

interface UseCustomersQueryOptions {
  skip?: boolean;
  search?: string;
  onCompleted?: (data: GQLResponse<'customers', CustomersQuery>) => void;
  strict?: boolean;
}

export const useCustomersQuery = ({
  skip,
  search: manualSearch,
  onCompleted,
  strict,
}: UseCustomersQueryOptions = {}): UseCustomersQuery => {
  const queryParams = useQueryParams();
  const search = manualSearch || queryParams.get('q') || '';
  const page = queryParams.get('page') || '1';

  const { data, loading, refetch } = useQuery<
    GQLResponse<'customers', CustomersQuery>,
    Record<'input', Partial<ListQueryInput>>
  >(CUSTOMERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: LIMIT_PER_PAGE,
        page: Number(page),
        search,
        strict,
      },
    },
    skip,
    onCompleted,
  });

  return {
    customers: data?.customers?.data ?? [],
    pagination: data?.customers?.pagination,
    loading: !data || loading,
    refetch,
  };
};

export default useCustomersQuery;

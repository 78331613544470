import React from 'react';
import { Loadable } from '~/ui';
import { usePlansByOwnerIdQuery } from '../../graphql/queries';
import { PlanInterface } from '../../interfaces';
import PlansList from '../PlansList/PlansList';
import styles from './PlansByOwner.module.sass';

interface PlansByOwnerProps {
  ownerId: string;
  planPath: (ownerId: string, planId: string) => string;
  planGrouper?: (plans: PlanInterface[]) => Record<string, PlanInterface[]>;
}

const PlansByOwner: React.FC<PlansByOwnerProps> = ({ ownerId, planPath, planGrouper }) => {
  const { loading, plans } = usePlansByOwnerIdQuery({ ownerId });

  return (
    <Loadable isLoading={loading} className={styles.loader} loaderInline>
      <PlansList ownerId={ownerId} planPath={planPath} plans={plans} planGrouper={planGrouper} />
    </Loadable>
  );
};

export default PlansByOwner;

import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Dropdown, DropdownItem, Button, notify } from '@macpaw/macpaw-ui';
import { AccountIcon, DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { NotificationType } from '~/types';
import { emailTrim } from '~/utils';
import client from '../../ApolloClient';
import { signOut } from '../../auth';
import { ROUTE_PATHS } from '../../constants';
import { SUPPORT_PROFILE_QUERY } from '../../graphql/queries';
import { isAdmin } from '../../helpers/permissions';

const HeaderProfile: React.FC = () => {
  const { data } = useQuery(SUPPORT_PROFILE_QUERY, { fetchPolicy: 'cache-only' });

  if (!data?.profile) return null;

  const { profile } = data;

  const handleSignOut = () => {
    signOut(client);
    notify('You have successfully been signed out.', NotificationType.Success);
  };

  return (
    <div className="nav-customer">
      <Dropdown
        trigger={
          <Button
            className="nav-menuButton"
            color="transparent"
            iconLeft={<AccountIcon style={{ width: 24, height: 24 }} />}
            iconRight={<DropdownIcon />}>
            My Account
          </Button>
        }
        position="right">
        <DropdownItem withoutAction>
          <h5>{profile.name ?? 'MacPaw User'}</h5>
          <div className="p4 nav-email">{emailTrim(profile.email)}</div>
        </DropdownItem>
        <DropdownItem separator />
        {isAdmin(profile) && (
          <DropdownItem component={Link as unknown as ReactNode} to={ROUTE_PATHS.members()}>
            Team Members
          </DropdownItem>
        )}
        <DropdownItem component={Link as unknown as ReactNode} to={ROUTE_PATHS.settings()}>
          Settings
        </DropdownItem>
        <DropdownItem separator />
        <DropdownItem onClick={handleSignOut}>Sign Out</DropdownItem>
      </Dropdown>
    </div>
  );
};

export default HeaderProfile;

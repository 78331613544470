import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@macpaw/macpaw-ui';

interface ErrorScreen {
  styles: Readonly<{ [key: string]: string }>;
  icon: ReactNode;
  title: ReactNode;
  linkTitle: ReactNode;
  linkPath?: string;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  linkComponent?: ReactNode | typeof Link;
  description?: ReactNode;
  hint?: ReactNode;
  linkOnClick?: () => void;
}

const ErrorScreen: React.FC<ErrorScreen> = ({
  styles,
  icon,
  title,
  description,
  hint,
  linkTitle,
  titleVariant = 'h2',
  linkPath,
  linkComponent,
  linkOnClick,
}) => {
  const buttonPathProp = linkComponent ? 'to' : 'href';
  const buttonProps = linkOnClick ? { onClick: linkOnClick } : { [buttonPathProp]: linkPath };

  return (
    <div className={styles.error}>
      {icon}
      <div className={titleVariant}>{title}</div>
      {description}
      {!!(description && hint) && <br />}
      {hint}
      <div className={styles.actions}>
        <Button component={linkComponent as ReactNode} color="secondary" {...buttonProps}>
          {linkTitle}
        </Button>
      </div>
    </div>
  );
};

export default ErrorScreen;

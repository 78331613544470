import { ReactNode } from 'react';

export type Maybe<T> = null | undefined | T;

export type BaseCountry = {
  name: string;
  code: string;
};

export type State = {
  name: string;
  code: string;
};

export interface ErrorInfo {
  componentStack: string;
}

export interface ErrorFallbackComponentsProps {
  info?: Maybe<ErrorInfo>;
  error?: Error;
}

export enum Locale {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  uk = 'uk',
  zh = 'zh',
}

export enum Tag {
  CleanMyMacBusiness = 'cleanmymac-business',
  CleanMyMac = 'cleanmymac',
  CleanMyMacY = 'cleanmymacy',
  CleanMyPhone = 'cleanmyphone',
  Gemini = 'gemini',
  ClearVPN = 'clearvpn',
  CleanMyPC = 'cleanmypc',
}

export enum CustomerRole {
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  User = 'ROLE_USER',
  OrganizationMember = 'ROLE_ORGANIZATION_MEMBER',
}

export enum OrganizationMemberRole {
  Admin = 'admin',
  Member = 'member',
  Owner = 'owner',
}

export enum ActivationStatus {
  CanAccess = 'can_access',
  CannotAccess = 'cannot_access',
  WaitingUpdateDB = 'waiting_update_db',
}

export enum CustomerPlanTypeForRelatedPlans {
  Companion = 'companion',
  Parent = 'parent',
  Simple = 'simple',
}

export const CustomerNotificationPlanStatus = {
  OptInTrialing: 'opt_in_trialing',
  Cancelled: 'cancelled',
  NoOrder: 'no_order',
  CancelApproved: 'cancel_approved',
  PauseRequested: 'pause_requested',
  DisputeOpened: 'dispute_opened',
  FraudCheck: 'fraud_check',
  Paused: 'paused',
  PastDue: 'past_due',
  Refunded: 'refunded',
  Refresh: 'refresh',
  UpgradeToPaidPlan: 'upgrade_to_paid_plan',
  NewPlan: 'new_plan',
};

// TODO: check updated paddle api, remove master and union_pay if it was replaces by mastercard otherwise leave both
export enum CardType {
  Master = 'master',
  Mastercard = 'mastercard',
  Visa = 'visa',
  Amex = 'amex',
  Discover = 'discover',
  JCB = 'jcb',
  Maestro = 'maestro',
  Mada = 'mada',
  DinersClub = 'diners_club',
  UnionPay = 'unionpay',
  Union_Pay = 'union_pay',
}

export enum PaymentMethod {
  Card = 'card',
  PayPal = 'paypal',
}

export enum CustomerPlanStatus {
  Active = 'active',
  PauseRequested = 'pause_requested',
  CancelRequested = 'cancel_requested',
  CancelApproved = 'cancel_approved',
  PastDue = 'past_due',
  Paused = 'paused',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  FraudCheck = 'fraud_check',
  DisputeOpened = 'dispute_opened',
  PaymentDisputeBlocked = 'payment_dispute_blocked',
  HighRiskTransactionBlocked = 'high_risk_transaction_blocked',
  Blocked = 'blocked',
  Trialing = 'trialing',
  OptInTrialing = 'opt_in_trialing',
  NoOrder = 'no_order',
}

export enum PlanFeature {
  Upgrade = 'upgrade',
  LaunchApp = 'launchApp',
  Ownership = 'ownership',
  Management = 'management',
  PaymentInfo = 'paymentInfo',
  Activate = 'activate',
}

export enum TransactionPaymentStatus {
  Success = 'success',
  Failed = 'failed',
  Open = 'open',
  Closed = 'closed',
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum SeatStatus {
  Active = 'active',
  Pending = 'pending',
}

export enum CustomerPlanGroup {
  NeedsAttention = 'needs_attention',
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
  NewPlan = 'new_plan',
}

export enum OrganizationPlanGroup {
  Active = 'active',
  Inactive = 'inactive',
}

export enum ProductPlanType {
  Subscription = 'subscription',
  OneTime = 'oneTime',
}

export enum CustomerPlanType {
  Order = 'order',
  Support = 'support',
  Reseller = 'reseller',
  OptIn = 'opt-in',
  CmmBusinessBeta = 'cmm-business-beta',
  DevmateReseller = 'devmate-reseller',
}

export enum OrderStatus {
  Create = 'create',
  Blocked = 'blocked',
  FraudCheck = 'fraud_check',
  DisputeOpened = 'dispute_opened',
  InProgress = 'in_progress',
  Finish = 'finish',
  Trialing = 'trialing',
}

export interface BaseProduct {
  id: string;
  name: string;
  publicName: string;
  downloadApplications: [BaseDownloadApp];
  description: string;
  iconUrl: string;
  tag: Tag;
  priority: number;
  externalAccountUrl: Maybe<string>;
  urlSchemes: Maybe<string>;
}

export const BaseProductSchema = `
  id
  name
  publicName
  description
  iconUrl
  tag
  priority
  externalAccountUrl
  urlSchemes
`;

export interface BaseDownloadApp {
  url: string;
  name: string;
  platform: string;
}

export const BaseDownloadAppSchema = `
  url
  name
  platform
`;

export interface BaseOwner {
  id: string;
  email: string;
  name: Maybe<string>;
}

export const BaseOwnerSchema = `
  id
  email
  name
`;

export interface BasePlanView {
  id: string;
  vendorId: string;
  productId: string;
  productSlug: string;
  productTag: Tag;
  productName: string;
  productPublicName: string;
  productDescription: string;
  productIconUrl: string;
  productPriority: number;
  productDownloadUrl: string;
  productUrlSchemes: Maybe<string>;
  productExternalAccountUrl: Maybe<string>;
  productPlanId: string;
  productPlanType: ProductPlanType;
  productPlanSeatCount: number;
  productPlanActivationPeriod: Maybe<number>;
  productPlanGracePeriod: Maybe<number>;
  productPlanName: string;
  productPlanIconUrl: string;
}

export const BasePlanViewSchema = `
  id
  vendorId
  productId
  productSlug
  productTag
  productName
  productPublicName
  productDescription
  productIconUrl
  productPriority
  productDownloadUrl
  productUrlSchemes
  productExternalAccountUrl
  productPlanId
  productPlanType
  productPlanSeatCount
  productPlanActivationPeriod
  productPlanGracePeriod
  productPlanName
  productPlanIconUrl
`;

export enum BasePlanOwnerType {
  Customer = 'customer',
  Organization = 'organization',
}

export interface BaseCustomerPlan {
  id: string;
  ownerId: string;
  ownerType?: BasePlanOwnerType;
  productPlanSkuId: string;
  status: CustomerPlanStatus;
  activationStatus: ActivationStatus;
  startDate: number;
  dueDate: Maybe<number>;
  blockDate: Maybe<number>;
  type: CustomerPlanType;
  reason: Maybe<string>;
  planView: BasePlanView;
}

export const BaseCustomerPlanSchema = `
  id
  ownerId
  productPlanSkuId
  status
  activationStatus
  startDate
  dueDate
  blockDate
  type
  reason
`;

export interface BaseOrder {
  id: string;
  status: OrderStatus;
  subscriptionStatus?: string;
  customerProductPlanId: string;
  nextBillDate: Maybe<number>;
  createdAt: number;
  canceledAt: Maybe<number>;
  nextPaymentAmount: Maybe<number>;
  currency: Maybe<string>;
  updateUrl: Maybe<string>;
  cancelUrl: Maybe<string>;
  checkoutId: Maybe<string>;
}

export const BaseOrderSchema = `
  id
  status
  subscriptionStatus
  customerProductPlanId
  nextBillDate
  createdAt
  canceledAt
  nextPaymentAmount
  currency
  updateUrl
  cancelUrl
  checkoutId
`;

export enum ResellerOrderStatus {
  Created = 'created',
  Pending = 'pending',
  Paid = 'paid',
  Overdue = 'overdue',
}

export enum RecycleCodeFlowStep {
  Enter = 'enter',
  Confirm = 'confirm',
  Success = 'success',
}

export interface BasePayment {
  id: string;
  paymentMethod: PaymentMethod;
  lastFourDigits: Maybe<string>;
  cardType: Maybe<CardType>;
  expiryYear: Maybe<number>;
  expiryMonth: Maybe<string>;
}

export const BasePaymentSchema = `
  id
  paymentMethod
  lastFourDigits
  cardType
  expiryYear
  expiryMonth
`;

export interface BaseTransaction {
  id: string;
  date: number;
  refundReason: Maybe<string>;
  description: string;
  paymentStatus: TransactionPaymentStatus;
  amount: Maybe<number>;
  currency: Maybe<string>;
  receiptUrl: Maybe<string>;
}

export const BaseTransactionSchema = `
  id
  date
  refundReason
  description
  paymentStatus
  amount
  currency
  receiptUrl
`;

export interface BaseSeat {
  id: string;
  customerId: string;
  status: SeatStatus;
  lastAccessDate: Maybe<number>;
  machineName: Maybe<string>;
  machineModel: Maybe<string>;
  machineModelIconUrl: Maybe<string>;
  serialNumber: Maybe<string>;
}

export interface SeatInterface extends BaseSeat {}

export const BaseSeatSchema = `
  id
  customerId
  status
  lastAccessDate
  machineName
  machineModel
  machineModelIconUrl
  serialNumber
`;

export interface BaseUser {
  id: string;
  email: string;
  name: Maybe<string>;
}

export const BaseUserSchema = `
  id
  email
  name
`;

export interface BaseProfile extends BaseUser {
  roles?: CustomerRole[];
}

export const BaseProfileSchema = `
  ${BaseUserSchema}
  roles
`;

export const PreviewProfileSchema = `
  ${BaseUserSchema}
  roles
  isCompleted
`;

export interface BaseCustomer extends BaseUser {
  emailConfirmed: boolean;
  language: Locale;
}

export const BaseCustomerSchema = `
  ${BaseUserSchema}
  emailConfirmed
  language
`;

export enum CustomerAccountType {
  PersonalAccount = 'personal',
  BusinessAccount = 'business',
}

export enum ResellerCurrency {
  USD = 'USD',
  EUR = 'EUR',
}

export interface BaseProductPlan {
  id: string;
  type: ProductPlanType;
  seatCount: number;
  name: string;
  iconUrl: string;
  activationPeriod: Maybe<number>;
}

export const BaseProductPlanSchema = `
  id
  type
  seatCount
  activationPeriod
  name
  iconUrl
`;

export interface BasePagination {
  currentPage: number;
  elementsCount: number;
  pagesCount: number;
  perPage: number;
}

export const BasePaginationSchema = `
  currentPage
  elementsCount
  pagesCount
  perPage
`;

export interface BaseOrganization {
  id: string;
  name: string;
  country: BaseCountry;
  city: string;
  address: string;
  postCode: string;
  state?: State;
  vatNumber?: string;
}

export const BaseOrganizationSchema = `
  id
  name
  country {
    code
    name
  }
  city
  address
  postCode: postcode
  state {
    code
    name
  }
  vatNumber
`;

export interface BaseOrganizationMember {
  customerId: string;
  role: OrganizationMemberRole;
}

export const BaseOrganizationMemberSchema = `
  customerId
  role
`;

export interface EditOrganizationDetailsParams {
  name: string;
  country: string;
  city: string;
  address: string;
  postCode: string;
  state?: string;
  vatNumber?: string;
  organizationId: string;
}

export interface OrganizationMember {
  customerId: string;
  role: OrganizationMemberRole;
}

export interface OrganizationProductPricesSchema {
  id: string;
  customPrice: number;
  defaultPrice: number;
}

export const OrganizationProductPricesSchema = `
  id
  customPrice
  defaultPrice
`;

export enum NotificationType {
  Success = 'success',
  Error = 'error',
}

export interface GQLServerError {
  path: string;
  code: string;
  message: string;
}

export interface GQLServerErrorBody extends Response {
  errors?: GQLServerError[];
}

export interface GQLServerErrorResponse {
  body: GQLServerErrorBody;
}

export interface GQLErrorExtension {
  code: string;
  response: GQLServerErrorResponse;
}

export interface Country extends BaseCountry {
  hasVatNumber: boolean;
}

export interface LocationState {
  pathname?: string;
}

export type GQLResponse<Key extends string, ResponseType extends ObjectLiteral> = Record<Key, ResponseType>;

export interface BreadcrumbsConfigItem {
  path: string;
  disabled?: boolean;
  crumbNode: () => ReactNode;
  children?: BreadcrumbsConfigItem[];
}

export type BreadcrumbsItem = Omit<BreadcrumbsConfigItem, 'children'>;

export interface PaddlePriceObjectI {
  payNowPrice: string;
  recurrentPrice: string;
  recurrentIntervalType: string;
  recurrentIntervalLength: number | string;
}

export enum ConfirmationType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  WarningInfo = 'warning-info',
  Download = 'download',
  Key = 'key',
  Settings = 'settings',
  Discount = 'discount',
}

export type ConfirmationModalType = (typeof ConfirmationType)[keyof typeof ConfirmationType];

export interface ConfirmationModalBodyBasicProps {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  actionTitle?: string;
  actions?: React.ReactNode;
  type?: ConfirmationModalType;
  icon?: React.ReactNode;
  descriptionClassName?: string;
  contentClassName?: string;
  loading?: boolean;
  actionLoading?: boolean;
  onSubmit?: () => void;
  actionColor?: string;
  dataHook?: string;
}

export enum PlanCategory {
  External = 'external',
  Campaigning = 'campaigning',
  Shared = 'shared',
  Default = 'default',
}

export interface PropsWithDefaultIcon extends ConfirmationModalBodyBasicProps {
  type: ConfirmationModalType;
}

export interface PropsWithCustomIcon extends ConfirmationModalBodyBasicProps {
  icon: React.ReactNode;
}

export type ConfirmationModalBodyProps = PropsWithDefaultIcon | PropsWithCustomIcon;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'clsx';
import {
  Button,
  DialogActions,
  DialogContent,
  FormRow,
  Hint,
  Tab,
  TabList,
  TabPanel,
  TabsProvider,
} from '@macpaw/macpaw-ui';
import { FormCheckBox, FormDatePicker, FormInput, FormSelect, Loadable, LoadableText, StepItemProps } from '~/ui';
import { formatCurrency } from '~/utils';
import { OrgInviteToPlanFields, OrgInviteToPlanMode } from '../../../constants';
import { useGetOrganizationSlotPriceQuery, usePlanViewsQuery } from '../../../graphql/queries';
import { useMemoizedProductPlansByTag, useMemoizedUniqProductsList } from '../../../helpers/hooks';
import styles from './InviteToPlanForm.module.sass';

interface InviteToPlanFormProps extends Partial<StepItemProps> {
  onCancel: () => void;
  organizationId: string;
}

const InviteToPlanForm: React.FC<InviteToPlanFormProps> = ({ onCancel, organizationId, ...props }) => {
  const { setNextStep, prevStepKey } = props as StepItemProps;

  const isLoading = false;

  const planViewsFetchPolicy = prevStepKey ? 'cache-first' : 'network-only';
  const {
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { isDirty },
  } = useFormContext();

  const { planViews, loading: isPlansLoading } = usePlanViewsQuery({
    fetchPolicy: planViewsFetchPolicy,
    isBusiness: true,
  });

  const { loadSlotPrice, defaultPrice, customPrice, loading: loadingPrice } = useGetOrganizationSlotPriceQuery();

  const devicePrice = (customPrice || defaultPrice) ?? 0;

  const productTag = watch(OrgInviteToPlanFields.ProductTag);
  const inviteMode = watch(OrgInviteToPlanFields.InviteMode);
  const isDefaultPaymentDate = watch(OrgInviteToPlanFields.IsDefaultPaymentDate);
  const productPlanSkuId = watch(OrgInviteToPlanFields.ProductPlanSkuId);
  const quantity = watch(OrgInviteToPlanFields.Quantity);

  useEffect(() => {
    if (productPlanSkuId) loadSlotPrice(organizationId, productPlanSkuId);
  }, [productPlanSkuId]);

  const uniqProductsList = useMemoizedUniqProductsList(planViews);
  const productPlanList = useMemoizedProductPlansByTag(planViews, productTag);

  const isDisabledSubmit = !isDirty || !productTag;
  const paymentDatePlaceholder = isDefaultPaymentDate ? '1 year after payment' : '05/12/2022';

  const handleSubmitForm = handleSubmit(setNextStep);

  const onChangeInviteMode = (mode: string) => setValue(OrgInviteToPlanFields.InviteMode, mode);

  useEffect(() => {
    if (!isDefaultPaymentDate) return;
    clearErrors(OrgInviteToPlanFields.NextBillingDate);
    setValue(OrgInviteToPlanFields.NextBillingDate, '');
  }, [isDefaultPaymentDate]);

  return (
    <>
      <DialogContent className={styles.dialogContent}>
        <h4 className={cx(styles.dialogTitle, 'mb-24')}>Invite Customer to a New Plan</h4>
        <Loadable isLoading={isPlansLoading} className={styles.loader}>
          <FormRow>
            <FormSelect name={OrgInviteToPlanFields.ProductTag} label="Product" validateOnChange>
              <option disabled value="">
                Select a product
              </option>
              {uniqProductsList.map(({ productName, productTag: tagValue }) => (
                <option key={tagValue} value={tagValue}>
                  {productName}
                </option>
              ))}
            </FormSelect>
          </FormRow>
          <FormRow split>
            <FormSelect
              name={OrgInviteToPlanFields.ProductPlanSkuId}
              label="Product Plan"
              disabled={!productPlanList.length}
              validateOnChange>
              <option disabled value="">
                Select plan
              </option>
              {productPlanList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </FormSelect>
            <FormInput
              name={OrgInviteToPlanFields.Quantity}
              label="Devices"
              type="number"
              placeholder="6-10000"
              disabled={!productTag}
            />
          </FormRow>
          <div className={styles.tabsWrapper}>
            <TabsProvider scale="medium" initialTab={inviteMode} onSelectTab={onChangeInviteMode}>
              <TabList className={styles.tabList}>
                <Tab className={styles.tab} tab={OrgInviteToPlanMode.Paid}>
                  Paid Plan
                </Tab>
                <Tab className={styles.tab} tab={OrgInviteToPlanMode.Trial}>
                  Start With a Trial
                </Tab>
              </TabList>
              <div className={styles.tabs}>
                <TabPanel tab={OrgInviteToPlanMode.Paid}>
                  <FormRow split>
                    <div className={styles.inputWrapper}>
                      <FormInput
                        name={OrgInviteToPlanFields.BillImmediatelyPrice}
                        label="First Payment"
                        type="number"
                        placeholder="0,00"
                        currency="USD"
                      />
                      <Hint className={styles.hint}>
                        The amount customer will need to pay now to start using the new plan.
                      </Hint>
                    </div>
                    <div className={styles.inputWrapper}>
                      <FormDatePicker
                        name={OrgInviteToPlanFields.NextBillingDate}
                        label="Start Date"
                        disabled={isDefaultPaymentDate}
                        placeholder={paymentDatePlaceholder}
                        openSite="center"
                        dateFormat="dd.MM.yyyy"
                      />
                      <div className={styles.checkboxWrapper}>
                        <FormCheckBox
                          name={OrgInviteToPlanFields.IsDefaultPaymentDate}
                          label="Apply default billing cycle"
                          labelClassName={styles.label}
                        />
                      </div>
                    </div>
                  </FormRow>
                </TabPanel>
                <TabPanel tab={OrgInviteToPlanMode.Trial}>
                  <FormInput
                    name={OrgInviteToPlanFields.TrialDays}
                    label="Days on Free Trial"
                    type="number"
                    placeholder="7"
                  />
                  <Hint className={styles.hint}>
                    The customer will be automatically charged a yearly subscription price after the end of the trial.
                  </Hint>
                </TabPanel>
              </div>
            </TabsProvider>
          </div>
          {inviteMode !== OrgInviteToPlanMode.Trial && (
            <>
              <div className={styles.row}>
                <span>Price per Device</span>
                <span className={styles.dots} />
                <span className={styles.value}>
                  <LoadableText isLoading={loadingPrice}>{formatCurrency(devicePrice, 'USD')}</LoadableText>
                </span>
              </div>
              <div className={styles.row}>
                <strong>Total Yearly Price</strong>
                <strong className={styles.dots} />
                <strong className={styles.value}>
                  <LoadableText isLoading={loadingPrice && quantity}>
                    {formatCurrency(devicePrice * quantity, 'USD')}
                  </LoadableText>
                </strong>
              </div>
            </>
          )}
        </Loadable>
      </DialogContent>
      <DialogActions>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSubmitForm} disabled={isDisabledSubmit} loading={isLoading}>
          Create Payment Link
        </Button>
      </DialogActions>
    </>
  );
};

export default InviteToPlanForm;

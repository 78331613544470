import React from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'clsx';
import { Button } from '@macpaw/macpaw-ui';
import { CloseIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import SearchLoadingIcon from '../../images/icons/search-loading-icon.svg?react';

interface SearchFormResetProps {
  className?: string;
  onReset: () => void;
  isLoading?: boolean;
}

const SearchFormReset: React.FC<SearchFormResetProps> = ({ className, onReset, isLoading }) => {
  const { watch, resetField } = useFormContext();

  const search = watch('search');
  const isDisabled = !search;

  const buttonClassName = cx(className, { '-loading': isLoading });

  const handleResetClick = () => {
    resetField('search');
    onReset();
  };

  return (
    <Button icon type="button" className={buttonClassName} disabled={isDisabled} onClick={handleResetClick}>
      {isLoading ? <SearchLoadingIcon /> : <CloseIcon />}
    </Button>
  );
};

export default SearchFormReset;

import { uniq } from 'lodash';
import { PlanViewInterface } from '../interfaces';

export const getUniqueProductIds = (planViews: PlanViewInterface[]) => {
  return uniq(planViews.map(({ productId }) => productId));
};

export const getProductNameByProductId = (expectedProductId: string, planViews: PlanViewInterface[]) => {
  return planViews.find(({ productId }) => productId === expectedProductId)?.productName ?? '';
};

export const getProductByProductId = (expectedProductId: string, planViews: PlanViewInterface[]) => {
  return planViews.find(({ productId }) => productId === expectedProductId);
};

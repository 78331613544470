import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import cx from 'clsx';
import { Button, Panel } from '@macpaw/macpaw-ui';
import { DropdownIcon, PlusIcon, SearchIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { Loadable, useModals } from '~/ui';
import { groupCustomerPlans } from '~/utils';
import CustomerProfile from '../../components/CustomerProfile/CustomerProfile';
import OrganizationInfo from '../../components/OrganizationInfo/OrganizationInfo';
import PlansByOwner from '../../components/PlansByOwner/PlansByOwner';
import SearchableContainer from '../../components/Search/SearchableContainer';
import Section from '../../components/Section/Section';
import WidgetControls from '../../components/Widget/WidgetControlsComponent';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';
import { Modals, OrganizationInfoFields, ROUTE_PATHS, ANONYMUS_CUSTOMER } from '../../constants';
import { CUSTOMER_QUERY, usePlansByOwnerIdQuery } from '../../graphql/queries';
import { isOrganizationMember } from '../../helpers/customer';
import { useCustomerOrganizationInfoQuery } from '../../helpers/hooks';
import anonymusImage from '../../images/anonymus.svg?react';
import OrganizationIcon from '../../images/icons/organization-case.svg?react';
import styles from './Customer.module.sass';

const Customer: React.FC = () => {
  const { customerId = '' } = useParams<Record<'customerId', string>>();
  const { openModal } = useModals();

  const { data: customerProfileData, loading: isLoadingCustomer } = useQuery(CUSTOMER_QUERY, {
    variables: { customerId },
    skip: customerId === ANONYMUS_CUSTOMER,
  });

  const { plans, loading: isLoadingPlans } = usePlansByOwnerIdQuery({ ownerId: customerId });

  const isOrganizationCustomer = isOrganizationMember(customerProfileData?.customer);
  const { organization, owner } = useCustomerOrganizationInfoQuery(customerId, {
    skip: customerId === ANONYMUS_CUSTOMER || !isOrganizationCustomer,
  });

  if (customerId === ANONYMUS_CUSTOMER) {
    return (
      <SearchableContainer>
        <Panel>
          <div className={styles.anonymusBlock}>
            <img src={anonymusImage} width={128} height={128} alt="" />
            <h3 className="h3">This is an anonymous customer</h3>
            <p className="mb-40">
              Some plans are owned anonymously, and we don’t have any customer data for them. To see other plans owned
              by this customer, try searching with a license key.
            </p>
            <div className="mb-16">
              <Button color="secondary" iconLeft={<SearchIcon />} href={ROUTE_PATHS.licenses()}>
                Search Licenses
              </Button>
            </div>
          </div>
        </Panel>
      </SearchableContainer>
    );
  }

  const isLoading = isLoadingCustomer || isLoadingPlans;
  const isCreateOrganizationVisible = !isOrganizationCustomer && !isLoadingCustomer;

  const organizationBlockClassNames = cx({
    [styles.organizationBlockDisabled]: !organization,
  });

  const handleCreateOrganization = () => {
    openModal({
      name: Modals.CreateOrganizationModal,
      props: {
        customerId,
      },
    });
  };

  const handleGivePersonalPlan = () => {
    openModal({
      name: Modals.GivePersonalPlanModal,
      props: {
        customerId,
      },
    });
  };

  const renderAction = () => (
    <>
      {isCreateOrganizationVisible && (
        <Button
          outline
          scale="medium"
          color="secondary"
          iconLeft={<OrganizationIcon />}
          onClick={handleCreateOrganization}>
          Create Organization
        </Button>
      )}
      <Button scale="medium" color="secondary" iconLeft={<PlusIcon />} onClick={handleGivePersonalPlan}>
        Give Personal Plan
      </Button>
    </>
  );

  return (
    <SearchableContainer>
      <Loadable isLoading={isLoading}>
        <WithErrorBoundary>
          <WidgetControls />
          <Section title={<h2>Customer Details</h2>} action={renderAction()}>
            <CustomerProfile
              customerId={customerId}
              ownerId={owner?.id ?? null}
              organizationName={organization?.name ?? null}
              customerPlans={plans}
            />
          </Section>
          {isOrganizationCustomer && (
            <Section title="Organization Details">
              <Link to={ROUTE_PATHS.organizationPage(organization?.id)} className={organizationBlockClassNames}>
                <OrganizationInfo
                  organization={organization}
                  owner={owner}
                  action={<DropdownIcon className={styles.iconArrow} />}
                  fields={[OrganizationInfoFields.OwnerName, OrganizationInfoFields.OwnerEmail]}
                />
              </Link>
            </Section>
          )}
          <PlansByOwner ownerId={customerId} planPath={ROUTE_PATHS.customerPlanPage} planGrouper={groupCustomerPlans} />
        </WithErrorBoundary>
      </Loadable>
    </SearchableContainer>
  );
};

export default Customer;

/* eslint-disable camelcase */
import { FC, createContext, useContext, useEffect, useState } from 'react';
import { withDVCProvider, useDVCClient, useIsDVCInitialized } from '@devcycle/devcycle-react-sdk';
import ErrorBoundary from '../ErrorBoundary';
import {
  ExperimentsContextValue,
  ExperimentsFeatures,
  ExperimentsProviderProps,
  ExperimentsVariables,
} from './Experiments.types';

export const initialValue: ExperimentsContextValue = {
  isReady: false,
  variables: {},
  features: {},
  isProvided: false,
  identifyUser: async () => {},
  resetUser: async () => {},
};

export const ExperimentsContext = createContext(initialValue);

export const ExperimentsProvider: FC<ExperimentsProviderProps> = ({ sdkKey, children }) => {
  const ApplicationComponent: FC = () => {
    const [currentUser, setCurrentUser] = useState<string>('');
    const [features, setFeatures] = useState<ExperimentsFeatures>({});
    const [variables, setVariables] = useState<ExperimentsVariables>({});

    const dvcClient = useDVCClient();
    const isDCClientReady = useIsDVCInitialized();

    const identifyUser = async (userId: string): Promise<void> => {
      if (currentUser === userId) return;
      await dvcClient.identifyUser({
        user_id: userId,
      });
      setCurrentUser(userId);
    };

    const resetUser = async (): Promise<void> => {
      await dvcClient.resetUser();
      setCurrentUser('');
    };

    const updateExperiments = async (): Promise<void> => {
      const dcFeatures = dvcClient.allFeatures();
      const dcVariables = dvcClient.allVariables();

      setFeatures(dcFeatures);
      setVariables(dcVariables);
    };

    const contextValue = {
      isReady: isDCClientReady,
      isProvided: true,
      variables,
      features,
      identifyUser,
      resetUser,
    };

    useEffect(() => {
      if (!isDCClientReady) return () => {};

      updateExperiments();

      dvcClient.subscribe('featureUpdated:*', () => updateExperiments());

      return () => dvcClient.unsubscribe('featureUpdated:*');
    }, [isDCClientReady, dvcClient]);

    return <ExperimentsContext.Provider value={contextValue}>{children}</ExperimentsContext.Provider>;
  };

  const ProvidedComponent = withDVCProvider({ sdkKey })(ApplicationComponent);

  return (
    <ErrorBoundary fallback={() => <>{children}</>}>
      <ProvidedComponent />
    </ErrorBoundary>
  );
};

export const useExperimentsContext = () => {
  const context = useContext(ExperimentsContext);

  if (!context.isProvided) {
    throw new Error('ExperimentsProvider: useExperimentsContext must be used within a ExperimentsProvider');
  }

  return context;
};

import { gql } from '@apollo/client';
import { BasePaginationSchema } from '~/types';
import { PROFILE_FRAGMENT } from '../fragments';

export const SUPPORT_PROFILE_QUERY = gql`
  query Profile {
    profile {
      ...ProfileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const SUPPORT_MEMBERS_QUERY = gql`
  query Members($limit: Int, $offset: Int) {
    members(limit: $limit, offset: $offset) {
      data {
        ...ProfileFragment
      }
      pagination {
        ${BasePaginationSchema}
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;

import { gql, useQuery } from '@apollo/client';
import { GQLResponse, Maybe } from '~/types';

export const IS_BETA_PLAN_USED_QUERY = gql`
  query isBetaPlanUsed($organizationId: ID!) {
    isBetaPlanUsed(organizationId: $organizationId) {
      betaPlanUsed
    }
  }
`;

interface IsBetaPlanUsedOutput {
  isBetaPlanUsed: boolean;
  loading: boolean;
}

interface isBetaPlanUsedInput {
  betaPlanUsed: boolean;
}

export const useIsBetaPlanUsedQuery = (organizationId: Maybe<string>): IsBetaPlanUsedOutput => {
  const { data, loading } = useQuery<GQLResponse<'isBetaPlanUsed', isBetaPlanUsedInput>>(IS_BETA_PLAN_USED_QUERY, {
    skip: !organizationId,
    variables: {
      organizationId,
    },
  });

  return {
    isBetaPlanUsed: Boolean(data?.isBetaPlanUsed?.betaPlanUsed),
    loading,
  };
};

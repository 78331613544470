import React from 'react';
import { ConfirmationType } from '~/types';
import { ConfirmationModal } from '~/ui';

interface RemoveBetaPlanErrorProps {
  onCancel: () => void;
}

const RemoveBetaPlanError: React.FC<RemoveBetaPlanErrorProps> = ({ onCancel }) => (
  <ConfirmationModal
    title="This plan has already been removed"
    description="Looks like this plan had been removed before you hit that Remove button."
    type={ConfirmationType.Warning}
    onSubmit={onCancel}
    actionTitle="Got it"
  />
);

export default RemoveBetaPlanError;

import React from 'react';
import { SuccessModal } from '~/ui';

interface GivePlanSuccess {
  email: string;
  onSubmit: () => void;
}

const GivePlanSuccess: React.FC<GivePlanSuccess> = ({ email, onSubmit }) => (
  <SuccessModal
    title="Sounds like a plan!"
    description={
      <>
        We’ve sent an email to <strong>{email}</strong> inviting this customer to activate their new product.
      </>
    }
    onSubmit={onSubmit}
  />
);

export default GivePlanSuccess;

import React, { ElementType } from 'react';
import { Loader } from '@macpaw/macpaw-ui';
import styles from './Section.module.sass';

interface Section {
  title: React.ReactNode;
  action?: React.ReactElement;
  loading?: boolean;
}

const Section: React.FC<React.PropsWithChildren<Section>> = ({ title, action, loading, children }) => {
  let Title: ElementType | null = null as unknown as ElementType;

  if (typeof title === 'string') {
    Title = (<h3 className={styles.caption}>{title}</h3>) as unknown as ElementType;
  }

  if (typeof title === 'object') {
    Title = (<div className={styles.caption}>{title}</div>) as unknown as ElementType;
  }

  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <>{Title}</>
        {loading && <Loader size={32} inline className={styles.loader} />}

        {action && <div className={styles.action}>{action}</div>}
      </div>
      {children}
    </div>
  );
};

export default Section;

import React from 'react';
import { CustomerInterface } from '../../interfaces';
import { TableBody, TableCell, TableHeader, TableRow } from '../Table';
import UserListItem from './UserListItem';
import styles from './UsersList.module.sass';

interface UsersList {
  users: CustomerInterface[];
  userPath: (id: string) => string;
}

const UsersList: React.FC<UsersList> = ({ users, userPath }) => (
  <>
    <TableHeader className={styles.tableGridPattern}>
      <TableCell />
      <TableCell>Name</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>Added</TableCell>
      <TableCell />
    </TableHeader>
    <TableBody>
      {users.map((user: CustomerInterface) => (
        <TableRow key={user.id}>
          <UserListItem user={user} userPath={userPath} className={styles.tableGridPattern} />
        </TableRow>
      ))}
    </TableBody>
  </>
);

export default UsersList;

import React from 'react';
import { Button, DialogActions, DialogContent, DialogIcon } from '@macpaw/macpaw-ui';
import { useModals } from '~/ui';
import { Modals } from '../../constants';
import { usePauseCustomerPlanMutation } from '../../graphql/mutations';
import PauseIcon from '../../images/icons/pause.svg?react';
import { CustomerPlanInterface } from '../../interfaces';

interface PlanPauseProps {
  customerPlan: CustomerPlanInterface;
  onComplete?: () => void;
}

const PlanPauseModal: React.FC<PlanPauseProps> = ({ customerPlan, onComplete }) => {
  const { closeModal } = useModals();
  const { pausePlan, loading } = usePauseCustomerPlanMutation();

  const handlePause = async () => {
    await pausePlan(customerPlan.id);
    closeModal(Modals.PlanPauseModal);
    onComplete?.();
  };

  return (
    <>
      <DialogContent>
        <DialogIcon>
          <PauseIcon width={160} height={160} />
        </DialogIcon>
        <h3>Pause Subscription Plan</h3>
        <p>
          Are you sure you want to pause this subscription plan? This will pause all payments, and the customer will
          lose access on the next billing date unless it is unpaused beforehand.
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handlePause} loading={loading}>
          Pause Plan
        </Button>
      </DialogActions>
    </>
  );
};

export default PlanPauseModal;

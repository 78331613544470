import React from 'react';
import { Banner, Label, Grid, GridRow, GridCell } from '@macpaw/macpaw-ui';
import { isCompanionPlan } from '~/helpers/plan';
import { LoadableText, PaymentIcon, PaymentMask } from '~/ui';
import { formatCurrency, formatDate, formatExpireDate, isOneTime } from '~/utils';
import Section from '../../components/Section/Section';
import { usePaymentInfoQuery } from '../../graphql/queries';
import getIsPastDueWithAccess from '../../helpers/getIsPastDueWithAccess';
import CardIcon from '../../images/icons/card-front.svg?react';
import { PaymentInterface, CustomerPlanInterface } from '../../interfaces';
import PanelEmptyState from '../PanelEmptyState/PanelEmptyState';

interface PlanPayment {
  plan: CustomerPlanInterface;
}

const PlanPayment: React.FC<PlanPayment> = ({ plan }) => {
  const isEmpty = !plan.order || isOneTime(plan.planView);
  const { loading, paymentInfo } = usePaymentInfoQuery({ planId: plan.id, skip: isEmpty });

  const isPastDueWithAccess = getIsPastDueWithAccess(plan);
  const order = plan?.order;
  const currency = order?.currency ?? '';

  if (isEmpty) {
    return (
      <PanelEmptyState
        title="Payment Method"
        subtitle={
          isCompanionPlan(plan)
            ? 'Payments for this plan are managed via its parent plan.'
            : 'No Payment Method available'
        }
        icon={<CardIcon />}
      />
    );
  }

  return (
    <Section title="Payment Method">
      {isPastDueWithAccess && (
        <Banner className="mb-16">
          <b>Payment failed</b>. Need update billing method.
        </Banner>
      )}
      <Grid id="payment" icon={<PaymentIcon paymentInfo={paymentInfo} />}>
        <GridRow>
          <GridCell type="primary">
            <Label>Card Number</Label>
            <LoadableText loaderSize={24} isLoading={loading}>
              <PaymentMask short paymentInfo={paymentInfo as PaymentInterface} />
            </LoadableText>
          </GridCell>
          <GridCell type="primary">
            <Label>Next Payment Date</Label>
            <LoadableText formatter={formatDate} isLoading={!order} isConditional>
              {order?.nextBillDate ?? 0}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Expiration Date</Label>
            <LoadableText isLoading={loading} isConditional>
              {formatExpireDate(paymentInfo?.expiryMonth && paymentInfo?.expiryYear ? paymentInfo : null)}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Next billing amount</Label>
            <LoadableText isLoading={!order} isConditional>
              {order?.nextPaymentAmount && formatCurrency(order.nextPaymentAmount, currency)}
            </LoadableText>
          </GridCell>
        </GridRow>
      </Grid>
    </Section>
  );
};

export default PlanPayment;

import React, { Component } from 'react';
import { Maybe, ErrorFallbackComponentsProps, ErrorInfo } from '~/types';

interface ErrorBoundaryProps {
  fallback?: React.ComponentType<ErrorFallbackComponentsProps>;
}

interface ErrorBoundaryState {
  error: Maybe<Error>;
  info: Maybe<ErrorInfo>;
}

class ErrorBoundary extends Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
  constructor(props: React.PropsWithChildren<ErrorBoundaryProps>) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({
      error,
      info,
    });
  }

  render(): React.ReactNode {
    const { error, info } = this.state;
    const { children, fallback: FallbackComponent } = this.props;

    if (error !== null) {
      return FallbackComponent ? <FallbackComponent info={info} error={error} /> : null;
    }

    return children;
  }
}

export default ErrorBoundary;

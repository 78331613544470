import React from 'react';
import { Pagination as PaginationFromUIKit } from '@macpaw/macpaw-ui';
import { BasePagination } from '~/types';
import { useRouterDomContext } from './RouterDomProvider';

interface Pagination {
  pagination: BasePagination;
}

const Pagination: React.FC<Pagination> = ({ pagination }) => {
  const { linkComponent: Link, locationHook: useLocation } = useRouterDomContext();
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const getLink = (pageNumber: number): string => {
    if (pageNumber > 1) {
      queryParams.set('page', pageNumber.toString());
    } else {
      queryParams.delete('page');
    }

    return `${pathname}?${queryParams.toString()}`;
  };

  return (
    <PaginationFromUIKit
      renderItem={(pageNumber) => <Link to={getLink(pageNumber)}>{pageNumber}</Link>}
      maxPage={pagination.pagesCount}
      currentPage={pagination.currentPage}
    />
  );
};

export default Pagination;

import React from 'react';
import { Outlet } from 'react-router-dom';
import { BreadcrumbsProvider as Provider } from '~/ui';
import { BREADCRUMBS_CONFIG } from '../../constants';

const BreadcrumbsProvider: React.FC = () => (
  <Provider config={BREADCRUMBS_CONFIG} view="default">
    <Outlet />
  </Provider>
);

export default BreadcrumbsProvider;

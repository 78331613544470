/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, DialogActions, DialogContent, FormRow, Tab, TabList, TabPanel, TabsProvider } from '@macpaw/macpaw-ui';
import { TabsContextValue } from '@macpaw/macpaw-ui/lib/Tabs/TabContext';
import { FormInput, FormSelect, Loadable, StepItemProps } from '~/ui';
import { formatDate, isOneTime } from '~/utils';
import { CustomerRefundInputTypes, DECIMALS_COUNT, RefundSteps, RefundTypes } from '../../../constants';
import { useTransactionsQuery } from '../../../graphql/queries';
import { findAllPurchaseTransactions, findLastPurchaseTransaction } from '../../../helpers/plan';
import { CustomerPlanInterface, TransactionInterface } from '../../../interfaces';
import { RefundFormCheckbox } from '../components';
import styles from './PlanRefundFormStep.module.sass';

interface TransactionData {
  transactionId: string;
  maximumAmount: number;
  currentAmount: number;
  currency: string;
}

interface PlanRefundFormStepProps extends Partial<StepItemProps> {
  customerPlan: CustomerPlanInterface;
  onCancel: () => void;
}

const NEXT_STEPS = [RefundSteps.FullRefundWarning, RefundSteps.PartialRefundWarning];

const PlanRefundFormStep: React.FC<PlanRefundFormStepProps> = ({ customerPlan, onCancel, ...props }) => {
  const { setStep, prevStepKey } = props as StepItemProps;
  const isWentFromNext = NEXT_STEPS.includes(prevStepKey as RefundSteps);
  const transactionsFetchPolicy = isWentFromNext ? 'cache-first' : 'cache-and-network';
  const tabsContextRef = useRef<TabsContextValue>(null);
  const { transactions, loading } = useTransactionsQuery({
    customerPlanId: customerPlan.id,
    fetchPolicy: transactionsFetchPolicy,
  });
  const {
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  const allPurchasedTransactions = useMemo(() => findAllPurchaseTransactions(transactions), [transactions]);

  const initialTab = useMemo(() => {
    if (!prevStepKey || prevStepKey === RefundSteps.FullRefundWarning) return RefundTypes.Full;

    return RefundTypes.Partial;
  }, [prevStepKey]);

  const currency = watch(CustomerRefundInputTypes.Currency);
  const transactionId = watch(CustomerRefundInputTypes.TransactionId);

  const isOneTimePurchase = isOneTime(customerPlan.planView);
  const isDisabledSubmit =
    errors?.[CustomerRefundInputTypes.CurrentTransactionAmount] || errors?.[CustomerRefundInputTypes.Reason] || loading;

  const formatAmountValue = () => {
    const { [CustomerRefundInputTypes.CurrentTransactionAmount]: currentAmount } = getValues();

    const numberValue = Number(currentAmount);

    if (isNaN(numberValue)) return;

    setValue(CustomerRefundInputTypes.CurrentTransactionAmount, numberValue.toFixed(DECIMALS_COUNT));
  };

  const setTransactionData = ({
    transactionId: transactionIdValue,
    maximumAmount,
    currentAmount,
    currency: currencyValue,
  }: TransactionData) => {
    setValue(CustomerRefundInputTypes.TransactionId, transactionIdValue);
    setValue(CustomerRefundInputTypes.MaximumTransactionAmount, maximumAmount);
    setValue(CustomerRefundInputTypes.CurrentTransactionAmount, currentAmount);
    setValue(CustomerRefundInputTypes.Currency, currencyValue);
  };

  const handleSubmit = async () => {
    const { current: tabsContext } = tabsContextRef;

    formatAmountValue();

    if (!tabsContext) return;

    const { activeTab } = tabsContext;

    if (activeTab === RefundTypes.Full) {
      setStep(RefundSteps.FullRefundWarning);
    } else {
      const isCurrentAmountValid = await trigger(CustomerRefundInputTypes.CurrentTransactionAmount);

      if (isCurrentAmountValid) {
        setStep(RefundSteps.PartialRefundWarning);
      }
    }
  };

  useEffect(() => {
    if (isWentFromNext) return;

    const currentTransactionInfo = allPurchasedTransactions.find(
      (item: TransactionInterface) => item.id === transactionId,
    );

    setTransactionData({
      transactionId,
      maximumAmount: Number(currentTransactionInfo?.amount),
      currentAmount: Number(currentTransactionInfo?.amount),
      currency: currentTransactionInfo?.currency as string,
    });
  }, [transactionId, allPurchasedTransactions, isWentFromNext]);

  useEffect(() => {
    if (!transactions.length) return;
    const {
      [CustomerRefundInputTypes.MaximumTransactionAmount]: maximumAmount,
      [CustomerRefundInputTypes.CurrentTransactionAmount]: currentAmount,
    } = getValues();
    const allPurchaseTransactions = findAllPurchaseTransactions(transactions);
    const transaction = findLastPurchaseTransaction(allPurchaseTransactions);

    if (!currentAmount && !maximumAmount) {
      setTransactionData({
        transactionId: transaction?.id,
        maximumAmount: Number(transaction?.amount),
        currentAmount: Number(transaction?.amount),
        currency: transaction?.currency as string,
      });
    }
  }, [transactions]);

  const renderBlockPlanField = () => (
    <RefundFormCheckbox name={CustomerRefundInputTypes.BlockPlanChecked}>
      <b>Block this plan.</b> Blocking will deactivate this plan the moment the refund is complete.
    </RefundFormCheckbox>
  );

  const renderCancelPlanField = () => (
    <RefundFormCheckbox name={CustomerRefundInputTypes.CancelChecked}>
      <b>Cancel this plan.</b> The plan will be deactivated automatically on the next billing date.
    </RefundFormCheckbox>
  );

  return (
    <>
      <DialogContent className={styles.refund}>
        <h3>Transaction Refund</h3>
        <Loadable isLoading={loading} className={styles.loader} loaderSize={48}>
          <div className="mb-48">
            <FormSelect
              name={CustomerRefundInputTypes.TransactionId}
              label="Select transaction for refund (Last 90 days)">
              {allPurchasedTransactions.map((item: TransactionInterface) => (
                <option key={`transaction-${item.id}`} value={item.id}>
                  {`${formatDate(item.date)} - ${item.amount} ${item.currency}`}
                </option>
              ))}
            </FormSelect>
          </div>
          <div className={styles.refundTypeBlock}>
            <TabsProvider initialTab={initialTab} innerRef={tabsContextRef}>
              <div className={styles.refundTypeForm}>
                <TabList className={styles.refundTabs}>
                  <Tab tab={RefundTypes.Full} className={styles.refundTabButton}>
                    Full Refund
                  </Tab>
                  <Tab tab={RefundTypes.Partial} className={styles.refundTabButton}>
                    Partial Refund
                  </Tab>
                </TabList>
                <TabPanel tab={RefundTypes.Full}>
                  <FormRow>
                    <div className={styles.refundAmount}>
                      <FormInput
                        name={CustomerRefundInputTypes.MaximumTransactionAmount}
                        label="Refund amount"
                        disabled
                        currency={currency as string}
                      />
                    </div>
                  </FormRow>
                  {renderBlockPlanField()}
                </TabPanel>
                <TabPanel tab={RefundTypes.Partial}>
                  <FormRow>
                    <div className={styles.refundAmount}>
                      <FormInput
                        name={CustomerRefundInputTypes.CurrentTransactionAmount}
                        type="number"
                        label="Refund amount"
                        currency={currency as string}
                      />
                    </div>
                  </FormRow>
                  {isOneTimePurchase ? renderBlockPlanField() : renderCancelPlanField()}
                </TabPanel>
              </div>
            </TabsProvider>
          </div>
          <FormRow>
            <FormInput name={CustomerRefundInputTypes.Reason} label="Refund reason" />
          </FormRow>
        </Loadable>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" outline>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSubmit} disabled={isDisabledSubmit}>
          Request Refund
        </Button>
      </DialogActions>
    </>
  );
};

export default PlanRefundFormStep;

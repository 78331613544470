import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'clsx';
import { Grid, GridCell, GridRow, Label } from '@macpaw/macpaw-ui';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { CustomerPlanGroup } from '~/types';
import { BillingCycle, DynamicDate } from '~/ui';
import { getCustomerPlanGroup } from '~/utils';
import { PlanInterface } from '../../interfaces';
import styles from './PlansListItem.module.sass';

interface PlansListItemProps {
  ownerId: string;
  plan: PlanInterface;
  planPath: (ownerId: string, planId: string) => string;
}

const PlansListItem: React.FC<PlansListItemProps> = ({ plan, ownerId, planPath }) => {
  const { planView, id, order } = plan;
  const { productName } = planView;

  const planGroup = getCustomerPlanGroup(plan);

  return (
    <Link to={planPath(ownerId, id)} className={cx(styles.link, 'mb-16')}>
      <Grid
        outline={planGroup === CustomerPlanGroup.Inactive}
        icon={<img src={planView.productPlanIconUrl} alt="" />}
        action={<DropdownIcon className={styles.arrow} />}>
        <GridRow>
          <GridCell type="primary">
            <Label>Product</Label>
            <b>{productName}</b>
          </GridCell>
          <GridCell type="primary">
            <Label>Plan Name</Label>
            {productName}
          </GridCell>
          <GridCell type="secondary">
            <Label>Billing Cycle</Label>
            <BillingCycle customerPlan={plan} />
          </GridCell>
          <GridCell type="secondary">
            <DynamicDate customerPlan={plan} order={order} />
          </GridCell>
        </GridRow>
      </Grid>
    </Link>
  );
};

export default PlansListItem;

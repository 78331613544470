import React from 'react';
import { Form, Step, Stepper, useModals } from '~/ui';
import { Modals, PlanReduceSizeInputTypes, ReduceSizeSteps } from '../../constants';
import { OrganizationPlanInterface } from '../../interfaces';
import { PlanReduceSizeSchema } from '../../schemas';
import { ReducePlanSizeReduceStep, ReducePlanSizeSuccessStep, ReducePlanSizeWarningStep } from './steps';

interface ReducePlanSize {
  customerPlan: OrganizationPlanInterface;
  onComplete?: () => void;
}

const ReducePlanSizeModal: React.FC<ReducePlanSize> = ({ customerPlan, onComplete }) => {
  const { closeModal } = useModals();

  const handleCancel = () => {
    closeModal(Modals.ReducePlanSizeModal);
  };

  return (
    <Form
      initialValues={{
        [PlanReduceSizeInputTypes.ReduceCount]: '',
        [PlanReduceSizeInputTypes.VacantSeatsCount]: '',
      }}
      validationSchema={PlanReduceSizeSchema}
      validateMode="onBlur">
      <Stepper initialStep={ReduceSizeSteps.Reduce}>
        <Step stepKey={ReduceSizeSteps.Reduce}>
          <ReducePlanSizeReduceStep customerPlan={customerPlan} onCancel={handleCancel} />
        </Step>
        <Step stepKey={ReduceSizeSteps.Warning}>
          <ReducePlanSizeWarningStep customerPlan={customerPlan} onComplete={onComplete} />
        </Step>
        <Step stepKey={ReduceSizeSteps.Success}>
          <ReducePlanSizeSuccessStep onCancel={handleCancel} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default ReducePlanSizeModal;

import { ErrorResponse } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';

class BaseSentry {
  constructor() {
    if (!this.isSentryEnabled) return;
    Sentry.init(this.sentryOptions);
    this.setUser = this.setUser.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.captureException = this.captureException.bind(this);
    this.captureGraphQLException = this.captureGraphQLException.bind(this);
  }

  get isSentryEnabled(): boolean {
    return false;
  }

  get sentryOptions(): Sentry.BrowserOptions {
    return { dsn: '', environment: '', ignoreErrors: [] };
  }

  isIgnoredException(error: Error) {
    return error.message === '401: Unauthorized';
  }

  public setUser(id: string) {
    if (!this.isSentryEnabled) return;
    Sentry.setUser({ id });
  }

  public clearUser() {
    if (!this.isSentryEnabled) return;
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  captureException(error: Error) {
    if (!this.isSentryEnabled || this.isIgnoredException(error)) {
      // eslint-disable-next-line no-console
      console.error(error);
    } else {
      Sentry.captureException(error);
    }
  }

  captureGraphQLException({ graphQLErrors, networkError }: ErrorResponse) {
    if (!this.isSentryEnabled) {
      // eslint-disable-next-line no-console
      if (graphQLErrors) console.error(graphQLErrors);
      // eslint-disable-next-line no-console
      if (networkError) console.error(networkError);

      return;
    }

    if (networkError) {
      this.captureException(networkError);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const exception = Error(error.message);

        if (this.isIgnoredException(exception)) return;

        Sentry.withScope((scope) => {
          scope.setExtra('raw', JSON.stringify(error));

          if (error.extensions?.code) {
            scope.setTag('code', error.extensions.code);
          }

          if (error.path) {
            scope.setTag('path', error.path.join(' '));
          }

          this.captureException(exception);
        });
      });
    }
  }
}

export default BaseSentry;

import React from 'react';
import ForbiddenImage from '../../images/forbidden.png';
import styles from './Error.module.sass';

const AccessDenied: React.FC = () => {
  return (
    <div className={styles.error}>
      <img src={ForbiddenImage} width={362} alt="" />
      <div className="h4">
        Restricted access.
        <br />
        Authorized personnel only
      </div>
      Looks like you’ve into the grey zone of the MacPaw site account.
      <br />
      Don’t worry, we’ll help you back on track.
    </div>
  );
};

export default AccessDenied;

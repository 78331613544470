/* eslint-disable no-param-reassign */
import React, { useState, useMemo, useEffect, MutableRefObject, ReactElement, memo } from 'react';
import { usePrevious } from '~/utils';
import { StepperContextValueType, StepperContext } from './StepperContext';

type StepperProps = {
  children: React.ReactNode;
  initialStep: string;
  updateAnyway?: boolean;
  innerRef?: MutableRefObject<StepperContextValueType | null>;
  onFinish?: () => void;
  onChangeStep?: (step: string) => void;
};

const getStepsKeysFromChildren = (children: React.ReactNode): string[] => {
  const elements = React.Children.toArray(children);
  const steps = elements.map((element) => (element as ReactElement)?.props?.stepKey ?? '');

  return steps as string[];
};

const Stepper: React.FC<StepperProps> = ({
  children,
  initialStep = '',
  innerRef,
  onFinish = (): void => {},
  onChangeStep,
}) => {
  const steps = useMemo(() => getStepsKeysFromChildren(children), [children]);

  const initialStepIndex = Math.max(steps.indexOf(initialStep), 0);
  const [currentStep, setCurrentStep] = useState(initialStepIndex);

  const maxStep = steps.length - 1;
  const currentStepKey = steps[currentStep];

  const { previous, forceSetPrevious } = usePrevious<string>(currentStepKey);

  const setNextStep = () => setCurrentStep((step: number) => (step === maxStep ? maxStep : step + 1));

  const setStep = (step: string) => {
    const nextStep = Math.max(steps.indexOf(step), 0);

    setCurrentStep(nextStep);
  };

  const providerValue = {
    steps,
    setNextStep,
    currentStep,
    currentStepKey,
    prevStepKey: previous,
    maxStep,
    setStep,
    onFinish,
    setPreviousStep: forceSetPrevious,
  };

  useEffect(() => {
    setCurrentStep(initialStepIndex);
  }, [initialStepIndex]);

  useEffect(() => {
    if (!innerRef) return;

    innerRef.current = providerValue;
  }, [providerValue]);

  useEffect(() => {
    onChangeStep?.(currentStepKey);
  }, [currentStepKey]);

  const childrenList = React.Children.toArray(children).map((element, index) => {
    return React.cloneElement(element as ReactElement, { ...(element as ReactElement).props, step: index });
  });

  return <StepperContext.Provider value={providerValue}>{childrenList}</StepperContext.Provider>;
};

export default memo(Stepper, ({ children: prevChildren }, { children: nextChildren, updateAnyway }) => {
  const prevSteps = getStepsKeysFromChildren(prevChildren);
  const nextSteps = getStepsKeysFromChildren(nextChildren);

  if (updateAnyway || prevSteps.length !== nextSteps.length) return false;

  const isEqual = prevSteps.reduce((acc, step, index) => {
    const nextStep = nextSteps[index];

    if (!acc) return acc;

    return step === nextStep;
  }, true);

  return isEqual;
});

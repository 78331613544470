import React, { useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { DialogContent, DialogActions, Button, FormRow, notify } from '@macpaw/macpaw-ui';
import { Maybe, NotificationType } from '~/types';
import { Loadable, useModals, Form, FormInput, FormMultiselect } from '~/ui';
import { getGQLServerErrors } from '~/utils';
import {
  EMAIL_ALREADY_EXIST_ERROR,
  ERROR_CODE_TO_COPY,
  Modals,
  Role,
  RoleTitle,
  SOMETHING_WENT_WRONG_TRY_AGAIN,
} from '../../constants';
import { useEditMemberMutation } from '../../graphql/mutations';
import { useRolesQuery } from '../../graphql/queries';
import { SupportProfileInterface } from '../../interfaces';
import { EditTeamMemberForm } from '../../schemas';
import styles from './MemberEdit.module.sass';

enum EditTeamMemberFormField {
  Name = 'name',
  Email = 'email',
  Roles = 'roles',
}

interface FormValues extends ObjectLiteral {
  name: string;
  email: string;
  roles: Array<{
    value: string;
  }>;
}
interface MemberEditProps {
  member: Maybe<SupportProfileInterface>;
  onComplete: () => Promise<void> | void;
}
const MemberEditModal: React.FC<MemberEditProps> = ({ member, onComplete }) => {
  const formRef = useRef<UseFormReturn>(null);
  const { closeModal } = useModals();
  const { roles, loading: rolesLoading } = useRolesQuery();
  const { editMember, loading } = useEditMemberMutation();

  const initialValues = useMemo(
    () => ({
      [EditTeamMemberFormField.Name]: member?.name,
      [EditTeamMemberFormField.Email]: member?.email,
      [EditTeamMemberFormField.Roles]: member?.roles?.map((role) => ({ value: role })),
    }),
    [member],
  );

  const handleError = (gqlError: GraphQLError) => {
    const { current: form } = formRef;

    const errors = getGQLServerErrors(gqlError);

    errors.forEach((error) => {
      switch (error.code) {
        case EMAIL_ALREADY_EXIST_ERROR:
          form?.setError(EditTeamMemberFormField.Email, {
            message: ERROR_CODE_TO_COPY[error.code],
          });
          break;
        default:
          notify(SOMETHING_WENT_WRONG_TRY_AGAIN, NotificationType.Error);
      }
    });
  };

  const handleSubmit = async (values: ObjectLiteral) => {
    const { name, email, roles: formRoles } = values as FormValues;

    try {
      await editMember({
        userId: member!.id,
        name,
        email,
        roles: formRoles.map(({ value }: { value: string }) => value),
      });

      closeModal(Modals.MemberEditModal);

      await onComplete?.();
    } catch (err) {
      handleError(err as GraphQLError);
    }
  };

  return (
    <Loadable isLoading={rolesLoading} className={styles.loader}>
      <Form
        innerRef={formRef}
        validationSchema={EditTeamMemberForm}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        <DialogContent>
          <>
            <h3>Edit Team Member</h3>
            <div className={styles.inviteFormWrapper}>
              <FormRow>
                <FormInput
                  autoFocus
                  type="text"
                  name={EditTeamMemberFormField.Name}
                  placeholder="What is this member’s name?"
                  label="Name"
                />
              </FormRow>
              <FormRow>
                <FormInput
                  type="email"
                  name={EditTeamMemberFormField.Email}
                  placeholder="What is this member’s work email address?"
                  label="Email Address"
                />
              </FormRow>
              <FormRow>
                <FormMultiselect
                  placeholder="Please select applicable roles…"
                  label="Role"
                  name={EditTeamMemberFormField.Roles}>
                  {roles?.map((currentRole: Role) => (
                    <option
                      key={currentRole}
                      label={currentRole}
                      value={currentRole}
                      selected={member?.roles?.includes(currentRole)}>
                      {RoleTitle[currentRole]}
                    </option>
                  ))}
                </FormMultiselect>
              </FormRow>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <Button loading={loading} type="submit" color="secondary">
            Save Changes
          </Button>
        </DialogActions>
      </Form>
    </Loadable>
  );
};

export default MemberEditModal;

/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import cx from 'clsx';
import { Dropdown, DropdownItem, Button } from '@macpaw/macpaw-ui';
import { DropdownIcon, SearchIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { ROUTE_PATHS } from '~/constants';
import { useLazyLicenseQuery, useLazyRedeemQuery } from '~/graphql/queries';
import { DropdownItemInterface, DropdownItemKey, SearchFormField, CustomerPlanInterface } from '~/interfaces';
import { RedeemSearchSchema } from '~/schemas';
import { Form, FormInput, FormCheckBox, Loadable } from '~/ui';
import { useQueryParams } from '~/utils';
import PlansList from '../PlansList/PlansList';
import SearchEmptyState from '../Search/SearchEmptyState';
import SearchFormReset from '../SearchForm/SearchFormReset';
import styles from './LicensesSearch.module.sass';

const dropDownItems = [
  { name: 'By License Key', key: DropdownItemKey.License },
  { name: 'By Redeem Code', key: DropdownItemKey.Redeem },
];

const LicensesSearch: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<DropdownItemInterface>({
    name: 'By License Key',
    key: DropdownItemKey.License,
  });
  const [planItems, setPlanItems] = useState<Array<CustomerPlanInterface> | null>(null);
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const queryParams = useQueryParams();
  const formRef = useRef<UseFormReturn>(null);
  const navigate = useNavigate();

  const search = queryParams.get('q') || '';
  const { getLicenseInfo, loading: licenseSearchLoading } = useLazyLicenseQuery();
  const { getRedeemInfo, loading: redeemSearchLoading } = useLazyRedeemQuery();
  const isRedeemSelected = selectedValue.key === DropdownItemKey.Redeem;

  const fetchRedeemData = async () => {
    const planInfo = await getRedeemInfo({
      redeemCode: formRef?.current?.watch(SearchFormField.Search),
    });

    return planInfo;
  };

  const fetchLicenseData = async () => {
    const planInfo = await getLicenseInfo({
      search: formRef?.current?.watch(SearchFormField.Search),
    });

    return planInfo;
  };

  useEffect(() => {
    if (search && isRedeemSelected) {
      fetchRedeemData()
        .then((result) => {
          if (!result.redeemCodeInfo) {
            setIsEmptyResult(true);
          } else {
            navigate(
              ROUTE_PATHS.customerPlanPage(
                result.redeemCodeInfo.customerId,
                result.redeemCodeInfo.customerProductPlanId,
              ),
            );
          }
        })
        .catch();
    }
    if (search && !isRedeemSelected) {
      fetchLicenseData()
        .then((result) => {
          if (!result.license) {
            setIsEmptyResult(true);
          } else {
            setPlanItems(result.license);
          }
        })
        .catch();
    }
  }, [search, isRedeemSelected]);

  const handleFormSubmit = async () => {
    const value = formRef?.current?.watch(SearchFormField.Search);
    const searchParam = new URLSearchParams({
      ...(value ? { q: value } : {}),
    }).toString();

    navigate({
      pathname: ROUTE_PATHS.licenses(),
      search: searchParam,
    });
  };

  const handleResetForm = () => {
    formRef?.current?.setValue(SearchFormField.Search, '');
  };

  const onSelectHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedElement = event.target as HTMLButtonElement;

    setPlanItems(null);
    setIsEmptyResult(false);
    formRef?.current?.setFocus(SearchFormField.Search);
    setSelectedValue({
      name: selectedElement.textContent!,
      key: selectedElement.value === DropdownItemKey.License ? DropdownItemKey.License : DropdownItemKey.Redeem,
    });
    formRef?.current?.setValue('isRedeem', selectedElement.value === DropdownItemKey.Redeem);
  };

  return (
    <>
      <div className={cx('mb-32', styles.searchWrapper)}>
        <Dropdown
          trigger={
            <Button iconRight={<DropdownIcon />} outline>
              {selectedValue.name}
            </Button>
          }>
          {dropDownItems.map((item: DropdownItemInterface, i: number) => (
            <DropdownItem key={`${item.name}-${i}`} value={item.key} onClick={(event) => onSelectHandler(event)}>
              {item.name}
            </DropdownItem>
          ))}
        </Dropdown>
        <Form
          validationSchema={RedeemSearchSchema}
          validateMode="onSubmit"
          innerRef={formRef}
          initialValues={{ [SearchFormField.Search]: search, isRedeem: selectedValue.key === DropdownItemKey.Redeem }}
          onSubmit={handleFormSubmit}
          className={styles.wideSearch}>
          <FormCheckBox name="isRedeem" className={styles.checkbox} />
          <div className={styles.inputWrap}>
            <FormInput name={SearchFormField.Search} placeholder="Search" className={styles.input} />
            <span className={styles.searchIcon}>
              <SearchIcon />
            </span>
            <SearchFormReset className={styles.resetButton} onReset={handleResetForm} />
          </div>
        </Form>
      </div>
      <Loadable isLoading={redeemSearchLoading || licenseSearchLoading}>
        {search && (
          <div className="mb-16">
            <h3>Search Result</h3>
            {planItems &&
              planItems.map((plan, i) => (
                <PlansList
                  key={`${plan.id}-${i}`}
                  ownerId={plan.ownerId}
                  planPath={ROUTE_PATHS.customerPlanPage}
                  plans={[plan]}
                />
              ))}
          </div>
        )}
        {isEmptyResult && <SearchEmptyState />}
      </Loadable>
    </>
  );
};

export default LicensesSearch;

import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ErrorScreen } from '~/ui';
import { IS_WIDGET, ROUTE_PATHS } from '../../constants';
import ErrorIcon from '../../images/icons/500.png';
import styles from './Error.module.sass';

const Error500: React.FC = () => {
  const linkProps = IS_WIDGET
    ? { linkTitle: 'Refresh', linkOnClick: () => document.location.reload() }
    : {
        linkTitle: 'Go Back to Dashboard',
        linkPath: ROUTE_PATHS.home(),
        linkComponent: Link as unknown as ReactNode,
      };

  useEffect(() => {
    document.body.classList.add(styles.lightBackground);

    return () => {
      document.body.classList.remove(styles.lightBackground);
    };
  }, []);

  return (
    <ErrorScreen
      styles={styles}
      icon={<img src={ErrorIcon} width={360} alt="" />}
      title="Oops, something wrong."
      description="A technical error has occurred, please contact the Platform Team."
      {...linkProps}
    />
  );
};

export default Error500;

import { Maybe } from '~/types';
import { useModals } from '~/ui';
import { Modals } from '../../constants';
import { OrganizationInterface } from '../../interfaces';
import { OrganizationPaymentButton, OrganizationPaymentDropdownButton } from './OrganizationPaymentButtons';

interface OrganizationPaymentLinkButtonProps {
  organization: Maybe<OrganizationInterface>;
  loading: boolean;
}
const OrganizationPaymentLinkButton: React.FC<OrganizationPaymentLinkButtonProps> = ({ organization, loading }) => {
  const { openModal } = useModals();

  const handleOpenModal = (modalName: Modals) => {
    openModal({
      name: modalName,
      props: {
        organizationId: organization?.id,
        name: organization?.name,
        email: organization?.ownerEmail,
      },
    });
  };

  if (organization?.isCmmBusinessDashboardBeta)
    return (
      <OrganizationPaymentDropdownButton
        handleInviteToPlanModal={() => handleOpenModal(Modals.OrganizationInviteToPlanModal)}
        handleBetaPlanModal={() => handleOpenModal(Modals.GiveOrganizationBetaPlanModal)}
        organizationId={organization.id}
        disabled={loading}
      />
    );

  return (
    <OrganizationPaymentButton
      handleOpenModal={() => handleOpenModal(Modals.OrganizationInviteToPlanModal)}
      disabled={loading}
    />
  );
};

export default OrganizationPaymentLinkButton;

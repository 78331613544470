import React, { PropsWithChildren } from 'react';
import { FormRow } from '@macpaw/macpaw-ui';
import { FormCheckBox } from '~/ui';

interface RefundFormCheckboxProps extends FormCheckBox {
  contentClassName?: string;
}

const RefundFormCheckbox: React.FC<PropsWithChildren<RefundFormCheckboxProps>> = ({
  children,
  contentClassName = '',
  ...props
}) => (
  <FormRow>
    <FormCheckBox {...props}>
      <div className={contentClassName}>{children}</div>
    </FormCheckBox>
  </FormRow>
);

export default RefundFormCheckbox;

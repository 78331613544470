import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import {
  ERROR_CODE_TO_COPY,
  FATAL_ERRORS,
  FORBIDDEN_ERROR,
  GENERAL_ERROR,
  IGNORED_ERRORS,
  NOT_FOUND_ERRORS,
  ERRORS_WITHOUT_REDIRECT,
  IS_WIDGET,
} from '../../constants';
import AccessDenied from '../../pages/Error/AccessDenied';
import Error404 from '../../pages/Error/Error404';
import Error500 from '../../pages/Error/Error500';
import { errorVar } from '../../store';

const ErrorHandler: React.FC<React.PropsWithChildren> = ({ children }) => {
  const error = useReactiveVar(errorVar);
  const location = useLocation();

  useEffect(() => {
    errorVar(null);
  }, [location]);

  useEffect(() => {
    if (!error) return;

    const errorCode = error.message;

    if (IS_WIDGET || errorCode === FORBIDDEN_ERROR) return;

    if ([...IGNORED_ERRORS, ...NOT_FOUND_ERRORS, ...FATAL_ERRORS].includes(errorCode)) return;

    const errorMessage = ERROR_CODE_TO_COPY[errorCode] || ERROR_CODE_TO_COPY[GENERAL_ERROR];

    notify(errorMessage, NotificationType.Error);
  }, [error]);

  if (!error) return <>{children}</>;

  const errorCode = error.message;

  if (errorCode === FORBIDDEN_ERROR) return <AccessDenied />;

  if (IGNORED_ERRORS.includes(errorCode) || ERRORS_WITHOUT_REDIRECT.includes(errorCode)) return <>{children}</>;

  if (NOT_FOUND_ERRORS.includes(errorCode)) return <Error404 />;

  if (IS_WIDGET || FATAL_ERRORS.includes(errorCode)) return <Error500 />;

  return <>{children}</>;
};

export default ErrorHandler;

const LIMIT = 26;
const THREE = 3;

export default function nameTrim(name: string, limit = LIMIT): string {
  if (name.length < limit) {
    return name;
  }

  return `${name.slice(0, limit - THREE)}...`;
}

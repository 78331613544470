import { SuccessModal } from '~/ui';

interface GiveOrganizationBetaPlanSuccessProps {
  onSubmit: () => void;
  email: string;
}

const GiveOrganizationBetaPlanSuccess: React.FC<GiveOrganizationBetaPlanSuccessProps> = ({ onSubmit, email }) => (
  <SuccessModal
    title="Beta plan is up and running!"
    description={
      <>
        To start using it, the customer has to follow the link sent to <strong>{email}.</strong>
      </>
    }
    onSubmit={onSubmit}
  />
);

export default GiveOrganizationBetaPlanSuccess;

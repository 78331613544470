import React, { HTMLAttributes } from 'react';

interface InfinitySymbol extends HTMLAttributes<HTMLDivElement> {}

const InfinitySymbol: React.FC<InfinitySymbol> = (props) => {
  const { style, ...other } = props;

  return (
    <div style={{ ...style, fontSize: 26, lineHeight: 1, fontWeight: 300 }} {...other}>
      &infin;
    </div>
  );
};

export default InfinitySymbol;

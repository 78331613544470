import { gql } from '@apollo/client';

export const UNPAUSE_CUSTOMER_PLAN_MUTATION = gql`
  mutation UnpauseCustomerPlan($customerPlanId: ID!) {
    unpauseCustomerPlan(customerPlanId: $customerPlanId)
  }
`;

export const SIGNIN_AS_CUSTOMER_MUTATION = gql`
  mutation SignInAsCustomer($customerId: ID!, $clientId: String!) {
    signInAsCustomer(customerId: $customerId, clientId: $clientId) {
      token
    }
  }
`;

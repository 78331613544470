import React from 'react';
import { Maybe } from '~/types';
import { formatCurrency as _formatCurrency } from '~/utils';
import { DECIMALS_COUNT } from '../constants';

export const formatCurrency = (number: number, currency = 'USD'): string => _formatCurrency(number, currency);

export const formatPriceToDecimal = (price: number | string, decimals = DECIMALS_COUNT): string =>
  Number(price).toFixed(decimals);

export const formatQty = (qty: number | string): number => Number(qty || 1);

export const formatToStrong = (value: Maybe<string>): React.ReactNode => <strong>{value}</strong>;

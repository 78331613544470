import React from 'react';
import { Label } from '@macpaw/macpaw-ui';
import { BaseOrder, BaseCustomerPlan, CustomerPlanStatus, Maybe } from '~/types';
import { formatDate, conditionValue, isOneTime, isChinaPlan } from '~/utils';
import InfinitySymbol from './InfinitySymbol';

interface DynamicDate {
  customerPlan?: Maybe<BaseCustomerPlan>;
  order?: BaseOrder;
  locale?: string;
  labelCancel?: string;
  labelRenews?: string;
  labelBlocked?: string;
  labelRefund?: string;
  labelTrialEnds?: string;
}

// eslint-disable-next-line complexity
const DynamicDate: React.FC<DynamicDate> = (props) => {
  const {
    customerPlan,
    order,
    locale = 'en',
    labelCancel = 'Canceled On',
    labelRenews = 'Renews On',
    labelBlocked = 'Blocked On',
    labelRefund = 'Refund On',
    labelTrialEnds = 'Trial Ends',
  } = props;
  const blockedStatuses = [
    CustomerPlanStatus.Blocked,
    CustomerPlanStatus.HighRiskTransactionBlocked,
    CustomerPlanStatus.PaymentDisputeBlocked,
  ];
  const cancelledStatuses = [CustomerPlanStatus.CancelRequested, CustomerPlanStatus.CancelApproved];
  const pausedStatuses = [CustomerPlanStatus.Paused, CustomerPlanStatus.PauseRequested];

  if (!customerPlan) {
    return (
      <>
        <Label>{labelRenews}</Label>–
      </>
    );
  }

  if (blockedStatuses.includes(customerPlan.status)) {
    return (
      <>
        <Label>{labelBlocked}</Label>
        {formatDate(customerPlan.blockDate!, locale)}
      </>
    );
  }

  if (customerPlan.status === CustomerPlanStatus.Refunded) {
    return (
      <>
        <Label>{labelRefund}</Label>
        {formatDate(customerPlan.blockDate!, locale)}
      </>
    );
  }

  if (customerPlan.status === CustomerPlanStatus.FraudCheck) {
    return (
      <>
        <Label>{labelRenews}</Label>
        {formatDate(customerPlan.startDate, locale)}
      </>
    );
  }

  if (cancelledStatuses.includes(customerPlan.status)) {
    return (
      <>
        <Label>{labelRenews}</Label>
        Cancelled
      </>
    );
  }

  if (customerPlan.status === CustomerPlanStatus.Cancelled) {
    return (
      <>
        <Label>{labelCancel}</Label>
        {conditionValue(order && formatDate(order.canceledAt!, locale))}
      </>
    );
  }

  if (pausedStatuses.includes(customerPlan.status)) {
    return (
      <>
        <Label>{labelRenews}</Label>
        Paused
      </>
    );
  }

  if (customerPlan.status === CustomerPlanStatus.Trialing) {
    return (
      <>
        <Label>{labelTrialEnds}</Label>
        {formatDate(customerPlan.dueDate!, locale)}
      </>
    );
  }

  if (isOneTime(customerPlan.planView) && !isChinaPlan(customerPlan.planView.productPlanId)) {
    return (
      <>
        <Label>{labelRenews}</Label>
        <InfinitySymbol />
      </>
    );
  }

  return (
    <>
      <Label>{labelRenews}</Label>
      {formatDate(customerPlan.dueDate!, locale)}
    </>
  );
};

export default DynamicDate;

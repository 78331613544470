import React from 'react';
import { NotificationsContainer } from '@macpaw/macpaw-ui';
import { FormProvider } from '~/ui';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import WithErrorBoundary from './components/WithErrorBoundary/WithErrorBoundary';
import WithLayout from './components/WithLayout/WithLayout';
import Routes from './routes/Routes';

const AppRoot: React.FC = () => (
  <WithLayout>
    <WithErrorBoundary>
      <FormProvider>
        <NotificationsContainer />
        <ErrorHandler>
          <Routes />
        </ErrorHandler>
      </FormProvider>
    </WithErrorBoundary>
  </WithLayout>
);

export default AppRoot;

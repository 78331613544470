import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'clsx';

interface NavigationProps {
  items: {
    path: string;
    copy: string;
  }[];
  styles: Readonly<{ [key: string]: string }>;
}

const Navigation: React.FC<NavigationProps> = ({ items, styles }) => {
  const location = useLocation();

  return (
    <nav className={styles.navigation}>
      <ul className={styles['navigation-list']}>
        {items.map(({ path, copy }) => (
          <li key={copy}>
            <Link to={path} className={cx({ [styles['-active']]: location.pathname === path })}>
              {copy}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;

import { ErrorResponse } from '@apollo/client/link/error';
import { getGQLErrorCode } from '~/utils';
import { GENERAL_ERROR } from '../constants';

class GQLError extends Error {
  public name: string;
  public origin: ErrorResponse;

  constructor(origin: ErrorResponse) {
    super();

    this.name = this.constructor.name;
    this.origin = origin;
  }

  get errorCode(): string {
    const errorMessage = getGQLErrorCode(this.origin);

    return errorMessage || GENERAL_ERROR;
  }

  get message(): string {
    return `${this.name} ${this.errorCode}`;
  }
}
export default GQLError;

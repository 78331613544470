import { FetchPolicy, gql, useLazyQuery } from '@apollo/client';
import { Maybe } from '~/types';
import { ApolloInput, GQLResponse } from '../../interfaces';

export const ORGANIZATION_PURCHASE_LINK_QUERY = gql`
  query OrganizationPurchaseLink($input: OrganizationPurchaseLinkInput!) {
    organizationPurchaseLink(input: $input)
  }
`;

interface OrganizationPurchaseLinkInput {
  organizationId: string;
  productPlanSkuId: string;
  quantity: number;
  trialDays?: number;
  billImmediatelyPrice?: number;
  nextBillingDate?: number;
}

interface LoadPurchaseLinkQueryInput {
  input: OrganizationPurchaseLinkInput;
  skip?: boolean;
}

interface UseOrganizationPurchaseLinkQueryInput {
  fetchPolicy?: FetchPolicy;
}

interface UseOrganizationPurchaseLinkQuery {
  loadPurchaseLink: (input: LoadPurchaseLinkQueryInput) => Promise<Maybe<string>>;
  purchaseLink: Maybe<string>;
  loading: boolean;
}

export const useLazyOrganizationPurchaseLinkQuery = ({
  fetchPolicy,
}: UseOrganizationPurchaseLinkQueryInput = {}): UseOrganizationPurchaseLinkQuery => {
  const [loadData, { data: purchaseLinkData, loading }] = useLazyQuery<
    GQLResponse<'organizationPurchaseLink', string>,
    ApolloInput<OrganizationPurchaseLinkInput>
  >(ORGANIZATION_PURCHASE_LINK_QUERY, {
    fetchPolicy,
  });

  const loadPurchaseLink = async ({ input, skip }: LoadPurchaseLinkQueryInput): Promise<Maybe<string>> => {
    if (skip) return null;

    const { data } = await loadData({
      variables: {
        input,
      },
    });

    return data?.organizationPurchaseLink;
  };

  return {
    loadPurchaseLink,
    purchaseLink: purchaseLinkData?.organizationPurchaseLink,
    loading,
  };
};

import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import cx from 'clsx';
import { Button, DialogActions, DialogContent, FormRow } from '@macpaw/macpaw-ui';
import { WarningIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { ProductPlanType, Tag } from '~/types';
import { Form, FormInput, FormSelect, Loadable, StepItemProps } from '~/ui';
import { GiveOrganizationBetaPlanFields, GiveOrganizationBetaPlanModalStep } from '../../../constants';
import { useGiveOrganizationBetaPlanMutation } from '../../../graphql/mutations';
import { usePlanViewsQuery } from '../../../graphql/queries';
import { useMemoizedProductBetaPlansByTag, useMemoizedUniqProductsList } from '../../../helpers/hooks';
import { GiveOrganizationBetaPlanSchema } from '../../../schemas';
import styles from '../GiveOrganizationBetaPlanModal.module.sass';

const INITIAL_VALUES = {
  [GiveOrganizationBetaPlanFields.ProductTag]: '',
  [GiveOrganizationBetaPlanFields.ProductPlanSkuId]: '',
  [GiveOrganizationBetaPlanFields.Quantity]: '',
};

interface GiveOrganizationBetaPlanFormProps extends Partial<StepItemProps> {
  onCancel: () => void;
  organizationId: string;
}

const GiveOrganizationBetaPlanForm: React.FC<GiveOrganizationBetaPlanFormProps> = ({
  onCancel,
  organizationId,
  ...props
}) => {
  const { setStep } = props as StepItemProps;
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);

  const { planViews, loading: isPlansLoading } = usePlanViewsQuery({
    fetchPolicy: 'cache-first',
    isBusiness: true,
  });

  const { giveBetaPlan, loading } = useGiveOrganizationBetaPlanMutation({
    onCompleted: (data) => {
      const IS_SUCCESS_CREATION = !!data?.giveOrganizationBetaPlan?.isSuccessfulCreation;

      setStep(
        IS_SUCCESS_CREATION ? GiveOrganizationBetaPlanModalStep.Success : GiveOrganizationBetaPlanModalStep.Error,
      );
    },
  });

  const uniqProductsList = useMemoizedUniqProductsList(planViews);
  const productPlanList = useMemoizedProductBetaPlansByTag(planViews, Tag.CleanMyMacBusiness);

  useEffect(() => {
    formRef.current?.setValue(GiveOrganizationBetaPlanFields.ProductTag, Tag.CleanMyMacBusiness);

    const productPlanId =
      planViews.find(
        ({ productTag: planProductTag, productPlanType }) =>
          planProductTag === Tag.CleanMyMacBusiness && productPlanType === ProductPlanType.Subscription,
      )?.id ?? '';

    formRef.current?.setValue(GiveOrganizationBetaPlanFields.ProductPlanSkuId, productPlanId);
  }, [planViews]);

  const submitForm = () => {
    const values = formRef.current?.getValues();

    giveBetaPlan({
      organizationId,
      productPlanSkuId: values?.[GiveOrganizationBetaPlanFields.ProductPlanSkuId],
      seatCount: Number(values?.[GiveOrganizationBetaPlanFields.Quantity]),
    });
  };

  return (
    <Form
      initialValues={INITIAL_VALUES}
      innerRef={formRef}
      validationSchema={GiveOrganizationBetaPlanSchema}
      validateMode="onSubmit"
      onSubmit={submitForm}>
      <DialogContent className={styles.dialogContent}>
        <h4 className={cx(styles.dialogTitle, 'mb-24')}>Give New Beta Plan for Organization</h4>
        <Loadable isLoading={isPlansLoading} className={styles.loader}>
          <FormRow>
            <FormSelect name={GiveOrganizationBetaPlanFields.ProductTag} label="Product" disabled validateOnChange>
              <option disabled value="">
                New Plan
              </option>
              {uniqProductsList.map(({ productName, productTag: tagValue }) => (
                <option key={tagValue} value={tagValue}>
                  {productName}
                </option>
              ))}
            </FormSelect>
          </FormRow>
          <FormRow split>
            <FormSelect
              name={GiveOrganizationBetaPlanFields.ProductPlanSkuId}
              label="Product Plan"
              disabled
              validateOnChange>
              {productPlanList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </FormSelect>
            <FormInput
              name={GiveOrganizationBetaPlanFields.Quantity}
              label="Devices"
              type="number"
              placeholder="6-10000"
            />
          </FormRow>
          <div className={styles.limitations}>
            <h5 className={styles.limitations__title}>
              <WarningIcon /> <span>How Beta plans work</span>
            </h5>
            <ul className={styles.limitations__list}>
              <li className={styles.limitations__listItem}>An Organization can be given a Beta plan only once.</li>
              <li className={styles.limitations__listItem}>
                Beta plans are non-editable. You won’t be able to add or remove device slots after creating such a plan.
              </li>
              <li className={styles.limitations__listItem}>
                Beta plans don’t have payment links. The plan will appear automatically in Organization’s Account after
                you press the button below.
              </li>
              <li className={styles.limitations__listItem}>
                After creating a Beta plan, you need to ask the customer to sign in to their MacPaw Account and activate
                the plan.
              </li>
            </ul>
          </div>
        </Loadable>
      </DialogContent>
      <DialogActions>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" loading={loading} type="submit" data-testid="create-beta-plan">
          Create Beta Plan
        </Button>
      </DialogActions>
    </Form>
  );
};

export default GiveOrganizationBetaPlanForm;

import React, { PropsWithChildren } from 'react';
import cx from 'clsx';
import { DialogIcon, DialogContent, DialogActions, Button } from '@macpaw/macpaw-ui';
import { ButtonColor } from '@macpaw/macpaw-ui/lib/Button/Button';
import { ConfirmationModalBodyProps } from '~/types';
import Loadable from '../../Loadable';
import styles from './confirmationModal.module.sass';
import ConfirmationModalIcon from './ConfirmationModalIcon';

const ConfirmationModal: React.FC<PropsWithChildren<ConfirmationModalBodyProps>> = ({
  loading,
  actionLoading = false,
  title,
  description,
  actionTitle = 'Done',
  actions,
  children,
  type,
  icon,
  onSubmit = () => {},
  descriptionClassName,
  contentClassName,
  actionColor = 'secondary',
  dataHook,
}) => {
  const descriptionClassNames = cx(styles.confirmContent, descriptionClassName, {
    [styles.confirmDefaultWidth]: !descriptionClassName,
  });

  const contentClassNames = cx(styles.confirmContent, contentClassName, {
    [styles.confirmDefaultWidth]: !contentClassName,
  });

  return (
    <>
      <DialogContent data-testid={`${dataHook}-content`}>
        <Loadable isLoading={Boolean(loading)} className={styles.loaderContainer}>
          <DialogIcon>{type ? <ConfirmationModalIcon type={type} /> : icon}</DialogIcon>
          <h3 className="mb-16" data-testid={`${dataHook}-title`}>
            {title}
          </h3>
          <p className={descriptionClassNames} data-testid={`${dataHook}-description`}>
            {description}
          </p>
          {children && <div className={contentClassNames}>{children}</div>}
        </Loadable>
      </DialogContent>
      <DialogActions data-testid={`${dataHook}-actions`}>
        {actions || (
          <Button
            color={actionColor as ButtonColor}
            loading={actionLoading}
            onClick={onSubmit}
            data-testid={`${dataHook}-action`}>
            {actionTitle}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default ConfirmationModal;

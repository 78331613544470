import React from 'react';
import { Form, Step, Stepper, useModals } from '~/ui';
import { CustomerRefundInputTypes, Modals, RefundSteps, RefundTypes } from '../../constants';
import { CustomerPlanInterface } from '../../interfaces';
import { CustomerTransactionRefundSchema } from '../../schemas';
import { PlanRefundConfirmationStep, PlanRefundFormStep, PlanRefundSuccessStep } from './steps';

const INITIAL_VALUES = {
  [CustomerRefundInputTypes.TransactionId]: '',
  [CustomerRefundInputTypes.CancelChecked]: false,
  [CustomerRefundInputTypes.BlockPlanChecked]: false,
  [CustomerRefundInputTypes.PartialError]: null,
  [CustomerRefundInputTypes.CurrentTransactionAmount]: '',
  [CustomerRefundInputTypes.MaximumTransactionAmount]: '',
  [CustomerRefundInputTypes.Reason]: '',
  [CustomerRefundInputTypes.Currency]: 'USD',
};

interface PlanTransactionRefundProps {
  customerPlan: CustomerPlanInterface;
  onComplete: () => void;
}

const PlanTransactionRefundModal: React.FC<PlanTransactionRefundProps> = ({ customerPlan, onComplete }) => {
  const { closeModal } = useModals();

  const handleCancel = () => {
    closeModal(Modals.PlanTransactionRefundModal);
  };

  return (
    <Form initialValues={INITIAL_VALUES} validationSchema={CustomerTransactionRefundSchema} validateMode="onBlur">
      <Stepper initialStep={RefundSteps.Form}>
        <Step stepKey={RefundSteps.Form}>
          <PlanRefundFormStep customerPlan={customerPlan} onCancel={handleCancel} />
        </Step>
        <Step stepKey={RefundSteps.FullRefundWarning}>
          <PlanRefundConfirmationStep
            type={RefundTypes.Full}
            customerPlanId={customerPlan.id}
            customerPlanView={customerPlan.planView}
            onComplete={onComplete}
          />
        </Step>
        <Step stepKey={RefundSteps.PartialRefundWarning}>
          <PlanRefundConfirmationStep
            type={RefundTypes.Partial}
            customerPlanId={customerPlan.id}
            customerPlanView={customerPlan.planView}
            onComplete={onComplete}
          />
        </Step>
        <Step stepKey={RefundSteps.Success}>
          <PlanRefundSuccessStep />
        </Step>
      </Stepper>
    </Form>
  );
};

export default PlanTransactionRefundModal;

import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Maybe } from '~/types';
import { Form, Stepper, Step, StepperContextValueType, useModals } from '~/ui';
import { CreateOrganizationStep, CreateOrganizationFields, Modals, ROUTE_PATHS } from '../../constants';
import { useCreateOrganizationMutation } from '../../graphql/mutations';
import { CUSTOMER_QUERY } from '../../graphql/queries';
import handleFormServerError from '../../helpers/handleFormServerError';
import { CreateOrganizationData } from '../../interfaces';
import { OrganizationDetailsSchema } from '../../schemas';
import { CreateOrganizationForm, CreateOrganizationSuccess } from './steps';

const INITIAL_VALUES = {
  [CreateOrganizationFields.Name]: '',
  [CreateOrganizationFields.Address]: '',
  [CreateOrganizationFields.City]: '',
  [CreateOrganizationFields.State]: '',
  [CreateOrganizationFields.PostCode]: '',
  [CreateOrganizationFields.Country]: '',
  [CreateOrganizationFields.VatNumber]: '',
};

interface CreateOrganizationProps {
  customerId: string;
}

const CreateOrganizationModal: React.FC<CreateOrganizationProps> = ({ customerId }) => {
  const navigate = useNavigate();
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);
  const stepperRef = useRef<StepperContextValueType>(null);
  const organizationIdRef = useRef<Maybe<string>>('');
  const { closeModal } = useModals();

  const { data: customerProfileData } = useQuery(CUSTOMER_QUERY, {
    fetchPolicy: 'cache-only',
    variables: { customerId },
  });
  const { createOrganization, loading } = useCreateOrganizationMutation();

  const handleCancel = () => closeModal(Modals.CreateOrganizationModal);

  const handleSuccessSubmit = () => {
    const { current: organizationId } = organizationIdRef;

    handleCancel();

    if (organizationId) navigate(ROUTE_PATHS.organizationPage(organizationId));
  };

  const handleSubmit = async (values: ObjectLiteral) => {
    const { current: stepper } = stepperRef;

    if (!stepper) return;

    const organizationData = {
      ...values,
      ownerEmail: customerProfileData.customer.email as string,
    } as CreateOrganizationData;

    try {
      organizationIdRef.current = await createOrganization(organizationData, customerId);
      stepper.setNextStep();
    } catch (error) {
      handleFormServerError(error as Error, formRef.current?.setError);
    }
  };

  return (
    <Form
      initialValues={INITIAL_VALUES}
      validationSchema={OrganizationDetailsSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}>
      <Stepper initialStep={CreateOrganizationStep.Form} innerRef={stepperRef} updateAnyway>
        <Step stepKey={CreateOrganizationStep.Form}>
          <CreateOrganizationForm isLoading={loading} onCancel={handleCancel} />
        </Step>
        <Step stepKey={CreateOrganizationStep.Success}>
          <CreateOrganizationSuccess onSubmit={handleSuccessSubmit} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default CreateOrganizationModal;

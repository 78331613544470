import React from 'react';
import { Panel, Button } from '@macpaw/macpaw-ui';
import { DeviceIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { CustomerPlanType } from '~/types';
import { LoadableText, useModals } from '~/ui';
import { Modals } from '../../constants';
import { useOrganizationPlanSeatStatsQuery } from '../../graphql/queries';
import deviceIcon from '../../images/icons/device.png';
import { OrganizationPlanInterface } from '../../interfaces';
import Section from '../Section/Section';
import styles from './OrganizationPlanDevices.module.sass';

interface OrganizationPlanDevicesProps {
  plan: OrganizationPlanInterface;
}
const OrganizationPlanDevices: React.FC<OrganizationPlanDevicesProps> = ({ plan }) => {
  const { openModal } = useModals();

  const { loading: isLoadingSeats, seatsCount, vacantSeatsCount } = useOrganizationPlanSeatStatsQuery(plan);

  const handleOpenModal = (modalName: Modals) => () => {
    openModal({
      name: modalName,
      props: {
        plan,
        vacantSeatsCount,
        seatsCount,
      },
    });
  };

  const renderAction = () =>
    plan.type === CustomerPlanType.CmmBusinessBeta ? (
      <Button scale="medium" color="secondary" onClick={handleOpenModal(Modals.AddDeviceSlotsToPlanModal)}>
        Add Device Slots
      </Button>
    ) : (
      <></>
    );

  return (
    <Section title="Devices" action={renderAction()}>
      <div className={styles.devicesStatus}>
        <div className={styles.deviceStatusCount}>
          <DeviceIcon className={styles.deviceStatusIcon} />
          <LoadableText loaderSize={24} isLoading={isLoadingSeats}>
            Available: {vacantSeatsCount} of {seatsCount}
          </LoadableText>
        </div>
      </div>
      <Panel outline className={styles.emptyPanel}>
        <img src={deviceIcon} className={styles.emptyIcon} alt="" />
        <div>
          <div className="p3 mb-4">
            <b>Device Management</b>
          </div>
          <div className="p3">Sign in to this organization to manage its devices.</div>
        </div>
      </Panel>
    </Section>
  );
};

export default OrganizationPlanDevices;

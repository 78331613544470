import React from 'react';
import { Maybe } from '~/types';
import { useStepperContext } from './StepperContext';

export interface StepItemProps {
  step: number;
  stepKey: string;
  prevStepKey: Maybe<string>;
  maxStep: number;
  onFinish: () => void;
  setStep: (step: string) => void;
  setPreviousStep: (step: Maybe<string>) => void;
  setNextStep: () => void;
}

type StepRenderFNType = (props: StepItemProps) => JSX.Element;
interface Step {
  stepKey: string;
  step?: number;
  children?: StepRenderFNType | React.ReactElement;
}

const Step: React.FC<Step> = ({ stepKey, step = 0, children }) => {
  const { prevStepKey, currentStep, setNextStep, setStep, setPreviousStep, maxStep, onFinish } = useStepperContext();
  const isActiveStep = step === currentStep;

  if (!isActiveStep) return null;

  const stepBag = {
    setNextStep,
    setPreviousStep,
    stepKey,
    prevStepKey,
    step,
    setStep,
    maxStep,
    onFinish,
  };

  if (typeof children === 'function') {
    return <>{(children as StepRenderFNType)(stepBag)}</>;
  }

  const child = React.Children.only<React.ReactElement>(children as React.ReactElement);

  return <>{React.cloneElement(child, stepBag)}</>;
};

export default Step;

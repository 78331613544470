import { gql } from 'graphql-tag';

export const STATES_QUERY = gql`
  query States($countryCode: String!) {
    states(countryCode: $countryCode) {
      code
      name
    }
  }
`;

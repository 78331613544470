import React from 'react';
import { ConfirmationType } from '~/types';
import { useModals, ConfirmationModal } from '~/ui';

import { Modals } from '../../../constants';

const PlanRefundSuccessStep: React.FC = () => {
  const { closeModal } = useModals();

  const handleSubmit = () => closeModal(Modals.PlanTransactionRefundModal);

  return (
    <ConfirmationModal
      type={ConfirmationType.Settings}
      title="Refund will be done in a moment"
      description="This could take up to a few minutes."
      actionTitle="Got It"
      onSubmit={handleSubmit}
    />
  );
};

export default PlanRefundSuccessStep;

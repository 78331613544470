import { BaseCustomerPlan, CustomerPlanStatus, CustomerPlanGroup } from '~/types';

export default function getCustomerPlanGroup(customerPlan: BaseCustomerPlan): CustomerPlanGroup {
  switch (customerPlan.status) {
    case CustomerPlanStatus.Active:
    case CustomerPlanStatus.PauseRequested:
    case CustomerPlanStatus.CancelRequested:
    case CustomerPlanStatus.CancelApproved:
    case CustomerPlanStatus.Trialing:
      return CustomerPlanGroup.Active;

    case CustomerPlanStatus.Paused:
    case CustomerPlanStatus.PastDue:
      return CustomerPlanGroup.NeedsAttention;

    case CustomerPlanStatus.FraudCheck:
    case CustomerPlanStatus.DisputeOpened:
      return CustomerPlanGroup.Pending;

    default:
      return CustomerPlanGroup.Inactive;
  }
}

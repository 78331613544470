/* eslint-disable no-useless-escape */
const VALID_PROTOCOLS = ['http:', 'https:'];
const XSS_REGEXP = /(\b)(on\S+)(\s*)=|javascript|<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/gi;
const DEFAULT_SAFE_PATH = '#';

const getXSSProtectedUrl = (path: string): string => {
  const isVulnerablePath = XSS_REGEXP.test(path);

  if (isVulnerablePath) return DEFAULT_SAFE_PATH;

  const pathUrl = new URL(path);
  const isValidProtocol = VALID_PROTOCOLS.includes(pathUrl.protocol);

  return isValidProtocol ? `${pathUrl.origin}${pathUrl.pathname}` : DEFAULT_SAFE_PATH;
};

export default getXSSProtectedUrl;

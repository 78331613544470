import React, { useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import { Grid, GridCell, GridRow, Label, notify, Tag } from '@macpaw/macpaw-ui';
import { CopyIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { ActivationStatus, BaseCustomerPlan, CustomerPlanType, Maybe, NotificationType } from '~/types';
import { LoadableText, BillingCycle, DynamicDate, Loadable } from '~/ui';
import { conditionValue, formatDate } from '~/utils';
import PlanStatusLabel from '../../components/PlanStatusLabel/PlanStatusLabel';
import { COPIED_TO_CLIPBOARD } from '../../constants';
import { formatToStrong } from '../../helpers/formatters';
import { getPaddleSubscriptionLink } from '../../helpers/getPaddleSubscriptionLink';
import { isTrial } from '../../helpers/plan';
import stringifyBoolean from '../../helpers/stringifyBoolean';
import { OrderInterface, OrganizationInterface, OrganizationOwner, OrganizationPlanInterface } from '../../interfaces';
import OrganizationPlanManageMenu from './OrganizationPlanManageMenu';
import styles from './OrganizationPlanOverview.module.sass';

interface OrganizationPlanOverviewProps {
  plan: Maybe<OrganizationPlanInterface>;
  owner: Maybe<OrganizationOwner>;
  organization: Maybe<OrganizationInterface>;
  order?: OrderInterface;
}

const OrganizationPlanOverview: React.FC<OrganizationPlanOverviewProps> = ({ plan, owner, organization, order }) => {
  const paddleProductId = order?.paddleProductId;
  const paddleSubscriptionId = order?.paddleSubscriptionId;
  const planView = plan?.planView;
  const startDate = plan?.startDate;
  const activationStatus = plan?.activationStatus;
  const startDateTitle = plan && isTrial(plan) ? 'Trial Started' : 'Purchase Date';
  const [clipboard, copyToClipboard] = useCopyToClipboard();
  const paddleSubscriptionLink = paddleSubscriptionId ? getPaddleSubscriptionLink(paddleSubscriptionId) : null;

  useEffect(() => {
    if (clipboard.value) {
      notify(COPIED_TO_CLIPBOARD, NotificationType.Success);
    } else if (clipboard.error) {
      notify(clipboard.error.message, NotificationType.Error);
    }
  }, [clipboard]);

  return (
    <Grid
      icon={
        <Loadable loaderSize={80} loaderInline isLoading={!plan}>
          <img src={plan?.planView.productPlanIconUrl} alt="" />
        </Loadable>
      }
      action={
        <OrganizationPlanManageMenu ownerId={owner?.id} disabled={!owner} organization={organization} plan={plan} />
      }>
      <GridRow>
        <GridCell type="primary">
          <Label>Product</Label>
          <LoadableText formatter={formatToStrong} isLoading={!plan} isConditional>
            {planView?.productName}
          </LoadableText>
        </GridCell>
        <GridCell type="primary">
          <Label>Plan</Label>
          <LoadableText isLoading={!plan}>
            <span className={styles.productPlanName}>{planView?.productPlanName}</span>
            {plan?.type === CustomerPlanType.CmmBusinessBeta && (
              <Tag borderRadius={24} color="secondary">
                Beta
              </Tag>
            )}
          </LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>{startDateTitle}</Label>
          <LoadableText formatter={formatDate} isLoading={!plan}>
            {startDate ?? 0}
          </LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <LoadableText isLoading={!plan}>
            <DynamicDate
              customerPlan={plan as unknown as BaseCustomerPlan}
              order={plan?.order}
              labelRenews="Renewal Date"
            />
          </LoadableText>
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell type="primary">
          <Label>User</Label>
          <LoadableText formatter={formatToStrong} isLoading={!owner} isConditional>
            {owner?.email}
          </LoadableText>
        </GridCell>
        <GridCell type="primary">
          <Label>User Email Address</Label>
          <LoadableText isLoading={!organization}>{organization?.ownerEmail}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Can Access</Label>
          <LoadableText formatter={stringifyBoolean}>{activationStatus === ActivationStatus.CanAccess}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Duration</Label>
          <LoadableText isLoading={!plan}>
            <BillingCycle customerPlan={plan as unknown as BaseCustomerPlan} />
          </LoadableText>
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell type="primary">
          <Label>Subscription ID</Label>
          {conditionValue(
            paddleSubscriptionLink && (
              <div className={styles.withInlineIcon}>
                {paddleSubscriptionId}
                <CopyIcon
                  className={[styles.inlineIcon, styles.copyIcon].join(' ')}
                  onClick={() => copyToClipboard(String(paddleSubscriptionId))}
                />
              </div>
            ),
          )}
        </GridCell>
        <GridCell type="primary">
          <Label>Product ID</Label>
          <LoadableText isConditional>{paddleProductId}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Status</Label>
          <LoadableText isLoading={!plan}>
            <PlanStatusLabel plan={plan as unknown as BaseCustomerPlan} />
          </LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Reason</Label>
          <LoadableText isLoading={!plan} isConditional>
            <span style={{ textTransform: 'capitalize' }}>{plan?.reason}</span>
          </LoadableText>
        </GridCell>
      </GridRow>
    </Grid>
  );
};

export default OrganizationPlanOverview;

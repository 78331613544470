import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, Panel, notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { StepItemProps } from '~/ui';
import { emailTrim, MS_IN_S, ONE_MINUTE_TIMEOUT_S } from '~/utils';
import {
  AuthInputType,
  AuthStepType,
  DEFAULT_RESEND_TIME,
  MIN_TIME_BEFORE_PASSWORD_RESET,
  PIN_RESEND_NAME,
  SUPPORT_TOKEN,
  SOMETHING_WENT_WRONG,
  ERROR_COPY,
  PINCODE_INVALID_ERROR,
  PINCODE_MAX_ATTEMPT_ERROR,
  LENGTH_PINCODE,
} from '../../constants';
import { SIGN_IN_PASSWORD_MUTATION, SIGN_IN_PIN_CODE_MUTATION } from '../../graphql/mutations';
import sentry from '../../helpers/sentry';
import recoverLogo from '../../images/auth/password-reset.png';
import { AuthSuccessInterface } from '../../interfaces';
import { tokenVar } from '../../store';
import styles from './Auth.module.sass';
import FormPin from './FormPin/FormPin';

const AuthPin: React.FC = (props) => {
  const { setStep } = props as StepItemProps;
  const [isLoading, setIsLoading] = useState(false);
  const [isResendAgainShow, setIsResendAgainShow] = useState(false);

  const {
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();
  const { email, password } = getValues();

  const getInitialTimerValue = useCallback(() => {
    let result = ONE_MINUTE_TIMEOUT_S;
    const resendTimeObjectJSON: string | null = localStorage.getItem(PIN_RESEND_NAME);
    const now = new Date().getTime();

    if (resendTimeObjectJSON) {
      const resendTimeObject = JSON.parse(resendTimeObjectJSON);
      const emailInLocalStorage = Object.keys(resendTimeObject);
      let newEmailUsed = true;

      emailInLocalStorage.forEach((emailInStorage: string) => {
        if (emailInStorage === email) {
          const savedTimestampStringified: string = resendTimeObject[emailInStorage];
          const savedTimestamp = parseInt(savedTimestampStringified, 10);
          const diff = now - savedTimestamp;

          if (diff < MIN_TIME_BEFORE_PASSWORD_RESET) {
            result = ONE_MINUTE_TIMEOUT_S - Math.round(diff / MS_IN_S);
          }
          newEmailUsed = false;
        }
      });
      if (newEmailUsed) {
        resendTimeObject[email] = now;
        localStorage.setItem(PIN_RESEND_NAME, JSON.stringify(resendTimeObject));
      }
    } else {
      const cookiePayload = {
        [email]: now,
      };

      localStorage.setItem(PIN_RESEND_NAME, JSON.stringify(cookiePayload));
    }

    return result;
  }, [email]);

  const [resendTime, setResendTime] = useState(DEFAULT_RESEND_TIME);

  useEffect(() => {
    setResendTime(getInitialTimerValue());

    return () => {};
  }, [getInitialTimerValue]);

  const removeCurrentEmailFromStorage = () => {
    const resendTimeObjectJSON: string | null = localStorage.getItem(PIN_RESEND_NAME);

    if (resendTimeObjectJSON) {
      const resendTimeObject = JSON.parse(resendTimeObjectJSON);
      const emailInLocalStorage = Object.keys(resendTimeObject);

      if (emailInLocalStorage.length === 1) {
        localStorage.removeItem(PIN_RESEND_NAME);
      } else {
        delete resendTimeObject[email];
        localStorage.setItem(PIN_RESEND_NAME, JSON.stringify(resendTimeObject));
      }
    }
  };

  const [signin, { loading }] = useMutation(SIGN_IN_PIN_CODE_MUTATION, {
    onCompleted(data: AuthSuccessInterface) {
      const { token } = data.signInPinCode;

      if (token) {
        window.localStorage.setItem(SUPPORT_TOKEN, token);
        tokenVar(token);
      }
    },
    onError(error) {
      setIsLoading(false);
      if (Array.isArray(error.graphQLErrors)) {
        error.graphQLErrors.forEach((err) => {
          const errCode = err.extensions?.response.body.errors[0].code;

          switch (errCode) {
            case PINCODE_INVALID_ERROR:
              setError(AuthInputType.Pin, {
                message: ERROR_COPY.pinCodeInvalid,
              });
              break;
            case PINCODE_MAX_ATTEMPT_ERROR:
              setResendTime(0);
              setIsResendAgainShow(false);
              removeCurrentEmailFromStorage();
              setError(AuthInputType.Copy, {
                message: ERROR_COPY.pinCodeAttemt,
              });
              setStep(AuthStepType.PasswordSignIn);
              break;
            default:
              notify(SOMETHING_WENT_WRONG, NotificationType.Error);
          }
        });
      } else {
        sentry.captureException(error);
      }
    },
  });
  const [resendPin] = useMutation(SIGN_IN_PASSWORD_MUTATION, {
    onCompleted() {
      setResendTime(ONE_MINUTE_TIMEOUT_S);
      setIsResendAgainShow(true);
    },
    onError(error) {
      setIsLoading(false);
      sentry.captureException(error);
    },
  });

  useEffect(() => {
    let interval: number;

    if (resendTime) {
      interval = window.setInterval(() => {
        setResendTime(resendTime - 1);
      }, MS_IN_S);
    }

    return () => clearInterval(interval);
  }, [resendTime]);

  const handleSubmit = () => {
    const formValues = getValues();

    if (errors[AuthInputType.Pin]) {
      if (formValues[AuthInputType.Pin].length !== LENGTH_PINCODE) {
        setError(AuthInputType.Pin, {
          message: `Verification code must be ${LENGTH_PINCODE} characters`,
        });
      }

      return;
    }

    if (loading) return;

    setIsLoading(true);
    signin({ variables: formValues });
  };

  const handleResendPin = () => {
    resendPin({ variables: { email, password } });
  };

  return (
    <Panel className={styles.panel}>
      <div className={`${styles.logo} mb-24`}>
        <img src={recoverLogo} width={128} height={128} alt="" />
      </div>
      <div className={styles.form}>
        <div className="h3 mb-8">Enter verification code</div>
        <div className="p3 mb-32">
          <div className="mb-8">
            We’ve sent a verification code to <strong>{emailTrim(email)}</strong>.
          </div>
          Enter the code to continue.{' '}
          {resendTime === 0 && !isResendAgainShow && (
            <Button asLink onClick={handleResendPin} className="pin-resend-button">
              Resend
            </Button>
          )}
        </div>
        <FormPin
          isLoading={isLoading}
          resendTime={resendTime}
          isResendAgainShow={isResendAgainShow}
          handleResendPin={handleResendPin}
          handleSubmit={handleSubmit}
        />
      </div>
    </Panel>
  );
};

export default AuthPin;

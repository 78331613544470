import { ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import { SERVER_ERROR_CODE_400, SERVER_ERROR_CODE_500 } from '~/utils';
import { handleAuthError } from './auth';
import {
  APP_API_CLIENT_ID,
  ERRORS_WITHOUT_REDIRECT,
  APP_GRAPHQL_ENDPOINT,
  AUTH_ERRORS,
  FORBIDDEN_ERROR,
} from './constants';
import sentry from './helpers/sentry';
import { tokenVar, userIdVar, errorVar } from './store';

const MAX_ATTEMPTS_COUNT = 5;

const authTokenLink = () => {
  return setContext((request, previousContext) => {
    const token = tokenVar();
    const userId = userIdVar();

    return {
      headers: {
        ...previousContext.headers,
        Authorization: token ? `Bearer ${token}` : '',
        clientId: APP_API_CLIENT_ID,
        currentCustomerId: previousContext?.headers?.currentCustomerId ?? (userId || ''),
      },
    };
  });
};

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink({
      attempts: (count) => count < MAX_ATTEMPTS_COUNT,
    }),
    onError((errorResponse: ErrorResponse) => {
      const { graphQLErrors, networkError } = errorResponse;

      sentry.captureGraphQLException(errorResponse);

      if (networkError) {
        errorVar(Error('NETWORK_ERROR'));

        return;
      }

      if (!graphQLErrors) return;

      if (graphQLErrors[0]?.extensions?.code === FORBIDDEN_ERROR) {
        errorVar(Error(FORBIDDEN_ERROR));

        return;
      }

      const apiResponseStatus = graphQLErrors[0]?.extensions?.response?.status;
      const apiResponseCode = graphQLErrors[0]?.extensions?.response?.body?.errors[0].code;

      if (ERRORS_WITHOUT_REDIRECT.includes(apiResponseCode)) {
        errorVar(Error(apiResponseCode));

        return;
      }

      if (apiResponseStatus === SERVER_ERROR_CODE_500) {
        errorVar(Error('UNKNOWN_ERROR'));

        return;
      }

      if (apiResponseStatus === SERVER_ERROR_CODE_400) {
        errorVar(Error('NOT_FOUND_ERROR'));

        return;
      }

      const errors = graphQLErrors[0]?.extensions?.response?.body?.errors;

      if (!Array.isArray(errors)) {
        errorVar(Error('UNKNOWN_ERROR'));

        return;
      }

      const errorCode = errors[0].code;

      if (AUTH_ERRORS.includes(errorCode)) {
        handleAuthError(client, errorCode);

        return;
      }

      errorVar(Error(errorCode));
    }),
    authTokenLink(),
    new HttpLink({
      uri: APP_GRAPHQL_ENDPOINT,
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          customers: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
    possibleTypes: {
      PlanOwner: ['Owner', 'OrganizationBase'],
    },
  }),
  resolvers: {},
});

export default client;

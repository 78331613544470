import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Hint } from '@macpaw/macpaw-ui';
import { Maybe } from '~/types';
import { FormCheckBox } from '~/ui';
import { SetCustomPriceFields } from '../../../constants';
import styles from './PriceFieldFooter.module.sass';

interface PriceFieldFooterProps {
  isLoading: boolean;
  currentPrice: Maybe<number>;
  isCustomPriceSet: boolean;
}

const PriceFieldFooter: React.FC<PriceFieldFooterProps> = ({ isLoading, currentPrice, isCustomPriceSet }) => {
  const { watch } = useFormContext();
  const productPlanId = watch(SetCustomPriceFields.ProductPlanSkuId);
  const isDefaultPrice = watch(SetCustomPriceFields.IsDefaultPrice);

  const getFormFieldsConfig = () => {
    const isShowPriceFooter = !isLoading && !!productPlanId && typeof currentPrice === 'number';
    const isShowCustomPriceHint = isCustomPriceSet && !isLoading;
    const isShowCustomPriceAttentionHint = !isCustomPriceSet && productPlanId && !isDefaultPrice;
    const isDisabledDefaultPriceCheckbox = isLoading || isCustomPriceSet || !currentPrice;

    return {
      isShowPriceFooter,
      isShowCustomPriceHint,
      isShowCustomPriceAttentionHint,
      isDisabledDefaultPriceCheckbox,
    };
  };

  const { isShowPriceFooter, isShowCustomPriceHint, isShowCustomPriceAttentionHint, isDisabledDefaultPriceCheckbox } =
    getFormFieldsConfig();

  if (!isShowPriceFooter) return null;

  return (
    <>
      {isShowCustomPriceAttentionHint && (
        <Hint className={styles.hint}>
          <strong>Custom price can be set only once.</strong>
          Changing the pricing for a specific plan is irreversible. To set new pricing for this Organization, you’ll
          need to create a new plan over again.
        </Hint>
      )}
      {isShowCustomPriceHint ? (
        <Hint className={styles.hint}>Custom price for this plan has already been set.</Hint>
      ) : (
        <div className={styles.checkboxWrapper}>
          <FormCheckBox
            name={SetCustomPriceFields.IsDefaultPrice}
            label="Apply the default price per device"
            labelClassName={styles.label}
            disabled={isDisabledDefaultPriceCheckbox}
          />
        </div>
      )}
    </>
  );
};

export default PriceFieldFooter;

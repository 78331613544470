import React from 'react';
import { Select } from '@macpaw/macpaw-ui';
import type { SelectProps } from '@macpaw/macpaw-ui/lib/Select/Select';
import { useFormError, useFormField } from './Form.hooks';
import { BaseFormElementProps } from './Form.types';
import { useFormContext } from './FormContext';

interface FormSelectProps extends BaseFormElementProps, Omit<SelectProps, 'name'> {}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  children,
  validateOnChange,
  formatErrorMessage,
  onChange,
  ...rest
}) => {
  const { trans } = useFormContext();
  const {
    field: { onChange: formOnChange, onBlur, value = '' },
    setValue,
    clearErrors,
  } = useFormField<string>(name);
  const { isError, message: errorMessage } = useFormError(name, {
    formatValue: formatErrorMessage ?? trans,
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (validateOnChange && isError) clearErrors();

    formOnChange(e);

    if (onChange) {
      onChange(e);
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <Select
      id={name}
      name={name}
      data-testid={name}
      selected={value}
      error={errorMessage as string}
      onBlur={onBlur}
      onChange={handleChange}
      {...rest}>
      {children}
    </Select>
  );
};

export default FormSelect;

import { BaseSentry } from '~/utils';
import { APP_ENVIRONMENT, APP_IS_PRODUCTION, APP_SENTRY_DNS } from '../constants';

class Sentry extends BaseSentry {
  get sentryOptions(): { dsn: string; environment: string } {
    return {
      dsn: APP_SENTRY_DNS,
      environment: APP_ENVIRONMENT,
    };
  }

  get isSentryEnabled(): boolean {
    return APP_IS_PRODUCTION;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Sentry();

export const MIN_TIME_BEFORE_PASSWORD_RESET = 60000;
export const DEFAULT_RESEND_TIME = 60;
export const PIN_RESEND_NAME = 'pinResendInfo';
export const SUPPORT_TOKEN = 'supportToken';
export const LENGTH_PINCODE = 6;

export enum AuthInputType {
  Email = 'email',
  Password = 'password',
  Pin = 'pinCode',
  Copy = 'copy',
}

export enum AuthStepType {
  PasswordSignIn = 'password-signin',
  PinSignIn = 'pin-signin',
}

export enum UserInvitationInputType {
  Password = 'Password',
  ConfirmPassword = 'confirmPassword',
}

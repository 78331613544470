import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { formatDate, conditionValue, emailTrim, nameTrim, useQueryParams } from '~/utils';
import CustomerIcon from '../../images/icons/customer.svg?react';
import { CustomerInterface } from '../../interfaces';
import { TableCell } from '../Table';
import styles from './UsersList.module.sass';

interface UserListItemProps {
  user: CustomerInterface;
  className?: string;
  userPath: (id: string) => string;
}

const UserListItem: React.FC<UserListItemProps> = ({ user, className, userPath }) => {
  const queryParams = useQueryParams();
  const search = queryParams.get('q') || '';
  const page = queryParams.get('page') || '1';

  return (
    <Link to={userPath(user.id)} state={{ search, page }} className={className}>
      <TableCell>
        <CustomerIcon width={40} height={40} />
      </TableCell>
      <TableCell>
        <div className={styles.content}>{conditionValue(user.name && <strong>{nameTrim(user.name!)}</strong>)}</div>
      </TableCell>
      <TableCell>
        <div className={styles.content}>{emailTrim(user.email)}</div>
      </TableCell>
      <TableCell>
        <div className={styles.content}>{formatDate(user.createdAt)}</div>
      </TableCell>
      <TableCell>
        <DropdownIcon className={styles.arrow} />
      </TableCell>
    </Link>
  );
};

export default UserListItem;

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Label, Button, Dropdown, DropdownItem, Panel, Grid, GridRow, GridCell, notify } from '@macpaw/macpaw-ui';
import { DeviceIcon, DeviceActiveIcon, MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { SeatStatus, NotificationType, Maybe, BasePlanOwnerType } from '~/types';
import { conditionValue, formatDate } from '~/utils';
import { REMOVE_SEAT_MUTATION, REMOVE_DEVICE_MUTATION } from '../../graphql/mutations';
import { SEATS_QUERY } from '../../graphql/queries';
import deviceIcon from '../../images/icons/device.png';
import UnknownDeviceIcon from '../../images/icons/unknown-device.svg?react';
import UserPendingIcon from '../../images/icons/user-pending.svg?react';
import { CustomerOwnerInterface, CustomerPlanInterface, PlanInterface, SeatInterface } from '../../interfaces';
import Section from '../Section/Section';
import styles from './PlanDevices.module.sass';

interface PlanDevices {
  plan: PlanInterface;
  customerId?: string;
}

const isOwnSeat = (seat: SeatInterface, customerId: string) => seat.customerId === customerId;

const PlanDevices: React.FC<PlanDevices> = ({ plan, customerId }) => {
  const { customerPlanId, planId } = useParams<Record<'customerPlanId' | 'planId', string>>();
  const isDevicesAvailable = !plan.planView.productExternalAccountUrl;

  const { data, loading, refetch } = useQuery(SEATS_QUERY, {
    variables: {
      customerPlanId: planId ?? customerPlanId,
    },
    skip: !isDevicesAvailable,
  });

  const [removeSeat] = useMutation(REMOVE_SEAT_MUTATION, {
    onCompleted() {
      refetch();
      notify('Seat has been removed successfully!', NotificationType.Success);
    },
    onError() {
      // prevent catching error
    },
  });

  const [removeDevice] = useMutation(REMOVE_DEVICE_MUTATION, {
    onCompleted() {
      refetch();
      notify('Device has been removed successfully!', NotificationType.Success);
    },
    onError() {
      // prevent catching error
    },
  });

  const seats = useMemo(() => {
    if (!data) return null;

    const seatsList = data?.seats as SeatInterface[];

    if (plan.ownerType === BasePlanOwnerType.Organization) {
      return seatsList.filter(({ customerId: seatCustomerId }) => seatCustomerId === customerId);
    }

    return seatsList;
  }, [data, plan, customerId]);

  const { activeSeats, availableSeats } = useMemo(() => {
    if (!seats) return { activeSeats: seats, availableSeats: seats };

    if (plan.ownerType === BasePlanOwnerType.Organization) {
      return seats.reduce(
        (acc, seat) => {
          if (seat.status === SeatStatus.Active) {
            acc.activeSeats.push(seat);
          } else {
            // eslint-disable-next-line no-param-reassign
            acc.availableSeats += 1;
          }

          return acc;
        },
        { activeSeats: [] as SeatInterface[], availableSeats: 0 },
      );
    }

    return { activeSeats: seats, availableSeats: plan.planView.productPlanSeatCount - seats.length };
  }, [seats, plan]);

  const handleRemoveSeat = (id: string) => {
    removeSeat({ variables: { customerProductPlanSeatId: id } });
  };

  const handleRemoveDevice = (id: string) => {
    removeDevice({ variables: { customerProductPlanSeatId: id } });
  };

  const onRemoveDevice = (seat: SeatInterface, planItem: CustomerPlanInterface) => {
    if (isOwnSeat(seat, planItem?.owner?.id ?? '')) {
      handleRemoveSeat(seat.id);
    } else {
      handleRemoveDevice(seat.id);
    }
  };

  if (!isDevicesAvailable) {
    const { productName } = plan.planView;

    return (
      <Section title="Devices">
        <Panel outline className={styles.empty}>
          <img src={deviceIcon} className={styles.emptyIcon} alt="" />
          <div>
            <p className="p3">
              <b>This list of devices is not available.</b>
            </p>
            <p className="p3">
              For {productName}, devices are not managed inside of the customer&apos;s MacPaw Account.
            </p>
          </div>
        </Panel>
      </Section>
    );
  }

  const SeatIcon: React.FC<{ seat: SeatInterface }> = ({ seat }) => {
    if (seat.status === SeatStatus.Pending) {
      return <UserPendingIcon className={styles.deviceIcon} />;
    }
    if (seat.machineModelIconUrl) {
      return <img src={seat.machineModelIconUrl} className={styles.deviceIcon} alt="" />;
    }

    return <UnknownDeviceIcon className={styles.deviceIcon} />;
  };

  return (
    <Section title="Devices" loading={loading}>
      <div className={styles.devicesStatus}>
        <div className={styles.deviceStatusCount}>
          <DeviceIcon className={styles.deviceStatusIcon} />
          Active: <b>{conditionValue(activeSeats?.length)}</b>
        </div>
        <div className={styles.deviceStatusCount}>
          <DeviceActiveIcon className={styles.deviceStatusIcon} />
          Available: <b>{conditionValue(availableSeats)}</b>
        </div>
      </div>
      {activeSeats && (
        <>
          {!activeSeats.length && (
            <Panel outline className={styles.empty}>
              <img src={deviceIcon} className={styles.emptyIcon} alt="" />
              <b>No Devices</b>
            </Panel>
          )}
          {activeSeats.map((seat) => {
            const owner = seat?.owner as Maybe<CustomerOwnerInterface>;

            return (
              <Grid
                key={seat.id}
                className={styles.devicesSeat}
                icon={<SeatIcon seat={seat} />}
                action={
                  <Dropdown
                    position="right"
                    className={styles.menuButton}
                    trigger={
                      <Button color="transparent" icon>
                        <MoreIcon />
                      </Button>
                    }>
                    {seat.status === SeatStatus.Active && (
                      <DropdownItem onClick={() => onRemoveDevice(seat, plan as CustomerPlanInterface)}>
                        Remove Device
                      </DropdownItem>
                    )}
                    {seat.status === SeatStatus.Active && !isOwnSeat(seat, plan?.owner?.id ?? '') && (
                      <DropdownItem separator />
                    )}
                    {!isOwnSeat(seat, plan?.owner?.id ?? '') && (
                      <DropdownItem onClick={() => handleRemoveSeat(seat.id)}>Remove from Plan</DropdownItem>
                    )}
                  </Dropdown>
                }>
                <GridRow>
                  <GridCell type="primary">
                    <Label>Device Name</Label>
                    {seat.machineName || '—'}
                  </GridCell>
                  <GridCell type="primary">
                    <Label>User</Label>
                    <div title={owner?.email}>{owner?.email}</div>
                  </GridCell>
                  <GridCell type="secondary">
                    <Label>Status</Label>
                    <div style={{ textTransform: 'capitalize' }}>{seat.status}</div>
                  </GridCell>
                  <GridCell type="secondary">
                    <Label>Last Accessed</Label>
                    {conditionValue(seat.lastAccessDate && formatDate(seat.lastAccessDate))}
                  </GridCell>
                </GridRow>
              </Grid>
            );
          })}
        </>
      )}
    </Section>
  );
};

export default PlanDevices;

export enum ReduceSizeSteps {
  Reduce = 'reduce',
  Warning = 'warning',
  Success = 'success',
}

export enum PlanReduceSizeInputTypes {
  VacantSeatsCount = 'vacantSeatsCount',
  ReduceCount = 'reduceCount',
}

export enum CustomerRefundInputTypes {
  TransactionId = 'transactionId',
  CancelChecked = 'CancelChecked',
  BlockPlanChecked = 'BlockPlanChecked',
  PartialError = 'partialError',
  CurrentTransactionAmount = 'currentTransactionAmount',
  MaximumTransactionAmount = 'maximumTransactionAmount',
  Reason = 'reason',
  Currency = 'currency',
}

import React from 'react';
import { Navigation } from '~/ui';
import { ROUTE_PATHS } from '../../constants';
import styles from './AccountTypeSwitcher.module.sass';

const AccountTypeSwitcher: React.FC = () => {
  const navItems = [
    {
      path: ROUTE_PATHS.home(),
      copy: 'Customers',
    },
    {
      path: ROUTE_PATHS.organizations(),
      copy: 'Organizations',
    },
    {
      path: ROUTE_PATHS.licenses(),
      copy: 'Licenses',
    },
  ];

  return <Navigation items={navItems} styles={styles} />;
};

export default AccountTypeSwitcher;

import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { IS_BETA_PLAN_USED_QUERY, PLANS_BY_OWNER_ID_QUERY } from '../queries';

const REMOVE_BETA_PLAN_MUTATION = gql`
  mutation removeBetaPlan($organizationPlanId: ID!) {
    removeBetaPlan(organizationPlanId: $organizationPlanId)
  }
`;

export const useRemoveBetaPlanMutation = () => {
  const [mutate, { loading }] = useMutation(REMOVE_BETA_PLAN_MUTATION);

  const removeBetaPlan = async (planId: string, ownerId: string, organizationId: string) => {
    return await mutate({
      variables: {
        organizationPlanId: planId,
      },
      refetchQueries: [
        {
          query: PLANS_BY_OWNER_ID_QUERY,
          variables: {
            ownerId,
          },
        },
        {
          query: IS_BETA_PLAN_USED_QUERY,
          variables: {
            organizationId,
          },
        },
      ],
    });
  };

  return {
    removeBetaPlan,
    loading,
  };
};

import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { OrganizationPlanInterface } from '../../interfaces';

export const ORGANIZATION_PLAN_SEAT_STATS_QUERY = gql`
  query organizationPlanSeatStats($customerPlanId: ID!) {
    organizationPlanSeatStats(customerPlanId: $customerPlanId) {
      customerProductPlanId
      occupiedSeatsCount
    }
  }
`;

export const useOrganizationPlanSeatStatsQuery = (customerPlan: OrganizationPlanInterface) => {
  const { data, loading } = useQuery(ORGANIZATION_PLAN_SEAT_STATS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      customerPlanId: customerPlan.id,
    },
  });

  const occupiedSeatCount = data?.organizationPlanSeatStats?.occupiedSeatsCount;
  const seatsCount = customerPlan.seatCount;
  const vacantSeatsCount = occupiedSeatCount >= 0 && seatsCount && seatsCount >= 0 && seatsCount - occupiedSeatCount;

  return {
    occupiedSeatCount,
    seatsCount,
    vacantSeatsCount,
    loading,
  };
};

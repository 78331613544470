import React from 'react';
import { Button, DialogActions, DialogContent, DialogIcon } from '@macpaw/macpaw-ui';
import { useModals } from '~/ui';
import { Modals } from '../../constants';
import { useResendActivationEmailMutation } from '../../graphql/mutations';
import ResendIcon from '../../images/icons/resend.svg?react';
import { CustomerPlanInterface } from '../../interfaces';

interface CustomerPlanResendActivation {
  customerPlan: CustomerPlanInterface;
  onComplete?: () => void;
}

const PlanResendActivationModal: React.FC<CustomerPlanResendActivation> = ({ customerPlan, onComplete }) => {
  const ownerEmail = customerPlan.owner?.email;

  const { closeModal } = useModals();
  const { resendEmail, loading } = useResendActivationEmailMutation();

  const handleResendEmail = async () => {
    await resendEmail(customerPlan.id);
    closeModal(Modals.PlanResendActivationModal);
    onComplete?.();
  };

  return (
    <>
      <DialogContent>
        <DialogIcon>
          <ResendIcon width={160} height={160} />
        </DialogIcon>
        <h3>Resend Activation Email</h3>
        <p>
          Are you sure that you want to resend the activation email for this plan to <b>{ownerEmail}</b>?
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleResendEmail} loading={loading}>
          Send Email
        </Button>
      </DialogActions>
    </>
  );
};

export default PlanResendActivationModal;

import React from 'react';
import { ConfirmationType } from '~/types';
import { ConfirmationModal } from '~/ui';

interface SetCustomPriceRejectProps {
  onSubmit: () => void;
}

const SetCustomPriceReject: React.FC<SetCustomPriceRejectProps> = ({ onSubmit }) => (
  <ConfirmationModal
    type={ConfirmationType.WarningInfo}
    title="Couldn’t set the custom price"
    description="Looks like somebody else has just set a custom price for this plan. Unfortunately, we can’t change the price if it has already been set."
    actionTitle="Got It"
    onSubmit={onSubmit}
  />
);

export default SetCustomPriceReject;

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@macpaw/macpaw-ui';
import { useBreadcrumbs, useBreadcrumbsContext } from './BreadcrumbsContext';

interface BreadcrumbsMenuProps {
  className?: string;
}

const BreadcrumbsMenu: React.FC<BreadcrumbsMenuProps> = ({ className }) => {
  const { renderNode, isPortalView } = useBreadcrumbsContext();
  const breadcrumbs = useBreadcrumbs();

  const content = () => (
    <nav className={className}>
      <Breadcrumbs>
        {breadcrumbs.map(({ path, disabled, crumbNode }, index) => {
          const isLastItem = breadcrumbs.length === index + 1;
          const isStaticCrumbNode = disabled || isLastItem;

          return (
            <li className="breadcrumbs-item" key={path}>
              {isStaticCrumbNode ? (
                crumbNode()
              ) : (
                <Link to={path} className="breadcrumbs-link" state={{ noRedirect: true }}>
                  {crumbNode()}
                </Link>
              )}
            </li>
          );
        })}
      </Breadcrumbs>
    </nav>
  );

  if (isPortalView) {
    if (!renderNode) return null;

    return ReactDOM.createPortal(content(), renderNode);
  }

  return content();
};

export default BreadcrumbsMenu;

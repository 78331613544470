import React from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from '@macpaw/macpaw-ui';
import { BreadcrumbsMenu } from '~/ui';
import { useQueryParams } from '~/utils';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';
import { LIMIT_PER_PAGE } from '../../constants';
import { SUPPORT_PROFILE_QUERY, SUPPORT_MEMBERS_QUERY } from '../../graphql/queries';
import Error500 from '../Error/Error500';
import styles from './Members.module.sass';
import MembersInvite from './MembersInvite';
import MembersList from './MembersList';

const Members: React.FC = () => {
  const queryParams = useQueryParams();
  const page = queryParams.get('page') || '1';
  const currentPage = parseInt(page, 10);
  const offset = currentPage * LIMIT_PER_PAGE - LIMIT_PER_PAGE;
  const { data: myProfile } = useQuery(SUPPORT_PROFILE_QUERY, { fetchPolicy: 'cache-only' });
  const { data, loading, error, refetch } = useQuery(SUPPORT_MEMBERS_QUERY, {
    variables: { offset, limit: LIMIT_PER_PAGE },
  });

  if (loading) return <Loader />;
  if (error) return <Error500 />;

  const { pagination, data: members } = data.members;

  return (
    <>
      <BreadcrumbsMenu />
      <WithErrorBoundary>
        <div className={styles.topper}>
          <h3 className="h3">Team Members</h3>
          <MembersInvite refetch={refetch} />
        </div>
        <MembersList members={members} pagination={pagination} myProfile={myProfile.profile} refetch={refetch} />
      </WithErrorBoundary>
    </>
  );
};

export default Members;

import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { OrganizationPlanInterface } from '../../interfaces';
import { CUSTOMER_PLAN_QUERY } from '../queries';

const ADD_DEVICE_SLOT_TO_PLAN_MUTATION = gql`
  mutation addDeviceSlotsToPlan($organizationPlanId: ID!, $addedSeatCount: Int!) {
    addDeviceSlotsToPlan(organizationPlanId: $organizationPlanId, addedSeatCount: $addedSeatCount)
  }
`;

export const useAddDeviceSlotsToPlanMutation = () => {
  const [mutate, { loading }] = useMutation(ADD_DEVICE_SLOT_TO_PLAN_MUTATION);

  const addDeviceSlotsToPlan = async (customerPlan: OrganizationPlanInterface, addedSeatCount: number) => {
    return await mutate({
      variables: {
        organizationPlanId: customerPlan.id,
        addedSeatCount,
      },
      refetchQueries: [
        {
          query: CUSTOMER_PLAN_QUERY,
          variables: {
            customerPlanId: customerPlan.id,
          },
        },
      ],
    });
  };

  return {
    addDeviceSlotsToPlan,
    loading,
  };
};

import {
  BaseCustomerPlan,
  BasePlanOwnerType,
  BasePlanView,
  CustomerPlanStatus,
  CustomerPlanType,
  CustomerPlanTypeForRelatedPlans,
  ProductPlanType,
  TransactionPaymentStatus,
} from '~/types';
import { daysToNow, isOneTime, distanceToNowSync, isChinaPlan } from '~/utils';
import { MAX_TRANSACTIONS_DAYS_TO_DISPLAY } from '../constants';
import {
  PlanInterface,
  OrganizationPlanInterface,
  PlanViewInterface,
  TransactionInterface,
  CustomerPlanInterface,
} from '../interfaces';

export const isTrial = (plan: BaseCustomerPlan) => plan.status === CustomerPlanStatus.Trialing;

export const findLastPurchaseTransaction = (transactions: TransactionInterface[]): TransactionInterface => {
  return transactions.find((t) => {
    return t.description === 'transactionDescriptionPayment' && t.paymentStatus === TransactionPaymentStatus.Success;
  })!;
};

export const findAllPurchaseTransactions = (transactions: TransactionInterface[]): TransactionInterface[] => {
  return transactions.filter((t) => {
    return (
      t.description === 'transactionDescriptionPayment' &&
      t.amount &&
      daysToNow(t.date) < MAX_TRANSACTIONS_DAYS_TO_DISPLAY &&
      t.paymentStatus === TransactionPaymentStatus.Success
    );
  })!;
};

export const isOwner = (plan: PlanInterface, customerId: string) => {
  return plan.ownerId === customerId;
};

export const canBeBlocked = (customerPlan: PlanInterface): boolean => {
  if (isOneTime(customerPlan.planView)) return true;
  if ([CustomerPlanStatus.CancelApproved, CustomerPlanStatus.DisputeOpened].includes(customerPlan.status)) return true;

  return [CustomerPlanType.Support].includes(customerPlan.type);
};

export const getActivationPeriod = (planView: BasePlanView) => {
  const periodText = isChinaPlan(planView.productPlanId) ? 'One Year Non-Recurring' : 'One-Time Purchase';

  return planView.productPlanType === ProductPlanType.OneTime
    ? periodText
    : distanceToNowSync(planView.productPlanActivationPeriod!);
};

export const getActivationPeriodLabel = (planView: PlanViewInterface) => {
  const periodText = isChinaPlan(planView.productPlanId) ? 'One Year Non-Recurring' : 'One-Time Purchase';

  return planView.productPlanType === ProductPlanType.OneTime
    ? periodText
    : distanceToNowSync(planView.productPlanActivationPeriod!);
};

export const isOrganizationPlan = (plan?: PlanInterface) => {
  return !!plan && plan?.ownerType === BasePlanOwnerType.Organization;
};

export const getIsSagaInProgress = (plan?: PlanInterface) => !!(plan as OrganizationPlanInterface)?.sagaStateId;

export const isCompanionPlan = (customerPlan: CustomerPlanInterface | OrganizationPlanInterface) =>
  (customerPlan as CustomerPlanInterface)?.relatedPlans?.planType === CustomerPlanTypeForRelatedPlans.Companion;

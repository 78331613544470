import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { PlanViewInterface } from '../../interfaces';

const useMemoizedUniqProductsList = (planViews: PlanViewInterface[]) => {
  return useMemo(() => {
    if (!planViews || !planViews.length) return [];

    const products = planViews.map(({ productName, productTag }) => ({ productName, productTag }));

    return uniqBy(products, ({ productTag }) => productTag);
  }, [planViews]);
};

export default useMemoizedUniqProductsList;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogIcon, DialogContent, DialogActions, Button } from '@macpaw/macpaw-ui';
import { Form, FormCheckBox, StepItemProps } from '~/ui';
import { ROUTE_PATHS } from '../../../constants';
import { useRemoveCustomerMutation } from '../../../graphql/mutations';
import DeleteUsedIcon from '../../../images/icons/deactivate-member.svg?react';
import { RemoveCustomerValidationSchema } from '../../../schemas';
import { userIdVar } from '../../../store';

interface CustomerRemoveConfirmProps extends Partial<StepItemProps> {
  email: string;
  customerId: string;
  onCancel: () => void;
}

const CustomerRemoveConfirm: React.FC<CustomerRemoveConfirmProps> = ({ email, customerId, onCancel, ...props }) => {
  const { setNextStep } = props as StepItemProps;
  const navigate = useNavigate();
  const { removeCustomer, loading } = useRemoveCustomerMutation();
  const currentCustomerId = userIdVar() || '';

  const handleSubmit = async () => {
    await removeCustomer({ customerId, currentCustomerId });
    setNextStep();
    navigate(ROUTE_PATHS.home());
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={RemoveCustomerValidationSchema}
      initialValues={{
        deleteChecked: false,
      }}>
      <DialogContent>
        <DialogIcon>
          <DeleteUsedIcon width={160} height={160} />
        </DialogIcon>
        <h3>Delete this customer?</h3>
        <p>
          Deleting {email} will remove their product license, erase all the data associated with this account, and
          remove them from our mailing service. If this person is in an Organization, then it might be affected as well.
        </p>
      </DialogContent>
      <DialogActions>
        <FormCheckBox name="deleteChecked" style={{ marginRight: 'auto' }}>
          I understand all of the risks
        </FormCheckBox>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" color="warning" loading={loading}>
          Delete Customer
        </Button>
      </DialogActions>
    </Form>
  );
};

export default CustomerRemoveConfirm;

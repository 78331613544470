import React from 'react';
import { Button, Dropdown, Grid, GridCell, GridRow, Label, Tag } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { Maybe } from '~/types';
import { LoadableText } from '~/ui';
import { formatDate } from '~/utils';
import { formatToStrong } from '../../helpers/formatters';
import CustomerIcon from '../../images/icons/user.svg?react';
import { OrganizationInterface, OrganizationOwner } from '../../interfaces';
import OrganizationBetaStatusSwitcher from '../OrganizationBetaStatusSwitcher/OrganizationBetaStatusSwitcher';
import styles from './OrganizationProfile.module.sass';

interface OrganizationProfileProps {
  organization: Maybe<OrganizationInterface>;
  owner: Maybe<OrganizationOwner>;
}

const OrganizationProfile: React.FC<OrganizationProfileProps> = ({ organization, owner }) => {
  const renderAction = () => (
    <Dropdown
      position="right"
      trigger={
        <Button icon color="transparent">
          <MoreIcon />
        </Button>
      }>
      <OrganizationBetaStatusSwitcher
        organizationId={organization?.id}
        isCmmBusinessDashboardBeta={organization?.isCmmBusinessDashboardBeta}
      />
    </Dropdown>
  );

  return (
    <Grid icon={<CustomerIcon />} action={renderAction()}>
      <GridRow>
        <GridCell type="primary">
          <Label>Name</Label>
          <LoadableText formatter={formatToStrong} isConditional>
            {organization?.name}
          </LoadableText>
        </GridCell>
        <GridCell type="primary">
          <Label>Owner</Label>
          <LoadableText formatter={formatToStrong} isConditional>
            {owner?.name}
          </LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Owner Email</Label>
          <LoadableText>{owner?.email}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Contact Email</Label>
          <LoadableText isConditional>{organization?.ownerEmail}</LoadableText>
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell type="primary">
          <Label>Finished Setup</Label>
          <LoadableText>{owner?.isCompleted ? 'Yes' : 'No'}</LoadableText>
        </GridCell>
        <GridCell type="primary">
          <Label>Email Confirmed</Label>
          <LoadableText>{owner?.emailConfirmed ? 'Yes' : 'No'}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Creation By</Label>
          <LoadableText formatter={formatDate} isConditional>
            {organization?.createdAt ?? 0}
          </LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Beta Allowed</Label>
          <LoadableText>
            <Tag
              borderRadius={24}
              color={organization?.isCmmBusinessDashboardBeta ? 'primary' : 'custom'}
              className={styles.betaTag}>
              {organization?.isCmmBusinessDashboardBeta ? 'Yes' : 'No'}
            </Tag>
          </LoadableText>
        </GridCell>
      </GridRow>
    </Grid>
  );
};

export default OrganizationProfile;

import React, { useContext, createContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadcrumbsConfigItem, BreadcrumbsItem, Maybe } from '~/types';
import { matchRoutePaths } from '~/utils';

interface BreadcrumbsContextValue {
  config: BreadcrumbsConfigItem[];
  isPortalView: boolean;
  renderNode?: Maybe<HTMLElement>;
}

const initialValue: BreadcrumbsContextValue = {
  config: [],
  isPortalView: false,
};

const BreadcrumbsContext = createContext(initialValue);

interface BreadcrumbsProviderBaseProps {
  config: BreadcrumbsConfigItem[];
  view?: 'default' | 'portal';
}

interface BreadcrumbsProviderPortalViewProps extends BreadcrumbsProviderBaseProps {
  view: 'portal';
  renderNode: Maybe<HTMLElement>;
}

interface BreadcrumbsProviderDefaultViewProps extends BreadcrumbsProviderBaseProps {
  view: 'default';
}

type BreadcrumbsProviderProps = BreadcrumbsProviderDefaultViewProps | BreadcrumbsProviderPortalViewProps;

export const BreadcrumbsProvider: React.FC<PropsWithChildren<BreadcrumbsProviderProps>> = ({
  config,
  view,
  children,
  ...props
}) => {
  const isPortalView = view === 'portal';
  const renderNode = view === 'portal' ? (props as BreadcrumbsProviderPortalViewProps).renderNode : null;
  const value = {
    config,
    renderNode,
    isPortalView,
  };

  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};

export const useBreadcrumbsContext = () => {
  const value = useContext(BreadcrumbsContext);

  return value;
};

export const useBreadcrumbs = () => {
  const { pathname } = useLocation();
  const { config } = useBreadcrumbsContext();

  const matchRoutesWithBreadcrumbs = (currentConfig: BreadcrumbsConfigItem[]): BreadcrumbsItem[] => {
    const breadcrumbs = matchRoutePaths(currentConfig, pathname);

    return breadcrumbs.map(
      ({ pathName, item: { crumbNode, disabled } }): BreadcrumbsItem => ({
        path: pathName,
        crumbNode,
        disabled,
      }),
    );
  };

  return matchRoutesWithBreadcrumbs(config);
};

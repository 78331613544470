import { gql, useMutation } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';

export const BLOCK_CUSTOMER_PLAN_MUTATION = gql`
  mutation BlockCustomerPlan($customerPlanId: ID!) {
    blockCustomerPlan(customerPlanId: $customerPlanId)
  }
`;

interface UseBlockCustomerPlanMutationInput {
  onComplete?: () => void;
}

export const useBlockCustomerPlanMutation = ({ onComplete }: UseBlockCustomerPlanMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<Record<'customerPlanId', string>>(BLOCK_CUSTOMER_PLAN_MUTATION);

  const blockPlan = async (customerPlanId: string) => {
    await mutate({
      variables: {
        customerPlanId,
      },
    });
    notify('Plan has been successfully blocked', NotificationType.Success);
    onComplete?.();
  };

  return {
    blockPlan,
    loading,
  };
};

const DEFAULT_MAX_EMAIL_LENGTH = 32;
const THREE_DOTS_LENGTH = 3;
const TRIM_MIDDLE_INDEX = 13;

const emailTrim = (email: string, limit = DEFAULT_MAX_EMAIL_LENGTH): string => {
  if (email.length < limit) {
    return email;
  }

  if (limit < DEFAULT_MAX_EMAIL_LENGTH) {
    const symbols = limit / THREE_DOTS_LENGTH;

    return `${email.slice(0, symbols)}...${email.slice(-symbols)}`;
  }

  return `${email.slice(0, TRIM_MIDDLE_INDEX)}...${email.slice(-TRIM_MIDDLE_INDEX)}`;
};

export default emailTrim;

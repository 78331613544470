import { BasePagination } from '~/types';

const updatePaginationGQLEntity = (pagination: BasePagination, itemsDiffAmount: number) => {
  const { currentPage, elementsCount, perPage } = pagination;
  const newElementsCount = elementsCount + itemsDiffAmount;
  const newPagesCount = Math.ceil(newElementsCount / perPage);

  return {
    ...pagination,
    elementsCount: newElementsCount,
    pagesCount: newPagesCount,
    currentPage,
    perPage,
  };
};

export default updatePaginationGQLEntity;

import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Maybe } from '~/types';
import { Form, Stepper, Step, StepperContextValueType, useModals } from '~/ui';
import { CreateCustomerStep, CreateCustomerFields, Modals, ROUTE_PATHS } from '../../constants';
import { useCreateCustomerMutation } from '../../graphql/mutations';
import handleFormServerError from '../../helpers/handleFormServerError';
import { CreateCustomerFormValidationSchema } from '../../schemas';
import { CreateCustomerFormBody, CreateCustomerSuccess } from './steps';

const CreateCustomerModal: React.FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);
  const stepperRef = useRef<StepperContextValueType>(null);
  const customerIdRef = useRef<Maybe<string>>('');
  const { closeModal } = useModals();

  const { createCustomer, loading } = useCreateCustomerMutation();

  const handleCloseModal = () => {
    closeModal(Modals.CreateCustomerModal);
  };

  const handleSuccessSubmit = () => {
    const { current: customerId } = customerIdRef;

    handleCloseModal();

    if (customerId) navigate(ROUTE_PATHS.customerPage(customerId));
  };

  const handleSubmit = async (values: Record<string, string>) => {
    const { current: stepper } = stepperRef;

    if (!stepper) return;

    try {
      customerIdRef.current = await createCustomer(values.email);

      stepper.setNextStep();
    } catch (error) {
      handleFormServerError(error as Error, formRef.current?.setError);
    }
  };

  return (
    <Form
      initialValues={{
        [CreateCustomerFields.Email]: '',
      }}
      innerRef={formRef}
      validationSchema={CreateCustomerFormValidationSchema}
      onSubmit={handleSubmit}>
      <Stepper initialStep={CreateCustomerStep.Form} innerRef={stepperRef} updateAnyway>
        <Step stepKey={CreateCustomerStep.Form}>
          <CreateCustomerFormBody isLoading={loading} onCancel={handleCloseModal} />
        </Step>
        <Step stepKey={CreateCustomerStep.Success}>
          <CreateCustomerSuccess onSubmit={handleSuccessSubmit} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default CreateCustomerModal;

import { gql, useQuery } from '@apollo/client';
import { GQLResponse } from '../../interfaces';

export const DISTRIBUTE_REASONS_QUERY = gql`
  query DistributeReasons {
    distributeReasons
  }
`;

interface UseDistributeReasonsQuery {
  reasons: string[];
  loading: boolean;
}

export const useDistributeReasonsQuery = (): UseDistributeReasonsQuery => {
  const { data, loading } = useQuery<GQLResponse<'distributeReasons', string[]>>(DISTRIBUTE_REASONS_QUERY);

  return {
    reasons: data?.distributeReasons ?? [],
    loading,
  };
};

import { gql, useQuery } from '@apollo/client';
import { Maybe } from '~/types';
import { useRefresh } from '~/utils';
import { GQLResponse, PlanInterface } from '../../interfaces';
import { CUSTOMER_PLAN_FRAGMENT, ORGANIZATION_PLAN_FRAGMENT } from '../fragments';

export const CUSTOMER_PLAN_QUERY = gql`
  query CustomerPlan($customerPlanId: ID!) {
    customerPlan(customerPlanId: $customerPlanId) {
      ...CustomerPlanFragment
      ...OrganizationPlanFragment
    }
  }
  ${CUSTOMER_PLAN_FRAGMENT}
  ${ORGANIZATION_PLAN_FRAGMENT}
`;

interface UseCustomerPlanQueryInput {
  customerPlanId: Maybe<string>;
}

export const useCustomerPlanQuery = ({ customerPlanId }: UseCustomerPlanQueryInput) => {
  const { data, loading, refetch } = useQuery<
    GQLResponse<'customerPlan', PlanInterface>,
    Record<'customerPlanId', Maybe<string>>
  >(CUSTOMER_PLAN_QUERY, {
    variables: { customerPlanId },
    skip: !customerPlanId,
  });

  const [refreshing, refreshPlan] = useRefresh(refetch);

  return {
    plan: data?.customerPlan,
    loading,
    refreshPlan,
    refreshing,
  };
};

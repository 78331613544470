import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, DialogActions, DialogContent, FormRow, Input } from '@macpaw/macpaw-ui';
import { FormInput, StepItemProps } from '~/ui';
import { capitalize } from '~/utils';
import { MIN_ORGANIZATION_PLAN_DEVICE_COUNT, PlanReduceSizeInputTypes, ReduceSizeSteps } from '../../../constants';
import { useOrganizationPlanSeatStatsQuery } from '../../../graphql/queries';
import { OrganizationPlanInterface } from '../../../interfaces';
import TextLoader from '../../TextLoader/TextLoader';
import styles from './ReducePlanSize.module.sass';

interface ReducePlanSizeReduceStepProps {
  customerPlan: OrganizationPlanInterface;
  onCancel: () => void;
}

const ReducePlanSizeReduceStep: React.FC<ReducePlanSizeReduceStepProps> = ({ customerPlan, onCancel, ...props }) => {
  const { setStep } = props as StepItemProps;
  const { trigger, setError, getValues, setValue } = useFormContext();

  const { loading, seatsCount, vacantSeatsCount } = useOrganizationPlanSeatStatsQuery(
    customerPlan as OrganizationPlanInterface,
  );

  useEffect(() => {
    setValue(PlanReduceSizeInputTypes.VacantSeatsCount, vacantSeatsCount);
  }, [setValue, vacantSeatsCount]);

  const handleSubmit = async () => {
    const isValid = await trigger(PlanReduceSizeInputTypes.ReduceCount);

    if (isValid) {
      const { [PlanReduceSizeInputTypes.ReduceCount]: count } = getValues();

      if (seatsCount - count < MIN_ORGANIZATION_PLAN_DEVICE_COUNT) {
        setError(PlanReduceSizeInputTypes.ReduceCount, {
          message: `Plans for ${customerPlan.planView.productName} must have at least ${MIN_ORGANIZATION_PLAN_DEVICE_COUNT} devices.`,
        });

        return;
      }

      setStep(ReduceSizeSteps.Warning);
    }
  };

  const formatReduceCount = (value: string): string => {
    const count = Number(String(value).replace(/\D/g, ''));

    return `${value === '' ? '' : count}`;
  };

  const handleInputKeyUp = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') handleSubmit();
  };

  return (
    <>
      <DialogContent className={styles.dialogContent}>
        <h4 className="mb-24">Reducing Plan Size</h4>
        <p>
          <b>Only vacant device slots</b> can be removed from a plan. If the plan has fewer vacant slots than you need
          to subtract, then remove enough slots from members and come back.
        </p>
        <FormRow>
          <Input
            label="Plan"
            type="text"
            value={`${customerPlan.planView.productName} – ${capitalize(customerPlan.planView.productPlanType)}`}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            icon={loading && <TextLoader />}
            label="Vacant Device Slots"
            value={loading ? '' : `${vacantSeatsCount} of ${seatsCount}`}
            disabled
          />
        </FormRow>
        <FormRow>
          <FormInput
            label="Reduce plan size by"
            placeholder={loading ? '' : '0 slots'}
            disabled={vacantSeatsCount === 0}
            icon={loading && <TextLoader />}
            formatValue={formatReduceCount}
            onKeyUp={handleInputKeyUp}
            name={PlanReduceSizeInputTypes.ReduceCount}
          />
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button color="primary" outline onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSubmit}>
          Remove Device Slots
        </Button>
      </DialogActions>
    </>
  );
};

export default ReducePlanSizeReduceStep;

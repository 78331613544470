import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReactCodeInput from 'react-verification-code-input';
import { useFormInputChangeHandler, useFormError, useFormField } from './Form.hooks';
import { BaseFormElementProps } from './Form.types';
import { useFormContext } from './FormContext';
import styles from './FormPinInput.module.sass';

interface FormPinInput extends BaseFormElementProps {
  length?: number;
  autoFocus?: boolean;
  onComplete?: (code: string) => void;
}

const FormPinInput: React.FC<FormPinInput> = ({
  name,
  validateOnChange,
  formatErrorMessage,
  onComplete = () => {},
  ...props
}) => {
  const { trans } = useFormContext();
  const {
    field: { ref },
    watch,
  } = useFormField<string>(name);
  const currentValue = watch(name);

  const { isError, message: errorMessage } = useFormError(name, {
    formatValue: formatErrorMessage ?? trans,
  });

  const handleChange = useFormInputChangeHandler(name, { validateOnChange });

  return (
    <>
      <div className="pin-input input">
        <ReactCodeInput
          onChange={handleChange}
          ref={ref}
          fieldWidth={36}
          fieldHeight={48}
          className={styles.pinInput}
          onComplete={(value) => (value !== currentValue ? onComplete?.(value) : null)}
          {...props}
        />
      </div>
      {isError && <div className="p4 pin-error">{errorMessage}</div>}
    </>
  );
};

export default FormPinInput;

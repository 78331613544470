import React from 'react';
import { SuccessModal } from '~/ui';

interface CreateOrganizationSuccess {
  onSubmit: () => void;
}

const CreateOrganizationSuccess: React.FC<CreateOrganizationSuccess> = ({ onSubmit }) => (
  <SuccessModal
    title="Organization created!"
    description="The customer can now invite members to their Organization and purchase products for a custom price."
    onSubmit={onSubmit}
  />
);

export default CreateOrganizationSuccess;

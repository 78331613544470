import { gql } from '@apollo/client';
import { BaseOrderSchema } from '~/types';

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    ${BaseOrderSchema}
    paddleProductId
    paddleSubscriptionId
  }
`;

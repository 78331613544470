import React from 'react';
import { useParams, Link } from 'react-router-dom';
import cx from 'clsx';
import { Loader } from '@macpaw/macpaw-ui';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import OrganizationInfo from '../../components/OrganizationInfo/OrganizationInfo';
import PlanDevices from '../../components/PlanDevices/PlanDevices';
import PlanNotifications from '../../components/PlanNotifications/PlanNotifications';
import PlanPayment from '../../components/PlanPayment/PlanPayment';
import PlanRelatedPlansBlock from '../../components/PlanRelatedPlansBlock/PlanRelatedPlansBlock';
import PlanTransactions from '../../components/PlanTransactions/PlanTransactions';
import SearchableContainer from '../../components/Search/SearchableContainer';
import Section from '../../components/Section/Section';
import WidgetControls from '../../components/Widget/WidgetControlsComponent';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';
import { OrganizationInfoFields, ROUTE_PATHS } from '../../constants';
import { useCustomerPlanQuery, useOrganizationInfoQuery } from '../../graphql/queries';
import getIsRestrictedStatus from '../../helpers/getIsRestrictedStatus';
import { isOrganizationPlan } from '../../helpers/plan';
import { CustomerPlanInterface } from '../../interfaces';
import Error404 from '../Error/Error404';
import styles from './CustomerPlan.module.sass';
import PlanOverview from './CustomerPlanOverview';

const CustomerPlan: React.FC = () => {
  const { customerPlanId, customerId } = useParams<Record<'customerPlanId' | 'customerId', string>>();

  const { plan, loading, refreshPlan, refreshing } = useCustomerPlanQuery({ customerPlanId });

  const showOrganizationInfo = isOrganizationPlan(plan);

  const { organization, owner } = useOrganizationInfoQuery({
    organizationId: plan?.ownerId ?? '',
    skip: !showOrganizationInfo,
  });

  if (loading) {
    return (
      <SearchableContainer>
        <Loader />
      </SearchableContainer>
    );
  }

  if (!plan) {
    return <Error404 />;
  }

  const organizationBlockClassNames = cx({
    [styles.organizationBlockDisabled]: !organization,
  });

  const isRestrictedStatus = getIsRestrictedStatus(plan?.status);

  return (
    <SearchableContainer>
      <WithErrorBoundary>
        <WidgetControls />
        <PlanNotifications plan={plan as CustomerPlanInterface} isRefreshing={refreshing} refresh={refreshPlan} />
        <PlanOverview
          customerPlan={plan as CustomerPlanInterface}
          order={plan?.order}
          refresh={refreshPlan}
          isOrganizationPlan={showOrganizationInfo}
        />
        {showOrganizationInfo && (
          <Section title="Organization Details">
            <Link to={ROUTE_PATHS.organizationPage(organization?.id)} className={organizationBlockClassNames}>
              <OrganizationInfo
                action={<DropdownIcon className={styles.iconArrow} />}
                organization={organization}
                owner={owner}
                fields={[OrganizationInfoFields.OwnerName, OrganizationInfoFields.OwnerEmail]}
              />
            </Link>
          </Section>
        )}
        {!isRestrictedStatus && (
          <>
            <PlanRelatedPlansBlock plan={plan as CustomerPlanInterface} />
            <PlanDevices customerId={customerId} plan={plan} />
            <PlanPayment plan={plan as CustomerPlanInterface} />
          </>
        )}
        <PlanTransactions plan={plan as CustomerPlanInterface} />
      </WithErrorBoundary>
    </SearchableContainer>
  );
};

export default CustomerPlan;

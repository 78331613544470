import * as Yup from 'yup';
import { getValidateEmailShape, MS_IN_S } from '~/utils';
import {
  ERROR_CODE_TO_COPY,
  ERROR_COPY,
  LENGTH_PINCODE,
  AuthInputType,
  SetCustomPriceFields,
  OrgInviteToPlanFields,
  CustomerRefundInputTypes,
  PlanReduceSizeInputTypes,
  UserInvitationInputType,
  AddDeviceSlotsToPlanFields,
  GiveOrganizationBetaPlanFields,
  OrgInviteToPlanMode,
  MINIMUM_ORGANIZATION_PLAN_PRICE,
} from './constants';
import {
  PASSWORD_LENGTH_MIN,
  PASSWORD_LENGTH_MAX,
  INPUT_MAX_LENGTH,
  NAME_LENGTH_MAX,
  ORGANIZATION_PLAN_MAX_SEATS_COUNT,
  ORGANIZATION_PLAN_MIN_SEATS_COUNT,
  EMAIL_LENGTH_MAX,
} from './constants/validation';

const TEAM_MEMBERS_ERRORS = {
  email: "* Please enter member's email",
  name: '* Please enter the name for this member',
  roles: 'Please select roles for this member',
};

const USER_ERRORS = {
  email: "* Please enter user's email",
  name: '* Please enter the name for this user',
};

export const TeamMemberForm = Yup.object().shape({
  email: getValidateEmailShape(TEAM_MEMBERS_ERRORS.email, TEAM_MEMBERS_ERRORS.email).max(
    EMAIL_LENGTH_MAX,
    'Your email is too long. Please make it a couple of characters shorter',
  ),
  name: Yup.string()
    .trim()
    .max(NAME_LENGTH_MAX, ERROR_CODE_TO_COPY['errors.nameTooLong'])
    .required(TEAM_MEMBERS_ERRORS.name),
  roles: Yup.array()
    .min(1, TEAM_MEMBERS_ERRORS.roles)
    .of(
      Yup.object()
        .shape({
          value: Yup.string().required(),
        })
        .required(),
    ),
});

export const EditTeamMemberForm = TeamMemberForm;
export const InviteTeamMemberForm = TeamMemberForm;

export const BasePasswordValidationSchema = Yup.string().strict().required(ERROR_COPY.passwordEmpty);

export const NewPasswordValidationSchema = BasePasswordValidationSchema.trim(ERROR_COPY.passwordStartsWithSpace)
  .min(PASSWORD_LENGTH_MIN, ERROR_COPY.passwordTooShort)
  .max(PASSWORD_LENGTH_MAX, ERROR_COPY.passwordTooLong);

export const UserSettingsFormValidationSchema = Yup.object().shape({
  password: BasePasswordValidationSchema,
  newPassword: NewPasswordValidationSchema.notOneOf([Yup.ref('password')], ERROR_COPY.oldAndNewPasswordsAreIdentical),
  confirmPassword: BasePasswordValidationSchema.oneOf([Yup.ref('newPassword')], ERROR_COPY.passwordsDontMatch),
});

export const AuthFormSchema = Yup.object().shape({
  [AuthInputType.Email]: getValidateEmailShape(
    ERROR_CODE_TO_COPY['errors.invalidEmail'],
    ERROR_CODE_TO_COPY['errors.email.empty'],
  ),
  [AuthInputType.Password]: Yup.string().strict().required(ERROR_CODE_TO_COPY['errors.password.empty']),
  [AuthInputType.Pin]: Yup.string().length(LENGTH_PINCODE, ''),
});

export const RemoveCustomerValidationSchema = Yup.object().shape({
  deleteChecked: Yup.bool().oneOf([true]).required(),
});

export const EditCustomerFormValidationSchema = Yup.object().shape({
  email: getValidateEmailShape(USER_ERRORS.email, USER_ERRORS.email),
  name: Yup.string()
    .trim()
    .max(NAME_LENGTH_MAX, "The name is too long. Our limit is 128 characters, we hope that's enough!"),
});

export const GivePersonalPlanValidationSchema = Yup.object().shape({
  reason: Yup.string().trim().required(ERROR_COPY.emptyField),
  product: Yup.string().trim().required(ERROR_COPY.emptyField),
  productPlanId: Yup.string().trim().required(ERROR_COPY.emptyField),
});

export const CreateCustomerFormValidationSchema = Yup.object().shape({
  email: getValidateEmailShape('Please enter a valid email address', 'Please enter an email address'),
});

export const OrganizationDetailsSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  country: Yup.string().required('Country is required'),
  address: Yup.string().trim().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().when('country', {
    is: 'US',
    then: Yup.string().trim().required('State is required'),
  }),
  postCode: Yup.string().trim().required('Postal code is required'),
  vatNumber: Yup.string().nullable(),
});

export const OrganizationCustomPriceFormSchema = Yup.object().shape({
  [SetCustomPriceFields.ProductTag]: Yup.string().required('Product is required'),
  [SetCustomPriceFields.ProductPlanSkuId]: Yup.string().required('Product Plan is required'),
  [SetCustomPriceFields.Price]: Yup.string()
    .required('Price is required')
    .test({
      name: 'is-not-default',
      message: 'Price must be different from default',
      test: (value, { parent }) => {
        if (parent.isDefaultPrice) return true;

        if (String(parent.defaultPrice) === String(value)) return false;

        return true;
      },
    })
    .test({
      name: 'is-more-than-zero',
      message: 'Custom price must be greater than zero.',
      test: (value, { parent }) => {
        if (parent.isDefaultPrice) return true;

        if (Number(value) < 0) return false;

        return true;
      },
    }),
});

export const OrganizationInviteToPlanSchema = Yup.object().shape({
  [OrgInviteToPlanFields.ProductTag]: Yup.string().required('Select for which product you want to create a new plan'),
  [OrgInviteToPlanFields.InviteMode]: Yup.string(),
  [OrgInviteToPlanFields.ProductPlanSkuId]: Yup.string().when(OrgInviteToPlanFields.ProductTag, {
    is: (value: string) => Boolean(value),
    then: Yup.string().required('Select a new plan for the customer'),
  }),
  [OrgInviteToPlanFields.Quantity]: Yup.string().when(OrgInviteToPlanFields.ProductTag, {
    is: (value: string) => Boolean(value),
    then: Yup.string()
      .required('Set the device quantity for the new plan')
      .test({
        name: 'is-in-range',
        message: 'Devices quantity must be in range 6-10000',
        test: (value) => {
          const numberValue = Number(value);

          return !(numberValue > ORGANIZATION_PLAN_MAX_SEATS_COUNT || numberValue < ORGANIZATION_PLAN_MIN_SEATS_COUNT);
        },
      }),
  }),
  [OrgInviteToPlanFields.BillImmediatelyPrice]: Yup.string().test({
    name: 'is-valid-price',
    message: `This payment must be at least $${MINIMUM_ORGANIZATION_PLAN_PRICE}.`,
    test: (value, { parent }) => {
      return (
        Number(value) >= MINIMUM_ORGANIZATION_PLAN_PRICE ||
        parent[OrgInviteToPlanFields.InviteMode] !== OrgInviteToPlanMode.Paid
      );
    },
  }),
  [OrgInviteToPlanFields.NextBillingDate]: Yup.string().test({
    name: 'is-valid-date',
    message: 'Set the billing date for the new plan',
    test: (value, { parent }) => {
      if (parent.isDefaultPaymentDate) return true;

      return new Date(Number(value) * MS_IN_S).getTime() > 0;
    },
  }),
});

export const GiveOrganizationBetaPlanSchema = Yup.object().shape({
  [GiveOrganizationBetaPlanFields.ProductTag]: Yup.string().required(
    'Select for which product you want to create a new beta plan',
  ),
  [GiveOrganizationBetaPlanFields.ProductPlanSkuId]: Yup.string().when(GiveOrganizationBetaPlanFields.ProductTag, {
    is: (value: string) => Boolean(value),
    then: Yup.string().required('Select a new beta plan for the organization'),
  }),
  [GiveOrganizationBetaPlanFields.Quantity]: Yup.string().when(GiveOrganizationBetaPlanFields.ProductTag, {
    is: (value: string) => Boolean(value),
    then: Yup.string()
      .required('Set the device quantity for the new beta plan')
      .test({
        name: 'is-in-range',
        message: 'Devices quantity must be in range 6-10000',
        test: (value) => {
          const numberValue = Number(value);

          return !(numberValue > ORGANIZATION_PLAN_MAX_SEATS_COUNT || numberValue < ORGANIZATION_PLAN_MIN_SEATS_COUNT);
        },
      }),
  }),
});

export const CustomerTransactionRefundSchema = Yup.object().shape({
  [CustomerRefundInputTypes.Reason]: Yup.string().max(
    INPUT_MAX_LENGTH,
    'This field is limited to 256 symbols. Please write a shorter refund reason.',
  ),
  [CustomerRefundInputTypes.MaximumTransactionAmount]: Yup.number(),
  [CustomerRefundInputTypes.CurrentTransactionAmount]: Yup.number()
    .typeError('The amount must be a number.')
    .positive('The amount must be positive.')
    .test({
      name: 'exceeds-the-transaction',
      message: 'You can execute refund for an amount not exceeding transaction.',
      test: (value, { parent: { [CustomerRefundInputTypes.MaximumTransactionAmount]: maximumAmount } }) => {
        return Number(value) <= maximumAmount;
      },
    }),
});

export const PlanReduceSizeSchema = Yup.object().shape({
  [PlanReduceSizeInputTypes.VacantSeatsCount]: Yup.number(),
  [PlanReduceSizeInputTypes.ReduceCount]: Yup.number()
    .required()
    .typeError('This part is pretty important.')
    .min(1, 'This part is pretty important.')
    .test({
      name: 'enough-vacant-slots',
      message: 'Not enough vacant slots.',
      test: (value, { parent }) => Number(value) <= parent.vacantSeatsCount,
    }),
});

export const UserInvitationSchema = Yup.object().shape({
  [UserInvitationInputType.Password]: NewPasswordValidationSchema,
  [UserInvitationInputType.ConfirmPassword]: BasePasswordValidationSchema.oneOf(
    [Yup.ref(UserInvitationInputType.Password)],
    ERROR_COPY.passwordsDontMatch,
  ),
});

export const AddDeviceSlotsToPlanFormSchema = Yup.object().shape({
  [AddDeviceSlotsToPlanFields.CurrentSeatCount]: Yup.number(),
  [AddDeviceSlotsToPlanFields.AddedSeatCount]: Yup.number()
    .required()
    .min(1, 'The amount must be greater than 0.')
    .test({
      name: 'is-exceeds-limit',
      message: `Plans have a maximum limit of ${ORGANIZATION_PLAN_MAX_SEATS_COUNT} devices. To add devices, create a new plan.`,
      test: (value, { parent }) =>
        parent[AddDeviceSlotsToPlanFields.CurrentSeatCount] + value <= ORGANIZATION_PLAN_MAX_SEATS_COUNT,
    })
    .required(),
});

export const RedeemSearchSchema = Yup.object().shape({
  isRedeem: Yup.bool(),
  search: Yup.string().test({
    name: 'is-redeem-core',
    message: 'Incorrect code format. Double-check the spelling and try again.',
    test: (value, { parent }) => {
      if (parent.isRedeem && value) {
        return /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/.test(value);
      }

      return true;
    },
  }),
});

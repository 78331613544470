import { gql, useQuery } from '@apollo/client';
import { Maybe } from '~/types';
import { GQLResponse, OrganizationInterface, OrganizationOwner } from '../../interfaces';
import { COUNTRY_LOCATION_FRAGMENT } from '../fragments';

export const ORGANIZATION_DETAILS_QUERY = gql`
  query organizationDetails($organizationId: ID!) {
    organizationDetails(organizationId: $organizationId) {
      organization {
        id
        name
        ownerEmail
        country {
          ...CountryLocationFragment
        }
        address
        city
        postCode: postcode
        state {
          ...CountryLocationFragment
        }
        vatNumber
        createdAt
        isCmmBusinessDashboardBeta
      }
      owner {
        id
        name
        email
        emailConfirmed
        isCompleted
        role
      }
    }
  }
  ${COUNTRY_LOCATION_FRAGMENT}
`;

interface OrganizationInfoQuery {
  organization: OrganizationInterface;
  owner: OrganizationOwner;
}

interface UseOrganizationInfoQueryInput {
  organizationId: Maybe<string>;
}

interface UseOrganizationInfoQuery extends Partial<OrganizationInfoQuery> {
  loading: boolean;
}

export const useOrganizationDetailsQuery = ({
  organizationId,
}: UseOrganizationInfoQueryInput): UseOrganizationInfoQuery => {
  const { data, loading } = useQuery<
    GQLResponse<'organizationDetails', OrganizationInfoQuery>,
    Record<'organizationId', Maybe<string>>
  >(ORGANIZATION_DETAILS_QUERY, {
    variables: {
      organizationId,
    },
    skip: !organizationId,
  });

  return {
    loading,
    organization: data?.organizationDetails?.organization,
    owner: data?.organizationDetails?.owner,
  };
};

import React, { PropsWithChildren } from 'react';
import cx from 'clsx';
import styles from './TableBody.module.sass';

interface TableBodyProps {
  className?: string;
}

const TableBody: React.FC<PropsWithChildren<TableBodyProps>> = ({ children, className }) => {
  const tableBodyClassNames = cx(styles.body, className);

  return <div className={tableBodyClassNames}>{children}</div>;
};

export default TableBody;

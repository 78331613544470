import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import cx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import { SearchIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { Form, FormInput } from '~/ui';
import { usePrevious, useQueryParams } from '~/utils';
import { SEARCH_DEBOUNCE_DELAY } from '../../constants';
import { SearchFormField } from '../../interfaces';
import styles from './SearchForm.module.sass';
import SearchFormReset from './SearchFormReset';

const MIN_SEARCH_LENGTH = 3;

interface SearchFormProps {
  searchPath: string;
  formClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  isResetButtonLoading?: boolean;
  searchTriggerEvents: Array<'submit' | 'typing'>;
}

const SearchForm: React.FC<SearchFormProps> = ({
  searchPath,
  formClassName,
  inputClassName,
  placeholder = 'Search by keyword…',
  isResetButtonLoading = false,
  searchTriggerEvents,
}) => {
  const queryParams = useQueryParams();
  const initialSearchValue = queryParams.get('q') || '';
  const initialPageValue = queryParams.get('page') || '1';
  const navigate = useNavigate();
  const formRef = useRef<UseFormReturn>(null);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const formClassNames = cx(styles.form, formClassName);
  const inputClassNames = cx(styles.input, inputClassName);
  const shouldTriggerOnSubmit = searchTriggerEvents.includes('submit');
  const shouldTriggerOnTyping = searchTriggerEvents.includes('typing');
  const [page, setPage] = useState(initialPageValue);
  const { previous: previousSearchValue } = usePrevious<string>(searchValue);
  const shouldDeletePageParam = previousSearchValue !== searchValue && !!previousSearchValue;

  const handleSearch = debounce((value: string) => {
    const shouldSetPageParam = page && page !== '1';
    const search = new URLSearchParams({
      ...(value ? { q: value } : {}),
      ...(shouldSetPageParam ? { page } : {}),
    }).toString();

    navigate({
      pathname: searchPath,
      search,
    });
  }, SEARCH_DEBOUNCE_DELAY);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedHandleSearch = useCallback(handleSearch, [page]);

  const handleFormSubmit = () => {
    const isSearchValueValid = searchValue.length >= MIN_SEARCH_LENGTH || searchValue.length === 0;

    if (!shouldTriggerOnSubmit || !isSearchValueValid) return;

    memoizedHandleSearch(searchValue);
  };

  const handleResetForm = () => {
    formRef?.current?.setValue(SearchFormField.Search, '');
    memoizedHandleSearch('');
  };

  useEffect(() => {
    const shouldRefetch = (searchValue && searchValue.length >= MIN_SEARCH_LENGTH) || searchValue === '';

    if (shouldTriggerOnTyping && shouldRefetch) {
      memoizedHandleSearch(searchValue);
    }
  }, [memoizedHandleSearch, searchValue, shouldTriggerOnTyping]);

  useEffect(() => {
    if (shouldDeletePageParam) setPage('1');
  }, [shouldDeletePageParam]);

  useEffect(() => {
    const subscribe = formRef?.current?.watch((data) => {
      setSearchValue(data[SearchFormField.Search] || '');
    });

    return () => subscribe?.unsubscribe();
  }, [formRef?.current?.watch]);

  return (
    <Form
      innerRef={formRef}
      initialValues={{ [SearchFormField.Search]: initialSearchValue }}
      onSubmit={handleFormSubmit}
      className={formClassNames}>
      <span className={styles.searchIcon}>
        <SearchIcon />
      </span>
      <FormInput name={SearchFormField.Search} placeholder={placeholder} className={inputClassNames} />
      <SearchFormReset className={styles.resetButton} onReset={handleResetForm} isLoading={isResetButtonLoading} />
    </Form>
  );
};

export default SearchForm;

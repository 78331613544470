import React from 'react';
import { useController, useFormContext as useReactHookFormContext, ArrayPath } from 'react-hook-form';
import { Multiselect } from '@macpaw/macpaw-ui';
import type { MultiselectProps } from '@macpaw/macpaw-ui/lib/Multiselect/Multiselect';
import { BaseFormElementProps } from './Form.types';
import FormArray from './FormArray';
import { useFormContext } from './FormContext';

type MultiselectFieldValue = Array<{ value: string | number }>;

interface FormMultiselectProps extends BaseFormElementProps, Omit<MultiselectProps, 'name'> {
  children: React.ReactElement | React.ReactElement[];
}

interface MultiselectOption {
  value: string;
  label: string;
  selected: boolean;
}

const FormMultiselect: React.FC<FormMultiselectProps> = ({
  name,
  children,
  validateOnChange,
  formatErrorMessage,
  ...rest
}) => {
  const { trans } = useFormContext();
  const { control, setValue, clearErrors } = useReactHookFormContext();
  const {
    field: { onBlur, value: multiselectValues = [] },
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useController<Record<string, MultiselectFieldValue>>({ name, control });

  const isInvalid = error && (isTouched || isSubmitted);
  const errorMessage = isInvalid ? formatErrorMessage?.(error?.message ?? '') ?? trans(error?.message ?? '') : '';

  const onChangeMultiselect = (values: string[]) => {
    if (!validateOnChange && isInvalid) {
      clearErrors(name);
    }

    setValue(
      name,
      values.map((value) => ({ value })),
    );
  };

  return (
    <FormArray name={name as ArrayPath<ObjectLiteral>}>
      <Multiselect
        error={errorMessage as string | React.ReactNode}
        onChange={onChangeMultiselect}
        onBlur={onBlur}
        {...rest}>
        {React.Children.map<React.ReactNode, React.ReactElement<MultiselectOption>>(
          children,
          (child): React.ReactElement => {
            return React.cloneElement<MultiselectOption>(child, {
              key: child.props.value,
              selected: (multiselectValues as MultiselectFieldValue).some(
                ({ value }: { value: string | number }) => value === child.props.value,
              ),
            });
          },
        )}
      </Multiselect>
    </FormArray>
  );
};

export default FormMultiselect;

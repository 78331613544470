import { CustomerPlanTypeForRelatedPlans } from '~/types';
import { CustomerPlanInterface } from '../../interfaces';
import styles from './PlanRelatedPlansBlock.module.sass';
import PlanRelatedPlansBlockItem from './PlanRelatedPlansBlockItem';

interface PlanRelatedPlansBlockProps {
  plan: CustomerPlanInterface;
}

const PlanRelatedPlansBlock: React.FC<PlanRelatedPlansBlockProps> = ({ plan }) => {
  if (!plan.relatedPlans || plan?.relatedPlans?.planType === CustomerPlanTypeForRelatedPlans.Simple) return null;

  const isParentPlan = plan.relatedPlans.planType === CustomerPlanTypeForRelatedPlans.Parent;

  return (
    <div className="mb-64">
      <h2 className={styles.title}>{isParentPlan ? 'Companion Plans' : 'Parent Plan'}</h2>
      {plan.relatedPlans.plans.map((currentPlan) => (
        <PlanRelatedPlansBlockItem key={currentPlan.planId} {...currentPlan} plan={plan} />
      ))}
    </div>
  );
};

export default PlanRelatedPlansBlock;

import React from 'react';
import { Button } from '@macpaw/macpaw-ui';
import { Refresh } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { IS_WIDGET } from '../../constants';
import styles from './Widget.module.sass';

const WidgetRefresh: React.FC = () => {
  if (!IS_WIDGET) return null;

  return (
    <Button className={styles.refresh} onClick={document.location.reload} icon color="secondary">
      <Refresh />
    </Button>
  );
};

export default WidgetRefresh;

import React from 'react';
import { Button, DialogContent, DialogActions, FormRow } from '@macpaw/macpaw-ui';
import { Maybe } from '~/types';
import { Form, FormInput, useModals } from '~/ui';
import { Modals } from '../../constants';
import { useEditCustomerProfileMutation } from '../../graphql/mutations';
import { EditCustomerFormValidationSchema } from '../../schemas';

interface CustomerProfileEditProps {
  customerId: string;
  email: string;
  name: Maybe<string>;
}

enum EditCustomerProfileField {
  Name = 'name',
  Email = 'email',
}

const CustomerProfileEditModal: React.FC<CustomerProfileEditProps> = ({ customerId, email, name }) => {
  const { closeModal } = useModals();
  const { editCustomerProfile, loading } = useEditCustomerProfileMutation({
    onComplete: () => closeModal(Modals.CustomerProfileEditModal),
  });

  const handleSubmit = (values: ObjectLiteral) => {
    editCustomerProfile({
      customerId,
      name: values.name,
      email: values.email,
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        [EditCustomerProfileField.Email]: email,
        [EditCustomerProfileField.Name]: name || '',
      }}
      validationSchema={EditCustomerFormValidationSchema}>
      <DialogContent>
        <FormRow>
          <h3>Customer Information</h3>
        </FormRow>
        <FormRow>
          <FormInput name={EditCustomerProfileField.Name} autoFocus type="text" placeholder="Enter customer's name" />
        </FormRow>
        <FormRow>
          <FormInput name={EditCustomerProfileField.Email} type="email" placeholder="Enter customer's email" />
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button loading={loading} type="submit" color="primary">
          Save
        </Button>
      </DialogActions>
    </Form>
  );
};

export default CustomerProfileEditModal;

import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Dropdown, DropdownItem, Button } from '@macpaw/macpaw-ui';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { SUPPORT_PROFILE_QUERY } from '~/graphql/queries';
import { isAffiliateManager, isSupport } from '~/helpers/permissions';
import { SupportProfileInterface } from '~/interfaces';

import './Header.sass';

const HeaderMenu: React.FC = () => {
  const { data, error } = useQuery(SUPPORT_PROFILE_QUERY, { fetchPolicy: 'cache-only' });

  const shouldShowDropdown = (profile: SupportProfileInterface) => {
    const withoutDropdown = profile.roles.length === 1 && (isSupport(profile) || isAffiliateManager(profile));

    return !withoutDropdown;
  };

  if (error || !data) return null;

  const { profile }: { profile: SupportProfileInterface } = data;
  // show dropdown for admins or multiple roles, for example: ["ROLE_AFFILIATE_MANAGER","ROLE_SUPPORT"]
  const withoutDropdown = !shouldShowDropdown(profile);

  return (
    <div className={`nav-menu ${withoutDropdown && 'nav-menu-single'}`}>
      <Dropdown
        position={isMobile ? 'left' : 'right'}
        trigger={
          <Button className="nav-menuButton" data-qa="account-switch" color="transparent" iconRight={<DropdownIcon />}>
            Support Dashboard
          </Button>
        }>
        <DropdownItem component={Link as unknown as ReactNode} to="/">
          Support Dashboard
        </DropdownItem>
      </Dropdown>
    </div>
  );
};

export default HeaderMenu;

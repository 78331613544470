import React from 'react';
import { GridCell, GridRow, Label } from '@macpaw/macpaw-ui';
import { Maybe } from '~/types';
import { conditionValue } from '~/utils';
import { PlanLicense } from '../../interfaces';

interface CustomerPlanGridLicense {
  planLicense: Maybe<PlanLicense>;
}

const CustomerPlanGridLicense: React.FC<CustomerPlanGridLicense> = ({ planLicense }) => {
  if (!planLicense) return null;
  const { licenseKey, campaign } = planLicense;

  return (
    <GridRow>
      <GridCell type="primary">
        <Label>Activation Number</Label>
        {conditionValue(licenseKey)}
      </GridCell>
      <GridCell type="primary">
        <Label>Campaign</Label>
        {conditionValue(campaign)}
      </GridCell>
      <GridCell type="secondary" />
      <GridCell type="secondary" />
    </GridRow>
  );
};

export default CustomerPlanGridLicense;

import { Role } from '../constants';
import { SupportProfileInterface } from '../interfaces';

export const isAdmin = (profile: SupportProfileInterface): boolean => {
  let hasAccess = false;
  const { roles } = profile;

  if (roles.includes(Role.Admin) || roles.includes(Role.SuperAdmin)) {
    hasAccess = true;
  }

  return hasAccess;
};

export const isAffiliateManager = (profile: SupportProfileInterface): boolean => {
  const { roles } = profile;

  return roles.includes(Role.AffiliateManager);
};

export const isSupport = (profile: SupportProfileInterface): boolean => {
  const { roles } = profile;

  return roles.includes(Role.Support);
};

import React, { useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { Button, FormRow, Panel, notify } from '@macpaw/macpaw-ui';
import { Form, FormInput, FormPassword } from '~/ui';
import Section from '../../components/Section/Section';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';
import {
  CHANGE_PASSWORD_INVALID_ERROR,
  ERROR_CODE_TO_COPY,
  NEW_PASSWORD_SAME_ERROR,
  PASSWORD_LENGTH_ERROR,
  SUPPORT_TOKEN,
  APP_IS_DEVELOP,
} from '../../constants';
import { CHANGE_PASSWORD_MUTATION } from '../../graphql/mutations';
import { SUPPORT_PROFILE_QUERY } from '../../graphql/queries';
import sentry from '../../helpers/sentry';
import letterIcon from '../../images/icons/letter.png';
import lockIcon from '../../images/icons/lock.png';
import userIcon from '../../images/icons/user-support.png';
import { ChangePasswordInterface } from '../../interfaces';
import { UserSettingsFormValidationSchema } from '../../schemas';
import { tokenVar } from '../../store';
import styles from './Settings.module.sass';

enum PasswordFieldType {
  password = 'password',
  newPassword = 'newPassword',
  confirmPassword = 'confirmPassword',
}

const Settings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, refetch } = useQuery(SUPPORT_PROFILE_QUERY);
  const formRef = useRef<UseFormReturn>(null);

  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted({ changePassword: { token } }: ChangePasswordInterface) {
      const { current: form } = formRef;

      setIsLoading(false);
      notify(
        "Password updated! P.S. We've terminated all other sessions to make sure your account is safe.",
        'success',
      );

      form?.setValue('newPassword', '');
      form?.setValue('confirmPassword', '');

      if (token) {
        window.localStorage.setItem(SUPPORT_TOKEN, token);
        tokenVar(token);
        refetch();
      }
    },
    onError(error) {
      const { current: form } = formRef;

      setIsLoading(false);

      if (Array.isArray(error.graphQLErrors)) {
        error.graphQLErrors.forEach((err) => {
          const errCode = err.extensions?.response.body.errors[0].code;
          const errPath = err.extensions?.response.body.errors[0].path;

          if (errPath === PasswordFieldType.password) {
            switch (errCode) {
              case PASSWORD_LENGTH_ERROR:
                form?.setError(PasswordFieldType.password, {
                  message: ERROR_CODE_TO_COPY[PASSWORD_LENGTH_ERROR],
                });
                break;
              case CHANGE_PASSWORD_INVALID_ERROR:
                form?.setError(PasswordFieldType.password, {
                  message: ERROR_CODE_TO_COPY[CHANGE_PASSWORD_INVALID_ERROR],
                });
                break;
              default:
                break;
            }
          }

          if (errPath === PasswordFieldType.newPassword && errCode === NEW_PASSWORD_SAME_ERROR) {
            form?.setError(PasswordFieldType.newPassword, {
              message: ERROR_CODE_TO_COPY[NEW_PASSWORD_SAME_ERROR],
            });
          }
        });
      } else {
        sentry.captureException(error);
      }
    },
  });

  const handleSubmit = ({ password, newPassword }: ObjectLiteral) => {
    setIsLoading(true);
    changePassword({ variables: { password, newPassword } });
  };

  return (
    <WithErrorBoundary>
      <Section title="Account Settings">
        <Panel>
          <div className={styles.accountInfo}>
            <div className={styles.accountInfoIn}>
              <div className={styles.icon}>
                <img src={userIcon} width={80} height={80} alt="" />
              </div>
              <div className={styles.accountInfoDescription}>
                <div className={`${styles.accountInfoTitle} mb-8`}>Your Name</div>
                <div className={styles.accountInfoText}>{data.profile.name ? data.profile.name : 'MacPaw User'}</div>
              </div>
            </div>
            <div className={styles.accountInfoIn}>
              <div className={styles.icon}>
                <img src={letterIcon} width={80} height={80} alt="" />
              </div>
              <div className={styles.accountInfoDescription}>
                <div className={`${styles.accountInfoTitle} mb-8`}>Your Email Address</div>
                <div className={styles.accountInfoText}>{data.profile.email}</div>
              </div>
            </div>
          </div>
        </Panel>
      </Section>

      <Panel>
        <div className={styles.triggerIn}>
          <div className={styles.icon}>
            <img src={lockIcon} width={80} height={80} alt="" />
          </div>
          <div className={styles.description}>
            <div className={`${styles.descriptionTitle} mb-8`}>Password</div>
            <div className={styles.descriptionText}>Set a new password for your Dashboard Account.</div>
          </div>
        </div>
        <Form
          withDevTools={APP_IS_DEVELOP}
          className={styles.form}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validationSchema={UserSettingsFormValidationSchema}
          validateMode="onSubmit"
          initialValues={{
            username: data.profile.email,
            [PasswordFieldType.password]: '',
            [PasswordFieldType.newPassword]: '',
            [PasswordFieldType.confirmPassword]: '',
          }}>
          <FormInput type="text" name="username" autoComplete={data.email} hidden />

          <FormRow>
            <FormPassword
              name={PasswordFieldType.password}
              autoComplete="current-password"
              label="Current Password"
              placeholder="Enter your current password"
            />
          </FormRow>

          <FormRow>
            <FormPassword
              withToggle
              name={PasswordFieldType.newPassword}
              autoComplete="new-password"
              label="New Password"
              placeholder="Enter a new password"
            />
          </FormRow>

          <FormRow>
            <FormPassword
              name={PasswordFieldType.confirmPassword}
              autoComplete="new-password"
              label="Confirm Password"
              placeholder="Confirm new password"
            />
          </FormRow>

          <Button type="submit" color="secondary" loading={isLoading}>
            Change Password
          </Button>
        </Form>
      </Panel>
    </WithErrorBoundary>
  );
};

export default Settings;

import React from 'react';
import { FormSelect } from '~/ui';
import styles from './GivePlanReasonsSelect.module.sass';

interface GivePlanReasonsSelectProps {
  name: string;
  reasons: string[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const GivePlanReasonsSelect: React.FC<GivePlanReasonsSelectProps> = ({
  name,
  reasons,
  label = 'Reason to give product',
  placeholder = 'Select Reason',
  disabled,
}) => (
  <FormSelect name={name} className={styles.reason} label={label} disabled={disabled} validateOnChange>
    <option value="" disabled>
      {placeholder}
    </option>
    {reasons?.map((reason) => (
      <option key={reason} value={reason}>
        {reason}
      </option>
    ))}
  </FormSelect>
);

export default GivePlanReasonsSelect;

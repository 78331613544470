import React from 'react';
import cx from 'clsx';
import { BaseCustomerPlan, CustomerPlanGroup, CustomerPlanStatus } from '~/types';
import { getCustomerPlanColor, getCustomerPlanGroup } from '~/utils';
import styles from './PlanStatusLabel.module.sass';

const PlanStatusLabel: React.FC<{ plan: BaseCustomerPlan }> = ({ plan }) => {
  const planGroup = getCustomerPlanGroup(plan);
  const color = getCustomerPlanColor(plan);
  let label = 'Active';

  if (plan.status === CustomerPlanStatus.Trialing) {
    label = 'Trial';
  } else if (planGroup === CustomerPlanGroup.Inactive) {
    label = 'Inactive';
  } else if (plan.status === CustomerPlanStatus.Paused) {
    label = 'Paused';
  } else if (plan.status === CustomerPlanStatus.PastDue) {
    label = 'Past due';
  } else if (planGroup === CustomerPlanGroup.Pending) {
    label = 'Pending';
  }

  return (
    <div className={cx(styles.statusLabel)} style={{ backgroundColor: color }}>
      {label}
    </div>
  );
};

export default PlanStatusLabel;

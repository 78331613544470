/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLazyQuery, useQuery } from '@apollo/client';
import { State, Country } from '~/types';
import { usePrevious, hasVatNumber } from '~/utils';
import { STATES_QUERY, COUNTRIES_QUERY } from '../../graphql/queries';

interface UseOrganizationCountriesData {
  isLoadingCountries: boolean;
  isLoadingStates: boolean;
  countries: Country[];
  states: State[];
}

interface UseOrganizationCountriesDataInput {
  countryFieldName: string;
  stateFieldName: string;
  vatNumberFieldName?: string;
}

const COUNTRY_US = 'US';

const useOrganizationCountriesData = ({
  countryFieldName,
  stateFieldName,
  vatNumberFieldName,
}: UseOrganizationCountriesDataInput): UseOrganizationCountriesData => {
  const [states, setStates] = useState<State[]>([]);
  const { watch, setValue, clearErrors } = useFormContext();

  const { data, loading: isLoadingCountries } = useQuery(COUNTRIES_QUERY);
  const [getStatesForCountry, { loading: isLoadingStates }] = useLazyQuery(STATES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted(currentData) {
      setStates(currentData.states);
    },
  });

  const country = watch(countryFieldName);
  const { previous: prevCountry } = usePrevious(country);
  const countries = data?.countries ?? [];

  useEffect(() => {
    if (country === prevCountry) return;

    if (country === COUNTRY_US) {
      getStatesForCountry({ variables: { countryCode: country } });
    } else {
      setStates([]);
      clearErrors(stateFieldName);
      setValue(stateFieldName, '');
    }
    if (!hasVatNumber(countries, country) && vatNumberFieldName) {
      setValue(vatNumberFieldName, '');
    }
  }, [country, countries]);

  return {
    isLoadingCountries,
    isLoadingStates,
    countries,
    states: states ?? [],
  };
};

export default useOrganizationCountriesData;

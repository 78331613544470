import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { ORGANIZATION_ADDED_TO_BETA, ORGANIZATION_REMOVED_FROM_BETA } from '../../constants';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { ORGANIZATION_DETAILS_QUERY } from '../queries';

export const UPDATE_ORGANIZATION_DASHBOARD_MUTATION = gql`
  mutation UpdateOrganizationDashboard($organizationId: ID!, $isCmmBusinessDashboardBeta: Boolean) {
    updateOrganizationDashboard(
      organizationId: $organizationId
      isCmmBusinessDashboardBeta: $isCmmBusinessDashboardBeta
    )
  }
`;

interface UpdateOrganizationDashboardVariables {
  organizationId: string;
  isCmmBusinessDashboardBeta: boolean;
}

export const useUpdateOrganizationDashboardMutation = () => {
  const [mutate, { loading }] = useMutation<void, UpdateOrganizationDashboardVariables>(
    UPDATE_ORGANIZATION_DASHBOARD_MUTATION,
  );

  const updateOrganizationDashboard = async (organizationId: string, isCmmBusinessDashboardBeta: boolean) => {
    try {
      await mutate({
        variables: {
          organizationId,
          isCmmBusinessDashboardBeta,
        },
        refetchQueries: [
          {
            query: ORGANIZATION_DETAILS_QUERY,
            variables: {
              organizationId,
            },
          },
        ],
      });

      notify(
        isCmmBusinessDashboardBeta ? ORGANIZATION_ADDED_TO_BETA : ORGANIZATION_REMOVED_FROM_BETA,
        NotificationType.Success,
      );
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);
    }

    return null;
  };

  const addOrganizationToBeta = (organizationId: string) => updateOrganizationDashboard(organizationId, true);

  const removeOrganizationFromBeta = (organizationId: string) => updateOrganizationDashboard(organizationId, false);

  return {
    addOrganizationToBeta,
    removeOrganizationFromBeta,
    loading,
  };
};

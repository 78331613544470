import { gql, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { EMAIL_HAS_BEEN_SENT } from '../../constants';

export const RESEND_INVITE_MUTATION = gql`
  mutation ResendInvite($userId: ID!) {
    resendInvite(userId: $userId)
  }
`;

interface UseResendInviteMemberMutationInput {
  onComplete?: () => void;
  onError?: (error: GraphQLError) => void;
}

export const useResendInviteMemberMutation = ({ onComplete, onError }: UseResendInviteMemberMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<void, Record<'userId', string>>(RESEND_INVITE_MUTATION);

  const resendInvite = async (userId: string) => {
    try {
      await mutate({
        variables: {
          userId,
        },
      });

      notify(EMAIL_HAS_BEEN_SENT, NotificationType.Success);
      onComplete?.();
    } catch (err) {
      if (!onError) throw err;

      onError(err as GraphQLError);
    }
  };

  return {
    resendInvite,
    loading,
  };
};

import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { formatDate, conditionValue, emailTrim, nameTrim } from '~/utils';
import { ROUTE_PATHS } from '../../constants';
import OrganizationIcon from '../../images/icons/business.svg?react';
import { OrganizationInterface } from '../../interfaces';
import { TableCell } from '../Table';
import styles from './OrganizationsList.module.sass';

interface OrganizationListItemProps {
  organization: OrganizationInterface;
  className?: string;
}

const OrganizationListItem: React.FC<OrganizationListItemProps> = ({
  organization: { id, name = '', ownerEmail, createdAt },
  className,
}) => (
  <Link to={ROUTE_PATHS.organizationPage(id)} className={className}>
    <TableCell>
      <OrganizationIcon width={40} height={40} />
    </TableCell>
    <TableCell>{conditionValue(name && <strong>{nameTrim(name)}</strong>)}</TableCell>
    <TableCell>{emailTrim(ownerEmail)}</TableCell>
    <TableCell>{formatDate(createdAt)}</TableCell>
    <TableCell>
      <DropdownIcon className={styles.arrow} />
    </TableCell>
  </Link>
);

export default OrganizationListItem;

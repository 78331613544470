import React from 'react';
import { Button, DialogActions, DialogContent, DialogIcon } from '@macpaw/macpaw-ui';
import SuccessIcon from '../../../images/icons/success-progress.svg?react';

interface ReducePlanSizeSuccessStepProps {
  onCancel: () => void;
}

const ReducePlanSizeSuccessStep: React.FC<ReducePlanSizeSuccessStepProps> = ({ onCancel }) => (
  <>
    <DialogContent>
      <DialogIcon>
        <SuccessIcon width={128} height={128} />
      </DialogIcon>
      <h4>Plan size will be updated in a moment</h4>
      <p>This could take up to a few minutes.</p>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={onCancel}>
        Got it
      </Button>
    </DialogActions>
  </>
);

export default ReducePlanSizeSuccessStep;

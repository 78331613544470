import { gql, useQuery, useLazyQuery, FetchPolicy } from '@apollo/client';
import { Maybe } from '~/types';
import { GQLResponse } from '../../interfaces';

export const ORGANIZATION_SLOT_PRICE_QUERY = gql`
  query organizationSlotPrice($organizationId: ID!, $productPlanSkuId: ID!) {
    organizationSlotPrice(organizationId: $organizationId, productPlanSkuId: $productPlanSkuId) {
      defaultPrice
      customPrice
    }
  }
`;

interface OrganizationSlotPrice {
  defaultPrice: number;
  customPrice: number;
}

type OrganizationSlotPriceQueryVariables = Record<'organizationId' | 'productPlanSkuId', string>;

interface UseOrganizationSlotPriceQueryInput {
  organizationId: string;
  productPlanSkuId: string;
  fetchPolicy?: FetchPolicy;
  skip?: boolean;
}

export const useOrganizationSlotPriceQuery = ({
  organizationId,
  productPlanSkuId,
  fetchPolicy,
  skip,
}: UseOrganizationSlotPriceQueryInput) => {
  const { data, loading } = useQuery<
    GQLResponse<'organizationSlotPrice', OrganizationSlotPrice>,
    OrganizationSlotPriceQueryVariables
  >(ORGANIZATION_SLOT_PRICE_QUERY, {
    skip,
    fetchPolicy,
    variables: {
      organizationId,
      productPlanSkuId,
    },
  });

  return {
    defaultPrice: data?.organizationSlotPrice.defaultPrice,
    customPrice: data?.organizationSlotPrice.customPrice,
    loading,
  };
};

export const useGetOrganizationSlotPriceQuery = ({ fetchPolicy }: Partial<UseOrganizationSlotPriceQueryInput> = {}) => {
  const [loadData, { data, loading }] = useLazyQuery<
    GQLResponse<'organizationSlotPrice', OrganizationSlotPrice>,
    OrganizationSlotPriceQueryVariables
  >(ORGANIZATION_SLOT_PRICE_QUERY, { fetchPolicy });

  const loadSlotPrice = (organizationId: string, productPlanSkuId: string) =>
    loadData({
      variables: {
        organizationId,
        productPlanSkuId,
      },
    });

  return {
    loadSlotPrice,
    defaultPrice: data?.organizationSlotPrice.defaultPrice,
    customPrice: data?.organizationSlotPrice.customPrice,
    loading,
  };
};

interface UseLazyOrganizationSlotPriceInput {
  fetchPolicy?: FetchPolicy;
}

export const useLazyOrganizationSlotPriceQuery = ({ fetchPolicy }: UseLazyOrganizationSlotPriceInput = {}) => {
  const [loadData, { data: priceData, loading }] = useLazyQuery<
    GQLResponse<'organizationSlotPrice', OrganizationSlotPrice>
  >(ORGANIZATION_SLOT_PRICE_QUERY, { fetchPolicy });

  const loadOrganizationPrice = async ({
    organizationId,
    productPlanSkuId,
    skip,
  }: UseOrganizationSlotPriceQueryInput): Promise<Maybe<OrganizationSlotPrice>> => {
    if (skip) return null;

    const { data } = await loadData({
      variables: {
        organizationId,
        productPlanSkuId,
      },
    });

    return data?.organizationSlotPrice;
  };

  return {
    loadOrganizationPrice,
    defaultPrice: priceData?.organizationSlotPrice.defaultPrice,
    customPrice: priceData?.organizationSlotPrice.customPrice,
    loading,
  };
};

import { gql } from '@apollo/client';

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFragment on Pagination {
    perPage
    pagesCount
    currentPage
    elementsCount
  }
`;

import React, { ReactNode } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Button, ExternalLink } from '@macpaw/macpaw-ui';
import { getXSSProtectedUrl } from '~/utils';
import { IS_WIDGET } from '../../constants';
import getAbsoluteUrlPath from '../../helpers/getAbsoluteUrlPath';
import styles from './Widget.module.sass';
import WidgetRefresh from './WidgetRefresh';

const WidgetControlsComponent: React.FC = () => {
  const { pathname } = useLocation();
  const { customerId, customerPlanId } = useParams<Record<'customerId' | 'customerPlanId', string>>();
  const absoluteUrl = getAbsoluteUrlPath(pathname);
  const isBackButton = customerId && customerPlanId;

  if (!IS_WIDGET) return null;

  return (
    <div className={styles.controls}>
      {isBackButton && (
        <Button asLink component={Link as unknown as ReactNode} to={`/${customerId}`}>
          ‹ Back
        </Button>
      )}
      <Button
        href={getXSSProtectedUrl(absoluteUrl)}
        component={ExternalLink as unknown as ReactNode}
        className={styles.openInNew}
        asLink>
        Open in Dashboard
      </Button>
      <WidgetRefresh />
    </div>
  );
};

export default WidgetControlsComponent;

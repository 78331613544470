import React, { useEffect, useState } from 'react';
import { Loader, Panel } from '@macpaw/macpaw-ui';
import { TICKET_REQUESTER_EMAIL_KEY, EXTERNAL_PATHS, ZENDESK_CONSOLE_ERROR } from '../../constants';
import { useCustomersQuery } from '../../graphql/queries';
import UserAttentionIcon from '../../images/icons/user-attention.svg?react';
import { CustomerInterface } from '../../interfaces';
import styles from './Widget.module.sass';

const Widget: React.FC = () => {
  const [isSDKLoaded, setIsSDKLoaded] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');
  const [isCustomerExists, setIsCustomerExists] = useState<null | boolean>(null);

  const getTicketRequesterEmail = (client: ObjectLiteral) =>
    client.get(TICKET_REQUESTER_EMAIL_KEY).then((data: ObjectLiteral) => data[TICKET_REQUESTER_EMAIL_KEY]);

  useCustomersQuery({
    skip: !customerEmail,
    search: customerEmail,
    onCompleted: (data) => {
      const customers: CustomerInterface[] = data.customers.data;

      if (!customers.length) {
        setIsCustomerExists(false);

        return;
      }
      window.location.replace(`/${customers[0].id}`);
    },
  });

  useEffect(() => {
    const script = document.createElement('script');

    script.src = EXTERNAL_PATHS.zafSdk();
    script.onload = () => setIsSDKLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!isSDKLoaded) {
      return;
    }

    const client = window.ZAFClient.init();

    if (!client) {
      console.error(ZENDESK_CONSOLE_ERROR); // eslint-disable-line no-console

      return;
    }

    getTicketRequesterEmail(client)
      .then((email: string) => {
        setCustomerEmail(email);
      })
      .catch(() => {});
  }, [isSDKLoaded]);

  if (isCustomerExists === false) {
    return (
      <Panel className={styles.empty}>
        <UserAttentionIcon className={styles.emptyIcon} />
        <h6>This user information was not found.</h6>
      </Panel>
    );
  }

  return <Loader />;
};

export default Widget;

import React, { HTMLAttributes, useEffect, useState } from 'react';
import { BaseCustomerPlan, Locale } from '~/types';
import { distanceToNow, isOneTime, isChinaPlan } from '~/utils';
import InfinitySymbol from './InfinitySymbol';
import LoadableText from './LoadableText';

interface BillingCycle extends HTMLAttributes<HTMLDivElement> {
  customerPlan: Partial<BaseCustomerPlan>;
  locale?: Locale;
}

const BillingCycle: React.FC<BillingCycle> = ({ customerPlan, locale = Locale.en }) => {
  const [distance, setDistance] = useState<string | null>(null);

  useEffect(() => {
    if (customerPlan.planView) {
      distanceToNow(customerPlan.planView.productPlanActivationPeriod!, locale).then((result) => {
        setDistance(result);
      });
    }
  }, []);

  if (!customerPlan.planView) return null;

  if (isOneTime(customerPlan.planView) && !isChinaPlan(customerPlan.planView.productPlanId!)) return <InfinitySymbol />;

  // if plan is not one-time, activationPeriod must exist
  return <LoadableText isLoading={!distance}>{distance}</LoadableText>;
};

export default BillingCycle;

import { gql, useMutation } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { CUSTOMER_FRAGMENT } from '../fragments';

export const EDIT_CUSTOMER_PROFILE_MUTATION = gql`
  mutation EditCustomerProfile($customerId: ID!, $name: String, $email: String!) {
    editCustomerProfile(customerId: $customerId, name: $name, email: $email) {
      ...CustomerFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

interface EditCustomerProfileMutationVariables {
  email: string;
  name: string;
  customerId: string;
}

interface UseEditCustomerProfileMutationInput {
  onComplete?: () => void;
}

export const useEditCustomerProfileMutation = ({ onComplete }: UseEditCustomerProfileMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<EditCustomerProfileMutationVariables>(EDIT_CUSTOMER_PROFILE_MUTATION);

  const editCustomerProfile = async (variables: EditCustomerProfileMutationVariables) => {
    await mutate({ variables });

    onComplete?.();
    notify('User data successfully changed', NotificationType.Success);
  };

  return {
    editCustomerProfile,
    loading,
  };
};

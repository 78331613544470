import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContent, DialogActions, Button, FormRow } from '@macpaw/macpaw-ui';
import { FormInput } from '~/ui';
import { CreateCustomerFields } from '../../../constants';

interface CreateCustomerFormBodyProps {
  isLoading: boolean;
  onCancel: () => void;
}

const CreateCustomerFormBody: React.FC<CreateCustomerFormBodyProps> = ({ isLoading, onCancel }) => {
  const { watch } = useFormContext();

  const email = watch(CreateCustomerFields.Email);

  return (
    <>
      <DialogContent>
        <h3 className="mb-24">Creating customer account</h3>
        <FormRow>
          <FormInput disabled={isLoading} label="Send invitation email to" name={CreateCustomerFields.Email} />
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button outline disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" color="secondary" loading={isLoading} disabled={!email}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateCustomerFormBody;

import React from 'react';
import { Button } from '@macpaw/macpaw-ui';
import { ConfirmationModal, useModals } from '~/ui';
import { Modals } from '../../constants';
import { useResendInviteMemberMutation } from '../../graphql/mutations';
import ResendInviteIcon from '../../images/icons/resend.svg?react';
import { SupportProfileInterface } from '../../interfaces';

interface MemberResendInviteProps {
  member: SupportProfileInterface | null;
  onComplete?: () => Promise<void> | void;
}

const MemberResendInviteModal: React.FC<MemberResendInviteProps> = ({ member, onComplete }) => {
  const { closeModal } = useModals();
  const { resendInvite, loading } = useResendInviteMemberMutation();

  const handleSubmit = async () => {
    if (!member) return;

    await resendInvite(member?.id);

    closeModal(Modals.MemberResendInviteModal);

    await onComplete?.();
  };

  return (
    <ConfirmationModal
      icon={<ResendInviteIcon width={160} height={160} />}
      title="Resend invitation to member"
      description={
        <>
          Has a team member misplaced their invitation or forgotten their password? No worries. Let’s just resend the
          invitation to <strong>{member!.email}</strong> so they can get back in the game.
        </>
      }
      actions={
        <Button type="submit" onClick={handleSubmit} loading={loading} color="secondary">
          Resend Invitation
        </Button>
      }
    />
  );
};

export default MemberResendInviteModal;

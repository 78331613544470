import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, Stepper, Step, StepperContextValueType, useModals } from '~/ui';
import { Modals, AddDeviceSlotsToPlanSteps, AddDeviceSlotsToPlanFields } from '../../constants';
import { OrganizationPlanInterface } from '../../interfaces';
import { AddDeviceSlotsToPlanFormSchema } from '../../schemas';
import { AddDeviceSlotsToPlanForm, AddDeviceSlotsToPlanSuccess } from './steps';

interface AddDeviceSlotsToPlanModalProps {
  plan: OrganizationPlanInterface;
  vacantSeatsCount: number;
  seatsCount: number;
}

const INITIAL_VALUES = {
  [AddDeviceSlotsToPlanFields.AddedSeatCount]: '',
};

const AddDeviceSlotsToPlanModal: React.FC<AddDeviceSlotsToPlanModalProps> = ({
  plan,
  vacantSeatsCount,
  seatsCount,
}) => {
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);
  const stepperRef = useRef<StepperContextValueType>(null);
  const { closeModal } = useModals();

  const handleCancel = () => closeModal(Modals.AddDeviceSlotsToPlanModal);

  return (
    <Form initialValues={INITIAL_VALUES} innerRef={formRef} validationSchema={AddDeviceSlotsToPlanFormSchema}>
      <Stepper initialStep={AddDeviceSlotsToPlanSteps.Form} innerRef={stepperRef} updateAnyway>
        <Step stepKey={AddDeviceSlotsToPlanSteps.Form}>
          <AddDeviceSlotsToPlanForm
            plan={plan}
            vacantSeatsCount={vacantSeatsCount}
            seatsCount={seatsCount}
            onCancel={handleCancel}
          />
        </Step>
        <Step stepKey={AddDeviceSlotsToPlanSteps.Success}>
          <AddDeviceSlotsToPlanSuccess onSubmit={handleCancel} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default AddDeviceSlotsToPlanModal;

import React from 'react';
import { Password } from '@macpaw/macpaw-ui';
import { InputProps } from '@macpaw/macpaw-ui/lib/Input/Input';
import type { PasswordProps } from '@macpaw/macpaw-ui/lib/Password/Password';
import { useFormInputChangeHandler, useFormError, useFormField } from './Form.hooks';
import { BaseFormElementProps } from './Form.types';
import { useFormContext } from './FormContext';

interface FormPasswordProps extends BaseFormElementProps, Omit<PasswordProps, 'name'> {}

const FormPassword: React.FC<FormPasswordProps> = ({ name, validateOnChange, formatErrorMessage, ...props }) => {
  const { trans } = useFormContext();
  const {
    watch,
    field: { onBlur },
  } = useFormField<string>(name);

  const { message: errorMessage } = useFormError(name, {
    formatValue: formatErrorMessage ?? trans,
  });
  const handleChange = useFormInputChangeHandler(name, { validateOnChange });

  const value = watch(name);

  return (
    <Password
      name={name}
      defaultValue={value}
      onChange={handleChange as InputProps['onChange']}
      onBlur={onBlur}
      error={errorMessage as string}
      {...props}
    />
  );
};

export default FormPassword;

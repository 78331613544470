import { createContext, useContext } from 'react';
import { Maybe } from '~/types';

export interface StepperContextValueType {
  steps: string[];
  currentStep: number;
  maxStep: number;
  currentStepKey: string | null;
  prevStepKey: Maybe<string>;
  onFinish: () => void;
  setStep: (step: string) => void;
  setPreviousStep: (step: Maybe<string>) => void;
  setNextStep: () => void;
}

const initialStepperValue: StepperContextValueType = {
  steps: [],
  currentStep: 0,
  maxStep: 0,
  currentStepKey: null,
  prevStepKey: null,
  onFinish: () => {},
  setStep: () => {},
  setPreviousStep: () => {},
  setNextStep: () => {},
};

export const StepperContext = createContext<StepperContextValueType>(initialStepperValue);

export const useStepperContext = () => useContext(StepperContext);

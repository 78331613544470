import { gql, useMutation } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';

export const PAUSE_CUSTOMER_PLAN_MUTATION = gql`
  mutation PauseCustomerPlan($customerPlanId: ID!) {
    pauseCustomerPlan(customerPlanId: $customerPlanId)
  }
`;

interface UsePauseCustomerPlanMutationInput {
  onComplete?: () => void;
}

export const usePauseCustomerPlanMutation = ({ onComplete }: UsePauseCustomerPlanMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<Record<'customerPlanId', string>>(PAUSE_CUSTOMER_PLAN_MUTATION);

  const pausePlan = async (customerPlanId: string) => {
    await mutate({
      variables: {
        customerPlanId,
      },
    });
    notify('Plan has been successfully paused', NotificationType.Success);
    onComplete?.();
  };

  return {
    pausePlan,
    loading,
  };
};

import React from 'react';
import { Button } from '@macpaw/macpaw-ui';
import { ConfirmationModal, StepItemProps } from '~/ui';
import { useRemoveBetaPlanMutation } from '../../../graphql/mutations/removeBetaPlan';
import DeviceAlert from '../../../images/icons/device-alert.svg?react';
import { RemoveBetaPlanSteps } from '../constants';

interface RemoveBetaPlanConfirmProps extends Partial<StepItemProps> {
  planId: string;
  ownerId: string;
  organizationId: string;
  onCancel: () => void;
}

const RemoveBetaPlanConfirm: React.FC<RemoveBetaPlanConfirmProps> = ({
  planId,
  ownerId,
  organizationId,
  onCancel,
  ...props
}) => {
  const { setStep } = props as StepItemProps;
  const { removeBetaPlan, loading } = useRemoveBetaPlanMutation();

  const handleSubmit = async () => {
    try {
      await removeBetaPlan(planId, ownerId, organizationId);

      setStep(RemoveBetaPlanSteps.Success);
    } catch (e) {
      setStep(RemoveBetaPlanSteps.Error);
    }
  };

  return (
    <ConfirmationModal
      title="Remove beta plan?"
      description={
        <>
          Removing <b>CleanMyMac X – Business (Beta)</b> means the app will be deactivated on all devices linked to this
          plan. Are you sure you want to do it?
        </>
      }
      icon={<DeviceAlert width={128} height={128} />}
      actions={
        <>
          <Button color="primary" outline onClick={onCancel} disabled={loading}>
            Back
          </Button>
          <Button color="warning" onClick={handleSubmit} disabled={loading}>
            Remove Plan
          </Button>
        </>
      }
    />
  );
};

export default RemoveBetaPlanConfirm;

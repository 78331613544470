import { gql, useQuery } from '@apollo/client';
import { Maybe } from '~/types';
import { useRefresh } from '~/utils';
import { GQLResponse, PlanInterface } from '../../interfaces';
import { ORGANIZATION_PLAN_FRAGMENT } from '../fragments';

export const ORGANIZATION_PLAN_QUERY = gql`
  query OrganizationPlan($planId: ID!) {
    organizationPlan(planId: $planId) {
      ...OrganizationPlanFragment
      ... on OrganizationPlan {
        seatCount
        sagaStateId
        sagaStateStatus
      }
    }
  }
  ${ORGANIZATION_PLAN_FRAGMENT}
`;

interface UseOrganizationPlanQueryInput {
  planId: Maybe<string>;
}

export const useOrganizationPlanQuery = ({ planId }: UseOrganizationPlanQueryInput) => {
  const { data, loading, refetch } = useQuery<
    GQLResponse<'organizationPlan', PlanInterface>,
    Record<'planId', Maybe<string>>
  >(ORGANIZATION_PLAN_QUERY, {
    variables: {
      planId,
    },
    skip: !planId,
  });

  const [refreshing, refreshPlan] = useRefresh(refetch);

  return {
    plan: data?.organizationPlan,
    loading,
    refreshPlan,
    refreshing,
  };
};

import React from 'react';
import cx from 'clsx';
import styles from './TableRow.module.sass';

interface TableRowProps {
  children: React.ReactElement<ObjectLiteral>;
  className?: string;
}

const TableRow: React.FC<TableRowProps> = ({ children, className }) => {
  const tableRowClassNames = cx(styles.row, className);

  return (
    <div className={tableRowClassNames}>
      {React.cloneElement(children, {
        ...children?.props,
        className: cx(styles.rowChild, children?.props?.className ?? ''),
      })}
    </div>
  );
};

export default TableRow;

import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { GQLResponse } from '~/types';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { IS_BETA_PLAN_USED_QUERY, ORGANIZATION_INFO_QUERY, PLANS_BY_OWNER_ID_QUERY } from '../queries';

const GIVE_ORGANIZATION_BETA_PLAN_MUTATION = gql`
  mutation GiveOrganizationBetaPlan($organizationId: ID!, $productPlanSkuId: ID!, $seatCount: Int!) {
    giveOrganizationBetaPlan(
      organizationId: $organizationId
      productPlanSkuId: $productPlanSkuId
      seatCount: $seatCount
    ) {
      isSuccessfulCreation
    }
  }
`;

interface UseGiveOrganizationBetaPlanMutationInput {
  onCompleted?: (data: GQLResponse<'giveOrganizationBetaPlan', MutationResponse>) => void;
  onError?: () => void;
}

interface GiveOrganizationBetaPlanVariables {
  organizationId: string;
  productPlanSkuId: string;
  seatCount: number;
}

interface MutationResponse {
  isSuccessfulCreation: boolean;
}

interface UseGiveCustomerProductPlanMutation {
  giveBetaPlan: (input: GiveOrganizationBetaPlanVariables) => Promise<void>;
  loading: boolean;
}

export const useGiveOrganizationBetaPlanMutation = ({
  onCompleted,
  onError,
}: UseGiveOrganizationBetaPlanMutationInput = {}): UseGiveCustomerProductPlanMutation => {
  const [mutate, { loading }] = useMutation<
    GQLResponse<'giveOrganizationBetaPlan', MutationResponse>,
    GiveOrganizationBetaPlanVariables
  >(GIVE_ORGANIZATION_BETA_PLAN_MUTATION, {
    onCompleted,
    onError,
  });

  const giveBetaPlan = async (input: GiveOrganizationBetaPlanVariables): Promise<void> => {
    try {
      await mutate({
        variables: input,
        refetchQueries: [
          {
            query: ORGANIZATION_INFO_QUERY,
            variables: {
              organizationId: input.organizationId,
            },
          },
          {
            query: PLANS_BY_OWNER_ID_QUERY,
            variables: {
              ownerId: input.organizationId,
            },
          },
          {
            query: IS_BETA_PLAN_USED_QUERY,
            variables: {
              organizationId: input.organizationId,
            },
          },
        ],
      });
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);
    }
  };

  return {
    giveBetaPlan,
    loading,
  };
};

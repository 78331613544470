/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { ApolloError, FetchPolicy, gql, useLazyQuery, useQuery } from '@apollo/client';
import { GQLResponse, Maybe } from '~/types';

export const DOWNLOAD_CODES_LINK_QUERY = gql`
  query DownloadCodesLink($orderId: ID!) {
    downloadCodesLink(orderId: $orderId) {
      link
    }
  }
`;

type DownloadCodesLinkQueryVariables = Record<'orderId', string>;

type DownloadCodesLinkQuery = GQLResponse<'downloadCodesLink', Record<'link', string>>;

interface UseDownloadCodesLinkQueryInput {
  orderId: string;
  fetchPolicy?: FetchPolicy;
  skip?: boolean;
  pollInterval?: number;
}

type UseLazyDownloadCodesLinkQueryInput = Omit<UseDownloadCodesLinkQueryInput, 'orderId' | 'pollInterval'>;

interface GetCodesLinkInput extends Omit<UseDownloadCodesLinkQueryInput, 'pollInterval'> {
  onError?: (error: ApolloError) => void;
  onComplete?: () => void;
}

export const useDownloadCodesLinkQuery = ({
  orderId,
  fetchPolicy,
  skip,
  pollInterval,
}: UseDownloadCodesLinkQueryInput) => {
  const { data, loading, stopPolling } = useQuery<DownloadCodesLinkQuery, DownloadCodesLinkQueryVariables>(
    DOWNLOAD_CODES_LINK_QUERY,
    {
      skip: !orderId || skip,
      fetchPolicy,
      pollInterval,
      variables: {
        orderId,
      },
    },
  );

  const link = data?.downloadCodesLink.link ?? '';

  useEffect(() => {
    if (pollInterval && link) stopPolling();
  }, [pollInterval, link]);

  return {
    link,
    loading,
  };
};

export const useLazyDownloadCodesLinkQuery = ({ fetchPolicy, skip }: UseLazyDownloadCodesLinkQueryInput) => {
  const [query, { data, loading }] = useLazyQuery<DownloadCodesLinkQuery, DownloadCodesLinkQueryVariables>(
    DOWNLOAD_CODES_LINK_QUERY,
    { fetchPolicy },
  );

  const link = data?.downloadCodesLink.link ?? '';

  const getCodesLink = async ({ orderId, onComplete, onError }: GetCodesLinkInput): Promise<Maybe<string>> => {
    if (skip) return null;

    const { data: linkData } = await query({
      variables: {
        orderId,
      },
      onError(err) {
        if (!onError) throw err;

        onError(err as ApolloError);
      },
    });

    onComplete?.();

    return linkData?.downloadCodesLink.link ?? '';
  };

  return {
    getCodesLink,
    link,
    loading,
  };
};

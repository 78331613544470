import React from 'react';
import { PlanInterface } from '../../interfaces';
import PlansListItem from './PlansListItem';

interface PlansListProps {
  ownerId: string;
  plans: PlanInterface[];
  planPath: (ownerId: string, planId: string) => string;
  planGrouper?: (plans: PlanInterface[]) => Record<string, PlanInterface[]>;
}

const PLANS_GROUP_ORDER = ['common', 'needs_attention', 'pending', 'active', 'inactive'];

const PLAN_GROUP_LABELS = {
  // eslint-disable-next-line camelcase
  needs_attention: 'Need Attention',
  pending: 'Pending',
  active: 'Active Plans',
  inactive: 'Inactive Plans',
  common: 'Product Plans',
};

const PlansList: React.FC<PlansListProps> = ({ ownerId, plans, planPath, planGrouper }) => {
  const renderPlans = (planItems: PlanInterface[]) => (
    <>
      {planItems.map((plan) => (
        <PlansListItem key={plan.id} plan={plan} ownerId={ownerId} planPath={planPath} />
      ))}
    </>
  );

  if (planGrouper) {
    const planGroups = planGrouper(plans);

    return (
      <>
        {PLANS_GROUP_ORDER.map((groupKey) => {
          const group = planGroups?.[groupKey as keyof typeof planGroups] ?? [];
          const planGroupLabel = PLAN_GROUP_LABELS[groupKey as keyof typeof PLAN_GROUP_LABELS];

          if (group.length) {
            return (
              <div className="mb-32" key={groupKey}>
                <h3 className="mb-16">{planGroupLabel}</h3>
                {renderPlans(group)}
              </div>
            );
          }

          return null;
        })}
      </>
    );
  }

  return renderPlans(plans);
};

export default PlansList;

import { Step, Stepper, useModals } from '~/ui';
import { GiveOrganizationBetaPlanModalStep, Modals } from '../../constants';
import { GiveOrganizationBetaPlanError, GiveOrganizationBetaPlanForm, GiveOrganizationBetaPlanSuccess } from './Steps';

interface GiveOrganizationBetaPlanModalProps {
  organizationId: string;
  email: string;
}

const GiveOrganizationBetaPlanModal: React.FC<GiveOrganizationBetaPlanModalProps> = ({ organizationId, email }) => {
  const { closeModal } = useModals();

  const handleCancel = () => closeModal(Modals.GiveOrganizationBetaPlanModal);

  return (
    <Stepper initialStep={GiveOrganizationBetaPlanModalStep.Form}>
      <Step stepKey={GiveOrganizationBetaPlanModalStep.Form}>
        <GiveOrganizationBetaPlanForm onCancel={handleCancel} organizationId={organizationId} />
      </Step>
      <Step stepKey={GiveOrganizationBetaPlanModalStep.Success}>
        <GiveOrganizationBetaPlanSuccess onSubmit={handleCancel} email={email} />
      </Step>
      <Step stepKey={GiveOrganizationBetaPlanModalStep.Error}>
        <GiveOrganizationBetaPlanError onSubmit={handleCancel} />
      </Step>
    </Stepper>
  );
};

export default GiveOrganizationBetaPlanModal;

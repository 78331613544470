import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { Button, FormRow, Panel, notify } from '@macpaw/macpaw-ui';
import styles from '~/components/Auth/Auth.module.sass';
import {
  ERROR_CODE_TO_COPY,
  GENERAL_ERROR,
  INVITE_TOKEN_NOT_FOUND_ERROR,
  SIGN_UP_PASSWORD_BLANK_SPACE_ERROR,
  SIGN_UP_PASSWORD_LENGTH_ERROR,
  ROUTE_PATHS,
  UserInvitationInputType,
  ERROR_COPY,
} from '~/constants';
import { SIGN_UP_MUTATION } from '~/graphql/mutations';
import sentry from '~/helpers/sentry';
import LockIcon from '~/images/icons/lock.png';
import { UserInvitationSchema } from '~/schemas';
import { NotificationType } from '~/types';
import { Form, FormPassword } from '~/ui';
import { emailTrim, useQueryParams } from '~/utils';
import Error404 from '../Error/Error404';

const UserInvitationPage: React.FC = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const formRef = useRef<UseFormReturn>(null);

  const email = queryParams.get('email');
  const inviteToken = queryParams.get('token');

  const [signUp, { loading }] = useMutation(SIGN_UP_MUTATION, {
    onCompleted() {
      navigate(ROUTE_PATHS.signUpByEmail(encodeURIComponent(email!)));
    },
    onError(error: ApolloError) {
      if (Array.isArray(error.graphQLErrors)) {
        error.graphQLErrors.forEach((err) => {
          const errCode = err.extensions?.response.body.errors[0].code;

          switch (errCode) {
            case INVITE_TOKEN_NOT_FOUND_ERROR:
              notify(ERROR_COPY.tokenInvalidTryLogin, NotificationType.Error);
              navigate(ROUTE_PATHS.login());
              break;
            case SIGN_UP_PASSWORD_LENGTH_ERROR:
              formRef.current?.setError(UserInvitationInputType.Password, { message: ERROR_CODE_TO_COPY[errCode] });
              break;
            case SIGN_UP_PASSWORD_BLANK_SPACE_ERROR:
              formRef.current?.setError(UserInvitationInputType.Password, { message: ERROR_CODE_TO_COPY[errCode] });
              break;
            default:
              notify(ERROR_CODE_TO_COPY[GENERAL_ERROR], NotificationType.Error);
              break;
          }
        });
      } else {
        sentry.captureException(error);
      }
    },
  });

  if (!email || !inviteToken) return <Error404 />;

  const handleSubmit = ({ [UserInvitationInputType.Password]: password }: ObjectLiteral) => {
    signUp({
      variables: {
        email,
        password,
        inviteToken,
      },
    });
  };

  return (
    <Panel className={styles.panel}>
      <div className={styles.logo}>
        <img src={LockIcon} width={128} height={128} alt="" />
      </div>
      <div className={styles.form}>
        <div className="h3 mb-8">Create a password</div>
        <p className="mb-32">
          You’re creating an account for <span className={styles.emailWrapper}>{emailTrim(email)}</span>
        </p>
        <Form
          initialValues={{
            [UserInvitationInputType.Password]: '',
            [UserInvitationInputType.ConfirmPassword]: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={UserInvitationSchema}
          validateMode="onBlur">
          <FormRow>
            <FormPassword
              name={UserInvitationInputType.Password}
              placeholder="Enter your password"
              withToggle
              autoFocus
            />
          </FormRow>
          <FormRow>
            <FormPassword name={UserInvitationInputType.ConfirmPassword} placeholder="Reenter your password" />
          </FormRow>
          <Button color="secondary" type="submit" wide loading={loading}>
            Create Account
          </Button>
        </Form>
      </div>
    </Panel>
  );
};

export default UserInvitationPage;

import React from 'react';
import cx from 'clsx';
import { Panel } from '@macpaw/macpaw-ui';
import Section from '../Section/Section';
import styles from './PanelEmptyState.module.sass';

interface PanelEmptyStateProps {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  description?: string;
}

const PanelEmptyState: React.FC<PanelEmptyStateProps> = ({ icon, title, subtitle, description }) => {
  const subtitleClassNames = cx('p3', {
    'mb-4': !!description,
  });

  return (
    <Section title={title}>
      <Panel outline className={styles.empty}>
        <span className={styles.emptyIcon}>{icon}</span>
        <div>
          <div className={subtitleClassNames}>
            <b>{subtitle}</b>
          </div>
          {description && <div className="">{description}</div>}
        </div>
      </Panel>
    </Section>
  );
};

export default PanelEmptyState;

import { gql } from '@apollo/client';
import { BaseCustomerSchema } from '~/types';

export const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    ${BaseCustomerSchema}
    createdAt
    source
    isCompleted
    roles
  }
`;

import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { BasePlanView } from '~/types';
import { isOneTime } from '~/utils';
import { CustomerRefundInputTypes, RefundTypes } from '../../constants';
import {
  useBlockCustomerPlanMutation,
  useCancelCustomerPlanMutation,
  useFullRefundMutation,
  usePartialRefundMutation,
} from '../../graphql/mutations';

interface UsePlanRefundInput {
  refundType: RefundTypes;
  customerPlanView: BasePlanView;
  onComplete?: () => void | Promise<void>;
  onError?: (error: GraphQLError) => void;
}

const usePlanRefund = ({ refundType, customerPlanView, onComplete, onError }: UsePlanRefundInput) => {
  const { getValues } = useFormContext();
  const { blockPlan, loading: isBlockPlanLoading } = useBlockCustomerPlanMutation();
  const { cancelPlan, loading: isCancelPlanLoading } = useCancelCustomerPlanMutation();
  const { refundFull, loading: isFullRefundLoading } = useFullRefundMutation();
  const { refundPartial, loading: isPartialRefundLoading } = usePartialRefundMutation();

  const isLoading = useMemo(() => {
    const isRestActionsLoading = isCancelPlanLoading || isBlockPlanLoading;

    if (refundType === RefundTypes.Full) {
      return isFullRefundLoading || isRestActionsLoading;
    }

    return isPartialRefundLoading || isRestActionsLoading;
  }, [refundType, isBlockPlanLoading, isCancelPlanLoading, isFullRefundLoading, isPartialRefundLoading]);

  const {
    [CustomerRefundInputTypes.Reason]: reason,
    [CustomerRefundInputTypes.TransactionId]: transactionId,
    [CustomerRefundInputTypes.CancelChecked]: cancelChecked,
    [CustomerRefundInputTypes.BlockPlanChecked]: blockPlanChecked,
    [CustomerRefundInputTypes.CurrentTransactionAmount]: currentTransactionAmount,
  } = getValues();

  const amount = parseFloat(currentTransactionAmount);

  const handlePlanRefundCompleted = async (customerPlanId: string) => {
    if (cancelChecked && !isOneTime(customerPlanView)) {
      await cancelPlan(customerPlanId);
    } else if (blockPlanChecked && isOneTime(customerPlanView)) {
      await blockPlan(customerPlanId);
    } else {
      onComplete?.();
    }
  };

  const refundPlan = async (customerPlanId: string) => {
    try {
      if (refundType === RefundTypes.Full) {
        await refundFull({
          transactionId,
          reason,
        });
      } else {
        await refundPartial({
          transactionId,
          amount,
          reason,
        });
      }

      await handlePlanRefundCompleted(customerPlanId);
      await onComplete?.();
    } catch (err) {
      if (onError) {
        onError?.(err as GraphQLError);

        return;
      }

      throw err;
    }
  };

  return {
    refundPlan,
    isLoading,
  };
};

export default usePlanRefund;

import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Loader } from '@macpaw/macpaw-ui';
import { Role } from '../../constants';
import { SUPPORT_PROFILE_QUERY } from '../../graphql/queries';
import { SupportProfileInterface } from '../../interfaces';
import { userIdVar, tokenVar } from '../../store';
import PrivateRouteElement from './PrivateRouteElement';

interface PrivateRoute extends React.PropsWithChildren {
  roles?: Role[];
  location: Partial<Location>;
}

const PrivateRoute: React.FC<PrivateRoute> = (props) => {
  const token = useReactiveVar(tokenVar);

  const { data, loading, error } = useQuery(SUPPORT_PROFILE_QUERY, { skip: !token });

  if (loading) return <Loader />;

  if (error) return null;

  if (data) {
    const { profile }: { profile: SupportProfileInterface } = data;

    userIdVar(profile.id);
  }

  return <PrivateRouteElement data={data} token={token} {...props} />;
};

export default PrivateRoute;

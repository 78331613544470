import { ApolloError, gql, useMutation } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { REFUND_COMPLETED } from '../../constants';

export const FULL_REFUND_MUTATION = gql`
  mutation fullRefund($transactionId: ID!, $reason: String) {
    fullRefund(transactionId: $transactionId, reason: $reason)
  }
`;

interface FullRefundMutationVariables {
  transactionId: string;
  reason?: string;
}

interface UseFullRefundMutationInput {
  onComplete?: () => void;
  onError?: (error?: ApolloError) => void;
}

interface UseFullRefundMutationOutput {
  refundFull: (variables: FullRefundMutationVariables) => Promise<void>;
  loading: boolean;
}

export const useFullRefundMutation = ({
  onComplete,
  onError,
}: UseFullRefundMutationInput = {}): UseFullRefundMutationOutput => {
  const [mutate, { loading }] = useMutation<void, FullRefundMutationVariables>(FULL_REFUND_MUTATION);

  const refundFull = async (variables: FullRefundMutationVariables) => {
    try {
      await mutate({ variables });

      notify(REFUND_COMPLETED, NotificationType.Success);
      onComplete?.();
    } catch (err) {
      onError?.(err as ApolloError);
    }
  };

  return {
    refundFull,
    loading,
  };
};

import * as React from 'react';
import { Tooltip } from '@macpaw/macpaw-ui';

interface GiveBetaPlanButtonProps {
  isBetaPlanUsed: boolean;
  loading: boolean;
  children?: React.ReactNode;
}

const GiveBetaPlanButtonLayout: React.FC<GiveBetaPlanButtonProps> = ({ isBetaPlanUsed, loading, children }) => {
  return isBetaPlanUsed && !loading ? (
    <Tooltip content="Beta plans can only be created once for every Organization." position="top">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default GiveBetaPlanButtonLayout;

import { gql, useLazyQuery } from '@apollo/client';
import { CUSTOMER_PLAN_FRAGMENT } from '../fragments';

const LICENSE_QUERY = gql`
  query LicenseQuery($search: String!) {
    license(search: $search) {
      ...CustomerPlanFragment
    }
  }
  ${CUSTOMER_PLAN_FRAGMENT}
`;

export const useLazyLicenseQuery = () => {
  const [query, { loading }] = useLazyQuery(LICENSE_QUERY);

  const getLicenseInfo = async ({ search }: { search: string }) => {
    const { data: planData } = await query({
      variables: {
        search,
      },
    });

    return planData;
  };

  return {
    getLicenseInfo,
    loading,
  };
};

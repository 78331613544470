import React, { ReactNode } from 'react';
import { Dropdown, DropdownItem, Button } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';

export interface ThreeDotsAction {
  id: string;
  type: string;
  title?: ReactNode;
  onAction?: () => void | Promise<void>;
}

interface ThreeDotsActions {
  actions: ThreeDotsAction[];
  disabled?: boolean;
}

// TODO: move ThreeDotsActions to macpaw-ui
const ThreeDotsActions: React.FC<ThreeDotsActions> = ({ actions = [], disabled }) => {
  const renderAction = ({ id, type, title = '', onAction }: ThreeDotsAction) => {
    switch (type) {
      case 'separator':
        return <DropdownItem key={id} separator />;
      case 'attention':
        return (
          <DropdownItem key={id} attention onClick={onAction}>
            {title}
          </DropdownItem>
        );
      default:
        return (
          <DropdownItem key={id} onClick={onAction}>
            {title}
          </DropdownItem>
        );
    }
  };

  if (!actions.length) return null;

  return (
    <Dropdown
      position="right"
      trigger={
        <Button icon color="transparent" disabled={disabled}>
          <MoreIcon />
        </Button>
      }>
      {actions.map(renderAction)}
    </Dropdown>
  );
};

export default ThreeDotsActions;

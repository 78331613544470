import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ApolloQueryResult } from '@apollo/client';
import { DialogContent, DialogIcon, DialogActions, Button, FormRow } from '@macpaw/macpaw-ui';
import { Form, FormInput, FormMultiselect, useModals } from '~/ui';
import { Modals, Role, RoleTitle } from '../../constants';
import { useSendMemberInvitationMutation } from '../../graphql/mutations';
import handleFormServerError from '../../helpers/handleFormServerError';
import InviteMemberIcon from '../../images/icons/invite-member.svg?react';
import { InviteTeamMemberForm } from '../../schemas';
import styles from './MembersInviteModal.module.sass';

enum InviteMemberFormField {
  Name = 'name',
  Email = 'email',
  Roles = 'roles',
}
interface MembersInviteModalProps {
  refetch: () => Promise<ApolloQueryResult<void>>;
  roles: Role[];
}

const MembersInviteModal: React.FC<MembersInviteModalProps> = ({ refetch, roles }) => {
  const formRef = useRef<UseFormReturn>(null);
  const { closeModal } = useModals();
  const getChosenRoles = () =>
    formRef.current?.watch(InviteMemberFormField.Roles)?.map(({ value }: { value: string }) => value) ?? [];

  const { sendMemberInvitation, loading } = useSendMemberInvitationMutation();

  const sendInvite = async (values: ObjectLiteral) => {
    try {
      await sendMemberInvitation({
        name: values.name,
        email: values.email,
        roles: getChosenRoles(),
      });

      refetch();

      closeModal(Modals.MembersInviteModal);
    } catch (error) {
      handleFormServerError(error as Error, formRef.current?.setError);
    }
  };

  return (
    <Form
      onSubmit={sendInvite}
      innerRef={formRef}
      validationSchema={InviteTeamMemberForm}
      initialValues={{
        [InviteMemberFormField.Name]: '',
        [InviteMemberFormField.Email]: '',
        [InviteMemberFormField.Roles]: [],
      }}>
      <DialogContent>
        <DialogIcon>
          <InviteMemberIcon width={160} height={160} />
        </DialogIcon>
        <h3>Invite a new team member</h3>
        <p className="mb-32">Mind telling me a bit about the new member?</p>
        <div className={styles.inviteFormWrapper}>
          <FormRow>
            <FormInput
              name={InviteMemberFormField.Name}
              autoFocus
              type="text"
              placeholder="What is this member’s name?"
              label="Name"
            />
          </FormRow>
          <FormRow>
            <FormInput
              type="email"
              name={InviteMemberFormField.Email}
              placeholder="What is this member’s work email address?"
              label="Email Address"
            />
          </FormRow>
          <FormRow>
            <FormMultiselect
              placeholder="Please select applicable roles…"
              label="Role"
              name={InviteMemberFormField.Roles}>
              {roles.map((currentRole: Role) => (
                <option key={currentRole} value={currentRole} selected={getChosenRoles().includes(currentRole)}>
                  {RoleTitle[currentRole]}
                </option>
              ))}
            </FormMultiselect>
          </FormRow>
        </div>
      </DialogContent>
      <DialogActions>
        <Button loading={loading} type="submit" color="secondary">
          Add Team Member
        </Button>
      </DialogActions>
    </Form>
  );
};

export default MembersInviteModal;

import { gql } from '@apollo/client';

export const CUSTOMER_ORGANIZATION_INFO_QUERY = gql`
  query customerOrganizationInfo($customerId: ID!) {
    customerOrganizationInfo(customerId: $customerId) {
      organization {
        id
        name
      }
      owner {
        customerId
        role
      }
    }
  }
`;

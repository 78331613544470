import React, { useEffect } from 'react';
import { Input } from '@macpaw/macpaw-ui';
import type { InputProps } from '@macpaw/macpaw-ui/lib/Input/Input';
import { usePrevious } from '~/utils';
import { useFormInputChangeHandler, useFormError, useFormField } from './Form.hooks';
import { BaseFormElementProps } from './Form.types';
import { useFormContext } from './FormContext';

interface FormInput extends BaseFormElementProps, Omit<InputProps, 'name'>, React.RefAttributes<HTMLInputElement> {
  formatValue?: (value: string | number) => string | number;
}

const FormInput: React.FC<FormInput> = ({ name, validateOnChange, formatValue, formatErrorMessage, ...props }) => {
  const { trans } = useFormContext();
  const {
    field: { onBlur, value = '', ref },
    clearErrors,
  } = useFormField<string>(name);
  const { previous: prevValue } = usePrevious(value);

  const { isError, message: errorMessage } = useFormError(name, {
    formatValue: formatErrorMessage ?? trans,
  });

  const handleChange = useFormInputChangeHandler(name, { formatValue, validateOnChange });

  const handleCleanupErrors = () => {
    if (!validateOnChange && isError) clearErrors();
  };

  useEffect(() => {
    if (prevValue && prevValue !== value) handleCleanupErrors();
  }, [value]);

  return (
    <Input
      value={value}
      name={name}
      error={errorMessage as string}
      ref={ref}
      onChange={handleChange as InputProps['onChange']}
      onBlur={onBlur}
      {...props}
    />
  );
};

export default FormInput;

import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Panel } from '@macpaw/macpaw-ui';
import { MemberStatus } from '~/constants';
import { SupportProfileInterface } from '~/interfaces';
import { BasePagination } from '~/types';
import { Pagination } from '~/ui';
import Member from './Member';
import styles from './Members.module.sass';

interface MembersList {
  members: [SupportProfileInterface];
  pagination: BasePagination;
  myProfile: SupportProfileInterface;
  refetch: () => Promise<ApolloQueryResult<void>>;
}

const MembersList: React.FC<MembersList> = ({ members, pagination, myProfile, refetch }) => {
  const activeMembers = members.filter(
    (member: SupportProfileInterface) => member.status === MemberStatus.Active || member.status === MemberStatus.Invite,
  );
  const inactiveMembers = members.filter((member: SupportProfileInterface) => {
    return member.status === MemberStatus.Deactivated || member.status === MemberStatus.Inactive;
  });

  return (
    <>
      <div className={styles.table}>
        <div className={styles.tableHead}>
          <div className={styles.tableRow}>
            <div className={styles.tableHeadName}>Name</div>
            <div className={styles.tableHeadEmail}>Email</div>
            <div className={styles.tableHeadRole}>Roles</div>
            <div className={styles.tableHeadStatus}>Status</div>
          </div>
        </div>
        {activeMembers.length > 0 && (
          <Panel className={styles.activeList}>
            {activeMembers.map((member: SupportProfileInterface) => {
              return <Member key={member.id} member={member} myId={myProfile.id} refetch={refetch} />;
            })}
          </Panel>
        )}
        {inactiveMembers.length > 0 && (
          <Panel outline className={styles.inactiveList}>
            {inactiveMembers.map((member: SupportProfileInterface) => {
              return <Member inactive key={member.id} member={member} myId={myProfile.id} refetch={refetch} />;
            })}
          </Panel>
        )}
      </div>
      <div className={styles.pagination_wrapper}>
        <Pagination pagination={pagination} />
      </div>
    </>
  );
};

export default MembersList;

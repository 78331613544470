import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { Button } from '@macpaw/macpaw-ui';
import { BasePlanView, ConfirmationType, GQLServerError } from '~/types';
import { StepItemProps, ConfirmationModal } from '~/ui';
import { getGQLServerErrors } from '~/utils';
import { CustomerRefundInputTypes, RefundSteps, RefundTypes } from '../../../constants';
import { usePlanRefund } from '../../../helpers/hooks';
import styles from './PlanRefundConfirmationStep.module.sass';

interface PlanRefundConfirmationStep extends Partial<StepItemProps> {
  type: RefundTypes;
  customerPlanId: string;
  customerPlanView: BasePlanView;
  onComplete?: () => void;
}

const GO_BACK_ERROR_CODES = [
  'refund.amount.notValid',
  'refund.transaction.notRefundable',
  'transaction.hasEmptyExternalOrderId',
];

const PlanRefundConfirmationStep: React.FC<PlanRefundConfirmationStep> = ({
  type,
  customerPlanId,
  customerPlanView,
  onComplete,
  ...props
}) => {
  const { setStep } = props as StepItemProps;
  const { getValues, setError } = useFormContext();
  const { refundPlan, isLoading } = usePlanRefund({
    refundType: type,
    customerPlanView,
  });

  const {
    [CustomerRefundInputTypes.CurrentTransactionAmount]: currentTransactionAmount,
    [CustomerRefundInputTypes.MaximumTransactionAmount]: maximumTransactionAmount,
    [CustomerRefundInputTypes.Currency]: currency,
  } = getValues();

  const amount = type === RefundTypes.Full ? maximumTransactionAmount : currentTransactionAmount;
  const title = `Proceed with ${type} refund?`;
  const description = `You are about to refund a total of ${amount} ${currency}. This action cannot be undone later.`;

  const handleGoBack = () => setStep(RefundSteps.Form);

  const handlePlanRefund = async () => {
    try {
      await refundPlan(customerPlanId);
      setStep(RefundSteps.Success);
      onComplete?.();
    } catch (err) {
      const serverErrors = getGQLServerErrors(err as GraphQLError);
      const shouldGoBack = serverErrors.some((error: GQLServerError) => GO_BACK_ERROR_CODES.includes(error.code));
      const hasNotValidAmountCode = serverErrors.some(
        (error: GQLServerError) => error.code === 'refund.amount.notValid',
      );

      if (shouldGoBack) setStep(RefundSteps.Form);
      if (hasNotValidAmountCode) {
        setError(CustomerRefundInputTypes.CurrentTransactionAmount, {
          message: 'The requested partial refund amount is too big.',
        });
      }
    }
  };

  return (
    <ConfirmationModal
      type={ConfirmationType.Warning}
      title={title}
      description={description}
      descriptionClassName={styles.description}
      actions={
        <>
          <Button color="primary" outline onClick={handleGoBack}>
            Back
          </Button>
          <Button color="secondary" onClick={handlePlanRefund} loading={isLoading}>
            Confirm Refund
          </Button>
        </>
      }
    />
  );
};

export default PlanRefundConfirmationStep;

import React from 'react';
import AccountTypeSwitcher from '../../components/AccountTypeSwitcher/AccountTypeSwitcher';
import CustomersList from '../../components/CustomersList/CustomersList';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';

const HomeSupportManager: React.FC = () => (
  <WithErrorBoundary>
    <AccountTypeSwitcher />
    <CustomersList />
  </WithErrorBoundary>
);

export default HomeSupportManager;

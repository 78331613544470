import React from 'react';
import { SuccessModal } from '~/ui';

interface RemoveBetaPlanSuccessProps {
  onSubmit: () => void;
}

const RemoveBetaPlanSuccess: React.FC<RemoveBetaPlanSuccessProps> = ({ onSubmit }) => {
  return (
    <SuccessModal
      title="Beta plan removed"
      description="Devices linked to the plan were deactivated as well."
      onSubmit={onSubmit}
    />
  );
};

export default RemoveBetaPlanSuccess;

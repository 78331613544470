import React, { PropsWithChildren } from 'react';
import cx from 'clsx';
import styles from './TableCell.module.sass';

interface TableCellProps {
  className?: string;
}

const TableCell: React.FC<PropsWithChildren<TableCellProps>> = ({ children, className }) => {
  const tableCellClassNames = cx(styles.cell, className);

  return <div className={tableCellClassNames}>{children}</div>;
};

export default TableCell;

import React from 'react';
import { ExternalLink, GridCell, GridRow, Label } from '@macpaw/macpaw-ui';
import { ExternalIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { ActivationStatus } from '~/types';
import { BillingCycle, DynamicDate } from '~/ui';
import { conditionValue, formatDate } from '~/utils';
import PlanStatusLabel from '../../components/PlanStatusLabel/PlanStatusLabel';
import TextLoader from '../../components/TextLoader/TextLoader';
import { useOrganizationPlanSeatStatsQuery } from '../../graphql/queries';
import { getPaddleSubscriptionLink } from '../../helpers/getPaddleSubscriptionLink';
import { isTrial } from '../../helpers/plan';
import { OrderInterface, OrganizationPlanInterface } from '../../interfaces';
import styles from './CustomerPlan.module.sass';

interface OrganizationPlanGridContent {
  customerPlan: OrganizationPlanInterface;
  order?: OrderInterface;
}

const OrganizationPlanGridContent: React.FC<OrganizationPlanGridContent> = ({ customerPlan, order }) => {
  const paddleProductId = order && order.paddleProductId;
  const paddleSubscriptionId = order && order.paddleSubscriptionId;
  const paddleSubscriptionLink = paddleSubscriptionId ? getPaddleSubscriptionLink(paddleSubscriptionId) : null;
  const { productName } = customerPlan.planView;

  const { loading: seatStatsLoading, seatsCount, vacantSeatsCount } = useOrganizationPlanSeatStatsQuery(customerPlan);

  return (
    <>
      <GridRow>
        <GridCell type="primary">
          <Label>Product</Label>
          <b>{productName}</b>
        </GridCell>
        <GridCell type="primary">
          <Label>Plan Name</Label>
          {productName}
        </GridCell>
        <GridCell type="secondary">
          <Label>{isTrial(customerPlan) ? 'Trial Started' : 'Purchase Date'}</Label>
          {formatDate(customerPlan.startDate)}
        </GridCell>
        <GridCell type="secondary">
          <DynamicDate customerPlan={customerPlan} order={customerPlan.order} />
        </GridCell>
      </GridRow>

      <GridRow>
        <GridCell type="primary">
          <Label>Can Access</Label>
          {customerPlan.activationStatus === ActivationStatus.CanAccess ? 'Yes' : 'No'}
        </GridCell>
        <GridCell type="primary">
          <Label>Billing Cycle</Label>
          <BillingCycle customerPlan={customerPlan} />
        </GridCell>
        <GridCell type="secondary">
          <Label>Subscription ID</Label>
          {conditionValue(
            paddleSubscriptionLink && (
              <div className={styles.withInlineIcon}>
                {paddleSubscriptionId}
                <ExternalLink href={paddleSubscriptionLink!}>
                  <ExternalIcon className={styles.inlineIcon} />
                </ExternalLink>
              </div>
            ),
          )}
        </GridCell>
        <GridCell type="secondary">
          <Label>Product ID</Label>
          {conditionValue(paddleProductId)}
        </GridCell>
      </GridRow>

      <GridRow>
        <GridCell type="primary">
          <Label>Status</Label>
          <PlanStatusLabel plan={customerPlan} />
        </GridCell>
        <GridCell type="primary">
          <Label>Reason</Label>
          {conditionValue(
            customerPlan.reason && <span style={{ textTransform: 'capitalize' }}>{customerPlan.reason}</span>,
          )}
        </GridCell>
        <GridCell type="secondary">
          <Label>Vacant Device Slots</Label>
          {!seatStatsLoading ? (
            <>
              <b>{vacantSeatsCount}</b> of <b>{seatsCount}</b>
            </>
          ) : (
            <TextLoader />
          )}
        </GridCell>

        <GridCell type="secondary" />
      </GridRow>
    </>
  );
};

export default OrganizationPlanGridContent;

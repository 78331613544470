import React from 'react';
import { useQueryParams } from '~/utils';
import styles from './SearchEmptyState.module.sass';

const SearchEmptyState: React.FC = () => {
  const queryParams = useQueryParams();
  const search = queryParams.get('q') || '';

  return (
    <div className={styles.emptyState}>
      <h3>
        Sorry, no matches found
        <span role="img" aria-label="sadface">
          {' '}
          🙁
        </span>
      </h3>
      <p>Your search for &quot;{search}&quot; didn’t return any results.</p>
    </div>
  );
};

export default SearchEmptyState;

import { gql, useQuery } from '@apollo/client';
import { Maybe } from '~/types';
import { GQLResponse, CustomerInterface } from '../../interfaces';
import { CUSTOMER_FRAGMENT } from '../fragments';

export const CUSTOMER_QUERY = gql`
  query Customer($customerId: ID!) {
    customer(customerId: $customerId) {
      ...CustomerFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

interface UseCustomersQueryInput {
  customerId: string;
}

interface UseCustomersQuery {
  customer: Maybe<CustomerInterface>;
  loading: boolean;
}

export const useCustomerQuery = ({ customerId }: UseCustomersQueryInput): UseCustomersQuery => {
  const { data, loading } = useQuery<GQLResponse<'customer', CustomerInterface>, Record<'customerId', string>>(
    CUSTOMER_QUERY,
    {
      skip: !customerId,
      variables: {
        customerId,
      },
    },
  );

  return {
    customer: data?.customer,
    loading,
  };
};

export enum SetCustomPriceStep {
  Form = 'form',
  Confirm = 'confirm',
  Success = 'success',
  Reject = 'reject',
}

export enum SetCustomPriceFields {
  ProductTag = 'productTag',
  ProductPlanSkuId = 'productPlanSkuId',
  Price = 'price',
  DefaultPrice = 'defaultPrice',
  IsDefaultPrice = 'isDefaultPrice',
}

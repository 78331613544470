import { FetchPolicy, gql, useQuery } from '@apollo/client';
import { GQLResponse, PlanViewInterface } from '../../interfaces';

export const PLAN_VIEWS_QUERY = gql`
  query PlanViews($bySupport: Boolean, $isBusiness: Boolean) {
    planViews(bySupport: $bySupport, isBusiness: $isBusiness) {
      id
      vendorId
      productId
      productSlug
      productTag
      productName
      productPublicName
      productDescription
      productIconUrl
      productPriority
      productDownloadUrl
      productUrlSchemes
      productExternalAccountUrl
      productPlanId
      productPlanType
      productPlanSeatCount
      productPlanActivationPeriod
      productPlanGracePeriod
      productPlanName
      productPlanIconUrl
    }
  }
`;

interface PlanViewsQueryVariables {
  isBusiness?: boolean;
  bySupport?: boolean;
}

interface UsePlanViewsQueryInput extends PlanViewsQueryVariables {
  skip?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface UsePlanViewsQuery {
  loading: boolean;
  planViews: PlanViewInterface[];
}

export const usePlanViewsQuery = ({
  isBusiness,
  bySupport = false,
  skip,
  fetchPolicy,
}: UsePlanViewsQueryInput = {}): UsePlanViewsQuery => {
  const { data, loading } = useQuery<GQLResponse<'planViews', PlanViewInterface[]>, PlanViewsQueryVariables>(
    PLAN_VIEWS_QUERY,
    {
      skip,
      fetchPolicy,
      variables: {
        isBusiness,
        bySupport,
      },
    },
  );

  return {
    planViews: data?.planViews ?? [],
    loading,
  };
};

import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { UNPAUSE_CUSTOMER_PLAN_MUTATION } from '../../graphql/mutations';
import { CustomerPlanInterface, PlanInterface } from '../../interfaces';

interface UnpausePlanActionProps {
  customerPlan: PlanInterface;
  onStatusChanged: () => void;
}

export const UnpausePlanAction: React.FC<UnpausePlanActionProps> = ({ customerPlan, onStatusChanged }) => {
  const [applyUnpause, { loading: unpauseLoading }] = useMutation(UNPAUSE_CUSTOMER_PLAN_MUTATION, {
    variables: { customerPlanId: (customerPlan as CustomerPlanInterface)?.mainLicenceId || customerPlan.id },
    onCompleted() {
      notify(
        (customerPlan as CustomerPlanInterface)?.mainLicenceId
          ? 'Your plans have been successfully unpaused.'
          : 'Your plan has been successfully unpaused.',
        NotificationType.Success,
      );
      onStatusChanged();
    },
    onError() {
      // prevent catching error
    },
  });

  return (
    <Button color="secondary" scale="medium" onClick={() => applyUnpause()} loading={unpauseLoading}>
      Unpause
    </Button>
  );
};

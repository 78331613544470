import React from 'react';
import { FormSelect } from '~/ui';
import { getProductNameByProductId, getUniqueProductIds } from '../../helpers/planView';
import { PlanViewInterface } from '../../interfaces';

interface GivePlanProductSelectProps {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  planViews: PlanViewInterface[];
}

const GivePlanProductSelect: React.FC<GivePlanProductSelectProps> = ({
  name,
  label = 'Select Product',
  disabled,
  placeholder = 'Select Product',
  planViews,
}) => {
  const uniqueProductIds = getUniqueProductIds(planViews);

  return (
    <FormSelect name={name} label={label} validateOnChange disabled={disabled}>
      <option value="" disabled>
        {placeholder}
      </option>
      {uniqueProductIds.map((productId) => (
        <option key={productId} value={productId}>
          {getProductNameByProductId(productId, planViews)}
        </option>
      ))}
    </FormSelect>
  );
};

export default GivePlanProductSelect;

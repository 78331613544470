import { gql } from '@apollo/client';
import { BaseOwnerSchema, BaseSeatSchema } from '~/types';

export const SEATS_QUERY = gql`
  query Seats($customerPlanId: ID!) {
    seats(customerPlanId: $customerPlanId) {
      ${BaseSeatSchema}
      owner {
        ${BaseOwnerSchema}
      }
    }
  }
`;

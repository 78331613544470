import { useEffect, useState } from 'react';
import { DropdownItem, Tooltip } from '@macpaw/macpaw-ui';
import { CustomerPlanStatus, CustomerPlanType, Maybe } from '~/types';
import { useUpdateOrganizationDashboardMutation } from '../../graphql/mutations';
import { usePlansByOwnerIdQuery } from '../../graphql/queries';

interface OrganizationBetaStatusSwitcherProps {
  organizationId: Maybe<string>;
  isCmmBusinessDashboardBeta: Maybe<boolean>;
}

const OrganizationBetaStatusSwitcher: React.FC<OrganizationBetaStatusSwitcherProps> = ({
  organizationId,
  isCmmBusinessDashboardBeta,
}) => {
  const [isHaveActiveBetaPlan, setIsHaveActiveBetaPlan] = useState(false);
  const { addOrganizationToBeta, removeOrganizationFromBeta } = useUpdateOrganizationDashboardMutation();
  const { loading, plans } = usePlansByOwnerIdQuery({ ownerId: organizationId ?? '' });

  useEffect(() => {
    if (plans.length) {
      setIsHaveActiveBetaPlan(
        plans.some(
          ({ status, type }) => status === CustomerPlanStatus.Active && type === CustomerPlanType.CmmBusinessBeta,
        ),
      );
    }
  }, [plans]);

  const RemoveButtonLayout = ({ children }: React.PropsWithChildren) =>
    isHaveActiveBetaPlan ? (
      <Tooltip
        content="To remove this Organization from beta, you need to first cancel its active beta plan."
        position="top">
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    );

  return (
    <>
      {isCmmBusinessDashboardBeta ? (
        <RemoveButtonLayout>
          <DropdownItem
            key="removeOrganizationFromBeta"
            onClick={() => removeOrganizationFromBeta(organizationId as string)}
            disabled={loading || isHaveActiveBetaPlan}>
            Remove from Beta
          </DropdownItem>
        </RemoveButtonLayout>
      ) : (
        <DropdownItem key="addOrganizationToBeta" onClick={() => addOrganizationToBeta(organizationId as string)}>
          Add to Beta
        </DropdownItem>
      )}
    </>
  );
};

export default OrganizationBetaStatusSwitcher;

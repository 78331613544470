import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { Role } from '../../constants';
import handleFormMutationError from '../../helpers/handleFormMutationError';

export const SEND_MEMBER_INVITATION_MUTATION = gql`
  mutation SendMemberInvitation($name: String!, $email: String!, $roles: [Roles]!) {
    sendMemberInvitation(name: $name, email: $email, roles: $roles)
  }
`;

interface MemberInvitationMutationVariables {
  name: string;
  email: string;
  roles: Role[];
}

interface UseSendMemberInvitationMutationInput {
  onCompleted?: () => void;
  onError?: (error: GraphQLError) => void;
}

export const useSendMemberInvitationMutation = ({
  onCompleted,
  onError,
}: UseSendMemberInvitationMutationInput = {}) => {
  const [mutate, { loading }] = useMutation(SEND_MEMBER_INVITATION_MUTATION);

  const sendMemberInvitation = async (variables: MemberInvitationMutationVariables) => {
    try {
      await mutate({ variables });

      notify('Invitation was sent successfully', NotificationType.Success);

      onCompleted?.();
    } catch (err) {
      handleFormMutationError(err as ErrorResponse);
      if (!onError) throw err;
      onError?.(err as GraphQLError);
    }
  };

  return {
    sendMemberInvitation,
    loading,
  };
};

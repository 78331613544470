import React from 'react';
import { Input } from '@macpaw/macpaw-ui';
import { ConfirmationType, Tag } from '~/types';
import { ConfirmationModal } from '~/ui';
import { APP_ENVIRONMENT, BASENAME, EXTERNAL_PATHS } from '../../../constants';
import styles from './SetCustomPriceSuccess.module.sass';

interface SetCustomPriceSuccessProps {
  onSubmit: () => void;
}

const SetCustomPriceSuccess: React.FC<SetCustomPriceSuccessProps> = ({ onSubmit }) => {
  const linkBasename = BASENAME?.[APP_ENVIRONMENT as keyof typeof BASENAME] ?? BASENAME.develop;
  const copyLink = EXTERNAL_PATHS.organizationSignUp(linkBasename, Tag.CleanMyMacBusiness);

  return (
    <ConfirmationModal
      type={ConfirmationType.Success}
      title="Custom price is set!"
      description="The customer can now purchase a plan using the custom price. You can ask them to sign in and make the purchase!"
      descriptionClassName={styles.description}
      onSubmit={onSubmit}>
      <Input
        type="text"
        value={copyLink}
        label="Copy and share this sign-in link"
        onChange={() => {}}
        clipboard={<span className={styles.tooltip}>Copy to clipboard</span>}
      />
    </ConfirmationModal>
  );
};

export default SetCustomPriceSuccess;

export const ROUTE_PATHS = {
  home: () => '/',
  signup: () => '/signup',
  login: () => '/login',
  loginAsCustomerRedirect: (url = ':url', token: string, clientId: string) =>
    `${url}/login-as-customer?token=${token}&clientId=${clientId}`,
  loginAsCustomer: () => '/login/as-customer',
  signInAsOwner: (id = ':id') => `/login/as-customer?customerId=${id}`,
  signUpByEmail: (email: string) => `/login?email=${email}`,
  zendesk: () => '/zendesk',
  members: () => '/members',
  plans: () => '/plans',
  settings: () => '/settings',
  acceptInvitation: () => '/accept/invitation',
  organizations: () => '/organizations',
  licenses: () => '/licenses',
  organizationPage: (id = ':organizationId') => `/organizations/${id}`,
  organizationPlanPage: (id = ':organizationId', planId = ':planId') => `/organizations/${id}/plans/${planId}`,
  customerPage: (customerId = ':customerId') => `/${customerId}`,
  customerPlanPage: (customerId = ':customerId', planId = ':customerPlanId') => `/${customerId}/${planId}`,
};

export const EXTERNAL_PATHS = {
  zafSdk: () => 'https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js',
  customerAccount: (basename: string) => `https://my.${basename}`,
  organizationSignUp: (basename: string, productTag: string) => `https://my.${basename}/business/${productTag}`,
};

export const BASENAME = {
  develop: 'platform.com',
  production: 'macpaw.com',
};

import React from 'react';
import { Navigate } from 'react-router-dom';
import { Role, ROUTE_PATHS } from '../../constants';
import { isAdmin } from '../../helpers/permissions';
import { SupportProfileInterface } from '../../interfaces';
import AccessDenied from '../../pages/Error/AccessDenied';

interface PrivateRouteElementProps extends React.PropsWithChildren {
  roles?: Role[];
  location: Partial<Location>;
  data: { profile: SupportProfileInterface };
  token: string | null;
}

const getPrivateRouteElement = (
  profile: SupportProfileInterface,
  isRoleHasAccess: boolean,
  location: Partial<Location>,
) => {
  const userRoles = profile.roles;

  if (!userRoles.length) return <Navigate replace to={ROUTE_PATHS.home()} state={location} />;

  return null;
};

const PrivateRouteElement: React.FC<PrivateRouteElementProps> = ({ roles, children, location, data, token }) => {
  if (token) {
    const { profile } = data;
    const userRoles = profile.roles;

    const isRoleHasAccess = Boolean(
      isAdmin(profile) || (roles && roles.some((role: Role): boolean => userRoles.includes(role))),
    );

    const element = getPrivateRouteElement(profile, isRoleHasAccess, location);

    if (element) return element;

    if (isRoleHasAccess) return <>{children}</>;

    return <AccessDenied />;
  }

  return <Navigate replace to={ROUTE_PATHS.login()} state={location} />;
};

export default PrivateRouteElement;

import { Link } from 'react-router-dom';
import { Grid, GridCell, GridRow, Label, Panel } from '@macpaw/macpaw-ui';
import { DropdownIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { BillingCycle, DynamicDate } from '~/ui';
import { ROUTE_PATHS } from '../../constants';
import { CustomerPlanInterface, CustomerPlanRelatedPlan } from '../../interfaces';
import styles from './PlanRelatedPlansBlock.module.sass';

interface PlanRelatedPlansBlockItemProps extends CustomerPlanRelatedPlan {
  plan: CustomerPlanInterface;
}

const PlanRelatedPlansBlockItem: React.FC<PlanRelatedPlansBlockItemProps> = ({
  planId,
  ownerId,
  productPlanIconUrl,
  plan,
}) => (
  <div className="mb-16">
    <Link to={ROUTE_PATHS.customerPlanPage(ownerId, planId)}>
      <Panel>
        <Grid
          component="div"
          icon={<img src={productPlanIconUrl} alt={plan.planView.productName} />}
          action={<DropdownIcon className={styles.arrow} />}>
          <GridRow>
            <GridCell type="primary">
              <Label>Product</Label>
              <strong>{plan.planView.productName}</strong>
            </GridCell>
            <GridCell type="primary">
              <Label>Plan</Label>
              {plan.planView.productName}
            </GridCell>
            <GridCell type="primary">
              <Label>Billing Cycle</Label>
              <BillingCycle customerPlan={plan} />
            </GridCell>
            <GridCell type="primary">
              <DynamicDate customerPlan={plan} order={plan?.order} />
            </GridCell>
          </GridRow>
        </Grid>
      </Panel>
    </Link>
  </div>
);

export default PlanRelatedPlansBlockItem;

import { useCallback, useMemo } from 'react';
import { useController, useFormContext, FieldPath } from 'react-hook-form';
import { FormFieldName, FormFormatMessageFN } from './Form.types';

export const useFormField = <FormFieldType>(name: string) => {
  const { control, clearErrors, setValue, watch } = useFormContext();
  const { field, fieldState, formState } = useController<Record<string, FormFieldType>>({
    name: name as FieldPath<Record<string, FormFieldType>>,
    control,
  });

  const handleSetFormValue = (value: FormFieldType) => {
    setValue(name, value);
  };

  const handleClearErrors = () => {
    clearErrors(name);
  };

  return {
    field,
    fieldState,
    formState,
    control,
    watch,
    clearErrors: handleClearErrors,
    setValue: handleSetFormValue,
  };
};

interface UseFormErrorOptions {
  formatValue?: FormFormatMessageFN;
}

export const useFormError = <FormFieldType>(
  name: FormFieldName,
  { formatValue = (value) => value }: UseFormErrorOptions = {},
) => {
  const {
    fieldState: { error },
  } = useFormField<FormFieldType>(name);

  const message = useMemo(() => {
    if (!error || !error?.message) return null;

    return formatValue?.(error.message) ?? error.message;
  }, [error]);

  return {
    isError: !!error,
    message,
  };
};

interface UseFormChangeHandlerOptions<FormFieldType> {
  validateOnChange?: boolean;
  formatValue?: (value: FormFieldType) => FormFieldType;
}

export const useFormInputChangeHandler = <FormFieldType = string>(
  name: FormFieldName,
  { validateOnChange, formatValue }: UseFormChangeHandlerOptions<FormFieldType> = {},
) => {
  const {
    field: { onChange },
    clearErrors,
    setValue,
  } = useFormField(name);
  const { isError } = useFormError<FormFieldType>(name);

  const handleChange = useCallback(
    (value: FormFieldType, event?: React.ChangeEvent<HTMLInputElement>) => {
      if (!validateOnChange && isError) {
        clearErrors();
      }

      const nextValue = formatValue ? formatValue(value) : value;

      if (event) onChange(event);
      setValue(nextValue);
    },
    [name, isError],
  );

  return handleChange;
};

import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, Stepper, Step, StepperContextValueType, useModals } from '~/ui';
import { GivePlanStep, GivePlanFields, Modals } from '../../constants';
import { useGiveCustomerProductPlanMutation } from '../../graphql/mutations';
import { useCustomerQuery } from '../../graphql/queries';
import handleFormServerError from '../../helpers/handleFormServerError';
import { GivePersonalPlanValidationSchema } from '../../schemas';
import { GivePersonalPlanForm, GivePersonalPlanSuccess } from './steps';

interface GivePersonalPlanProps {
  customerId: string;
}

const GivePersonalPlanModal: React.FC<GivePersonalPlanProps> = ({ customerId }) => {
  const formRef = useRef<UseFormReturn<ObjectLiteral>>(null);
  const stepperRef = useRef<StepperContextValueType>(null);
  const { closeModal } = useModals();

  const { customer, loading } = useCustomerQuery({ customerId });
  const { giveProductPlan, loading: isSubmitting } = useGiveCustomerProductPlanMutation();

  const handleSubmit = async (values: Record<string, string>) => {
    const { current: stepper } = stepperRef;
    const { productPlanId = '', reason } = values;

    try {
      await giveProductPlan({ customerId: customer?.id ?? '', productPlanId, reason });
      stepper?.setNextStep();
    } catch (error) {
      handleFormServerError(error as Error, formRef.current?.setError);
    }
  };

  const handleCancel = () => closeModal(Modals.GivePersonalPlanModal);

  return (
    <Form
      initialValues={{
        [GivePlanFields.Product]: '',
        [GivePlanFields.ProductPlanId]: '',
        [GivePlanFields.Reason]: '',
      }}
      innerRef={formRef}
      validationSchema={GivePersonalPlanValidationSchema}
      onSubmit={handleSubmit}>
      <Stepper initialStep={GivePlanStep.Form} innerRef={stepperRef} updateAnyway>
        <Step stepKey={GivePlanStep.Form}>
          <GivePersonalPlanForm isLoading={loading} isSubmitting={isSubmitting} onCancel={handleCancel} />
        </Step>
        <Step stepKey={GivePlanStep.Success}>
          <GivePersonalPlanSuccess email={customer?.email ?? ''} onSubmit={handleCancel} />
        </Step>
      </Stepper>
    </Form>
  );
};

export default GivePersonalPlanModal;

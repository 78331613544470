import React from 'react';
import { GraphQLError } from 'graphql';
import { notify } from '@macpaw/macpaw-ui';
import { Maybe, NotificationType } from '~/types';
import { ConfirmationModal, useModals } from '~/ui';
import { getGQLServerErrors } from '~/utils';
import { ERROR_CODE_TO_COPY, Modals, SOMETHING_WENT_WRONG_TRY_AGAIN } from '../../constants';
import { useMemberDeactivateMutation } from '../../graphql/mutations';
import DeactivateMemberIcon from '../../images/icons/deactivate-member.svg?react';
import { SupportProfileInterface } from '../../interfaces';

interface MemberDeactivateProps {
  member: Maybe<SupportProfileInterface>;
  onComplete: () => Promise<void> | void;
}

const MemberDeactivateModal: React.FC<MemberDeactivateProps> = ({ member, onComplete }) => {
  const { closeModal } = useModals();
  const { deactivateMember, loading } = useMemberDeactivateMutation();

  const handleError = (gqlError: GraphQLError) => {
    const errors = getGQLServerErrors(gqlError);

    errors.forEach((error) => {
      notify(ERROR_CODE_TO_COPY[error.code as string] ?? SOMETHING_WENT_WRONG_TRY_AGAIN, NotificationType.Error);
    });

    if (!errors.length) notify(SOMETHING_WENT_WRONG_TRY_AGAIN, NotificationType.Error);
  };

  const handleSubmit = async () => {
    try {
      await deactivateMember({
        userId: member!.id,
      });

      closeModal(Modals.MemberDeactivate);

      await onComplete?.();
    } catch (error) {
      handleError(error as GraphQLError);
    }
  };

  return (
    <ConfirmationModal
      icon={<DeactivateMemberIcon width={160} height={160} />}
      title="Are you sure you want to do this?"
      description={
        <p>
          Once deactivated, your team member,{' '}
          <strong>
            {member!.name} ({member!.email})
          </strong>
        </p>
      }
      actionTitle="Deactivate"
      actionColor="warning"
      actionLoading={loading}
      onSubmit={handleSubmit}
    />
  );
};

export default MemberDeactivateModal;

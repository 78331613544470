import { gql } from '@apollo/client';

export const SIGN_UP_MUTATION = gql`
  mutation SignUp($email: String!, $password: String!, $inviteToken: String!) {
    signUp(email: $email, password: $password, inviteToken: $inviteToken)
  }
`;

export const SIGN_IN_PASSWORD_MUTATION = gql`
  mutation SignInPassword($email: String!, $password: String!) {
    signInPassword(email: $email, password: $password)
  }
`;

export const SIGN_IN_PIN_CODE_MUTATION = gql`
  mutation SignInPinCode($email: String!, $password: String!, $pinCode: String!) {
    signInPinCode(email: $email, password: $password, pinCode: $pinCode) {
      token
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($password: String!, $newPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword) {
      token
    }
  }
`;

export const SIGN_OUT_MUTATION = gql`
  mutation SignOut {
    signOut {
      signedOut
    }
  }
`;

import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Tag, TagList, Dropdown, DropdownItem, Button } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { useModals } from '~/ui';
import { conditionValue, emailTrim, nameTrim } from '~/utils';
import { Role, RoleTitle, MemberStatus, Modals } from '../../constants';
import UserIcon from '../../images/icons/user.svg?react';
import { SupportProfileInterface } from '../../interfaces';

import styles from './Members.module.sass';

interface Member {
  member: SupportProfileInterface;
  myId: string;
  inactive?: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

const Member: React.FC<Member> = ({ member, myId, inactive, refetch }) => {
  const { openModal } = useModals();

  const handleOpenModal = (modalName: Modals) => () => {
    openModal({
      name: modalName,
      props: {
        member,
        onComplete: refetch,
      },
    });
  };

  return (
    <>
      <div className={styles.tableRow} key={member.id}>
        <div className={styles.tableName}>
          <UserIcon className={styles.nameIcon} width={32} height={32} />
          {conditionValue(member.name && <strong>{nameTrim(member.name)}</strong>)}
          {myId === member.id && <span className={styles.you}>you</span>}
        </div>
        <div className={styles.tableEmail}>{emailTrim(member.email)}</div>
        <div className={styles.tableRole}>
          <TagList>
            {member.roles.map((role: Role) => (
              <Tag key={role}>{RoleTitle[role]}</Tag>
            ))}
          </TagList>
        </div>
        {/* TODO: ask backend to change status 'invite' to 'invited' */}
        <div className={styles.tableStatus}>{member.status === MemberStatus.Invite ? 'Invited' : member.status}</div>
        <div className={styles.tableArrow} data-qa={member.email}>
          <Dropdown
            trigger={
              <Button color="transparent" icon disabled={inactive}>
                <MoreIcon />
              </Button>
            }
            position="right">
            <DropdownItem onClick={handleOpenModal(Modals.MemberResendInviteModal)} data-qa="resend-invite-button">
              Resend Invitation
            </DropdownItem>
            <DropdownItem onClick={handleOpenModal(Modals.MemberEditModal)} data-qa="edit-member-button">
              Edit Member
            </DropdownItem>
            <DropdownItem separator />
            <DropdownItem onClick={handleOpenModal(Modals.MemberDeactivate)} data-qa="deactivate-button">
              Deactivate
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Member;

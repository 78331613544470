import React from 'react';
import { Route, Routes as Router, useLocation, Link } from 'react-router-dom';
import { RouterDomProvider } from '~/ui';
import BreadcrumbsProvider from '../components/BreadcrumbsProvider/BreadcrumbsProvider';
import LoginAsCustomerRedirect from '../components/LoginAsCustomerRedirect/LoginAsCustomerRedirect';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import Widget from '../components/Widget/Widget';
import { Role, ROUTE_PATHS } from '../constants';
import AuthPage from '../pages/Auth/AuthPage';
import UserInvitationPage from '../pages/Auth/UserInvitationPage';
import Customer from '../pages/Customer/Customer';
import CustomerPlan from '../pages/CustomerPlan/CustomerPlan';
import Error404 from '../pages/Error/Error404';
import HomeSupportManager from '../pages/Home/HomeSupportManager';
import Licenses from '../pages/Licenses/Licenses';
import Members from '../pages/Members/Members';
import Organization from '../pages/Organization/Organization';
import OrganizationPlan from '../pages/OrganizationPlan/OrganizationPlan';
import Organizations from '../pages/Organizations/Organizations';
import Settings from '../pages/Settings/Settings';

const Routes: React.FC = () => {
  const location = useLocation();

  return (
    <Router>
      <Route element={<RouterDomProvider linkComponent={Link} locationHook={useLocation} />}>
        <Route element={<BreadcrumbsProvider />}>
          <Route
            path={ROUTE_PATHS.loginAsCustomer()}
            element={
              <PrivateRoute roles={[Role.Support]} location={location}>
                <LoginAsCustomerRedirect />
              </PrivateRoute>
            }
          />
          <Route path={ROUTE_PATHS.login()} element={<AuthPage />} />
          <Route path={ROUTE_PATHS.acceptInvitation()} element={<UserInvitationPage />} />
          <Route
            path={ROUTE_PATHS.home()}
            element={
              <PrivateRoute roles={[Role.Support, Role.AffiliateManager]} location={location}>
                <HomeSupportManager />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.settings()}
            element={
              <PrivateRoute roles={[Role.Support, Role.AffiliateManager]} location={location}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.members()}
            element={
              <PrivateRoute location={location}>
                <Members />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.zendesk()}
            element={
              <PrivateRoute roles={[Role.Support]} location={location}>
                <Widget />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.organizations()}
            element={
              <PrivateRoute roles={[Role.Support, Role.AffiliateManager]} location={location}>
                <Organizations />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.licenses()}
            element={
              <PrivateRoute roles={[Role.Support, Role.AffiliateManager]} location={location}>
                <Licenses />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.organizationPage()}
            element={
              <PrivateRoute roles={[Role.Support, Role.AffiliateManager]} location={location}>
                <Organization />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.organizationPlanPage()}
            element={
              <PrivateRoute roles={[Role.Support]} location={location}>
                <OrganizationPlan />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.customerPage()}
            element={
              <PrivateRoute roles={[Role.Support]} location={location}>
                <Customer />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.customerPlanPage()}
            element={
              <PrivateRoute roles={[Role.Support]} location={location}>
                <CustomerPlan />
              </PrivateRoute>
            }
          />
          <Route element={<Error404 />} />
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;

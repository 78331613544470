import React from 'react';
import { Step, Stepper, useModals } from '~/ui';
import { Modals } from '../../constants';
import { RemoveBetaPlanSteps } from './constants';
import { RemoveBetaPlanConfirm, RemoveBetaPlanError, RemoveBetaPlanSuccess } from './steps';

interface RemoveBetaPlanModalProps {
  planId: string;
  ownerId: string;
  organizationId: string;
  onSuccess: () => void;
}

const RemoveBetaPlanModal: React.FC<RemoveBetaPlanModalProps> = ({ planId, ownerId, organizationId, onSuccess }) => {
  const { closeModal } = useModals();

  const handleCancel = () => {
    closeModal(Modals.RemoveBetaPlanModal);
  };

  const handleSuccess = () => {
    handleCancel();
    onSuccess();
  };

  return (
    <Stepper initialStep={RemoveBetaPlanSteps.Confirm}>
      <Step stepKey={RemoveBetaPlanSteps.Confirm}>
        <RemoveBetaPlanConfirm
          onCancel={handleCancel}
          planId={planId}
          ownerId={ownerId}
          organizationId={organizationId}
        />
      </Step>
      <Step stepKey={RemoveBetaPlanSteps.Success}>
        <RemoveBetaPlanSuccess onSubmit={handleSuccess} />
      </Step>
      <Step stepKey={RemoveBetaPlanSteps.Error}>
        <RemoveBetaPlanError onCancel={handleCancel} />
      </Step>
    </Stepper>
  );
};

export default RemoveBetaPlanModal;

import { gql, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';

export const EDIT_MEMBER_MUTATION = gql`
  mutation EditMember($userId: ID!, $name: String!, $email: String!, $roles: [Roles]!) {
    editMember(userId: $userId, name: $name, email: $email, roles: $roles)
  }
`;

interface EditMemberMutationVariables {
  userId: string;
  name: string;
  email: string;
  roles: string[];
}

interface UseEditMemberMutationInput {
  onComplete?: () => Promise<void> | void;
  onError?: (error: GraphQLError) => void;
}

export const useEditMemberMutation = ({ onComplete, onError }: UseEditMemberMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<void, EditMemberMutationVariables>(EDIT_MEMBER_MUTATION);

  const editMember = async (variables: EditMemberMutationVariables) => {
    try {
      await mutate({ variables });
      await onComplete?.();

      notify('Member information was successfully updated', NotificationType.Success);
    } catch (err) {
      if (!onError) throw err;

      onError(err as GraphQLError);
    }
  };

  return {
    editMember,
    loading,
  };
};

import { gql } from '@apollo/client';
import { BaseCustomerPlanSchema, BaseOwnerSchema, BasePlanViewSchema } from '~/types';
import { ORDER_FRAGMENT } from './OrderFragments';

export const PLAN_VIEW_FRAGMENT = gql`
  fragment PlanViewFragment on PlanView {
    ${BasePlanViewSchema}
  }
`;

export const PLAN_OWNER_FRAGMENT = gql`
  fragment PlanOwnerFragment on PlanOwner {
    ... on Owner {
        ${BaseOwnerSchema}
    }
    ... on OrganizationBase {
      id
      name
    }
  }
`;

export const CUSTOMER_PLAN_RELATED_PLANS_FRAGMENT = gql`
  fragment CustomerPlanRelatedPlansFragment on RelatedPlans {
    planType
    plans {
      planId
      ownerId
      productPublicName
      productPlanIconUrl
      productPlanName
    }
  }
`;

export const CUSTOMER_PLAN_FRAGMENT = gql`
  fragment CustomerPlanFragment on CustomerPlan {
    ${BaseCustomerPlanSchema}
    mainLicenceId
    ownerType
    planView {
      ...PlanViewFragment
    }
    owner {
      ...PlanOwnerFragment
    }
    order {
      ...OrderFragment
    }
    planLicense {
      id
      customerId
      productBundleId
      customerProductPlanId
      licenseKey
      firstActivatedAt
      isEnabled
      disabledAt
      additional
      campaign
    }
    relatedPlans {
      ...CustomerPlanRelatedPlansFragment
    }
  }
  ${ORDER_FRAGMENT}
  ${PLAN_VIEW_FRAGMENT}
  ${PLAN_OWNER_FRAGMENT}
  ${CUSTOMER_PLAN_RELATED_PLANS_FRAGMENT}
`;

export const ORGANIZATION_PLAN_FRAGMENT = gql`
  fragment OrganizationPlanFragment on OrganizationPlan {
    ${BaseCustomerPlanSchema}
    ownerType
    seatCount
    planView {
      ...PlanViewFragment
    }
    owner {
      ...PlanOwnerFragment
    }
    order {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
  ${PLAN_VIEW_FRAGMENT}
  ${PLAN_OWNER_FRAGMENT}
`;

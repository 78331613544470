import { formatDistanceToNowStrict, Locale as DateFnsLocaleType } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Locale } from '~/types';
import { MS_IN_DAY } from './constants';

export type DateDistanceUnit = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' | undefined;

export type OptionsType = {
  addSuffix?: boolean | undefined;
  unit?: DateDistanceUnit;
  roundingMethod?: 'floor' | 'ceil' | 'round' | undefined;
  locale?: globalThis.Locale | undefined;
};

export type LocaleModuleImportType = typeof import('date-fns/locale/en-US');

export const DATE_FNS_LOCALES: { [key in Locale]: () => Promise<LocaleModuleImportType> } = {
  [Locale.en]: () => import('date-fns/locale/en-US'),
  // historically we use zh code to identify Simplified Chinese zh-CN
  [Locale.zh]: () => import('date-fns/locale/zh-CN'),
  [Locale.de]: () => import('date-fns/locale/de'),
  [Locale.es]: () => import('date-fns/locale/es'),
  [Locale.fr]: () => import('date-fns/locale/fr'),
  [Locale.it]: () => import('date-fns/locale/it'),
  [Locale.ja]: () => import('date-fns/locale/ja'),
  [Locale.ko]: () => import('date-fns/locale/ko'),
  [Locale.nl]: () => import('date-fns/locale/nl'),
  // historically we use pt code to identify Brazilian Portuguese pt-BR
  [Locale.pt]: () => import('date-fns/locale/pt-BR'),
  [Locale.pl]: () => import('date-fns/locale/pl'),
  [Locale.uk]: () => import('date-fns/locale/uk'),
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const distanceToNow = async (
  days: number,
  locale: Locale = Locale.en,
  config?: {
    unit?: DateDistanceUnit;
    disableCapitalize?: boolean;
  },
): Promise<string> => {
  const localeModule = await DATE_FNS_LOCALES[locale]?.();
  const dateFnsLocale = localeModule?.default || enUS;

  const options: OptionsType = {
    roundingMethod: 'floor',
    locale: dateFnsLocale as DateFnsLocaleType,
  };

  if (config?.unit) {
    options.unit = config.unit;
  }

  let value = formatDistanceToNowStrict(new Date(new Date().getTime() + days * MS_IN_DAY), options);

  if (!config?.disableCapitalize) {
    value = value
      .split(' ')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ');
  }

  return value;
};

export default distanceToNow;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContent, DialogActions, Button, FormRow } from '@macpaw/macpaw-ui';
import { Loadable } from '~/ui';
import { GivePlanFields } from '../../../constants';
import { useDistributeReasonsQuery, usePlanViewsQuery } from '../../../graphql/queries';
import { GivePlanProductPlanSelect, GivePlanProductSelect, GivePlanReasonsSelect } from '../../GivePlanInputs';
import styles from './GivePersonalPlanForm.module.sass';

interface GivePlanForm {
  isSubmitting: boolean;
  isLoading: boolean;
  onCancel: () => void;
}

const GivePlanForm: React.FC<GivePlanForm> = ({ isLoading, isSubmitting, onCancel }) => {
  const {
    formState: { isDirty },
    watch,
  } = useFormContext();

  const { reasons, loading: isLoadingReasons } = useDistributeReasonsQuery();
  const { planViews, loading: isLoadingPlanViews } = usePlanViewsQuery({
    bySupport: true,
  });

  const selectedProductId = watch(GivePlanFields.Product);
  const isGeneralLoading = isLoading || isLoadingReasons || isLoadingPlanViews;
  const isDisabledSubmit = isGeneralLoading || !isDirty;

  return (
    <>
      <DialogContent>
        <h3 className="mb-24">Give Personal Plan</h3>
        <Loadable isLoading={isLoadingPlanViews} className={styles.loader}>
          <FormRow>
            <GivePlanProductSelect label="Select Product" name={GivePlanFields.Product} planViews={planViews} />
          </FormRow>
          <FormRow>
            <GivePlanProductPlanSelect
              label="Plan"
              name={GivePlanFields.ProductPlanId}
              planViews={planViews}
              currentProductId={selectedProductId}
              disabled={!selectedProductId}
            />
          </FormRow>
          <FormRow>
            <GivePlanReasonsSelect
              label="Reason"
              name={GivePlanFields.Reason}
              reasons={reasons}
              disabled={isLoadingReasons}
            />
          </FormRow>
        </Loadable>
      </DialogContent>
      <DialogActions>
        <Button outline disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" color="secondary" loading={isSubmitting} disabled={isDisabledSubmit}>
          Give Plan
        </Button>
      </DialogActions>
    </>
  );
};

export default GivePlanForm;

import React from 'react';
import { Step, Stepper, useModals } from '~/ui';
import { CustomerRemoveSteps, Modals } from '../../constants';
import { CustomerRemoveConfirm, CustomerRemoveSuccess } from './steps';

interface CustomerRemoveProps {
  email: string;
  customerId: string;
}

const CustomerRemoveModal: React.FC<CustomerRemoveProps> = ({ email, customerId }) => {
  const { closeModal } = useModals();

  const handleClose = () => {
    closeModal(Modals.CustomerRemoveModal);
  };

  return (
    <Stepper initialStep={CustomerRemoveSteps.Confirm}>
      <Step stepKey={CustomerRemoveSteps.Confirm}>
        <CustomerRemoveConfirm email={email} customerId={customerId} onCancel={handleClose} />
      </Step>
      <Step stepKey={CustomerRemoveSteps.Success}>
        <CustomerRemoveSuccess email={email} onSubmit={handleClose} />
      </Step>
    </Stepper>
  );
};

export default CustomerRemoveModal;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@macpaw/macpaw-ui';
import { ConfirmationType } from '~/types';
import { StepItemProps, ConfirmationModal } from '~/ui';
import { SetCustomPriceFields } from '../../../constants';
import { useSetOrganizationCustomSlotPriceMutation } from '../../../graphql/mutations';
import { useOrganizationSlotPriceQuery } from '../../../graphql/queries';
import styles from './SetCustomPriceConfirm.module.sass';

interface SetCustomPriceConfirmProps extends Partial<StepItemProps> {
  name: string;
  organizationId: string;
  onGoBack: () => void;
}

const SetCustomPriceConfirm: React.FC<SetCustomPriceConfirmProps> = ({ name, organizationId, onGoBack, ...props }) => {
  const { setNextStep } = props as StepItemProps;

  const { getValues } = useFormContext();
  const [productPlanSkuId, price] = getValues([SetCustomPriceFields.ProductPlanSkuId, SetCustomPriceFields.Price]);

  const { defaultPrice } = useOrganizationSlotPriceQuery({
    organizationId,
    productPlanSkuId,
    fetchPolicy: 'cache-only',
  });
  const { setOrganizationCustomSlotPrice, loading } = useSetOrganizationCustomSlotPriceMutation();

  const handleSubmit = async () => {
    if (Number(price) !== Number(defaultPrice)) {
      await setOrganizationCustomSlotPrice({ organizationId, productPlanSkuId, price: Number(price) });
    }
    setNextStep();
  };

  return (
    <ConfirmationModal
      title="Set a new permanent price for this plan?"
      type={ConfirmationType.Warning}
      descriptionClassName={styles.description}
      description={
        <>
          You are about to set a custom plan price for <strong>{name}</strong>. Custom price can only be set once, and
          you won’t be able to change it for this plan in the future.
        </>
      }
      actions={
        <>
          <Button outline onClick={onGoBack}>
            Back
          </Button>
          <Button color="secondary" onClick={handleSubmit} loading={loading}>
            Confirm Price Change
          </Button>
        </>
      }
    />
  );
};

export default SetCustomPriceConfirm;

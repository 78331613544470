import { gql } from '@apollo/client';

export const REDEEM_CODE_QUERY = gql`
  query RedeemCode($customerPlanId: ID!) {
    redeemCode(customerPlanId: $customerPlanId) {
      code
      status
      customerProductPlanId
    }
  }
`;

import { ApolloError, gql, useMutation } from '@apollo/client';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';

export const PARTIAL_REFUND_MUTATION = gql`
  mutation PartialRefund($transactionId: ID!, $amount: Float!, $reason: String) {
    partialRefund(transactionId: $transactionId, amount: $amount, reason: $reason)
  }
`;

interface PartialRefundMutationVariables {
  transactionId: string;
  amount: number;
  reason: string;
}

interface UsePartialRefundMutationInput {
  onComplete?: () => void;
  onError?: (error?: ApolloError) => void;
}

interface UsePartialRefundMutationOutput {
  refundPartial: (variables: PartialRefundMutationVariables) => Promise<void>;
  loading: boolean;
}

export const usePartialRefundMutation = ({
  onComplete,
  onError,
}: UsePartialRefundMutationInput = {}): UsePartialRefundMutationOutput => {
  const [mutate, { loading }] = useMutation(PARTIAL_REFUND_MUTATION);

  const refundPartial = async (variables: PartialRefundMutationVariables) => {
    try {
      await mutate({ variables });

      notify('Partial refund completed', NotificationType.Success);
      onComplete?.();
    } catch (err) {
      onError?.(err as ApolloError);
    }
  };

  return {
    refundPartial,
    loading,
  };
};

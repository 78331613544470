import React from 'react';
import {
  SuccessIcon,
  AttentionIcon,
  DownloadIcon,
  KeyIcon,
  AlertIcon,
  InfoIcon,
  SettingsIcon,
  WarningInfoIcon,
  DiscountIcon,
} from '~/images/assets';
import { ConfirmationModalType, ConfirmationType } from '~/types';

interface ConfirmationModalIcon {
  type: ConfirmationModalType;
}

const ICON_SIZE = {
  width: 128,
  height: 128,
};

const confirmationIcons = {
  [ConfirmationType.Warning]: AttentionIcon,
  [ConfirmationType.Success]: SuccessIcon,
  [ConfirmationType.Download]: DownloadIcon,
  [ConfirmationType.Key]: KeyIcon,
  [ConfirmationType.Settings]: SettingsIcon,
  [ConfirmationType.Info]: InfoIcon,
  [ConfirmationType.WarningInfo]: WarningInfoIcon,
  [ConfirmationType.Error]: AlertIcon,
  [ConfirmationType.Discount]: DiscountIcon,
};

const ConfirmationModalIcon: React.FC<ConfirmationModalIcon> = ({ type }) => {
  const Component = confirmationIcons[type] as unknown as React.ElementType;

  if (Component) {
    return <Component {...ICON_SIZE} />;
  }

  return null;
};

export default ConfirmationModalIcon;

import { gql, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';

export const CANCEL_CUSTOMER_PLAN_MUTATION = gql`
  mutation CancelCustomerPlan($customerPlanId: ID!) {
    cancelCustomerPlan(customerPlanId: $customerPlanId)
  }
`;

interface UseCancelCustomerPlanMutationInput {
  onComplete?: () => void;
  onError?: (error: GraphQLError) => void;
}

export const useCancelCustomerPlanMutation = ({ onComplete, onError }: UseCancelCustomerPlanMutationInput = {}) => {
  const [mutate, { loading }] = useMutation<Record<'customerPlanId', string>>(CANCEL_CUSTOMER_PLAN_MUTATION);

  const cancelPlan = async (customerPlanId: string) => {
    try {
      await mutate({
        variables: {
          customerPlanId,
        },
      });
      notify('Plan has been successfully cancelled', NotificationType.Success);
      onComplete?.();
    } catch (err) {
      if (!onError) throw err;

      onError(err as GraphQLError);
    }
  };

  return {
    cancelPlan,
    loading,
  };
};

import React, { LazyExoticComponent, Suspense } from 'react';
import cx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog } from '@macpaw/macpaw-ui';
import { Maybe } from '~/types';
import styles from './ModalLauncher.module.sass';
import { useModalLauncherContext } from './ModalLauncherContext';
import { ModalItem } from './types';

type ModalBodyComponent =
  | Maybe<React.ComponentType<ObjectLiteral>>
  | LazyExoticComponent<React.ComponentType<ObjectLiteral>>;

const ModalLauncher = () => {
  const { modals, modalComponents, closeModal, ModalFallback } = useModalLauncherContext();

  return (
    <>
      {!isEmpty(modals) &&
        modals.map((modal: ModalItem) => {
          if (!modal.name) {
            throw new Error('ModalLauncher: Modal object should have "name" property');
          }

          const ModalBodyComponent = modalComponents?.[
            modal.name as keyof typeof modalComponents
          ] as ModalBodyComponent;

          const modalFallbackClassName = cx(styles.container, modal?.className);
          const modalClassNames = cx(modalFallbackClassName, {
            [styles?.[`layout-${modal?.layout}`]]: modal?.layout,
          });

          const handleClose = () => {
            if (modal?.onCloseModal) modal?.onCloseModal();
            closeModal(modal.name!);
          };

          const modalProps = {
            isOpen: true,
            onRequestClose: handleClose,
            style: modal?.style,
          };

          if (!ModalBodyComponent) return null;

          return (
            <Suspense
              key={modal.name}
              fallback={
                <Dialog {...modalProps} className={modalFallbackClassName}>
                  {ModalFallback ? <ModalFallback /> : ''}
                </Dialog>
              }>
              <Dialog {...modalProps} className={modalClassNames}>
                <ModalBodyComponent {...modal.props} />
              </Dialog>
            </Suspense>
          );
        })}
    </>
  );
};

export default ModalLauncher;

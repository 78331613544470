import React from 'react';
import { Banner } from '@macpaw/macpaw-ui';
import { isOrderInProgress, untilDeactivation } from '~/utils';
import { getIsSagaInProgress, isTrial } from '../../helpers/plan';
import { CustomerPlanInterface } from '../../interfaces';
import PlanNotification from '../PlanNotification/PlanNotification';
import Refresh from '../Refresh/Refresh';
import styles from './PlanNotifications.module.sass';

interface PlanNotificationsProps {
  plan: CustomerPlanInterface;
  isRefreshing: boolean;
  refresh: () => void;
}

const PlanNotifications: React.FC<PlanNotificationsProps> = ({ plan, isRefreshing, refresh }) => {
  const orderInProgress = isOrderInProgress(plan.order);
  const isSagaInProgress = getIsSagaInProgress(plan);
  const notifications = [];

  if (isSagaInProgress || orderInProgress) {
    notifications.push(<Refresh isFetching={isRefreshing} onClick={refresh} />);
  }

  if (isTrial(plan)) {
    const { productName } = plan.planView;

    notifications.push(
      <Banner type="secondary">
        <b>This plan is currently in trial status.</b> You have {untilDeactivation(plan)} days left in your free{' '}
        {productName} trial.
      </Banner>,
    );
  }

  notifications.push(<PlanNotification detailed customerPlan={plan} onStatusChanged={refresh} />);

  return (
    <>
      {notifications.map((n, i) => (
        <div className={styles.notification} key={i}>
          {n}
        </div>
      ))}
    </>
  );
};

export default PlanNotifications;

import React from 'react';
import { ErrorFallbackComponentsProps } from '~/types';
import { ErrorScreen } from '~/ui';
import ErrorIcon from '../../images/icons/500.png';
import styles from './Error.module.sass';

const ErrorBoundaryFallback: React.FC<ErrorFallbackComponentsProps> = () => (
  <ErrorScreen
    styles={styles}
    icon={<img src={ErrorIcon} width={360} alt="" />}
    title="Please remain calm."
    description="Seems like something went wrong. Please try again or contact our Support team."
    linkTitle="Go Back to Account"
    linkPath="/"
  />
);

export default ErrorBoundaryFallback;

import React from 'react';
import { IS_WIDGET } from '../../constants';
import Header from '../Header/Header';

const WithLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      {!IS_WIDGET && <Header />}
      {children}
    </>
  );
};

export default WithLayout;

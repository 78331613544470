import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, FormRow, Panel, notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import { FormInput, FormPassword, StepItemProps } from '~/ui';
import {
  AuthInputType,
  AuthStepType,
  PASSWORD_INVALID_ERROR,
  USER_DEACTIVATED_ERROR,
  USER_NOT_FOUND_ERROR,
  SOMETHING_WENT_WRONG,
  ERROR_COPY,
  ERROR_CODE_TO_COPY,
} from '../../constants';
import { SIGN_IN_PASSWORD_MUTATION } from '../../graphql/mutations';
import sentry from '../../helpers/sentry';
import Logo from '../../images/icons/macpaw.svg?react';
import styles from './Auth.module.sass';

const AuthSignInPassword: React.FC = (props) => {
  const { setStep } = props as StepItemProps;
  const {
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);

  const [signInPassword] = useMutation(SIGN_IN_PASSWORD_MUTATION, {
    onCompleted() {
      setStep(AuthStepType.PinSignIn);
    },
    onError(error) {
      setIsLoading(false);
      if (Array.isArray(error.graphQLErrors)) {
        error.graphQLErrors.forEach((err) => {
          const errCode = err.extensions?.response.body.errors[0].code;

          switch (errCode) {
            case USER_NOT_FOUND_ERROR:
              setError(AuthInputType.Email, {
                message: ERROR_COPY.userNotFound,
              });
              break;
            case PASSWORD_INVALID_ERROR:
              setError(AuthInputType.Password, {
                message: ERROR_CODE_TO_COPY[PASSWORD_INVALID_ERROR],
              });
              break;
            case USER_DEACTIVATED_ERROR:
              setError(AuthInputType.Email, {
                message: ERROR_COPY.userDeactivated,
              });
              break;
            default:
              notify(SOMETHING_WENT_WRONG, NotificationType.Error);
              break;
          }
        });
      } else {
        sentry.captureException(error);
      }
    },
  });

  const handleSubmit = () => {
    if (errors[AuthInputType.Email] || errors[AuthInputType.Password]) return;

    const { email, password } = getValues();

    setIsLoading(true);
    signInPassword({ variables: { email, password } });
  };

  return (
    <Panel className={styles.panel}>
      <div className={`${styles.logo} mb-24`}>
        <Logo width={128} height={128} />
      </div>
      <div className={styles.form}>
        <div className="h3 mb-8">Hey there!</div>
        {errors?.[AuthInputType.Copy] ? (
          <div className={`${styles.sharedError} mb-24`}>{errors[AuthInputType.Copy].message}</div>
        ) : (
          <div className="p3 mb-32">Let’s get started.</div>
        )}
        <FormRow>
          <FormInput autoFocus name={AuthInputType.Email} placeholder="Enter your email" disabled={isLoading} />
        </FormRow>
        <FormRow>
          <FormPassword name={AuthInputType.Password} placeholder="Enter your password" />
        </FormRow>
        <Button color="secondary" type="submit" wide loading={isLoading} onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </Panel>
  );
};

export default AuthSignInPassword;

/* eslint-disable no-useless-escape */
import { matchPath } from 'react-router-dom';
import { BreadcrumbsConfigItem } from '~/types';

interface MatchRoutePathsItem {
  item: BreadcrumbsConfigItem;
  isExact: boolean;
  pathName: string;
}

const PATTERN_PATH_REGEXP = /(?:\/\:\w{1,})/g;
const PATH_PARTS_DIVIDER = '/';

const generateCurrentPathByPattern = (pattern: string, pathName: string) => {
  const dynamicPathParams = Array.from(pattern.matchAll(PATTERN_PATH_REGEXP))
    .map((matchedParamArr) => matchedParamArr[0])
    ?.map((item) => item?.replace?.(PATH_PARTS_DIVIDER, ''));

  const patternParts = pattern.split(PATH_PARTS_DIVIDER);
  const pathNameParts = pathName.split(PATH_PARTS_DIVIDER);
  const hasParams = !!dynamicPathParams.length;

  if (!hasParams) return pattern;

  const paramsIndexes = dynamicPathParams.reduce(
    (acc, param) => ({ ...acc, [param]: patternParts.indexOf(param) }),
    {},
  );

  const currentPathParts = patternParts.map((key) => {
    if (typeof paramsIndexes?.[key as keyof typeof paramsIndexes] === 'number') {
      return pathNameParts?.[paramsIndexes?.[key as keyof typeof paramsIndexes]] ?? key;
    }

    return key;
  });

  return currentPathParts.join(PATH_PARTS_DIVIDER);
};

const getHasExactEquality = (pattern: string, path: string) => {
  const patternParts = pattern.split(PATH_PARTS_DIVIDER);
  const pathParts = path.split(PATH_PARTS_DIVIDER);

  const isSameLength = patternParts.length === pathParts.length;

  if (!isSameLength) return false;

  const isExact = patternParts.every((patternPart, index) => {
    const pathPart = pathParts[index];

    if (index === 1) return patternPart === pathPart;

    const isDynamicPattenPart = patternPart.startsWith(':');

    if (isDynamicPattenPart) {
      return typeof pathPart === 'string' && !!pathPart.length;
    }

    return patternPart === pathPart;
  });

  return isExact;
};

const matchRoutePaths = (routes: BreadcrumbsConfigItem[], pathName: string): MatchRoutePathsItem[] => {
  const exactlyEqualCurrentLevelItem = routes.find(({ path }) => path === pathName);

  const getMatchedRoutes = () => {
    if (exactlyEqualCurrentLevelItem) {
      return [
        {
          isExact: true,
          item: exactlyEqualCurrentLevelItem,
          pathName: exactlyEqualCurrentLevelItem.path,
        },
      ];
    }

    return routes.reduce((acc: MatchRoutePathsItem[], routeConfig: BreadcrumbsConfigItem): MatchRoutePathsItem[] => {
      const { path = '', children } = routeConfig;

      const hasChildren = !!children && !!children.length;
      const matchedPath = matchPath(path, pathName);
      const isExact = getHasExactEquality(path, pathName);

      const currentPathName = generateCurrentPathByPattern(path, pathName);

      const crumbItem = {
        isExact,
        item: routeConfig,
        pathName: currentPathName,
      };

      if (hasChildren) {
        const childrenMatchedRoutes = matchRoutePaths(children as BreadcrumbsConfigItem[], pathName);

        if (childrenMatchedRoutes.length) {
          const hasExactEquality = Boolean(
            childrenMatchedRoutes.find(({ isExact: isMatchedExactly }) => isMatchedExactly),
          );

          const nextAccumulator = hasExactEquality ? [] : acc;

          return [...nextAccumulator, crumbItem, ...childrenMatchedRoutes];
        }
      }

      if (matchedPath) {
        return [...acc, crumbItem];
      }

      return acc;
    }, []);
  };

  const matchedRoutes = getMatchedRoutes();

  return matchedRoutes ?? [];
};

export default matchRoutePaths;

export enum Modals {
  CreateCustomerModal = 'CreateCustomerModal',
  CreateOrganizationModal = 'CreateOrganizationModal',
  GivePersonalPlanModal = 'GivePersonalPlanModal',
  GivePlanModal = 'GivePlanModal',
  SetCustomPlanPriceModal = 'SetCustomPlanPriceModal',
  OrganizationInviteToPlanModal = 'OrganizationInviteToPlanModal',
  CustomerProfileEditModal = 'CustomerProfileEditModal',
  CustomerRemoveModal = 'CustomerRemoveModal',
  ConfirmationModal = 'ConfirmationModal',
  PlanResendActivationModal = 'PlanResendActivationModal',
  PlanPauseModal = 'PlanPauseModal',
  PlanCancelModal = 'PlanCancelModal',
  PlanBlockModal = 'PlanBlockModal',
  ReducePlanSizeModal = 'ReducePlanSizeModal',
  PlanTransactionRefundModal = 'PlanTransactionRefundModal',
  DownloadCodesModal = 'DownloadCodesModal',
  MembersInviteModal = 'MembersInviteModal',
  MemberResendInviteModal = 'MemberResendInviteModal',
  MemberEditModal = 'MemberEditModal',
  MemberDeactivate = 'MemberDeactivate',
  RemoveBetaPlanModal = 'RemoveBetaPlanModal',
  AddDeviceSlotsToPlanModal = 'AddDeviceSlotsToPlanModal',
  GiveOrganizationBetaPlanModal = 'GiveOrganizationBetaPlanModal',
}

import { FetchPolicy, gql, useQuery } from '@apollo/client';
import { GQLResponse } from '~/types';
import { Role } from '../../constants';

export const ROLES_QUERY = gql`
  query Roles {
    roles
  }
`;

interface UseRolesQueryOutput {
  roles: Role[];
  loading: boolean;
}

interface UseRolesQueryInput {
  fetchPolicy?: FetchPolicy;
  skip?: boolean;
}

export const useRolesQuery = ({ fetchPolicy, skip }: UseRolesQueryInput = {}): UseRolesQueryOutput => {
  const { data, loading } = useQuery<GQLResponse<'roles', Role[]>>(ROLES_QUERY, {
    fetchPolicy,
    skip,
  });

  return {
    roles: data?.roles ?? [],
    loading,
  };
};

import React from 'react';
import { ConfirmationType } from '~/types';
import { ConfirmationModal } from '~/ui';
import styles from './AddDeviceSlotsToPlanSuccess.module.sass';

interface SetCustomPriceSuccessProps {
  onSubmit: () => void;
}

const AddDeviceSlotsToPlanSuccess: React.FC<SetCustomPriceSuccessProps> = ({ onSubmit }) => {
  return (
    <ConfirmationModal
      type={ConfirmationType.Success}
      title="Plan size will be updated in a moment"
      description="This could take up to a few minutes."
      descriptionClassName={styles.description}
      onSubmit={onSubmit}
    />
  );
};

export default AddDeviceSlotsToPlanSuccess;

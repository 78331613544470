import React from 'react';
import AccountTypeSwitcher from '../../components/AccountTypeSwitcher/AccountTypeSwitcher';
import OrganizationList from '../../components/OrganizationList/OrganizationList';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';

const Organizations: React.FC = () => (
  <WithErrorBoundary>
    <AccountTypeSwitcher />
    <OrganizationList />
  </WithErrorBoundary>
);

export default Organizations;

import React from 'react';
import { ApolloQueryResult, useLazyQuery } from '@apollo/client';
import { Button } from '@macpaw/macpaw-ui';
import { useModals } from '~/ui';
import { Modals, Role } from '../../constants';
import { ROLES_QUERY } from '../../graphql/queries';

interface MembersInvite {
  refetch: () => Promise<ApolloQueryResult<void>>;
}
const MembersInvite: React.FC<MembersInvite> = (props) => {
  const { refetch } = props;
  const { openModal } = useModals();

  const [loadRoles, { loading }] = useLazyQuery(ROLES_QUERY, {
    variables: {
      skip: false,
    },
    onCompleted({ roles }: { roles: Role[] }) {
      openModal({
        name: Modals.MembersInviteModal,
        props: {
          refetch,
          roles,
        },
      });
    },
  });

  return (
    <Button color="secondary" scale="small" onClick={() => loadRoles()} loading={loading}>
      Add Team Member
    </Button>
  );
};

export default MembersInvite;

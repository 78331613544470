import { gql } from '@apollo/client';
import { BaseProfileSchema } from '~/types';

export const PROFILE_FRAGMENT = gql`
  fragment ProfileFragment on Profile {
    ${BaseProfileSchema}
    isCompleted
    status
  }
`;

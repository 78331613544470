import { useMemo } from 'react';
import { Tag } from '~/types';
import { PlanViewInterface } from '../../interfaces';

interface PlanListItem {
  id: string;
  name: string;
}

const useMemoizedProductPlansByTag = (planViews: PlanViewInterface[], tag: Tag) => {
  const productPlanList = useMemo(() => {
    if (!tag) return [];

    return planViews.reduce<PlanListItem[]>((acc, { productTag: planViewTag, id, productPlanName }) => {
      return planViewTag === tag ? [...acc, { id, name: productPlanName }] : acc;
    }, []);
  }, [planViews, tag]);

  return productPlanList;
};

export default useMemoizedProductPlansByTag;

import { gql, useQuery } from '@apollo/client';
import { ANONYMUS_CUSTOMER } from '../../constants';
import { GQLResponse, PlanInterface } from '../../interfaces';
import { CUSTOMER_PLAN_FRAGMENT, ORGANIZATION_PLAN_FRAGMENT } from '../fragments';

export const PLANS_BY_OWNER_ID_QUERY = gql`
  query PlansByOwnerId($ownerId: ID!) {
    plansByOwnerId(ownerId: $ownerId) {
      ...CustomerPlanFragment
      ...OrganizationPlanFragment
    }
  }
  ${CUSTOMER_PLAN_FRAGMENT}
  ${ORGANIZATION_PLAN_FRAGMENT}
`;

interface UsePlansByOwnerIdQueryInput {
  ownerId: string;
}

interface UsePlansByOwnerIdQuery {
  plans: PlanInterface[];
  loading: boolean;
}

export const usePlansByOwnerIdQuery = ({ ownerId }: UsePlansByOwnerIdQueryInput): UsePlansByOwnerIdQuery => {
  const { data, loading } = useQuery<GQLResponse<'plansByOwnerId', PlanInterface[]>, Record<'ownerId', string>>(
    PLANS_BY_OWNER_ID_QUERY,
    {
      variables: {
        ownerId,
      },
      skip: ANONYMUS_CUSTOMER === ownerId || !ownerId,
    },
  );

  return {
    plans: data?.plansByOwnerId || [],
    loading,
  };
};

import { gql, useLazyQuery } from '@apollo/client';

const REDEEM_CODE_INFO_QUERY = gql`
  query RedeemCodeInfoQuery($redeemCode: String!) {
    redeemCodeInfo(redeemCode: $redeemCode) {
      customerProductPlanId
      customerId
    }
  }
`;

export const useLazyRedeemQuery = () => {
  const [query, { loading }] = useLazyQuery(REDEEM_CODE_INFO_QUERY);

  const getRedeemInfo = async ({ redeemCode }: { redeemCode: string }) => {
    const { data: planData } = await query({
      variables: {
        redeemCode,
      },
    });

    return planData;
  };

  return {
    getRedeemInfo,
    loading,
  };
};

import React from 'react';
import { Button, DialogActions, DialogContent, DialogIcon } from '@macpaw/macpaw-ui';
import { useModals } from '~/ui';
import { Modals } from '../../constants';
import { useCancelCustomerPlanMutation } from '../../graphql/mutations';
import CancelIcon from '../../images/icons/cancel.svg?react';
import { CustomerPlanInterface } from '../../interfaces';

interface PlanCancelProps {
  customerPlan: CustomerPlanInterface;
  onComplete?: () => void;
}

const PlanCancelModal: React.FC<PlanCancelProps> = ({ customerPlan, onComplete }) => {
  const { closeModal } = useModals();
  const { cancelPlan, loading } = useCancelCustomerPlanMutation();

  const handleCancel = async () => {
    await cancelPlan(customerPlan.id);
    closeModal(Modals.PlanCancelModal);
    onComplete?.();
  };

  return (
    <>
      <DialogContent>
        <DialogIcon>
          <CancelIcon width={160} height={160} />
        </DialogIcon>
        <h3>Cancel Subscription Plan</h3>
        <p>
          Are you sure you want to cancel this subscription plan? The customer will no longer be able to update payment
          details and will lose access at the next billing date.
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={handleCancel} loading={loading}>
          Cancel Plan
        </Button>
      </DialogActions>
    </>
  );
};

export default PlanCancelModal;

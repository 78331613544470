import { Button } from '@macpaw/macpaw-ui';

interface OrganizationPaymentButtonProps {
  disabled: boolean;
  handleOpenModal: () => void;
}

const OrganizationPaymentButton: React.FC<OrganizationPaymentButtonProps> = ({ disabled, handleOpenModal }) => (
  <Button scale="medium" color="secondary" disabled={disabled} onClick={handleOpenModal}>
    Create a New Plan
  </Button>
);

export default OrganizationPaymentButton;

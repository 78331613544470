import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@macpaw/macpaw-ui';
import { ConfirmationType } from '~/types';
import { StepItemProps, ConfirmationModal } from '~/ui';
import { OrgInviteToPlanMode, ORGANIZATION_PLAN_MODE_NAMES } from '../../../constants';
import { useLazyOrganizationPurchaseLinkQuery, usePlanViewsQuery } from '../../../graphql/queries';
import styles from './InviteToPlanConfirm.module.sass';

interface InviteToPlanConfirmProps extends Partial<StepItemProps> {
  organizationId: string;
  onSuccess: (purchaseLink: string) => void;
  onGoBack: () => void;
}

const InviteToPlanConfirm: React.FC<InviteToPlanConfirmProps> = ({ organizationId, onSuccess, onGoBack, ...props }) => {
  const { setNextStep } = props as StepItemProps;
  const { getValues } = useFormContext();
  const { billImmediatelyPrice, nextBillingDate, productPlanSkuId, quantity, trialDays, inviteMode } = getValues();
  const { loadPurchaseLink, loading } = useLazyOrganizationPurchaseLinkQuery({ fetchPolicy: 'network-only' });
  const { planViews } = usePlanViewsQuery({
    fetchPolicy: 'cache-only',
    isBusiness: true,
  });

  const currentPlanName = useMemo(() => {
    const currentPlan = planViews?.find(({ id }) => id === productPlanSkuId);
    const modeName = ORGANIZATION_PLAN_MODE_NAMES[inviteMode as OrgInviteToPlanMode];

    return `${currentPlan?.productName} - ${currentPlan?.productPlanName} (${modeName})`;
  }, [planViews, productPlanSkuId, inviteMode]);

  const handleSubmit = async () => {
    const isTrial = inviteMode === OrgInviteToPlanMode.Trial;

    const purchaseLink = await loadPurchaseLink({
      input: {
        organizationId,
        productPlanSkuId,
        billImmediatelyPrice: isTrial ? 0 : Number(billImmediatelyPrice),
        nextBillingDate: isTrial ? 0 : Number(nextBillingDate),
        quantity: Number(quantity),
        trialDays: isTrial ? Number(trialDays) : 0,
      },
    });

    if (!purchaseLink) {
      return;
    }

    onSuccess(purchaseLink);
    setNextStep();
  };

  const renderDescription = () => {
    const isTrial = inviteMode === OrgInviteToPlanMode.Trial;

    if (isTrial) {
      return (
        <>
          We will now generate a link for setting up customer’s payment method. The new Organization-owned
          <strong> {currentPlanName}</strong>
          plan for <strong>{quantity} devices</strong>
          will be created automatically after the payment method is set by the customer.
        </>
      );
    }

    return (
      <>
        We will now create a payment link for the customer to move to an Organization-owned{' '}
        <strong>{currentPlanName}</strong>
        plan for <strong>{quantity} devices</strong>.
      </>
    );
  };

  return (
    <ConfirmationModal
      title="Did we get everything right?"
      type={ConfirmationType.Warning}
      descriptionClassName={styles.description}
      description={renderDescription()}
      actions={
        <>
          <Button outline onClick={onGoBack}>
            Back
          </Button>
          <Button color="secondary" onClick={handleSubmit} loading={loading}>
            Create Payment Link
          </Button>
        </>
      }
    />
  );
};

export default InviteToPlanConfirm;

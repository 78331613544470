/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { DatePicker } from '@macpaw/macpaw-ui';
import { MS_IN_MINUTE, MS_IN_S } from '~/utils';
import { useFormError, useFormField } from './Form.hooks';
import { BaseFormElementProps } from './Form.types';

interface FormDatePickerProps extends BaseFormElementProps {
  label?: string;
  scale?: 'medium' | 'small' | 'big';
  openSite?: 'top' | 'bottom' | 'center' | 'auto';
  closeOnSelect?: boolean;
  dateFormat?: string;
  defaultMonth?: Date;
  fromYear?: number;
  toYear?: number;
  fromDate?: Date;
  toDate?: Date;
  disabled?: boolean;
  placeholder?: string;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({ name, validateOnChange, formatErrorMessage, ...props }) => {
  const {
    field: { onBlur, value = '', ref },
    setValue,
    clearErrors,
  } = useFormField<number>(name);

  const { isError, message: errorMessage } = useFormError(name, {
    formatValue: formatErrorMessage,
  });

  const formatDateValue = (timestamp: number | string): Date | undefined => {
    // the library does not accept null value
    // eslint-disable-next-line no-undefined
    if (!timestamp) return undefined;

    const time = Number(timestamp) * MS_IN_S;

    return new Date(time);
  };
  const handleChange = (date: Date) => {
    const dateTimestamp = Math.ceil((date.getTime() - new Date().getTimezoneOffset() * MS_IN_MINUTE) / MS_IN_S);

    setValue(dateTimestamp);
  };

  const handleCleanupErrors = () => {
    if (!validateOnChange && isError) clearErrors();
  };

  useEffect(handleCleanupErrors, [value]);

  return (
    <DatePicker
      value={formatDateValue(value)}
      onChange={handleChange}
      onBlur={onBlur}
      ref={ref}
      error={errorMessage as string}
      selectionMode="single"
      {...props}
    />
  );
};

export default FormDatePicker;

import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { PLANS_BY_OWNER_ID_QUERY } from '../queries';

export const GIVE_CUSTOMER_PRODUCT_PLAN_MUTATION = gql`
  mutation GiveCustomerProductPlan($productPlanId: ID!, $customerId: String!, $reason: String!) {
    giveCustomerProductPlan(productPlanId: $productPlanId, customerId: $customerId, reason: $reason)
  }
`;

interface UseGiveCustomerProductPlanMutationInput {
  onCompleted?: () => void;
  onError?: () => void;
}

interface GiveCustomerProductPlanMutationVariables {
  reason: string;
  customerId: string;
  productPlanId: string;
}

interface UseGiveCustomerProductPlanMutation {
  giveProductPlan: (input: GiveCustomerProductPlanMutationVariables) => Promise<void>;
  loading: boolean;
}

export const useGiveCustomerProductPlanMutation = ({
  onCompleted,
  onError,
}: UseGiveCustomerProductPlanMutationInput = {}): UseGiveCustomerProductPlanMutation => {
  const [mutate, { loading }] = useMutation<void, GiveCustomerProductPlanMutationVariables>(
    GIVE_CUSTOMER_PRODUCT_PLAN_MUTATION,
    {
      onCompleted,
      onError,
    },
  );

  const giveProductPlan = async (input: GiveCustomerProductPlanMutationVariables): Promise<void> => {
    try {
      await mutate({
        variables: input,
        refetchQueries: [
          {
            query: PLANS_BY_OWNER_ID_QUERY,
            variables: {
              ownerId: input.customerId,
            },
          },
        ],
      });
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);
    }
  };

  return {
    giveProductPlan,
    loading,
  };
};

import React from 'react';
import { Banner, Grid, GridCell, GridRow, Label } from '@macpaw/macpaw-ui';
import { LoadableText, PaymentIcon, PaymentMask } from '~/ui';
import { formatCurrency, formatDate, formatExpireDate, isOneTime } from '~/utils';
import {
  useOrganizationPlanSeatStatsQuery,
  useOrganizationSlotPriceQuery,
  usePaymentInfoQuery,
} from '../../graphql/queries';
import { formatToStrong } from '../../helpers/formatters';
import getIsPastDueWithAccess from '../../helpers/getIsPastDueWithAccess';
import CardIcon from '../../images/icons/card-front.svg?react';
import { OrganizationPlanInterface, PaymentInterface } from '../../interfaces';
import PanelEmptyState from '../PanelEmptyState/PanelEmptyState';
import Section from '../Section/Section';

interface OrganizationPlanPaymentProps {
  plan: OrganizationPlanInterface;
  organizationId: string;
}

const OrganizationPlanPayment: React.FC<OrganizationPlanPaymentProps> = ({ plan, organizationId }) => {
  const isEmptyPlan = (planItem: OrganizationPlanInterface) => {
    return !planItem.order || isOneTime(planItem.planView);
  };

  const isEmpty = isEmptyPlan(plan);

  const { loading: isLoadingPayment, paymentInfo } = usePaymentInfoQuery({ planId: plan.id, skip: isEmpty });
  const { loading: isLoadingSeats, seatsCount, occupiedSeatCount } = useOrganizationPlanSeatStatsQuery(plan);
  const {
    loading: isLoadingPrice,
    customPrice,
    defaultPrice,
  } = useOrganizationSlotPriceQuery({
    organizationId,
    productPlanSkuId: plan.productPlanSkuId,
  });

  const devicePrice = (customPrice || defaultPrice) ?? 0;
  const isPastDueWithAccess = getIsPastDueWithAccess(plan);
  const order = plan?.order;
  const currency = order?.currency ?? '';

  if (isEmpty) {
    return <PanelEmptyState title="Payment Method" subtitle="No Payment Method available" icon={<CardIcon />} />;
  }

  return (
    <Section title="Payment Method">
      {isPastDueWithAccess && (
        <Banner className="mb-16">
          <b>Payment failed</b>. Need update billing method.
        </Banner>
      )}
      <Grid id="payment" icon={<PaymentIcon paymentInfo={paymentInfo} />}>
        <GridRow>
          <GridCell type="primary">
            <Label>Card Number</Label>
            <LoadableText loaderSize={24} isLoading={isLoadingPayment}>
              <PaymentMask short paymentInfo={paymentInfo as PaymentInterface} />
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Expiration Date</Label>
            <LoadableText isLoading={isLoadingPayment} isConditional>
              {formatExpireDate(paymentInfo?.expiryMonth && paymentInfo?.expiryYear ? paymentInfo : null)}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Next Payment Date</Label>
            <LoadableText formatter={formatDate} isLoading={!order} isConditional>
              {order?.nextBillDate ?? 0}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Next billing amount</Label>
            <LoadableText isLoading={!order} isConditional>
              {order?.nextPaymentAmount && formatCurrency(order.nextPaymentAmount, currency)}
            </LoadableText>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell type="primary">
            <Label>For One Slot</Label>
            <LoadableText isLoading={isLoadingPrice} formatter={formatToStrong}>
              {formatCurrency(devicePrice, currency)}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Active Devices</Label>
            <LoadableText isLoading={isLoadingSeats}>{seatsCount}</LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Total Users</Label>
            <LoadableText isLoading={isLoadingSeats}>{occupiedSeatCount}</LoadableText>
          </GridCell>
          <GridCell type="secondary" />
        </GridRow>
      </Grid>
    </Section>
  );
};

export default OrganizationPlanPayment;

import 'url-search-params-polyfill';
import { useQuery } from '@apollo/client';
import { CUSTOMER_QUERY, CUSTOMER_ORGANIZATION_INFO_QUERY } from '../../graphql/queries';
import { CustomerInterface, OrganizationInterface } from '../../interfaces';

interface QueryOptions {
  skip?: boolean;
}

const useCustomerOrganizationInfoQuery = (customerId: string, options?: QueryOptions) => {
  const { data, loading: organizationLoading } = useQuery(CUSTOMER_ORGANIZATION_INFO_QUERY, {
    variables: { customerId },
    skip: options?.skip,
  });
  const ownerId = data?.customerOrganizationInfo?.owner?.customerId as CustomerInterface | undefined;

  const { data: ownerData, loading: ownerLoading } = useQuery(CUSTOMER_QUERY, {
    variables: { customerId: ownerId },
    skip: !ownerId,
  });

  const organization = data?.customerOrganizationInfo?.organization as Partial<OrganizationInterface> | undefined;
  const owner = ownerData?.customer as CustomerInterface | undefined;

  return {
    organization,
    owner,
    loading: organizationLoading || ownerLoading,
  };
};

export default useCustomerOrganizationInfoQuery;

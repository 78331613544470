import React from 'react';
import { Loader } from '@macpaw/macpaw-ui';

interface Loadable {
  isLoading: boolean;
  className?: string;
  loaderSize?: number;
  loaderInline?: boolean;
  as?: React.ElementType;
  loaderDataTestId?: string;
}

const Loadable: React.FC<React.PropsWithChildren<Loadable>> = ({
  isLoading,
  className,
  loaderSize,
  loaderInline,
  children,
  as: Component = 'div',
  loaderDataTestId = 'loader',
}) => {
  const loaderElement =
    className || Component ? (
      <Component className={className}>
        <Loader size={loaderSize} inline={loaderInline} data-testid={loaderDataTestId} />
      </Component>
    ) : (
      <Loader size={loaderSize} inline={loaderInline} data-testid={loaderDataTestId} />
    );

  return <>{isLoading ? loaderElement : children}</>;
};

export default Loadable;

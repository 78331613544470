import React from 'react';
import styles from './FormLabel.module.sass';

interface FormLabelProps {
  children: string | React.ReactNode;
  isOptional?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({ children, isOptional }) => (
  <>
    {children}
    {isOptional && <span className={styles.optionalSuffix}>(Optional)</span>}
  </>
);

export default FormLabel;

import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { Maybe, GQLErrorExtension } from '~/types';

export const getGQLErrorParams = <ExpectedType>(error: GraphQLError, key = 'errors'): Maybe<ExpectedType> => {
  if (!error) return null;

  return error.extensions?.response.body.params?.[key];
};

export const getGQLServerErrors = (error: GraphQLError) => {
  const serverErrors = (error?.extensions as GQLErrorExtension)?.response?.body?.errors ?? [];

  return serverErrors;
};

export const getGQLServerError = (error: GraphQLError) => {
  const [serverError] = getGQLServerErrors(error);

  return serverError;
};

export const getGQLError = ({ graphQLErrors }: ErrorResponse) => {
  if (!graphQLErrors) return null;

  const [error] = graphQLErrors;

  return getGQLServerError(error);
};

export const getGQLValidationErrors = ({ graphQLErrors }: ErrorResponse): Maybe<Record<string, string>> => {
  if (!graphQLErrors) return null;

  const [error] = graphQLErrors;

  const serverErrors = (error?.extensions as GQLErrorExtension)?.response?.body?.errors ?? [];

  return serverErrors.reduce((acc, { code, path }) => ({ ...acc, [path]: code }), {});
};

export const getGQLErrorCode = (error: ErrorResponse): string | null => {
  const serverError = getGQLError(error);

  return serverError?.code ?? null;
};

export const getGQLErrorMessage = (error: ErrorResponse): string => {
  const serverError = getGQLError(error);

  return serverError?.message ?? '';
};

import { notify } from '@macpaw/macpaw-ui';
import { Maybe, NotificationType } from '~/types';
import { GeneralError, ValidationError } from '../errors';

type FieldErrorSetter = (fieldName: string, error: { message: string }) => void;

const handleFormServerError = (error: Error, setFieldError: Maybe<FieldErrorSetter>) => {
  if (error instanceof ValidationError && error.validationErrorMessages) {
    Object.entries(error.validationErrorMessages).forEach(([fieldName, message]) => {
      setFieldError?.(fieldName, { message });
    });
  } else {
    notify((error as GeneralError).message, NotificationType.Error);
  }
};

export default handleFormServerError;

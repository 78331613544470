import React from 'react';
import cx from 'clsx';
import { Input, Button } from '@macpaw/macpaw-ui';
import type { InputProps } from '@macpaw/macpaw-ui/lib/Input/Input';
import type { InputValueType } from '@macpaw/macpaw-ui/lib/types';
import { CircleMinusIcon, CirclePlusIcon } from '~/images/assets';
import { useFormError, useFormField } from '../Form.hooks';
import { BaseFormElementProps } from '../Form.types';
import { useFormContext } from '../FormContext';
import styles from './FormNumberInput.module.sass';

interface FormNumberInput
  extends BaseFormElementProps,
    Omit<InputProps, 'name'>,
    Omit<React.RefAttributes<HTMLInputElement>, 'type'> {
  formatValue?: (value: string) => string | number;
}

const FormNumberInput: React.FC<FormNumberInput> = ({
  name,
  validateOnChange,
  formatValue,
  formatErrorMessage,
  step = 1,
  min = 0,
  max,
  disabled,
  className,
  ...props
}) => {
  const { trans } = useFormContext();
  const {
    field: { onBlur, value: inputValue = '', ref },
    setValue,
    clearErrors,
  } = useFormField<string | number>(name);
  const { isError, message: errorMessage } = useFormError(name, {
    formatValue: formatErrorMessage ?? trans,
  });
  const inputClassNames = cx(className, styles.input);
  const plusButton = cx(styles.button, styles.right);
  const minusButton = cx(styles.button, styles.left);

  const isDisabledPlus = disabled || !!(max && Number(inputValue) === Number(max));
  const isDisabledMinus = disabled || Number(inputValue) === Number(min);

  const getMatchedValue = (value: string | number): number => {
    if (value < min) return Number(min);
    if (max && value > max) return Number(max);

    return Number(value);
  };

  const handleChange = (value: InputValueType) => {
    if (validateOnChange && isError) {
      clearErrors();
    }

    const nextValue = getMatchedValue(value as string);
    const formattedValue = formatValue?.(value.toString()) ?? nextValue;

    setValue(formattedValue);
  };

  const incrementValue = (direction: number) => () => {
    const nextValue = getMatchedValue(Number(inputValue) + direction);

    handleChange(nextValue);
  };

  return (
    <div className={styles.inputWrapper}>
      <Button
        icon
        color="transparent"
        className={minusButton}
        disabled={isDisabledMinus}
        onClick={incrementValue(-Number(step))}
        data-testid="minus">
        <CircleMinusIcon />
      </Button>
      <Input
        type="number"
        className={inputClassNames}
        name={name}
        value={inputValue.toString()}
        onChange={handleChange}
        onBlur={onBlur}
        ref={ref}
        error={errorMessage}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        {...props}
      />
      <Button
        icon
        color="transparent"
        className={plusButton}
        disabled={isDisabledPlus}
        onClick={incrementValue(Number(step))}>
        <CirclePlusIcon />
      </Button>
    </div>
  );
};

export default FormNumberInput;

import React from 'react';
import { SuccessModal } from '~/ui';

interface CustomerRemoveSuccessProps {
  email: string;
  onSubmit: () => void;
}

const CustomerRemoveSuccess: React.FC<CustomerRemoveSuccessProps> = ({ email, onSubmit }) => (
  <SuccessModal
    title="Customer account deleted"
    description={
      <>
        The account of <b>{email}</b> and the data associated with it no longer exist.
      </>
    }
    onSubmit={onSubmit}
  />
);

export default CustomerRemoveSuccess;

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContent, DialogActions, Button, FormRow, Loader } from '@macpaw/macpaw-ui';
import { StepItemProps, FormInput, FormSelect, Loadable } from '~/ui';
import { usePrevious } from '~/utils';
import { SetCustomPriceFields, SetCustomPriceStep } from '../../../constants';
import { useLazyOrganizationSlotPriceQuery, usePlanViewsQuery } from '../../../graphql/queries';
import handleFormServerError from '../../../helpers/handleFormServerError';
import { useMemoizedProductPlansByTag, useMemoizedUniqProductsList } from '../../../helpers/hooks';
import PriceFieldFooter from '../components/PriceFieldFooter';
import styles from './SetCustomPriceForm.module.sass';

interface SetCustomPriceFormProps extends Partial<StepItemProps> {
  organizationId: string;
  onCancel: () => void;
}

const SetCustomPriceForm: React.FC<SetCustomPriceFormProps> = ({ organizationId, onCancel, ...props }) => {
  const { setNextStep, prevStepKey, setStep } = props as StepItemProps;

  const planViewsFetchPolicy = prevStepKey ? 'cache-first' : 'network-only';

  const {
    defaultPrice,
    customPrice,
    loadOrganizationPrice,
    loading: isPricingLoading,
  } = useLazyOrganizationSlotPriceQuery({ fetchPolicy: 'network-only' });
  const { planViews, loading: isPlansLoading } = usePlanViewsQuery({
    fetchPolicy: planViewsFetchPolicy,
    isBusiness: true,
  });

  const [isLoading, setLoading] = useState(false);
  const {
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { isDirty },
  } = useFormContext();

  const productTag = watch(SetCustomPriceFields.ProductTag);
  const productPlanId = watch(SetCustomPriceFields.ProductPlanSkuId);
  const isDefaultPrice = watch(SetCustomPriceFields.IsDefaultPrice);

  const { previous: prevProductTag } = usePrevious(productTag);

  const getFormFieldsConfig = () => {
    const isCustomPriceSet = customPrice !== null;
    const currentPrice = isCustomPriceSet ? customPrice : defaultPrice;
    const isPricingInputLoading = isPricingLoading && !defaultPrice;
    const isDisabledPriceInput =
      !currentPrice || isPricingLoading || isDefaultPrice || (!isDefaultPrice && isCustomPriceSet);
    const isDisabledSubmit = !isDirty || isCustomPriceSet || isPricingLoading;
    const priceInputPlaceholder = isPricingInputLoading ? '' : 'Plan’s default price';

    return {
      isCustomPriceSet,
      currentPrice,
      isPricingInputLoading,
      isDisabledPriceInput,
      isDisabledSubmit,
      priceInputPlaceholder,
    };
  };

  const {
    isCustomPriceSet,
    currentPrice,
    isPricingInputLoading,
    isDisabledPriceInput,
    isDisabledSubmit,
    priceInputPlaceholder,
  } = getFormFieldsConfig();

  const uniqProductsList = useMemoizedUniqProductsList(planViews);
  const productPlanList = useMemoizedProductPlansByTag(planViews, productTag);

  const handleSubmitForm = handleSubmit(async (formData) => {
    try {
      setLoading(true);

      const currentPricingData = await loadOrganizationPrice({
        productPlanSkuId: formData.productPlanSkuId,
        organizationId,
      });

      if (currentPricingData?.customPrice) {
        setStep(SetCustomPriceStep.Reject);

        return;
      }

      setNextStep();
    } catch (error) {
      handleFormServerError(error as Error, setError);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!prevProductTag || prevProductTag === productTag) return;

    setValue(SetCustomPriceFields.ProductPlanSkuId, '');
  }, [productTag]);

  useEffect(() => {
    if (!productPlanId) return;

    loadOrganizationPrice({
      productPlanSkuId: productPlanId,
      organizationId,
    });
  }, [productPlanId, productPlanId]);

  useEffect(() => {
    if (isDefaultPrice) {
      setValue(SetCustomPriceFields.Price, defaultPrice);

      return;
    }

    const priceValue = isPricingInputLoading ? '' : currentPrice;

    setValue(SetCustomPriceFields.Price, priceValue);
  }, [isPricingInputLoading, currentPrice, isDefaultPrice]);

  useEffect(() => {
    if (!defaultPrice) return;

    setValue(SetCustomPriceFields.DefaultPrice, defaultPrice);
  }, [defaultPrice]);

  useEffect(() => {
    setValue(SetCustomPriceFields.IsDefaultPrice, !isCustomPriceSet);
  }, [isCustomPriceSet]);

  return (
    <>
      <DialogContent>
        <h3>Organization’s Custom Plan Price</h3>
        <Loadable isLoading={isPlansLoading} className={styles.loader}>
          <FormRow>
            <FormSelect name={SetCustomPriceFields.ProductTag} label="Product" validateOnChange>
              <option disabled value="">
                Select a product
              </option>
              {uniqProductsList.map(({ productName, productTag: tagValue }) => (
                <option key={tagValue} value={tagValue}>
                  {productName}
                </option>
              ))}
            </FormSelect>
          </FormRow>
          <FormRow>
            <FormSelect
              name={SetCustomPriceFields.ProductPlanSkuId}
              label="Product Plan"
              disabled={!productPlanList.length}
              validateOnChange>
              <option disabled value="">
                Select plan for custom pricing
              </option>
              {productPlanList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </FormSelect>
          </FormRow>
          <FormRow>
            <div className={styles.priceInputWrapper}>
              {isPricingInputLoading && (
                <div className={styles.priceLoader}>
                  <Loader size={24} inline />
                </div>
              )}
              <FormInput
                type="number"
                name={SetCustomPriceFields.Price}
                label={<span>Price per device</span>}
                placeholder={priceInputPlaceholder}
                disabled={isDisabledPriceInput}
                currency="USD"
              />
            </div>
            <PriceFieldFooter
              isLoading={isPricingInputLoading}
              currentPrice={currentPrice}
              isCustomPriceSet={isCustomPriceSet}
            />
          </FormRow>
        </Loadable>
      </DialogContent>
      <DialogActions>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSubmitForm} disabled={isDisabledSubmit} loading={isLoading}>
          Set Custom Price
        </Button>
      </DialogActions>
    </>
  );
};

export default SetCustomPriceForm;

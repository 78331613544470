import { gql, useMutation, useApolloClient } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { dropRight } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Maybe } from '~/types';
import { LIMIT_PER_PAGE } from '../../constants';
import { createCustomerGQLEntity, updatePaginationGQLEntity } from '../../helpers/gql';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { CreateCustomerData, ApolloInput, GQLResponse } from '../../interfaces';
import { CUSTOMERS_QUERY, CustomersQuery } from '../queries';

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input)
  }
`;

type CreateCustomerVariables = ApolloInput<CreateCustomerData>;

interface UseCreateCustomerMutation {
  loading: boolean;
  createCustomer: (email: string) => Promise<Maybe<string>>;
}

export const useCreateCustomerMutation = (): UseCreateCustomerMutation => {
  const client = useApolloClient();
  const [mutate, { loading }] = useMutation<void, CreateCustomerVariables>(CREATE_CUSTOMER_MUTATION);

  const handleCompletedCreateCustomer = (customerData: CreateCustomerData) => () => {
    const defaultVariables = {
      input: {
        limit: LIMIT_PER_PAGE,
        page: 1,
        search: '',
      },
    };

    const data = client.readQuery<GQLResponse<'customers', CustomersQuery>>({
      query: CUSTOMERS_QUERY,
      variables: defaultVariables,
    });

    const customers = data?.customers?.data;
    const pagination = data?.customers?.pagination;

    if (!customers || !pagination) return;

    const newCustomer = createCustomerGQLEntity(customerData);
    const nextPagination = updatePaginationGQLEntity(pagination, 1);
    const nextCustomers =
      customers.length < LIMIT_PER_PAGE ? [newCustomer, ...customers] : [newCustomer, ...dropRight(customers, 1)];

    client.writeQuery({
      query: CUSTOMERS_QUERY,
      variables: defaultVariables,
      data: {
        customers: {
          data: nextCustomers,
          pagination: nextPagination,
        },
      },
    });
  };

  const createCustomer = async (email: string) => {
    const customerData = {
      customerId: uuid(),
      email,
    };

    try {
      await mutate({
        variables: {
          input: customerData,
        },
        onCompleted: handleCompletedCreateCustomer(customerData),
      });

      return customerData.customerId;
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);

      return null;
    }
  };

  return {
    createCustomer,
    loading,
  };
};

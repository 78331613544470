import React from 'react';
import { Button, Grid, GridCell, GridRow, Label } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { Maybe } from '~/types';
import { LoadableText } from '~/ui';
import InfoIcon from '../../images/icons/info.svg?react';
import { OrganizationInterface } from '../../interfaces';

interface OrganizationContactsProps {
  organization: Maybe<OrganizationInterface>;
}

const OrganizationContacts: React.FC<OrganizationContactsProps> = ({ organization }) => {
  const renderAction = () => (
    <Button color="transparent" icon>
      <MoreIcon />
    </Button>
  );

  return (
    <Grid icon={<InfoIcon />} action={renderAction()}>
      <GridRow>
        <GridCell type="primary">
          <Label>Country</Label>
          <LoadableText isConditional>{organization?.country?.name}</LoadableText>
        </GridCell>
        <GridCell type="primary">
          <Label>City</Label>
          <LoadableText isConditional>{organization?.city}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>State</Label>
          <LoadableText isConditional>{organization?.state?.name}</LoadableText>
        </GridCell>
        <GridCell type="secondary">
          <Label>Post Code</Label>
          <LoadableText isConditional>{organization?.postCode}</LoadableText>
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell type="primary">
          <Label>Address</Label>
          <LoadableText isConditional>{organization?.address}</LoadableText>
        </GridCell>
        <GridCell type="primary" />
        <GridCell type="secondary">
          <Label>VAT Number</Label>
          <LoadableText isConditional>{organization?.vatNumber}</LoadableText>
        </GridCell>
        <GridCell type="secondary" />
      </GridRow>
    </Grid>
  );
};

export default OrganizationContacts;

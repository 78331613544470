import React, { useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useQuery } from '@apollo/client';
import { ExternalLink, Label, Panel, Grid, GridRow, GridCell, Tooltip, notify } from '@macpaw/macpaw-ui';
import { CopyIcon, ExternalIcon, InfoIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { isCompanionPlan } from '~/helpers/plan';
import { NotificationType } from '~/types';
import { formatDate, formatCurrency, conditionValue } from '~/utils';
import { COPIED_TO_CLIPBOARD, TransactionDescriptionCopy } from '../../constants';
import { TRANSACTIONS_QUERY } from '../../graphql/queries';
import CardIcon from '../../images/icons/card-back.svg?react';
import { CustomerPlanInterface, TransactionInterface } from '../../interfaces';
import Section from '../Section/Section';
import styles from './PlanTransactions.module.sass';

interface PlanTransactions {
  plan: CustomerPlanInterface;
}

const PlanTransactions: React.FC<PlanTransactions> = ({ plan }) => {
  const noOrder = !plan.order;
  const { data, loading } = useQuery(TRANSACTIONS_QUERY, {
    variables: { customerPlanId: plan.id },
    skip: noOrder,
  });
  const [clipboard, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (clipboard.value) {
      notify(COPIED_TO_CLIPBOARD, NotificationType.Success);
    } else if (clipboard.error) {
      notify(clipboard.error.message, NotificationType.Error);
    }
  }, [clipboard]);

  const transactions = data?.transactions as TransactionInterface[] | undefined;

  if (noOrder || transactions?.length === 0) {
    return (
      <Section title="Billing History">
        <Panel outline className={styles.empty}>
          <CardIcon className={styles.emptyIcon} />
          <b>
            {isCompanionPlan(plan)
              ? 'Billing history is available on the parent plan’s page.'
              : 'No Billing History available'}
          </b>
        </Panel>
      </Section>
    );
  }

  return (
    <Section title="Billing History" loading={loading}>
      {transactions &&
        transactions.map((transaction: TransactionInterface) => (
          <Grid key={transaction.id} outline className="mb-8">
            <GridRow>
              <GridCell type="secondary">
                <Label>Date</Label>
                {formatDate(transaction.date)}
              </GridCell>
              <GridCell type="secondary">
                <Label>Description</Label>
                {TransactionDescriptionCopy[transaction.description as keyof typeof TransactionDescriptionCopy]}
                {transaction.refundReason && (
                  <Tooltip content={transaction.refundReason} position="top">
                    <button className={styles.info} type="button">
                      <InfoIcon width={20} height={20} />
                    </button>
                  </Tooltip>
                )}
              </GridCell>
              <GridCell type="secondary">
                <Label>Order ID</Label>
                {conditionValue(
                  transaction.externalOrderId && (
                    <div className={styles.withInlineIcon}>
                      <b>{transaction.externalOrderId}</b>
                      <ExternalLink href={`https://vendors.paddle.com/orders/detail/${transaction.externalOrderId}`}>
                        <ExternalIcon className={styles.inlineIcon} />
                      </ExternalLink>
                    </div>
                  ),
                )}
              </GridCell>
              <GridCell type="secondary">
                <Label className={styles.gridLabel}>Status</Label>
                <span style={{ textTransform: 'capitalize' }}>{transaction.paymentStatus}</span>
              </GridCell>
              <GridCell type="secondary">
                <Label>Amount</Label>
                {conditionValue(transaction.amount && formatCurrency(transaction.amount, transaction.currency!))}{' '}
                {transaction.receiptUrl && (
                  <div className={styles.withInlineIcon}>
                    <ExternalLink href={transaction.receiptUrl}>Invoice</ExternalLink>
                    <button
                      type="button"
                      className={styles.inlineButton}
                      onClick={() => copyToClipboard(transaction.receiptUrl || '')}>
                      <CopyIcon className={styles.inlineIcon} />
                    </button>
                  </div>
                )}
              </GridCell>
            </GridRow>
          </Grid>
        ))}
    </Section>
  );
};

export default PlanTransactions;

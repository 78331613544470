/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button, Hint } from '@macpaw/macpaw-ui';
import { ConfirmationType } from '~/types';
import { ConfirmationModal } from '~/ui';
import { useDownloadCodesLinkQuery } from '../../graphql/queries';

interface DownloadCodesProps {
  orderId: string;
  setLink: (link: string) => void;
}

const DownloadCodesModal: React.FC<DownloadCodesProps> = ({ orderId, setLink }) => {
  const { link } = useDownloadCodesLinkQuery({
    orderId,
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (link) setLink(link);
  }, [link]);

  return (
    <ConfirmationModal
      type={ConfirmationType.Download}
      title="Generating your redeem codes..."
      description="We’ve started generating a CSV file with redeem codes to offer to your customers. It may take up to five minutes to create a file download link."
      actions={
        <>
          {link && <Hint>If your download didn’t start, just click Download Codes to try again.</Hint>}
          <Button href={link} color="secondary" loading={!link}>
            Download Codes
          </Button>
        </>
      }
    />
  );
};

export default DownloadCodesModal;

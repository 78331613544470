import React from 'react';
import { useQuery } from '@apollo/client';
import { Label, Button, Grid, GridRow, GridCell, Dropdown, DropdownItem } from '@macpaw/macpaw-ui';
import { MoreIcon } from '@macpaw/macpaw-ui/lib/Icons/jsx';
import { CustomerPlanStatus } from '~/types';
import { LoadableText, useModals } from '~/ui';
import { formatDate, conditionValue, unixTimestampToDate } from '~/utils';
import { Modals } from '../../constants';
import { CUSTOMER_QUERY } from '../../graphql/queries';
import stringifyBoolean from '../../helpers/stringifyBoolean';
import CustomerIcon from '../../images/icons/user.svg?react';
import { CustomerInterface, PlanInterface } from '../../interfaces';
import styles from './CustomerProfile.module.sass';

interface CustomerProfile {
  customerId: string;
  ownerId: string | null;
  organizationName: string | null;
  customerPlans: PlanInterface[];
}

const ACTIVE_PLAN_STATUSES = [
  CustomerPlanStatus.Active,
  CustomerPlanStatus.PauseRequested,
  CustomerPlanStatus.CancelRequested,
  CustomerPlanStatus.DisputeOpened,
  CustomerPlanStatus.PastDue,
  CustomerPlanStatus.Paused,
  CustomerPlanStatus.Trialing,
];

const CustomerProfile: React.FC<CustomerProfile> = ({ customerId, ownerId, organizationName, customerPlans }) => {
  const { openModal, closeModal } = useModals();
  const { data } = useQuery(CUSTOMER_QUERY, { variables: { customerId } });

  const customer = data?.customer as CustomerInterface | undefined;

  const handleOpenEditModal = () => {
    openModal({
      name: Modals.CustomerProfileEditModal,
      props: {
        customerId,
        email: customer?.email,
        name: customer?.name,
      },
    });
  };

  const handleRemoveUser = () => {
    const isOrganizationOwner = ownerId === customerId;
    const plansStatuses = customerPlans!.map((item: PlanInterface) => item.status);
    const plansActive = plansStatuses.some((status) => ACTIVE_PLAN_STATUSES.includes(status));
    const plansCancelActive = customerPlans.some(
      ({ status, dueDate }) =>
        CustomerPlanStatus.CancelApproved === status && unixTimestampToDate(dueDate ?? 0) > new Date(),
    );

    if (customerPlans && (plansActive || plansCancelActive)) {
      openModal({
        name: Modals.ConfirmationModal,
        props: {
          type: 'warning',
          title: 'This person has an active plan',
          description:
            'We can only delete customers without active plans. Please take one of the following steps and come back:',
          actionTitle: 'Got It',
          contentClassName: styles.confirmationContent,
          onSubmit: () => closeModal(Modals.ConfirmationModal),
          children: (
            <>
              <b>For Plan Owners</b> – block all of their plans <br />
              <b>For Members</b> – revoke their access from all existing plans
            </>
          ),
        },
      });

      return;
    }

    if (isOrganizationOwner) {
      openModal({
        name: Modals.ConfirmationModal,
        props: {
          type: 'warning',
          title: 'Can’t delete an Organization owner',
          description: (
            <>
              This person is the owner of the <b>{organizationName}</b> Organization. To delete their account, please
              transfer the ownership over the organization to another person and try again.
            </>
          ),
          onSubmit: () => closeModal(Modals.ConfirmationModal),
        },
      });

      return;
    }

    openModal({
      name: Modals.CustomerRemoveModal,
      props: {
        customerId,
        email: customer?.email,
        name: customer?.name,
      },
    });
  };

  const formatCreatedAt = (createdAt: number) => formatDate(Number(createdAt));

  const ActionButton = () => (
    <Dropdown
      position="right"
      trigger={
        <Button color="transparent" icon>
          <MoreIcon />
        </Button>
      }>
      <DropdownItem disabled={!customer} onClick={handleOpenEditModal}>
        Edit Customer Information
      </DropdownItem>
      <DropdownItem attention disabled={!customer} onClick={handleRemoveUser}>
        Remove Customer
      </DropdownItem>
    </Dropdown>
  );

  return (
    <>
      <Grid icon={<CustomerIcon />} action={<ActionButton />}>
        <GridRow>
          <GridCell type="primary">
            <Label>Name</Label>
            <LoadableText isLoading={!customer} isConditional>
              {customer?.name}
            </LoadableText>
          </GridCell>
          <GridCell type="primary">
            <Label>Email</Label>
            <LoadableText isLoading={!customer} isConditional>
              {customer?.email}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Qty Plans</Label>
            {conditionValue(customerPlans.length)}
          </GridCell>
          <GridCell type="secondary">
            <Label>Added</Label>
            <LoadableText isLoading={!customer} formatter={formatCreatedAt}>
              {customer?.createdAt ?? 0}
            </LoadableText>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell type="primary">
            <Label>Finished Setup</Label>
            <LoadableText isLoading={!customer} isConditional formatter={stringifyBoolean}>
              {customer?.isCompleted}
            </LoadableText>
          </GridCell>
          <GridCell type="primary">
            <Label>Email Confirmed</Label>
            <LoadableText isLoading={!customer} isConditional formatter={stringifyBoolean}>
              {customer?.emailConfirmed}
            </LoadableText>
          </GridCell>
          <GridCell type="secondary">
            <Label>Creation By</Label>
            <div style={{ textTransform: 'capitalize' }}>
              <LoadableText isLoading={!customer} isConditional>
                {customer?.source}
              </LoadableText>
            </div>
          </GridCell>
          <GridCell type="secondary" />
        </GridRow>
      </Grid>
    </>
  );
};

export default CustomerProfile;

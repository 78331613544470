import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContent, DialogActions, Button, FormRow } from '@macpaw/macpaw-ui';
import { Country, State } from '~/types';
import { FormInput, FormSelect } from '~/ui';
import { CreateOrganizationFields } from '../../../constants';
import { useOrganizationCountriesData } from '../../../helpers/hooks';
import FormLabel from '../../FormLabel/FormLabel';
import styles from './CreateOrganizationForm.module.sass';

interface CreateOrganizationFormProps {
  isLoading: boolean;
  onCancel: () => void;
}

const CreateOrganizationForm: React.FC<CreateOrganizationFormProps> = ({ isLoading, onCancel }) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const { countries, states, isLoadingCountries, isLoadingStates } = useOrganizationCountriesData({
    countryFieldName: CreateOrganizationFields.Country,
    stateFieldName: CreateOrganizationFields.State,
    vatNumberFieldName: CreateOrganizationFields.VatNumber,
  });

  const isGeneralLoading = isLoadingCountries || isLoadingStates;
  const isDisabledSubmit = isGeneralLoading || !isDirty;

  return (
    <>
      <DialogContent>
        <h3 className={styles.title}>Create Organization</h3>
        <FormRow>
          <FormInput
            name={CreateOrganizationFields.Name}
            label={<span>Organization Name</span>}
            placeholder="Type a name that will be visible for the customer"
          />
        </FormRow>
        <FormRow>
          <FormSelect name={CreateOrganizationFields.Country} label="Country" disabled={isLoadingCountries}>
            <option disabled value="">
              Select a country
            </option>
            {countries.map((countryOption: Country) => (
              <option key={countryOption.code} value={countryOption.code}>
                {countryOption.name}
              </option>
            ))}
          </FormSelect>
        </FormRow>
        <FormRow split>
          <FormInput
            name={CreateOrganizationFields.City}
            label={<FormLabel isOptional>City</FormLabel>}
            placeholder="Type a city"
          />
          <FormSelect name={CreateOrganizationFields.State} disabled={!states.length} label="State">
            <option disabled value="">
              Select a state
            </option>
            {states.map((state: State) => (
              <option key={state.code} value={state.code}>
                {state.name}
              </option>
            ))}
          </FormSelect>
        </FormRow>
        <FormRow>
          <FormInput
            name={CreateOrganizationFields.Address}
            label={<FormLabel isOptional>Address</FormLabel>}
            placeholder="Specify organization’s official address"
          />
        </FormRow>
        <FormRow split>
          <FormInput name={CreateOrganizationFields.PostCode} placeholder="18000" label="Postal Code" />
          <FormInput
            name={CreateOrganizationFields.VatNumber}
            label={<FormLabel isOptional>VAT</FormLabel>}
            placeholder="VAT Number"
          />
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" type="submit" disabled={isDisabledSubmit} loading={isLoading}>
          Create Organization
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateOrganizationForm;

import React, { useEffect, MutableRefObject, FormEvent } from 'react';
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import { Maybe } from '~/types';

type FormValidateMode = 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';
interface FormProps {
  children: React.ReactNode;
  validationSchema?: AnyObjectSchema;
  initialValues?: ObjectLiteral;
  withDevTools?: boolean;
  validateMode?: FormValidateMode;
  className?: string;
  reValidateMode?: Exclude<FormValidateMode, 'onTouched' | 'all'>;
  dataHook?: string;
  innerRef?: MutableRefObject<Maybe<UseFormReturn<ObjectLiteral>>>;
  onSubmit?: (values: ObjectLiteral) => void;
}

const Form: React.FC<FormProps> = ({
  onSubmit,
  validationSchema,
  validateMode = 'onChange',
  reValidateMode = 'onChange',
  initialValues,
  children,
  className,
  dataHook = 'form',
  innerRef,
  withDevTools,
}) => {
  const form = useForm({
    mode: validateMode,
    reValidateMode,
    defaultValues: initialValues,
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  });

  const handleFormSubmit = (e: FormEvent) => {
    if (!onSubmit) {
      e.preventDefault();

      return;
    }

    form.handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!innerRef) return;
    innerRef.current = form;
  }, [form]);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleFormSubmit} className={className} data-testid={dataHook}>
          {children}
        </form>
      </FormProvider>
      {/* @ts-ignore */}
      {withDevTools && <DevTool control={form.control} />}
    </>
  );
};

export default Form;

import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Button } from '@macpaw/macpaw-ui';
import { Loadable, useModals } from '~/ui';
import OrganizationContacts from '../../components/OrganizationContacts/OrganizationContacts';
import OrganizationPaymentLinkButton from '../../components/OrganizationPaymentLinkButton/OrganizationPaymentLinkButton';
import OrganizationProfile from '../../components/OrganizationProfile/OrganizationProfile';
import PlansByOwner from '../../components/PlansByOwner/PlansByOwner';
import SearchableContainer from '../../components/Search/SearchableContainer';
import Section from '../../components/Section/Section';
import WithErrorBoundary from '../../components/WithErrorBoundary/WithErrorBoundary';
import { Modals, ROUTE_PATHS } from '../../constants';
import { useOrganizationDetailsQuery } from '../../graphql/queries';
import { PlanInterface } from '../../interfaces';

const Organization: React.FC = () => {
  const { organizationId } = useParams<Record<'organizationId', string>>();
  const { loading, organization, owner } = useOrganizationDetailsQuery({ organizationId });
  const { openModal } = useModals();

  const groupOrganizationPlans = (plans: PlanInterface[]) => ({ common: plans });

  const handleOpenModal = (modalName: Modals) => () => {
    openModal({
      name: modalName,
      props: {
        organizationId,
        name: organization?.name,
      },
    });
  };

  const renderAction = () => (
    <>
      <OrganizationPaymentLinkButton loading={loading} organization={organization} />
      <Button
        scale="medium"
        color="secondary"
        onClick={handleOpenModal(Modals.SetCustomPlanPriceModal)}
        disabled={loading}>
        Custom Price Settings
      </Button>
    </>
  );

  if (!organizationId) return <Navigate to="/404" />;

  return (
    <SearchableContainer>
      <WithErrorBoundary>
        <Loadable isLoading={loading}>
          <Section title={<h2>Organization</h2>} action={renderAction()}>
            <OrganizationProfile organization={organization} owner={owner} />
          </Section>
          <Section title={<h3>Contacts</h3>}>
            <OrganizationContacts organization={organization} />
          </Section>
          <PlansByOwner
            ownerId={organizationId}
            planPath={ROUTE_PATHS.organizationPlanPage}
            planGrouper={groupOrganizationPlans}
          />
        </Loadable>
      </WithErrorBoundary>
    </SearchableContainer>
  );
};

export default Organization;

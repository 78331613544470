import { ApolloClient, makeVar, NormalizedCacheObject } from '@apollo/client';

export const tokenVar = makeVar<string | null>(null);
export const userIdVar = makeVar<string | null>(null);
export const errorVar = makeVar<Error | null>(null);

export const resetLocalStore = (client: ApolloClient<NormalizedCacheObject>) =>
  client.clearStore().then(() => {
    tokenVar(null);
    userIdVar(null);
  });

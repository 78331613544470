import { ConfirmationType } from '~/types';
import { ConfirmationModal } from '~/ui';
import styles from '../GiveOrganizationBetaPlanModal.module.sass';

interface GiveOrganizationBetaPlanErrorProps {
  onSubmit: () => void;
}

const GiveOrganizationBetaPlanError: React.FC<GiveOrganizationBetaPlanErrorProps> = ({ onSubmit }) => (
  <ConfirmationModal
    title="This Organization already has a Beta plan"
    description="We couldn’t create this plan because an Organization can be given a Beta plan only once."
    actionTitle="Got It"
    descriptionClassName={styles.description}
    type={ConfirmationType.Warning}
    onSubmit={onSubmit}
  />
);

export default GiveOrganizationBetaPlanError;

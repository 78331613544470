import { BreadcrumbsConfigItem } from '~/types';
import { CUSTOMERS_PLANS } from './pageConstants';
import { ROUTE_PATHS } from './routingConstants';

export const BREADCRUMBS_CONFIG: BreadcrumbsConfigItem[] = [
  {
    path: ROUTE_PATHS.home(),
    crumbNode: () => 'Home',
    children: [
      {
        path: ROUTE_PATHS.settings(),
        crumbNode: () => 'Settings',
      },
      {
        path: ROUTE_PATHS.members(),
        crumbNode: () => 'Team Members',
      },
      {
        path: ROUTE_PATHS.customerPage(),
        crumbNode: () => CUSTOMERS_PLANS,
        children: [
          {
            path: ROUTE_PATHS.customerPlanPage(),
            crumbNode: () => 'Plan',
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_PATHS.organizations(),
    crumbNode: () => 'Home',
    children: [
      {
        path: ROUTE_PATHS.organizationPage(),
        crumbNode: () => 'Organization',
        children: [
          {
            path: ROUTE_PATHS.organizationPlanPage(),
            crumbNode: () => "Organization's Plan",
          },
        ],
      },
    ],
  },
];
